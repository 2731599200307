import { useMemo } from 'react';
import { IconButton } from '@chakra-ui/react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Box } from '../../UI/Box';
import { Flex } from '../../UI/Flex';

import { ReactComponent as ArrowLeft } from 'src/assets/icons/arrow-left.svg';
import { ReactComponent as Logo } from 'src/assets/images/logo.svg';

import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { BottomTabNavigation } from '../BottomTabNavigation/BottomTabNavigation';
import { HEADER_HEIGHT } from '../Header';
import { FullLayoutProps } from './FullLayout';
import { Spinner } from 'src/components/UI/Spinner';
import { BottomTabNavigationBackdrop } from '../BottomTabNavigation/BottomTabNavigationBackdrop';

const HEADER_PADDING_TOP = 20;

const FullLayoutMobile = (props: FullLayoutProps) => {
  const { loadingPrescription, bottomBarHeight, isUserOnApp } = usePrescriberContext();

  const navigate = useNavigate();

  const containerMaxHeight = useMemo(() => {
    if (isUserOnApp) return window.innerHeight - bottomBarHeight - HEADER_PADDING_TOP;

    return window.innerHeight - HEADER_HEIGHT - bottomBarHeight;
  }, [bottomBarHeight, isUserOnApp]);

  const Header = useMemo(() => {
    if (isUserOnApp) {
      return <Box w={'100%'} h={`${HEADER_PADDING_TOP}px`}></Box>;
    }
    return (
      <Flex w={'100%'} h={'50px'} justifyContent={'flex-end'} p={'0 15px'} alignItems={'center'}>
        <Box w={'100%'}>
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Flex w={'105px'} justifyContent={'space-between'} h={'100%'} alignItems={'center'}>
              {props.showGoBack && (
                <IconButton
                  onClick={() => navigate(-1)}
                  aria-label="back"
                  size={'sm'}
                  borderRadius={'50%'}
                  variant={'ghost'}
                  mr={'5px'}
                  data-testId={'go-back-button'}
                >
                  <ArrowLeft />
                </IconButton>
              )}

              <Logo height={'20px'} fill={'#3D0E6B'} />
            </Flex>
          </Flex>
        </Box>
      </Flex>
    );
  }, [isUserOnApp, navigate, props.showGoBack]);

  return (
    <Flex justifyContent={'space-between'}>
      {loadingPrescription && (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          bg={'background'}
          zIndex={3}
          top={0}
          right={0}
          bottom={0}
          left={0}
          position={'absolute'}
          opacity={'0.5'}
        >
          <Spinner size={'xl'} color={'primary.1000'}></Spinner>
        </Box>
      )}

      <Box w={'100%'} maxW={'100%'} h={window.innerHeight}>
        {Header}
        <Box p={'0 20px'} h={'100%'} maxH={containerMaxHeight} overflowX={'auto'} id={'mobile-layout-body'}>
          <Outlet />
        </Box>
        <BottomTabNavigationBackdrop />
        <BottomTabNavigation />
      </Box>
    </Flex>
  );
};

export default FullLayoutMobile;
