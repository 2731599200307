import { SimpleGrid } from '@chakra-ui/react';

import { Flex } from 'src/components/UI/Flex';
import { Skeleton } from 'src/components/UI/Skeleton';

export function AttendancePlaceFormSkeleton() {
  return (
    <Flex
      flexDirection={'column'}
      gap={'20px'}
      bgColor={{
        base: 'transparent',
        xl: 'white',
      }}
      borderRadius={'20px'}
      p={{ base: '0px', sm: '32px' }}
      h={'full'}
    >
      <Skeleton h={'30px'} w={'30%'} />

      <Skeleton h={'160px'} borderRadius={'xl'} />

      <Flex flexDirection={'column'} gap={'10px'}>
        <Skeleton h={'10px'} w={'120px'} />

        <Skeleton h={'50px'} borderRadius={'lg'} />
      </Flex>

      <Flex flexDirection={'column'} gap={'10px'}>
        <Skeleton h={'10px'} w={'120px'} />

        <Skeleton h={'50px'} borderRadius={'lg'} />
      </Flex>

      <Flex flexDirection={'column'} gap={'10px'}>
        <Skeleton h={'10px'} w={'120px'} />

        <Skeleton h={'50px'} borderRadius={'lg'} />
      </Flex>

      <Flex
        gap={{
          base: '10px',
          sm: '0',
          md: '20px',
        }}
        alignItems={{
          base: 'center',
          sm: 'flex-start',
          md: 'center',
        }}
        flexDirection={{
          base: 'row',
          sm: 'column',
          md: 'row',
        }}
      >
        <Flex
          w={{
            base: '60%',
            sm: '100%',
            md: '30%',
          }}
          flexDirection={'column'}
          gap={'10px'}
        >
          <Skeleton h={'10px'} w={'60px'} />

          <Skeleton h={'50px'} borderRadius={'lg'} />
        </Flex>

        <Skeleton
          h={'16px'}
          w={'100px'}
          mt={{
            base: '20px',
            sm: '10px',
            md: '20px',
          }}
        />
      </Flex>

      <Flex
        flexDirection={{
          base: 'row',
          sm: 'column',
          xl: 'row',
        }}
        gap={{
          base: '10px',
          lg: '20px',
        }}
      >
        <Flex flexDirection={'column'} gap={'10px'} flex={1}>
          <Skeleton h={'10px'} w={'120px'} />

          <Skeleton h={'50px'} borderRadius={'lg'} />
        </Flex>

        <Flex flexDirection={'column'} gap={'10px'} minW={'110px'}>
          <Skeleton h={'10px'} w={'40px'} />

          <Skeleton h={'50px'} borderRadius={'lg'} />
        </Flex>
      </Flex>

      <Flex flexDirection={'column'} gap={'10px'}>
        <Skeleton h={'10px'} w={'120px'} />

        <Skeleton h={'50px'} borderRadius={'lg'} />
      </Flex>

      <Flex flexDirection={'column'} gap={'10px'}>
        <Skeleton h={'10px'} w={'120px'} />

        <Skeleton h={'50px'} borderRadius={'lg'} />
      </Flex>

      <SimpleGrid
        columns={{
          base: 1,
          lg: 2,
        }}
        gap={'20px'}
      >
        <Flex flexDirection={'column'} gap={'10px'}>
          <Skeleton h={'10px'} w={'120px'} />

          <Skeleton h={'50px'} borderRadius={'lg'} />
        </Flex>

        <Flex flexDirection={'column'} gap={'10px'}>
          <Skeleton h={'10px'} w={'120px'} />

          <Skeleton h={'50px'} borderRadius={'lg'} />
        </Flex>
      </SimpleGrid>

      <Skeleton
        w={{
          base: 'full',
          sm: '180px',
        }}
        h={'60px'}
        alignSelf={'flex-end'}
        mt={'auto'}
        borderRadius={'full'}
      />
    </Flex>
  );
}
