import React from 'react';
import {
  Accordion as ChakraAccordion,
  AccordionProps as ChakraAccordionProps,
  AccordionItem as ChakraAccordionItem,
  AccordionItemProps as ChakraAccordionItemProps,
  AccordionButton as ChakraAccordionButton,
  AccordionButtonProps as ChakraAccordionButtonProps,
  AccordionIcon as ChakraAccordionIcon,
  AccordionPanel as ChakraAccordionPanel,
  AccordionPanelProps as ChakraAccordionPanelProps,
  IconProps,
} from '@chakra-ui/react';

export function Accordion(props: ChakraAccordionProps) {
  return <ChakraAccordion {...props} />;
}

export function AccordionItem(props: ChakraAccordionItemProps) {
  return <ChakraAccordionItem {...props} />;
}

export function AccordionButton(props: ChakraAccordionButtonProps) {
  return <ChakraAccordionButton {...props} />;
}

export function AccordionIcon(props: IconProps) {
  return <ChakraAccordionIcon {...props} />;
}

export function AccordionPanel(props: ChakraAccordionPanelProps) {
  return <ChakraAccordionPanel {...props} />;
}
