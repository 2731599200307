import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser';

export interface People {
  set(prop: string, to: any, callback?: Callback): void;
  set_once(prop: string, to: any, callback?: Callback): void;
}

export type MixPanelEmitter = {
  track(
    event_name: string,
    properties?: Dict,
    optionsOrCallback?: RequestOptions | Callback,
    callback?: Callback
  ): void;
  people: People;
  identify(unique_id?: string): any;
  register(props: Dict, days?: number): void;
  get_distinct_id(): any;
};

export class MixPanelFactory {
  static create(): MixPanelEmitter {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '');

    return mixpanel;
  }
}
