import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import { BUTTON_VARIANTS } from 'src/theme/theme';

export function Button(props: Omit<ChakraButtonProps, 'variant'> & { variant?: BUTTON_VARIANTS }) {
  return (
    <ChakraButton
      _focus={{
        boxShadow: 0,
      }}
      _active={{
        backgroundColor: props.backgroundColor || props.bg,
      }}
      letterSpacing={'0.25px'}
      {...props}
    />
  );
}
