import InputMask from 'react-input-mask';
import { Input as ChakraInput, InputProps as ChakraInputProps } from '@chakra-ui/react';

export type MASKS_TYPES =
  | 'CPF'
  | 'CNS'
  | 'CNPJ'
  | 'BRAZIL_PHONE'
  | 'BRAZIL_LANDLINE_PHONE'
  | 'BRAZIL_POSTAL_CODE'
  | ' BRAZIL_LANDLINE_PHONE_NINE_DIGITS';

export const MASKS = {
  CPF: '999.999.999-99',
  CNS: '999.9999.9999.9999',
  CNPJ: '99.999.999/9999-99',
  BRAZIL_PHONE: '(99) 9 9999-9999',
  BRAZIL_LANDLINE_PHONE: '(99) 9999-9999',
  BRAZIL_LANDLINE_PHONE_NINE_DIGITS: '(99) 9 9999-9999',
  BRAZIL_POSTAL_CODE: '99999-999',
  BIRTH_DATE: '99/99/9999',
  ONLY: '99/99/9999',
  NUMBER_THREE_DIGITS: '999',
};

interface MaskedInputProps extends ChakraInputProps {
  mask: string | MASKS_TYPES;
  maskChar?: string;
  maskPlaceholder?: string;
}

export function MaskedInput(props: MaskedInputProps) {
  return <ChakraInput as={InputMask} autoComplete={'chrome-off'} {...props} />;
}
