import { useNavigate } from 'react-router-dom';

import { IconButton } from 'src/components/UI/IconButton';

import { ReactComponent as Logo } from 'src/assets/images/logo.svg';
import { ReactComponent as ArrowLeft } from 'src/assets/icons/arrow-left.svg';
import { ReactComponent as Help } from 'src/assets/icons/help.svg';

import { Flex } from '../UI/Flex';

interface HeaderProps {
  onGoBack?(): void;
}

export const HEADER_HEIGHT = 50;

export function Header({ onGoBack }: HeaderProps) {
  const navigate = useNavigate();

  return (
    <Flex
      bg={'background'}
      p={{
        base: '31px 0px',
        sm: '31px 15px',
      }}
      w={'100%'}
      h={HEADER_HEIGHT}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Flex w={'105px'} justifyContent={'space-between'} h={'100%'} alignItems={'center'}>
        <IconButton
          onClick={onGoBack}
          aria-label="back"
          size={'sm'}
          borderRadius={'50%'}
          variant={'ghost'}
          mr={'5px'}
          data-testId={'go-back-button'}
        >
          <ArrowLeft />
        </IconButton>
        <Logo height={'20px'} fill={'#3D0E6B'} />
      </Flex>
      <IconButton aria-label="help" onClick={() => navigate(`/ajuda`)} variant={'ghost'}>
        <Help />
      </IconButton>
    </Flex>
  );
}
