import React from 'react';
import { Box } from './UI/Box';
import Typography from './Typography';
import { Flex } from './UI/Flex';
import { Button } from './UI/Button';
import MevoPill from './Pill/Pill';

interface ConfirmDeleteDialogProps {
  text: string;
  onConfirm(): void;
  onCancel(): void;
}

export function ConfirmDeleteDialog(props: ConfirmDeleteDialogProps) {
  return (
    <Box bg={'surface'}>
      <Flex justifyContent={'center'} mb={'30px'} mt={'10px'}>
        <MevoPill pillColor="red" faceStyle="crazy" pillWidth={100} faceWidth={37} />
      </Flex>
      <Box mb={'10px'}>
        <Typography model="h2" weight="bold">
          Deseja realmente excluir?
        </Typography>
      </Box>
      <Box mb={'20px'}>
        <Typography model="body" size="large" weight="regular">
          {props.text}
        </Typography>
      </Box>
      <Flex justifyContent={'space-between'}>
        <Button onClick={props.onCancel} w={'100%'} h={'60px'} mr={'10px'}>
          Cancelar
        </Button>
        <Button onClick={props.onConfirm} variant={'outline-error'} w={'100%'} h={'60px'}>
          Excluir
        </Button>
      </Flex>
    </Box>
  );
}
