import { useCallback } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { VStack } from '@chakra-ui/react';

import MevoInput from 'src/components/Input';
import Typography from 'src/components/Typography';
import { Button } from 'src/components/UI/Button';
import { Flex } from 'src/components/UI/Flex';
import { FormControl } from 'src/components/UI/FormControl';
import { ProtectedPassword } from 'src/components/UI/Input/ProtectedPassword';

import { Yup } from 'src/helpers/Yup';

import { useFeedbackContext } from 'src/providers/FeedbackProvider';

import { AuthService } from 'src/services/AuthService';
import { Box } from 'src/components/UI/Box';

interface ChangePasswordFormFields {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  isPasswordProtected: boolean;
}

const initialValues: ChangePasswordFormFields = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
  isPasswordProtected: false,
};

const changePasswordFormFormSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Informe a senha atual.'),
  newPassword: Yup.string()
    .required('Informe uma senha válida.')
    .min(8, 'Sua senha deve ter no mínimo 8 caracteres')
    .max(16, 'Sua senha deve ter no máximo 16 caracteres')
    .test('validate password', 'Senha não atende os padrões de segurança.', (password, context): boolean => {
      return context.parent.isPasswordProtected;
    }),
  confirmNewPassword: Yup.string()
    .required('Informe uma senha de confirmação válida.')
    .min(8, 'Sua senha deve ter no mínimo 8 caracteres')
    .max(16, 'Sua senha deve ter no máximo 16 caracteres')
    .oneOf([Yup.ref('newPassword'), ''], 'A senha e senha de confirmação devem ser iguais.'),
  isPasswordProtected: Yup.bool().required(),
});

interface ChangePasswordFormProps {
  onEdit?(): void;
}

export function ChangePasswordForm(props: ChangePasswordFormProps) {
  const { openToast } = useFeedbackContext();

  const navigate = useNavigate();

  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    setFieldValue,
    setFieldTouched,
    validateField,
    handleSubmit,
    resetForm,
  } = useFormik<ChangePasswordFormFields>({
    initialValues: initialValues,
    validationSchema: changePasswordFormFormSchema,
    validateOnChange: false,
    onSubmit: onSubmit,
  });

  async function onSubmit(data: ChangePasswordFormFields) {
    try {
      await AuthService.changePassword(data.currentPassword, data.newPassword);

      resetForm();

      openToast('Dados salvos com sucesso.', {
        type: 'success',
        duration: 2000,
      });

      if (props.onEdit) {
        props.onEdit();
      }
    } catch (error: any) {
      if (error.name === 'NotAuthorizedException') {
        return openToast('Senha informada não corresponde à senha atual. Não foi possível alterar sua senha.', {
          type: 'error',
          duration: 2000,
        });
      }

      openToast('Ocorreu um erro ao salvar seus dados. Tente novamente em instantes.', {
        type: 'error',
        duration: 2000,
      });
    }
  }

  const updatePasswordFormValidation = useCallback(
    async (value: boolean) => {
      await setFieldTouched('newPassword', false, false);
      await setFieldValue('isPasswordProtected', value);
      await validateField('newPassword');
    },
    [setFieldValue, validateField, setFieldTouched]
  );

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        height: '100%',
      }}
      data-testId="change-password-form"
    >
      <Flex
        flexDirection={'column'}
        gap={'20px'}
        bgColor={{
          base: 'transparent',
          lg: 'white',
        }}
        borderRadius={'20px'}
        p={{ base: '0px', sm: '32px' }}
        h={'full'}
      >
        <Box display={{ base: 'none', sm: 'block' }}>
          <Typography model={'h2'} size={'large'} weight={'bold'}>
            Senha de acesso
          </Typography>
        </Box>

        <VStack alignItems={'flex-end'} spacing={0}>
          <FormControl isInvalid={!!errors.currentPassword}>
            <MevoInput
              name={'currentPassword'}
              labelText={'senha atual'}
              placeholder={'Digite sua senha atual'}
              type={'password'}
              value={values.currentPassword}
              errorText={errors.currentPassword}
              onChange={handleChange}
              onBlur={() => validateField('currentPassword')}
              m={0}
              autoFocus
            />
          </FormControl>

          <Button variant={'ghost'} onClick={() => navigate('/esqueceu-sua-senha')}>
            <Typography model={'body'} size={'large'} weight={'bold'} underline>
              Esqueceu sua senha?
            </Typography>
          </Button>
        </VStack>

        <VStack gap={'8px'}>
          <ProtectedPassword
            name={'newPassword'}
            label={'Nova senha'}
            errorMessage={errors.newPassword || errors.isPasswordProtected}
            isInvalid={!!errors.newPassword || !!errors.isPasswordProtected}
            placeholder={'Digite a nova senha'}
            value={values.newPassword}
            onChange={handleChange}
            showPasswordProtectionAttributes={true}
            onProtected={updatePasswordFormValidation}
            onBlur={() => validateField('newPassword')}
          />
        </VStack>

        <ProtectedPassword
          name={'confirmNewPassword'}
          label={'Confirmar senha'}
          errorMessage={errors.confirmNewPassword}
          isInvalid={!!errors.confirmNewPassword}
          placeholder={'Digite a senha de confirmação'}
          value={values.confirmNewPassword}
          onChange={handleChange}
          onBlur={() => validateField('confirmNewPassword')}
        />

        <Button
          type="submit"
          isLoading={isSubmitting}
          w={{
            base: 'full',
            md: '150px',
          }}
          h={'60px'}
          alignSelf={'flex-end'}
          mt={'auto'}
        >
          Salvar
        </Button>
      </Flex>
    </form>
  );
}
