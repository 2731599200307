import Typography from 'src/components/Typography';
import { Flex } from 'src/components/UI/Flex';

export function EmptyView() {
  return (
    <Flex
      justifyContent={'center'}
      gap={'10px'}
      direction={'column'}
      p={{ base: '10%', md: '20%' }}
      w={'100%'}
      h={'100%'}
    >
      <Typography model="h1" weight="bold" textAlign="center">
        Parece que não há prescrições por aqui.
      </Typography>
      <Typography model="body" size="large" weight="regular" textAlign="center">
        Não encontramos resultados para a sua busca. Emita uma prescrição ou refaça sua busca.
      </Typography>
    </Flex>
  );
}
