import { ReactComponent as RedStripe } from 'src/assets/icons/mevo/medicine//tarjavermelha.svg';
import { ReactComponent as RedStripeGeneric } from 'src/assets/icons/mevo/medicine//tarjavermelha_generico.svg';
import { ReactComponent as BlueStripe } from 'src/assets/icons/mevo/medicine//tarjaazul.svg';
import { ReactComponent as BlueStripeGeneric } from 'src/assets/icons/mevo/medicine//tarjaazul_generico.svg';
import { ReactComponent as BlackStripe } from 'src/assets/icons/mevo/medicine//tarjapreta.svg';
import { ReactComponent as BlackStripeGeneric } from 'src/assets/icons/mevo/medicine//tarjapreta_generico.svg';
import { ReactComponent as ProductStripe } from 'src/assets/icons/mevo/medicine//product.svg';
import { PrescriptionMedicine } from 'src/services/prescriptionHistory';

interface MedicineStripeProps {
  medicine: PrescriptionMedicine | null;
  isManual?: boolean;
}

export const getIcon = (medicine: PrescriptionMedicine | null, isManual?: boolean): Icon | null => {
  if (!medicine && isManual)
    return {
      altText: 'Ícone medicamento manual',
      image: BlackStripe,
    };

  if (!medicine) return null;

  const isGeneric = medicine.medicineType === 'GENERICO';

  if (medicine.medicineType === 'CORRELATO') {
    return {
      altText: 'Ícone produto correlato',
      image: ProductStripe,
    };
  }

  if (['1', '2', '3', '4', '5'].includes(`${medicine.ordinance344Id}` || '0')) {
    if (isGeneric) {
      return {
        altText: 'Ícone tarja preta genérico',
        image: BlackStripeGeneric,
      };
    }

    return {
      altText: 'Ícone tarja preta',
      image: BlackStripe,
    };
  }

  if (medicine.twoWayPrinting || medicine.antimicrobial) {
    if (isGeneric) {
      return {
        altText: 'Ícone tarja vermelha genérico',
        image: RedStripeGeneric,
      };
    }

    return {
      altText: 'Ícone tarja vermelha',
      image: RedStripe,
    };
  }

  if (medicine.MIP === true) {
    if (isGeneric) {
      return {
        altText: 'Ícone tarja azul genérico',
        image: BlueStripeGeneric,
      };
    }

    return {
      altText: 'Ícone tarja azul',
      image: BlueStripe,
    };
  }

  if (isGeneric) {
    return {
      altText: 'Ícone tarja vermelha genérico',
      image: RedStripeGeneric,
    };
  }

  return {
    altText: 'Ícone tarja vermelha',
    image: RedStripe,
  };
};

export default function MedicineStripe(props: MedicineStripeProps) {
  const icon = getIcon(props.medicine, props.isManual);

  if (!icon) return null;

  const ImageComponent = icon.image;

  return <ImageComponent alt={icon.altText} title={icon.altText} />;
}

interface Icon {
  altText: string;
  image: any;
}
