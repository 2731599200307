import styled from 'styled-components';

interface StyledPillProps {
  pillWidth: number | undefined;
}

interface StyledFaceProps {
  faceWidth: number | undefined;
  parsedPadding: string;
  parsedFacePosition: string;
}

export const PillContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  width: fit-content;
  height: fit-content;
`;

export const StyledPill = styled.div<StyledPillProps>`
  & > svg {
    width: ${(props) => props.pillWidth || 50}px;
    height: auto;
  }
`;

export const StyledFace = styled.div<StyledFaceProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: ${(props) => props.parsedFacePosition};
  ${(props) => props.parsedPadding};
  height: 100%;
  & > svg {
    width: ${(props) => props.faceWidth || 21}px;
    height: auto;
  }
`;
