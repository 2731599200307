import Typography from 'src/components/Typography';
import { Box } from 'src/components/UI/Box';
import { Search } from './Search';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DialogWrappedComponentProps, useFeedbackContext } from 'src/providers/FeedbackProvider';
import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { DOCUMENT_TYPE } from 'src/services/prescriber';
import { HistoryList } from './HistoryList';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  FetchPrescriptionHistoryParams,
  ORDER_BY,
  ORDER_TYPES,
  PRESCRIPTION_STATUS,
  PrescriptionHistory,
  PrescriptionHistoryService,
} from 'src/services/prescriptionHistory';
import { DateTime } from 'luxon';
import { CancelPrescriptionDialog } from './CancelPrescriptionDialog';
import { AnimatedRoute } from 'src/router/AnimatedRoute';

export enum RETROATIVE_DAYS {
  SEVEN_DAYS = 7,
  FIFTEEN_DAYS = 15,
  THIRTY_DAYS = 30,
}

export interface FormFilters {
  documentType: DOCUMENT_TYPE[];
  retroativeDays: RETROATIVE_DAYS | null;
  prescriptionStatus: PRESCRIPTION_STATUS[];
  initialDate: string;
  endDate: string;
  orderType: ORDER_TYPES;
  orderBy: ORDER_BY;
}

interface SearchForm {
  searchString: string;
  filters: FormFilters;
}

const ITEM_HEIGHT = 58;

export function History() {
  const { openToast, openBaseDialog } = useFeedbackContext();
  const { prescriberId } = usePrescriberContext();

  const [isLoading, setIsLoading] = useState(true);

  const [patientHistory, setPatientHistory] = useState<PrescriptionHistory[]>([]);

  const handleInitialDateFormat = (date: string, retroativeDays: RETROATIVE_DAYS | null) => {
    if (!date && !retroativeDays) return null;

    if (!date && retroativeDays) {
      return DateTime.fromJSDate(new Date()).minus({ days: retroativeDays }).toFormat('MM/dd/yyyy');
    }

    return DateTime.fromFormat(date, 'dd/MM/yyyy')
      .minus({ days: retroativeDays || 0 })
      .toFormat('MM/dd/yyyy');
  };

  const searchHistory = useCallback(
    async (searchParams: FetchPrescriptionHistoryParams) => {
      try {
        if (!prescriberId) return [];

        setIsLoading(true);

        const { prescriptions } = await PrescriptionHistoryService.fetchPrescriptionHistory(prescriberId, searchParams);

        setPatientHistory(prescriptions);
      } catch (error) {
        openToast('Erro ao buscar pacientes. Tente novamente em instantes.', { type: 'error', duration: 2000 });
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [prescriberId, openToast]
  );

  const nonUtilSpaceToRender = (40 / 100) * window.innerHeight;
  const itemsToRender = Math.floor((window.innerHeight - nonUtilSpaceToRender) / ITEM_HEIGHT);

  const formik = useFormik<SearchForm>({
    initialValues: {
      searchString: '',
      filters: {
        documentType: [],
        retroativeDays: null,
        prescriptionStatus: [],
        initialDate: '',
        endDate: '',
        orderBy: ORDER_BY.DATE,
        orderType: ORDER_TYPES.DESC,
      },
    },
    validateOnChange: false,
    async onSubmit({ searchString, filters }) {
      const initialDate = handleInitialDateFormat(filters.initialDate, filters.retroativeDays);
      const endDate = filters.endDate
        ? DateTime.fromFormat(filters.endDate, 'dd/MM/yyyy').toFormat('MM/dd/yyyy')
        : DateTime.fromJSDate(new Date()).toFormat('MM/dd/yyyy');

      const payload = {
        limit: itemsToRender,
        page: 1,
        orderType: filters.orderType,
        orderBy: filters.orderBy,
        ...(filters.documentType && { documentType: filters.documentType }),
        ...(filters.prescriptionStatus && { prescriptionStatus: filters.prescriptionStatus }),
        ...(initialDate && { initialDate: initialDate }),
        ...(endDate && { endDate: endDate }),
        ...(searchString && { query: searchString }),
      };

      await searchHistory(payload);
    },
  });

  const navigate = useNavigate();

  const onViewDetails = (data: PrescriptionHistory) => {
    navigate(
      { pathname: `/inicio/historico/${data._id}` },
      {
        state: {
          prescription: data,
        },
      }
    );
    return;
  };

  const onCancelPrescription = async (prescriptionId: number) => {
    try {
      await PrescriptionHistoryService.cancelPrescriptionHistory(prescriptionId);

      setPatientHistory((prev) => {
        const updatedState = prev.map((history) => {
          if (history.prescriptionId === prescriptionId) {
            return { ...history, prescriptionStatus: PRESCRIPTION_STATUS.CANCELED };
          }

          return history;
        });

        return updatedState;
      });
    } catch (error) {
      openToast('Erro ao cancelar prescrição. Tente novamente em instantes.', { type: 'error', duration: 2000 });
    }
  };

  const handleCancelPrescription = async (validationCode: string, prescriptionId: number) => {
    await openBaseDialog(
      (dialogProps: DialogWrappedComponentProps) => (
        <CancelPrescriptionDialog
          validationCode={validationCode}
          onCancel={async () => {
            await onCancelPrescription(prescriptionId);
            dialogProps.onClose();
          }}
          onGoBack={dialogProps.onClose}
        />
      ),
      {
        width: '270px',
        height: '340px',
      }
    );
  };

  const debouncedSubmit = useMemo(() => debounce(formik.handleSubmit, 350), [formik.handleSubmit]);

  useEffect(() => {
    debouncedSubmit();
  }, [formik.values.searchString, formik.values.filters, debouncedSubmit]);

  return (
    <AnimatedRoute>
      <Box mb={'10px'}>
        <Typography model="h1" weight="bold">
          Histórico de receitas
        </Typography>
      </Box>
      <Typography model="body" size={'large'} weight="regular">
        Verifique e consulte as suas receitas.
      </Typography>

      <Search
        isLoading={isLoading}
        searchString={formik.values.searchString}
        clearSearch={() => {
          formik.setFieldValue('searchString', '');
        }}
        clearFilters={() =>
          formik.setFieldValue('filters', {
            documentType: [],
            retroativeDays: null,
            prescriptionStatus: [],
            initialDate: null,
            endDate: null,
          })
        }
        filters={formik.values.filters}
        onChangeFilters={(newFilters) => formik.setFieldValue('filters', newFilters)}
        onChangeSearchString={(newSearchString) => formik.setFieldValue('searchString', newSearchString)}
      />

      <HistoryList
        onChangeOrderBy={(newOrder) => formik.setFieldValue('filters.orderBy', newOrder)}
        onChangeOrderType={(newOrderType) => formik.setFieldValue('filters.orderType', newOrderType)}
        patientHistory={patientHistory}
        onViewDetails={onViewDetails}
        orderBy={formik.values.filters.orderBy}
        orderType={formik.values.filters.orderType}
        onCancelPrescription={handleCancelPrescription}
        isLoading={isLoading}
      />
    </AnimatedRoute>
  );
}
