import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from 'src/components/UI/Box';
import Typography from 'src/components/Typography';
import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { useIsMobile } from 'src/helpers/useIsMobile';
import { DialogWrappedComponentProps, useFeedbackContext } from 'src/providers/FeedbackProvider';
import { ConfirmDeleteDialog } from 'src/components/ConfirmDeleteDialog';
import { Patient, PatientService } from 'src/services/patient';
import { BasicAbstractBackground } from 'src/components/BasicAbstractLayout';
import { Tracker } from 'src/shared/tracker/Tracker';
import { AnimatePresence, motion } from 'framer-motion';
import { PatientSearchList } from 'src/components/PatientSearchList';

export function ManagePatients() {
  const {
    prescriberId,
    startPrescrition,
    clickOnFirstPatientFromList,
    setPatients,
    patients,
    setUserDoesNotHaveAnyPatients,
  } = usePrescriberContext();

  const [shouldHideHeader, setShouldHideHeader] = useState(false);

  const { openBaseDialog, openToast } = useFeedbackContext();

  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const onDeletePatient = useCallback(
    async (patientId: string) => {
      try {
        if (!prescriberId) return;
        await PatientService.deletePatient(prescriberId, patientId);

        if (!patients) return;

        const updatedPatients = patients.filter((patient: Patient) => patient._id !== patientId);

        if (updatedPatients.length === 0) {
          setUserDoesNotHaveAnyPatients(true);
        }

        setPatients(updatedPatients);
      } catch (error) {
        openToast('Erro ao excluir paciente. Tente novamente em instantes.', { type: 'error', duration: 2000 });
      }
    },
    [prescriberId, patients, setPatients, setUserDoesNotHaveAnyPatients, openToast]
  );

  const handleDeletePatient = useCallback(
    async (patientId: string) => {
      await openBaseDialog(
        (dialogProps: DialogWrappedComponentProps) => (
          <Box width={'100%'} h={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <ConfirmDeleteDialog
              text="Ao escolher excluir, o cadastro deste paciente irá desaparecer permanentemente da sua base de pacientes."
              onCancel={dialogProps.onClose}
              onConfirm={async () => {
                await onDeletePatient(patientId);
                dialogProps.onClose();
              }}
            />
          </Box>
        ),
        {
          width: '270px',
          height: '356px',
        }
      );
    },
    [onDeletePatient, openBaseDialog]
  );

  const onEditPatient = useCallback(
    (patientId: string) => {
      navigate(`/inicio/editar-paciente/${patientId}`);
    },
    [navigate]
  );

  const onInitPrescription = useCallback(
    async (patientId: string) => {
      await startPrescrition(patientId);
    },
    [startPrescrition]
  );

  const handleCreatePatient = () => {
    Tracker.trackEvent({
      event: 'Cadastrar Paciente',
      parameters: {
        data: new Date(),
      },
    });

    navigate('/inicio/cadastro-completo-paciente');
  };

  return (
    <BasicAbstractBackground>
      <AnimatePresence>
        {!shouldHideHeader && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
              height: 0,
            }}
            style={{ display: 'flex' }}
          >
            <Box mb={'20px'}>
              <Box mb={'10px'}>
                <Typography model="h1" weight="bold" textAlign={'left'}>
                  Meus pacientes
                </Typography>
              </Box>
              <Typography model="body" size={'large'} weight="regular" textAlign={'left'}>
                Gerencie e adicione novos pacientes para atendimento.
              </Typography>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
      <PatientSearchList
        onCreatePatient={handleCreatePatient}
        onFocus={() => isMobile && setShouldHideHeader(true)}
        onBlur={() => setShouldHideHeader(false)}
        showActionButtons={true}
        onDelete={handleDeletePatient}
        onEnterCallback={clickOnFirstPatientFromList}
        onClickCallback={async (patient) => {
          Tracker.trackEvent({
            event: 'Editar Paciente',
            parameters: {
              data: new Date(),
            },
          });

          onEditPatient(patient._id);
        }}
        onInitPrescription={onInitPrescription}
        onEdit={onEditPatient}
      />
    </BasicAbstractBackground>
  );
}
