import { useIsMobile } from 'src/helpers/useIsMobile';
import FullLayoutDesktop from './FullLayoutDesktop';
import FullLayoutMobile from './FullLayoutMobile';

export interface FullLayoutProps {
  showGoBack?: boolean;
}

const FullLayout = (props: FullLayoutProps) => {
  const isMobile = useIsMobile();

  return isMobile ? <FullLayoutMobile {...props} /> : <FullLayoutDesktop {...props} />;
};

export default FullLayout;
