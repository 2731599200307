export const openWhatsApp = (phoneNumber: string, message?: string) => {
  if (message) {
    const urlParams = new URLSearchParams();

    urlParams.set('text', message);

    window.open(`https://wa.me/+55${phoneNumber}${message ? `?${String(urlParams)}` : ''}`, '_blank', 'noopener');
    return;
  }

  window.open(`https://wa.me/+55${phoneNumber}`, '_blank', 'noopener');
};
