import { api } from 'src/api';
import { Patient } from './patient';
import { ORDER_TYPES } from './prescriptionHistory';

export const PrescriberService = {
  async createUser(payload: CreatePrescriberPayload): Promise<void> {
    const { data } = await api.post(`/prescriber`, payload);
    return data;
  },
  async getPrescriber(prescriberId: string): Promise<Prescriber> {
    const { data } = await api.get(`/prescriber/${prescriberId}`);
    return data.data;
  },
  async updatePrescriber(prescriberId: string, payload: Prescriber): Promise<void> {
    const { data } = await api.put(`/prescriber/${prescriberId}`, payload);
    return data;
  },

  async startPrescrition(prescriberId: string, patientId: string): Promise<{ modalUrl: string }> {
    const { data } = await api.post(`/prescriber/${prescriberId}/patient/${patientId}/startprescription`);
    return data.data;
  },
  async searchPatients(
    prescriberId: string,
    payload?: SearchParams
  ): Promise<{
    hasNextPage: boolean;
    data: { patients: Patient[] | null };
  }> {
    if (!payload) {
      const { data } = await api.get(`/prescriber/${prescriberId}/patient`);

      return data.data;
    }

    const params = {
      document: payload.document,
      fullName: payload.fullName,
      page: payload.page,
      limit: payload.limit,
      orderBy: payload.orderBy,
      orderType: payload.orderType,
    };

    const { data } = await api.get(`/prescriber/${prescriberId}/patient`, { params });

    return data;
  },
  async validateCrm(payload: ValidateCrmPayload): Promise<void> {
    await api.post('/prescriber/validate', payload);
  },
  async updatePrescriberRegisterDraft(payload: SavePrescriberRegisterDraft): Promise<void> {
    const { data } = await api.post(`/prescriber/registerDraft`, payload);
    return data;
  },
  async removeLogo(prescriberId: string): Promise<void> {
    await api.delete(`prescriber/${prescriberId}/delete-logo`);
  },
  async deletePrescriber(prescriberId: string, document: string): Promise<void> {
    const params = {
      prescriberId,
      document,
    };

    await api.delete(`prescriber`, { data: params });
  },
};

export interface Metadata {
  total: number;
  pages: number;
}

export enum ORDER_BY {
  CREATE_AT = 'CREATE_AT',
  PATIENT_NAME = 'PATIENT_NAME',
}
export interface SearchParams {
  document?: string;
  fullName?: string;
  page?: number;
  limit?: number;
  orderBy: ORDER_BY;
  orderType: ORDER_TYPES;
}

export interface AttendanceAddress {
  logoUrl?: string;
  placeName: string;
  postalCode: string;
  address: string;
  houseNumber: string;
  district: string;
  complement: string;
  city: string;
  state: string;
  phoneNumber: string;
  CNES?: string;
}

export interface ProfessionalRecord {
  number: string;
  state: string;
  issuer: string;
  RQE?: string;
}

export interface CreatePrescriberPayload {
  fullName: string;
  document: string;
  documentType: string;
  cellphoneNumber: string;
  email: string;
  password: string;
  acceptedNewsLetter: boolean;
  attendanceAddress: AttendanceAddress;
  professionalRecord: ProfessionalRecord;
  consentTerm: PrescriberTermsOfUse;
  birthDate: string;
  utm?: UtmInformation;
  createdThroughApp: boolean;
}

export interface Prescriber {
  _id: string;
  fullName: string;
  document: string;
  documentType: string;
  cellphoneNumber: string;
  email: string;
  birthDate: Date | string;
  sex: 'Masculino' | 'Feminino';
  CNS: string;
  professionalRecord: ProfessionalRecord;
  attendanceAddress: AttendanceAddress;
}
export interface ValidateCrmPayload {
  email?: string;
  number?: string | number;
  state?: string;
  issuer?: string;
  validations: {
    email: boolean;
    cfm: boolean;
  };
}

export interface PrescriberTermsOfUse {
  termUrl: string;
  accepted: boolean;
  version: string;
  termReleaseDate: string;
}

export interface UtmInformation {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
}
export interface SavePrescriberRegisterDraft {
  fullName: string;
  email: string;
  cellphoneNumber: string;
}

export enum DOCUMENT_TYPE {
  MEDICINE = 'medicines',
  EXAM = 'exams',
  MEDICAL_CERTIFICATE = 'prescriptionMedicalStatements',
  MEDICAL_REPORT = 'medicalReports',
  MEDICAL_REFERRAL = 'prescriptionReferral',
}
