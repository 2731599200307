import { sleep } from './sleep';

const useAsyncSetFieldValue = (fieldName: string) => {
  const asyncSetFieldValue = async (value: any, setFieldValue?: any) => {
    try {
      setFieldValue(fieldName, value);
      await sleep(1);
      return;
    } catch (error) {
      console.error('Erro durante operação assíncrona:', error);
    } finally {
    }
  };
  return [asyncSetFieldValue];
};
export default useAsyncSetFieldValue;
