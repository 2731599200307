import { Flex } from 'src/components/UI/Flex';
import { ReactComponent as MedicoExponencialLogo } from 'src/assets/images/partners/medico_exponencial.svg';
import { ReactComponent as EMSLogo } from 'src/assets/images/partners/ems.svg';
import Typography from 'src/components/Typography';

export function EMSSignupBanner() {
  return (
    <Flex
      flexDir={{ base: 'column', md: 'row' }}
      alignItems={'center'}
      justifyContent={'center'}
      w={{ base: '100%', sm: '450px' }}
      gap={'10px'}
      p={'20px'}
      border={'1px solid'}
      borderColor={'primary.50'}
      borderRadius={'20px'}
      textAlign={{ base: 'center', md: 'left' }}
    >
      <Flex gap={'10px'} alignItems={'center'} justifyContent={'center'}>
        <MedicoExponencialLogo data-testid={'medico-exponencial-logo'} />

        <EMSLogo data-testid={'ems-logo'} height={'46px'} />
      </Flex>
      <Typography model="body" size="normal" weight="regular">
        Ambiente de acesso exclusivo para os médicos da comunidade Médico Exponencial. <b>Cadastre-se!</b>
      </Typography>
    </Flex>
  );
}
