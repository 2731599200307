import Typography from 'src/components/Typography';
import { Box } from 'src/components/UI/Box';
import { Flex } from 'src/components/UI/Flex';
import { PrescriptionStatusFilter } from './PrescriptionStatusFilter';
import { ToggleButton } from './ToggleButton';
import { useCallback, useEffect, useState } from 'react';
import { DOCUMENT_TYPE } from 'src/services/prescriber';
import MevoInput from 'src/components/Input';
import { MASKS } from 'src/components/UI/Input/MaskedInput';
import { Button } from 'src/components/UI/Button';
import { FormFilters } from './History';
import { ORDER_BY, ORDER_TYPES, PRESCRIPTION_STATUS } from 'src/services/prescriptionHistory';

interface AdvancedFiltersProps {
  resetFilters(): void;
  filters: FormFilters;
  onChangeFilters(filters: FormFilters): void;
  onClose(): void;
}

export function AdvancedFilters(props: AdvancedFiltersProps) {
  const { filters, resetFilters, onChangeFilters } = props;

  const [clonedFilters, setClonedFilters] = useState<FormFilters>({
    documentType: [],
    retroativeDays: null,
    prescriptionStatus: [],
    initialDate: '',
    endDate: '',
    orderBy: ORDER_BY.DATE,
    orderType: ORDER_TYPES.DESC,
  });

  useEffect(() => {
    setClonedFilters(filters);
  }, [filters]);

  const applyFilters = () => {
    onChangeFilters(clonedFilters);
  };

  const handleDocumentTypeChange = useCallback(
    (value: DOCUMENT_TYPE) => {
      if (clonedFilters.documentType.includes(value)) {
        setClonedFilters((prevState) => {
          return { ...prevState, documentType: prevState.documentType.filter((filter) => filter !== value) };
        });
        return;
      }

      setClonedFilters((prevState) => {
        return { ...prevState, documentType: [...prevState.documentType, value] };
      });
    },
    [clonedFilters.documentType]
  );

  const handleStatusChange = useCallback(
    (value: PRESCRIPTION_STATUS) => {
      if (clonedFilters.prescriptionStatus.includes(value)) {
        setClonedFilters((prevState) => {
          return {
            ...prevState,
            prescriptionStatus: prevState.prescriptionStatus.filter((filter) => filter !== value),
          };
        });

        return;
      }

      setClonedFilters((prevState) => {
        return { ...prevState, prescriptionStatus: [...clonedFilters.prescriptionStatus, value] };
      });
    },
    [clonedFilters.prescriptionStatus]
  );

  return (
    <>
      <Typography model="h2" weight="bold">
        Filtrar busca
      </Typography>
      <Box mt={'5px'}>
        <Typography model="body" size="large" weight="regular">
          Escolha o que gostaria na sua busca.
        </Typography>

        <Flex mt={'10px'} wrap={'wrap'} gap={'10px'}>
          <Flex w={'100%'} gap={'10px'}>
            <PrescriptionStatusFilter
              status={PRESCRIPTION_STATUS.EMITTED}
              onClick={() => handleStatusChange(PRESCRIPTION_STATUS.EMITTED)}
              isActive={clonedFilters.prescriptionStatus.includes(PRESCRIPTION_STATUS.EMITTED)}
            />

            <PrescriptionStatusFilter
              status={PRESCRIPTION_STATUS.CANCELED}
              onClick={() => handleStatusChange(PRESCRIPTION_STATUS.CANCELED)}
              isActive={clonedFilters.prescriptionStatus.includes(PRESCRIPTION_STATUS.CANCELED)}
            />
          </Flex>
          {/* <Box w={'50%'}>
            <PrescriptionStatusFilter
              status={PRESCRIPTION_STATUS.INITIATED}
              onClick={() => handleStatusChange(PRESCRIPTION_STATUS.INITIATED)}
              isActive={clonedFilters.prescriptionStatus.includes(PRESCRIPTION_STATUS.INITIATED)}
            />
          </Box> */}
        </Flex>

        <Box w={'100%'} h={'1px'} bg={'primary.100'} m={'10px 0px'} />

        <Flex gap={'10px'} wrap={'wrap'} mb={'10px'}>
          <Flex w={'100%'} gap={'10px'}>
            <ToggleButton
              onClick={() => handleDocumentTypeChange(DOCUMENT_TYPE.MEDICINE)}
              isActive={clonedFilters.documentType.includes(DOCUMENT_TYPE.MEDICINE)}
              label={
                <Typography model="body" size="small" weight="bold">
                  Receita
                </Typography>
              }
            />
            <ToggleButton
              onClick={() => handleDocumentTypeChange(DOCUMENT_TYPE.EXAM)}
              isActive={clonedFilters.documentType.includes(DOCUMENT_TYPE.EXAM)}
              label={
                <Typography model="body" size="small" weight="bold">
                  Exame
                </Typography>
              }
            />
          </Flex>

          <Flex w={'100%'} gap={'10px'}>
            <ToggleButton
              onClick={() => handleDocumentTypeChange(DOCUMENT_TYPE.MEDICAL_CERTIFICATE)}
              isActive={clonedFilters.documentType.includes(DOCUMENT_TYPE.MEDICAL_CERTIFICATE)}
              label={
                <Typography model="body" size="small" weight="bold">
                  Atestado
                </Typography>
              }
            />
            <ToggleButton
              onClick={() => handleDocumentTypeChange(DOCUMENT_TYPE.MEDICAL_REFERRAL)}
              isActive={clonedFilters.documentType.includes(DOCUMENT_TYPE.MEDICAL_REFERRAL)}
              label={
                <Typography model="body" size="small" weight="bold">
                  Encaminhamento
                </Typography>
              }
            />
          </Flex>

          <Flex w={'calc(50% - 5px)'}>
            <ToggleButton
              onClick={() => handleDocumentTypeChange(DOCUMENT_TYPE.MEDICAL_REPORT)}
              isActive={clonedFilters.documentType.includes(DOCUMENT_TYPE.MEDICAL_REPORT)}
              label={
                <Typography model="body" size="small" weight="bold">
                  Relatório
                </Typography>
              }
            />
          </Flex>
        </Flex>

        <Flex gap={'10px'}>
          <MevoInput
            labelText="data inicial"
            type={'tel'}
            h={'45px'}
            placeholder={'00/00/0000'}
            name={'birthDate'}
            mask={MASKS.BIRTH_DATE}
            value={clonedFilters.initialDate || ''}
            onChange={(e) => {
              setClonedFilters((prevState) => {
                return { ...prevState, initialDate: e.target.value };
              });
            }}
            maskChar={''}
          />
          <MevoInput
            labelText="data final"
            type={'tel'}
            h={'45px'}
            placeholder={'00/00/0000'}
            name={'birthDate'}
            mask={MASKS.BIRTH_DATE}
            value={clonedFilters.endDate || ''}
            onChange={(e) => {
              setClonedFilters((prevState) => {
                return { ...prevState, endDate: e.target.value };
              });
            }}
            maskChar={''}
          />
        </Flex>

        <Button
          textDecor={'underline'}
          variant="ghost"
          fontSize={'12px'}
          fontWeight={700}
          onClick={resetFilters}
          color={'primary.1000'}
          w={'100%'}
          mb={'10px'}
        >
          Limpar filtros
        </Button>

        <Flex w={'100%'} gap={'10px'}>
          <Button w={'100%'} variant="outline" onClick={props.onClose}>
            Voltar
          </Button>
          <Button w={'100%'} onClick={applyFilters}>
            Aplicar
          </Button>
        </Flex>
      </Box>
    </>
  );
}
