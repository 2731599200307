import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';

import { NextButton } from './NextButton';
import { TabsHeader } from './TabsHeader';
import { TABS_INDEX } from './SignUp';

import MevoInput from 'src/components/Input';
import Typography from 'src/components/Typography';
import { Box } from 'src/components/UI/Box';
import { FormControl } from 'src/components/UI/FormControl';
import { MASKS } from 'src/components/UI/Input/MaskedInput';

import { Yup } from 'src/helpers/Yup';
import { validateCPF } from 'src/helpers/cpf';
import { onlyDigits } from 'src/helpers/string';
import { removeSpacesAndSpecialChars } from 'src/helpers/removeSpacesAndSpecialChars';

import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { FormikPersist } from 'src/helpers/FormikPersist';
import { Flex } from 'src/components/UI/Flex';
import { DateFromFormat, DateFromIso, validateDateFromBrazilianForm } from 'src/helpers/Date';
import { Tracker } from 'src/shared/tracker/Tracker';
import { isEmpty, omit } from 'lodash';

import { FormErrorMessage } from 'src/components/UI/FormErrorMessage';
import { MEVO_PRIVACY_URL } from 'src/helpers/defaultValues';
import { Checkbox } from 'src/components/UI/Checkbox';
import { TermsOfUse, TermsOfUseService } from 'src/services/termsOfUse';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';
import { PrescriberService } from 'src/services/prescriber';

interface PersonalDataForm {
  fullName: string;
  document: string;
  email: string;
  cellphoneNumber: string;
  birthDate: string;
  termsOfUse: boolean;
  acceptedNewsLetter: boolean;
}

const initialValues: PersonalDataForm = {
  fullName: '',
  document: '',
  email: '',
  cellphoneNumber: '',
  birthDate: '',
  termsOfUse: false,
  acceptedNewsLetter: true,
};

const PersonalDataSchema = Yup.object().shape({
  fullName: Yup.string().required('Informe seu nome completo.'),
  document: Yup.string()
    .required('Informe um número de CPF válido.')
    .test('validate cpf', 'Informe um número de CPF válido.', function (document) {
      return validateCPF(document || '');
    }),
  email: Yup.string().email('Informe um email válido.').required('Informe um email válido.'),
  cellphoneNumber: Yup.string()
    .required('Informe um número de celular válido.')
    .test('validate phoneNumber', 'Informe um número de celular válido.', function (cellphoneNumber) {
      if (!cellphoneNumber) return false;
      return onlyDigits(cellphoneNumber).length === 11;
    }),
  birthDate: Yup.string()
    .required('Informe uma data de nascimento válida.')
    .test('match', 'Informe uma data de nascimento válida.', (birthDate) => {
      return validateDateFromBrazilianForm(birthDate);
    }),
  termsOfUse: Yup.bool().oneOf(
    [true],
    'Para prosseguir com o cadastro, você precisa realizar o consentimento da Política de Privacidade e Termos de Uso.'
  ),
  acceptedNewsLetter: Yup.bool().nullable(),
});

interface PersonalDataProps {
  onNext(): void;
  isStepCompleted: boolean;
}

export function PersonalData(props: PersonalDataProps) {
  const [termsOfUse, setTermsOfUse] = useState<TermsOfUse | null>(null);

  const { openToast } = useFeedbackContext();
  const { updateCreatePrescriberFormData } = usePrescriberContext();

  useEffect(() => {
    const run = async () => {
      try {
        setTermsOfUse(await TermsOfUseService.getTermsOfUse());
      } catch (error) {
        openToast('Erro ao buscar os Termos de Uso. Tente novamente em instantes.', {
          type: 'error-outline',
          duration: 4000,
        });
      }
    };

    run();
  }, [openToast]);

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      initialValues={initialValues}
      validationSchema={PersonalDataSchema}
      onSubmit={async (values, formik) => {
        const date = DateFromFormat(values.birthDate || '', 'dd/MM/yyyy');

        if (!termsOfUse) return;

        const term = {
          accepted: true,
          ...omit(termsOfUse, '_id', 'active'),
        };

        Tracker.trackEvent({
          event: 'cadastroPrescritorPrimeiroPassoConcluido',
          parameters: {
            Nome: values.fullName,
            email: values.email,
            'Data cadastro': new Date(),
            'Receber Novidades': values.acceptedNewsLetter,
          },
        });

        const payloadData = {
          ...values,
          fullName: removeSpacesAndSpecialChars(values.fullName),
          document: onlyDigits(values.document),
          documentType: 'CPF',
          cellphoneNumber: onlyDigits(values.cellphoneNumber),
          birthDate: DateFromIso(date),
          consentTerm: term,
          acceptedNewsLetter: values.acceptedNewsLetter,
        };
        await PrescriberService.updatePrescriberRegisterDraft({
          fullName: payloadData.fullName,
          cellphoneNumber: payloadData.cellphoneNumber,
          email: payloadData.email,
        });
        props.onNext();
        updateCreatePrescriberFormData(payloadData);
        formik.setSubmitting(false);
      }}
    >
      {(formikProps) => {
        const submit = async () => {
          const errors = await formikProps.validateForm();

          if (isEmpty(errors)) {
            return formikProps.submitForm();
          }

          Tracker.trackEvent({
            event: 'erroCadastroPrescritorPrimeiroPasso',
            parameters: errors,
          });
        };

        return (
          <form
            name={'personal-data'}
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
            style={{ width: '100%' }}
          >
            <TabsHeader
              tabClick={(index) => {
                if (index === TABS_INDEX.PERSONAL_DATA) return;

                submit();
              }}
              currentIndex={TABS_INDEX.PERSONAL_DATA}
              isFirstStepCompleted={props.isStepCompleted}
            />
            <Box>
              {formikProps.isSubmitting && <ConnectedFocusError />}
              <Box mb={'20px'}>
                <Typography model={'body'} size={'large'} weight={'bold'}>
                  Dados pessoais
                </Typography>
              </Box>
              <Box>
                <FormControl isInvalid={!!formikProps.errors.fullName} mb={'20px'}>
                  <MevoInput
                    id="fullName"
                    labelText="NOME COMPLETO*"
                    h={'45px'}
                    placeholderText={'Digite seu nome'}
                    name={'fullName'}
                    value={formikProps.values.fullName}
                    onChange={formikProps.handleChange}
                    onBlur={() => formikProps.validateField('fullName')}
                    autoFocus
                    errorText={formikProps.errors.fullName}
                  />
                </FormControl>

                <Flex gap={'20px'}>
                  <FormControl isInvalid={!!formikProps.errors.birthDate} mb={'20px'}>
                    <MevoInput
                      labelText="DATA DE NASCIMENTO*"
                      type={'tel'}
                      h={'45px'}
                      placeholder={'00/00/0000'}
                      name={'birthDate'}
                      value={formikProps.values.birthDate}
                      onChange={formikProps.handleChange}
                      onBlur={() => formikProps.validateField('birthDate')}
                      errorText={formikProps.errors.birthDate}
                      mask={MASKS.BIRTH_DATE}
                    />
                  </FormControl>
                  <FormControl isInvalid={!!formikProps.errors.document} mb={'20px'}>
                    <MevoInput
                      id="document"
                      labelText="CPF*"
                      type={'tel'}
                      h={'45px'}
                      placeholder={'000.000.000-00'}
                      name={'document'}
                      value={formikProps.values.document}
                      onChange={formikProps.handleChange}
                      onBlur={() => formikProps.validateField('document')}
                      errorText={formikProps.errors.document}
                      mask={MASKS.CPF}
                    />
                  </FormControl>
                </Flex>

                <FormControl isInvalid={!!formikProps.errors.email} mb={'20px'}>
                  <MevoInput
                    id="email"
                    labelText="EMAIL*"
                    h={'45px'}
                    placeholderText={'Digite seu email'}
                    name={'email'}
                    type={'email'}
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange}
                    onBlur={() => formikProps.validateField('email')}
                    errorText={formikProps.errors.email}
                  />
                </FormControl>

                <FormControl isInvalid={!!formikProps.errors.cellphoneNumber} mb={{ base: 0, sm: '16px' }}>
                  <MevoInput
                    id="cellphoneNumber"
                    labelText="Celular*"
                    type={'tel'}
                    h={'45px'}
                    placeholder={'(99) 9 9999-9999'}
                    name={'cellphoneNumber'}
                    value={formikProps.values.cellphoneNumber}
                    onChange={formikProps.handleChange}
                    onBlur={() => formikProps.validateField('cellphoneNumber')}
                    errorText={formikProps.errors.cellphoneNumber}
                    mask={MASKS.BRAZIL_PHONE}
                  />
                </FormControl>

                <Box mt={'20px'} mb={'16px'}>
                  <FormControl isInvalid={!!formikProps.errors.termsOfUse} mb={'20px'}>
                    <Flex>
                      <Checkbox size={'sm'} mr={'10px'} name="termsOfUse" onChange={formikProps.handleChange} />
                      <Typography model={'body'} size={'normal'} weight={'regular'}>
                        Ao informar meus dados, eu concordo com a{' '}
                        <a href={MEVO_PRIVACY_URL} target={'_blank'} rel={'noreferrer'}>
                          <u>Política de Privacidade</u>
                        </a>{' '}
                        e com os{' '}
                        <a href={termsOfUse?.termUrl} target={'_blank'} rel={'noreferrer'}>
                          <u>Termos de Uso</u>
                        </a>
                      </Typography>
                    </Flex>

                    <FormErrorMessage textTransform={'uppercase'}>{formikProps.errors.termsOfUse}</FormErrorMessage>
                  </FormControl>

                  <Checkbox
                    size={'sm'}
                    name="acceptedNewsLetter"
                    onChange={formikProps.handleChange}
                    isChecked={formikProps.values.acceptedNewsLetter}
                  >
                    <Typography model={'body'} size={'normal'} weight={'regular'}>
                      Aceito receber novidades e informações da Mevo Saúde em meu email e celular
                    </Typography>
                  </Checkbox>
                </Box>

                <NextButton
                  currentIndex={TABS_INDEX.PERSONAL_DATA}
                  position={{ base: 'fixed', sm: 'static' }}
                  padding={{ base: '0 20px', sm: '0' }}
                />
              </Box>
            </Box>

            <FormikPersist formName={'personal-data-form'} />
          </form>
        );
      }}
    </Formik>
  );
}
