import React from 'react';
import CombinedProviders from './providers/CombinedProviders';
import './App.css';
import RootRouter from './router/RootRouter';

function App() {
  return (
    <CombinedProviders>
      <RootRouter />
    </CombinedProviders>
  );
}

export default App;
