import { Flex } from '@chakra-ui/react';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NavButton from 'src/components/Helpers/NavButton';
import CheckIcon from 'src/components/Icons/CheckIcon';
import WhatsappIcon from 'src/components/Icons/WhatsappIcon';
import FlatLayout from 'src/components/Layout/FlatLayout';
import MevoPill from 'src/components/Pill/Pill';
import Typography from 'src/components/Typography';
import { Button } from 'src/components/UI/Button';
import FormBaseCard from 'src/components/UI/FormBaseCard';
import { DEFAULT_MEVO_WHATSAPP_NUMBER } from 'src/helpers/defaultValues';
import { openWhatsApp } from 'src/helpers/openWhatsApp';

export const EmailSent: React.FunctionComponent = () => {
  const [searchParams] = useSearchParams();

  const userEmail = React.useMemo(() => {
    return searchParams.get('email') || '';
  }, [searchParams]);

  const navigate = useNavigate();

  const handleWhatsApp = () => {
    openWhatsApp(DEFAULT_MEVO_WHATSAPP_NUMBER());
  };

  return (
    <FlatLayout>
      <Flex w={'100%'} justifyContent={'center'}>
        <FormBaseCard>
          <Flex direction="column" alignItems="center">
            <MevoPill pillColor="pink" pillWidth={150} faceStyle="happy" faceWidth={56} />
            <Flex direction={'row'} mt="20px">
              <Flex>
                <CheckIcon variantColor="support.green" width={28} fullColor />
              </Flex>
              <Flex flex={1} ml="10px">
                <Typography model="h1" weight="bold">
                  Enviado com sucesso!
                </Typography>
              </Flex>
            </Flex>
            <Flex my="20px">
              <Typography model="body" size="large" weight="regular">
                Se o usuário <b>{userEmail}</b> já estiver cadastrado enviaremos um email com um link para a recuperação
                de senha.
              </Typography>
            </Flex>
            <Flex mb="45px">
              <Typography model="body" size="large" weight="regular">
                Caso não tenha recebido o email, verifique sua caixa de spam ou{' '}
                <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleWhatsApp}>
                  <span>fale com a gente.</span>
                  <Flex display="inline-flex" alignItems="center" ml="5px">
                    <WhatsappIcon variantColor="primary.1000" width={16} />
                  </Flex>
                </span>
              </Typography>
            </Flex>
            <NavButton>
              <Button width="100%" height="60px" mt="16px" variant="outline" onClick={() => navigate('/')}>
                Voltar a tela inicial
              </Button>
            </NavButton>
          </Flex>
        </FormBaseCard>
      </Flex>
    </FlatLayout>
  );
};
