import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';
import { errorRoutes, protectedRoutes, publicRoutes, returnableProtectedRoutes } from './routes/routes';
import FullLayout from 'src/components/Layout/FullLayout/FullLayout';
import { AuthPlaceholder } from 'src/containers/App/AuthPlaceholder';
import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { AnimatePresence } from 'framer-motion';
import { HelpPage } from 'src/containers/Help/Help';
import { RedirectApp } from 'src/containers/RedirectApp/RedirectApp';

function RootRouter() {
  const { isUserOnApp } = usePrescriberContext();

  return (
    <BrowserRouter>
      <AnimatePresence mode="wait">
        <Routes>
          <Route path={'app/auth'} element={<AuthPlaceholder />} />
          <Route path={'app/redirect'} element={<RedirectApp />} />
          {!isUserOnApp &&
            publicRoutes.map((route) => {
              const Component = route.component;
              return <Route index={route.index} path={route.path} element={<Component />} key={route.path} />;
            })}
          <Route path={'/inicio'} element={<FullLayout />}>
            {protectedRoutes.map((route) => {
              const Component = route.component;
              return (
                <Route
                  path={route.path}
                  element={
                    <ProtectedRoute>
                      <Component />
                    </ProtectedRoute>
                  }
                  key={route.path}
                />
              );
            })}
          </Route>
          <Route path={'/inicio'} element={<FullLayout showGoBack />}>
            {returnableProtectedRoutes.map((route) => {
              const Component = route.component;
              return (
                <Route
                  path={route.path}
                  element={
                    <ProtectedRoute>
                      <Component />
                    </ProtectedRoute>
                  }
                  key={route.path}
                />
              );
            })}
          </Route>
          {errorRoutes.map((route) => {
            const Component = route.component;
            return <Route path={route.path} element={<Component />} key={route.path} />;
          })}
          <Route path={'/ajuda'} element={<HelpPage />} />;
        </Routes>
      </AnimatePresence>
    </BrowserRouter>
  );
}

export default RootRouter;
