import { Modal, ModalContent, useBreakpointValue } from '@chakra-ui/react';
import { Flex } from 'src/components/UI/Flex';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Mevo } from './Mevo/Mevo';
import { MevoMobile } from './Mevo/MevoMobile';
import { Tracker } from 'src/shared/tracker/Tracker';
import { EMS } from './EMS/EMS';
import { EMSMobile } from './EMS/EMSMobile';
import { useNavigateSearch } from 'src/helpers/useNavigateSearch';

export enum PARTNERS {
  EMS = 'EMS',
  DEFAULT = 'DEFAULT',
}

export function LoginBanner() {
  const [hubModalIsOpen, setHubModalIsOpen] = useState(false);
  const shouldModalBeOpened = useBreakpointValue(
    {
      base: true,
      sm: true,
      md: false,
    },
    { ssr: false }
  );
  const imageContainerWidth = { base: '100%', sm: '30%', md: '60%', lg: '70%' };

  function handleCloseHubModal() {
    setHubModalIsOpen(false);
  }

  useEffect(() => {
    if (shouldModalBeOpened) {
      return setHubModalIsOpen(true);
    }

    setHubModalIsOpen(false);
  }, [shouldModalBeOpened]);

  const [searchParams] = useSearchParams();

  const partner = searchParams.get('partner');

  const navigateWithSearch = useNavigateSearch();

  function handleGoToRegister() {
    Tracker.trackEvent({
      event: 'Cadastro Iniciado',
      parameters: {
        'Data cadastro': new Date(),
      },
    });

    navigateWithSearch('/cadastro');
  }

  const Banner = {
    [PARTNERS.EMS]: <EMS onRegister={handleGoToRegister} />,
    DEFAULT: <Mevo onRegister={handleGoToRegister} />,
  };

  const MobileBanner = {
    [PARTNERS.EMS]: <EMSMobile onRegister={handleGoToRegister} onLogin={handleCloseHubModal} />,
    DEFAULT: <MevoMobile onRegister={handleGoToRegister} onLogin={handleCloseHubModal} />,
  };

  return (
    <>
      <Modal
        isOpen={hubModalIsOpen}
        onClose={handleCloseHubModal}
        size={'full'}
        closeOnEsc={false}
        motionPreset={'slideInBottom'}
      >
        <ModalContent
          backgroundColor={'primary.1000'}
          px={{
            base: '30px',
            sm: '48px',
          }}
        >
          {MobileBanner[partner as keyof typeof MobileBanner] || MobileBanner[PARTNERS.DEFAULT]}
        </ModalContent>
      </Modal>

      <Flex w={imageContainerWidth} display={{ base: 'none', md: 'flex' }}>
        {Banner[partner as keyof typeof Banner] || Banner[PARTNERS.DEFAULT]}
      </Flex>
    </>
  );
}
