import React from 'react';
import { STATES } from 'src/helpers/brazilStates';
import Typography from './Typography';
import { Box } from './UI/Box';
import { FormErrorMessage } from './UI/FormErrorMessage';
import { Select } from './UI/Select/Select';

interface SelectStateProps {
  value: string;
  onChange(val: string): void;
  error?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
  onBlur?(): void;
}

function SelectState(props: SelectStateProps) {
  return (
    <>
      {props.label && (
        <Box mb={'10px'}>
          <Typography model="caption" weight="bold" textTransform="uppercase">
            {props.label}
          </Typography>
        </Box>
      )}
      <Select
        h={'45px'}
        m={0}
        p={0}
        value={props.value}
        placeholder={props.placeholder || 'Selecione o estado (UF)'}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        onBlur={props.onBlur}
        data-testid={'uf-select'}
      >
        {STATES.map((state) => (
          <option value={state.initials} key={state.name}>
            {state.initials}
          </option>
        ))}
      </Select>

      <FormErrorMessage fontWeight={'bold'} textTransform={'uppercase'}>
        {props.errorMessage}
      </FormErrorMessage>
    </>
  );
}

export default React.memo(SelectState);
