import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Header } from 'src/components/Layout/Header';
import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { ReactComponent as Allegril } from 'src/assets/mevo-pill/allegril.svg';
import abstractBackground from 'src/assets/images/background.png';
import { useIsMobile } from 'src/helpers/useIsMobile';
import { Flex } from 'src/components/UI/Flex';
import Typography from 'src/components/Typography';
import { AuthService } from 'src/services/AuthService';
import { openWhatsApp } from 'src/helpers/openWhatsApp';
import { DEFAULT_MEVO_WHATSAPP_NUMBER } from 'src/helpers/defaultValues';
import WhatsappIcon from 'src/components/Icons/WhatsappIcon';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';
import clockGif from 'src/assets/gifs/clock.gif';
import { Img } from '@chakra-ui/react';

const DISABLE_FOR_60_SECONDS = 60000;

export function ConfirmEmail() {
  const [disabled, setDisabled] = useState(false);

  const { openToast } = useFeedbackContext();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isMobile = useIsMobile();

  const userEmail = useMemo(() => {
    return searchParams.get('email') || '';
  }, [searchParams]);

  const resendEmail = useCallback(async () => {
    try {
      if (!userEmail) return;

      await AuthService.resendEmail(userEmail);
      openToast('E-mail reenviado com sucesso.', { type: 'success', duration: 4000 });

      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, DISABLE_FOR_60_SECONDS);
    } catch (error) {
      openToast('Você alcançou o limite de tentativas, tente novamente mais tarde.', { type: 'error', duration: 4000 });
    }
  }, [setDisabled, userEmail, openToast]);

  const handleWhatsApp = () => {
    openWhatsApp(DEFAULT_MEVO_WHATSAPP_NUMBER());
  };

  return (
    <Box w={'100%'} h={'100vh'} bg={'background'} overflowY={'auto'}>
      <Box p={'0 20px'}>
        <Header onGoBack={() => navigate('/')} />
      </Box>

      <Flex
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
        backgroundImage={isMobile ? '' : `url(${abstractBackground})`}
        pt={isMobile ? 0 : '100px'}
        justifyContent={'center'}
        h={'100%'}
      >
        <Box
          w={isMobile ? '100%' : '435px'}
          h={isMobile ? '100%' : '435px'}
          borderRadius={'30px'}
          boxShadow={!isMobile ? '12px 12px 40px rgba(61, 14, 107, 0.1)' : 'none'}
          bg={'background'}
        >
          <Box p={'40px'}>
            <Flex flexDir={isMobile ? 'column' : 'row'} alignItems={'center'} mb={'20px'}>
              <Box
                mr={!isMobile ? '20px' : 0}
                display={'flex'}
                alignItems={'center'}
                flexDirection={'column'}
                mb={'20px'}
              >
                <Allegril className={isMobile ? 'float-container' : ''} />
              </Box>

              <Typography model={'h1'} weight={'bold'}>
                Confirme seu email!
              </Typography>
            </Flex>

            <Box mb={'20px'}>
              <Typography model={'body'} size={'large'} weight={'regular'}>
                Acabamos de enviar um email com um link para <b>{userEmail}</b> para validar seu email.
              </Typography>
            </Box>

            <Box mb={'20px'}>
              <Typography model={'body'} size={'large'} weight={'regular'}>
                Caso não tenha recebido o email, verifique sua caixa de spam ou{' '}
                <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleWhatsApp}>
                  <span>fale com a gente.</span>
                  <Flex display="inline-flex" alignItems="center" ml="5px">
                    <WhatsappIcon variantColor="primary.1000" width={16} />
                  </Flex>
                </span>
              </Typography>
            </Box>

            <Box pos={isMobile ? 'absolute' : 'static'} bottom={'20px'} left={'20px'} right={'20px'}>
              {userEmail && (
                <Button h={'60px'} w={'100%'} variant={'outline'} isDisabled={disabled} onClick={resendEmail}>
                  {disabled ? <Img src={clockGif} h={'30px'}></Img> : 'Reenviar email'}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
