/* eslint-disable max-lines */
import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { InputRightAddon } from '@chakra-ui/react';

import ExitIcon from '../Icons/ExitIcon';
import AddIcon from '../Icons/AddIcon';

import { InputGroup } from '../UI/Input/InputGroup';
import { Spinner } from '../UI/Spinner';
import { Box } from '../UI/Box';

import { Flex } from 'src/components/UI/Flex';
import Typography from 'src/components/Typography';
import { Input } from 'src/components/UI/Input/Input';
import SearchIcon from 'src/components/Icons/SearchIcon';
import { Button } from 'src/components/UI/Button';
import { Grid } from 'src/components/UI/Grid';
import { GridItem } from 'src/components/UI/GridItem';

import { useIsMobile } from 'src/helpers/useIsMobile';

import { ORDER_BY } from 'src/services/prescriber';
import { Menu } from '../UI/Menu/Menu';
import { MenuButton } from '../UI/Menu/MenuButton';
import { MenuList } from '../UI/Menu/MenuList';
import { MenuItem } from '../UI/Menu/MenuItem';
import ArrowIcon from '../Icons/ArrowIcon';
import { ORDER_TYPES } from 'src/services/prescriptionHistory';
import { debounce } from 'lodash';
import useAsyncSetFieldValue from 'src/helpers/useAsyncSetFieldValue';

export interface searchValue {
  document: string;
  fullName: string;
}

export const SEARCH_HEIGHT = 245;
export const MOBILE_SEARCH_HEIGHT = 296;

interface SearchProps {
  onEnterCallback?(): void;
  onCreatePatient(): void;
  onFocus(): void;
  onBlur(): void;
}

export interface SearchForm {
  searchString: string;
  shouldCallAction: boolean;
  orderType: ORDER_TYPES;
  orderBy: ORDER_BY;
  selectedFilter: {
    label: string;
    arrowDirection: 'up' | 'down';
  };
  clearForm(): void;
}

interface Filters {
  [key: string]: {
    orderBy: ORDER_BY;
    orderType: ORDER_TYPES;
    filterLabel: string;
    arrowDirection: 'up' | 'down';
  };
}

const PATIENT_SEARCH_FILTERS: Filters = {
  MOST_RECENT_FIRST: {
    orderBy: ORDER_BY.CREATE_AT,
    orderType: ORDER_TYPES.DESC,
    filterLabel: 'Mais recentes primeiro',
    arrowDirection: 'down',
  },
  MOST_OLDER_FIRST: {
    orderBy: ORDER_BY.CREATE_AT,
    orderType: ORDER_TYPES.ASC,
    filterLabel: 'Mais antigos primeiro',
    arrowDirection: 'up',
  },
  PATIENT_NAME_A_TO_Z: {
    orderBy: ORDER_BY.PATIENT_NAME,
    orderType: ORDER_TYPES.ASC,
    filterLabel: 'Nome do paciente (A-Z)',
    arrowDirection: 'down',
  },
  PATIENT_NAME_Z_TO_A: {
    orderBy: ORDER_BY.PATIENT_NAME,
    orderType: ORDER_TYPES.DESC,
    filterLabel: 'Nome do paciente (Z-A)',
    arrowDirection: 'up',
  },
};

const StyledMenuItem = (props: { children: React.ReactNode; onClick(): void; isActive: boolean }) => {
  return (
    <MenuItem
      p={'5px 15px 5px 10px'}
      borderRadius={'20px'}
      display={'flex'}
      gap={'15px'}
      justifyContent={'space-between'}
      border={'2px solid'}
      borderColor={'secondary.100'}
      onClick={props.onClick}
      backgroundColor={props.isActive ? 'secondary.100' : 'transparent'}
    >
      {props.children}
    </MenuItem>
  );
};

export function Search(props: SearchProps) {
  const isMobile = useIsMobile();

  const { onBlur, onCreatePatient, onFocus, onEnterCallback } = props;

  const { isSubmitting, resetForm, values, setFieldValue, handleSubmit } = useFormikContext<SearchForm>();

  const debouncedSubmit = useMemo(() => debounce(handleSubmit, 200), [handleSubmit]);

  const [asyncSetPage] = useAsyncSetFieldValue('page');
  const [asyncSetShouldPaginate] = useAsyncSetFieldValue('shouldPaginate');

  const handlePatientFilter = useCallback(
    (value: { orderBy: ORDER_BY; orderType: ORDER_TYPES; filterLabel: string; arrowDirection: 'up' | 'down' }) => {
      asyncSetShouldPaginate(false);
      setFieldValue('orderBy', value.orderBy);
      setFieldValue('orderType', value.orderType);
      setFieldValue('selectedFilter', {
        label: value.filterLabel,
        arrowDirection: value.arrowDirection,
      });
      setFieldValue('page', 1);

      debouncedSubmit();
    },
    [asyncSetShouldPaginate, debouncedSubmit, setFieldValue]
  );

  const InputIconAddornment = useMemo(() => {
    if (isSubmitting) {
      return <Spinner color={'primary.1000'} />;
    }

    return values.searchString.trim().length >= 1 ? (
      <ExitIcon
        width={20}
        variantColor={'primary.main'}
        onClick={() => {
          resetForm();
          debouncedSubmit();
        }}
      />
    ) : (
      <SearchIcon width={20} onClick={debouncedSubmit} />
    );
  }, [isSubmitting, values.searchString, debouncedSubmit, resetForm]);

  return (
    <Box backgroundColor={'background'} borderRadius={'20px'}>
      <Grid templateColumns={'repeat(12, 1fr)'} gap={'10px'} backgroundColor={'background'} borderRadius={'20px'}>
        <GridItem w={'100%'} colSpan={{ base: 12, sm: 7, md: 9 }}>
          <Flex alignItems={'center'}>
            <InputGroup size="sm" h={'52px'}>
              <Input
                autoComplete="off"
                autoFocus={!isMobile}
                border={'none'}
                h={'52px'}
                placeholder={'Busque pelo Nome do paciente ou CPF'}
                value={values.searchString}
                name={'searchString'}
                onChange={async (e) => {
                  setFieldValue('shouldCallAction', false);
                  await setFieldValue('searchString', e.target.value);
                  await asyncSetPage(1, setFieldValue);

                  debouncedSubmit();
                }}
                onKeyDown={(e) => {
                  if (!onEnterCallback) return;

                  if (e.key === 'Enter') {
                    onEnterCallback();
                  }
                }}
                onFocus={onFocus}
                onBlur={onBlur}
              />
              <InputRightAddon
                bg={'surface'}
                h={'100%'}
                borderRightRadius={'10px'}
                border={'none'}
                children={InputIconAddornment}
              />
            </InputGroup>
          </Flex>
        </GridItem>
        <GridItem w={'100%'} colSpan={{ base: 12, sm: 5, md: 3 }}>
          <Button
            w={'100%'}
            borderRadius={{ base: '20px', sm: '10px' }}
            bg={'surface'}
            variant="ghost"
            h={{ base: '40px', sm: '52px' }}
            boxShadow={'12px 12px 40px rgba(61, 14, 107, 0.1)'}
            leftIcon={
              <Box w={'16px'} h={'16px'} mr={'10px'}>
                <AddIcon outlined={true} variantColor={'notification.success'} />
              </Box>
            }
            onClick={onCreatePatient}
          >
            <Typography textAlign="left" whiteSpace="pre-wrap" model={'body'} size={'large'} weight={'bold'}>
              Criar novo paciente
            </Typography>
          </Button>
        </GridItem>
      </Grid>

      <Flex justifyContent={'space-between'} alignItems={'center'} mt={'12px'}>
        {isMobile && (
          <Typography model={'body'} size={'small'} weight="bold">
            Pacientes cadastrados
          </Typography>
        )}

        <Menu isLazy>
          <MenuButton type="button" p={'5px 15px 5px 10px'} borderRadius={'20px'} backgroundColor={'secondary.100'}>
            <Flex display={'flex'} gap={'15px'} justifyContent={'space-between'}>
              <Typography model={'body'} size={'small'} weight="bold">
                {values.selectedFilter.label}
              </Typography>
              <ArrowIcon direction={values.selectedFilter.arrowDirection} width={8} variantColor="primary.main" />
            </Flex>
          </MenuButton>
          <MenuList
            boxShadow={'12px 12px 40px rgba(61, 14, 107, 0.1)'}
            bg={'surface'}
            borderRadius={'20px'}
            p={'15px'}
            display={'flex'}
            flexDirection={'column'}
            gap={'10px'}
            minWidth={'154px !important'}
          >
            <Typography model={'body'} size={'small'} weight="bold">
              Ordenar por:
            </Typography>
            <StyledMenuItem
              onClick={() => {
                handlePatientFilter(PATIENT_SEARCH_FILTERS.MOST_RECENT_FIRST);
              }}
              isActive={values.selectedFilter.label === PATIENT_SEARCH_FILTERS.MOST_RECENT_FIRST.filterLabel}
            >
              <Typography model={'body'} size={'small'} weight="bold">
                {PATIENT_SEARCH_FILTERS.MOST_RECENT_FIRST.filterLabel}
              </Typography>
              <ArrowIcon direction="down" width={8} variantColor="primary.main" />
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                handlePatientFilter(PATIENT_SEARCH_FILTERS.MOST_OLDER_FIRST);
              }}
              isActive={values.selectedFilter.label === PATIENT_SEARCH_FILTERS.MOST_OLDER_FIRST.filterLabel}
            >
              <Typography model={'body'} size={'small'} weight="bold">
                {PATIENT_SEARCH_FILTERS.MOST_OLDER_FIRST.filterLabel}
              </Typography>
              <ArrowIcon direction="up" width={8} variantColor="primary.main" />
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                handlePatientFilter(PATIENT_SEARCH_FILTERS.PATIENT_NAME_A_TO_Z);
              }}
              isActive={values.selectedFilter.label === PATIENT_SEARCH_FILTERS.PATIENT_NAME_A_TO_Z.filterLabel}
            >
              <Typography model={'body'} size={'small'} weight="bold">
                {PATIENT_SEARCH_FILTERS.PATIENT_NAME_A_TO_Z.filterLabel}
              </Typography>
              <ArrowIcon direction="down" width={8} variantColor="primary.main" />
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                handlePatientFilter(PATIENT_SEARCH_FILTERS.PATIENT_NAME_Z_TO_A);
              }}
              isActive={values.selectedFilter.label === PATIENT_SEARCH_FILTERS.PATIENT_NAME_Z_TO_A.filterLabel}
            >
              <Typography model={'body'} size={'small'} weight="bold">
                {PATIENT_SEARCH_FILTERS.PATIENT_NAME_Z_TO_A.filterLabel}
              </Typography>
              <ArrowIcon direction="up" width={8} variantColor="primary.main" />
            </StyledMenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Box>
  );
}
