import { useState } from 'react';
import { Hide, Show, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import { PersonalDataForm } from './PersonalDataForm';
import { ProfessionalDataForm } from './ProfessionalDataForm';
import { ChangePasswordForm } from './ChangePasswordForm';
import { AttendancePlaceForm } from './AttendancePlaceForm';

import { Header } from './Header';
import { AnimatedRoute } from 'src/router/AnimatedRoute';
import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { appShouldLogoutUser } from 'src/helpers/window';
import { Accordion, AccordionItem, AccordionPanel } from 'src/components/UI/Accordion';
import { TabFormButton } from 'src/components/TabFormButton';
import { AccordionFormButton } from 'src/components/AccordionFormButton';
import { Button } from 'src/components/UI/Button';
import { AuthService } from 'src/services/AuthService';
import { useNavigate } from 'react-router-dom';
import { DeleteAccountDialog } from './DeleteAccountDialog';
import { DialogWrappedComponentProps, useFeedbackContext } from 'src/providers/FeedbackProvider';
import { ConfirmAccountDeletionDialog } from './ConfirmAccountDeletionDialog';
import { sleep } from 'src/helpers/sleep';
import { PrescriberService } from 'src/services/prescriber';
import { Flex } from 'src/components/UI/Flex';

enum FORM_INDEX {
  PERSONAL_DATA = 0,
  PROFESSIONAL_DATA = 1,
  ATTENDANCE_DATA = 2,
  CHANGE_PASSWORD = 3,
  NONE = -1,
}

export function ManageProfessionalData() {
  const [accordionIndex, setAccordionIndex] = useState<FORM_INDEX>(FORM_INDEX.NONE);

  const { isUserOnApp, setPrescriberData, setPrescriberId, prescriberData } = usePrescriberContext();

  const { openBaseDialog, openToast } = useFeedbackContext();

  const navigate = useNavigate();

  const resetAccordion = () => {
    setAccordionIndex(FORM_INDEX.NONE);
  };

  const signOut = async () => {
    try {
      await AuthService.signOut();
      setPrescriberId(null);
      setPrescriberData(null);

      if (isUserOnApp) {
        appShouldLogoutUser();
        return;
      }

      navigate('/');
    } catch (e) {}
  };

  const deletePrescriber = () => {
    if (!prescriberData) return;

    try {
      PrescriberService.deletePrescriber(prescriberData._id, prescriberData.document);
      signOut();
    } catch (error) {
      openToast('Ocorreu um erro ao excluir esta conta. Tente novamente em instantes.', {
        type: 'error',
        duration: 2000,
      });
    }
  };

  const openConfirmDeletionAccountDialog = async () => {
    await openBaseDialog(
      (dialogProps: DialogWrappedComponentProps) => (
        <ConfirmAccountDeletionDialog
          onCancel={dialogProps.onClose}
          onConfirm={() => {
            dialogProps.onClose();
            deletePrescriber();
          }}
        />
      ),
      {
        width: '100%',
        height: 'auto',
        backgroundColor: 'transparent',
      }
    );
  };

  const openDeleteAccountDialog = async () => {
    if (!prescriberData) return;

    await openBaseDialog(
      (dialogProps: DialogWrappedComponentProps) => (
        <DeleteAccountDialog
          prescriberEmail={prescriberData.email}
          onCancel={dialogProps.onClose}
          onConfirm={async () => {
            dialogProps.onClose();

            //este sleep é para melhorar o ux
            await sleep(200);
            openConfirmDeletionAccountDialog();
          }}
        />
      ),
      {
        width: '100%',
        height: 'auto',
        backgroundColor: 'transparent',
      }
    );
  };

  return (
    <AnimatedRoute>
      <Header />

      <Hide below={'lg'}>
        <Tabs variant={'unstyled'} mt={'32px'} display={'flex'} gap={'40px'} flexDirection={'row'}>
          <TabList display={'flex'} flexDirection={'column'} gap={'5px'}>
            <TabFormButton text={'Dados Pessoais'} isDisabled={false} />
            <TabFormButton text={'Dados profissionais'} isDisabled={false} />
            <TabFormButton text={'Local de atendimento'} isDisabled={false} />
            <TabFormButton text={'Segurança'} isDisabled={false} />
            <Flex gap={'10px'}>
              <Button
                h={'50px'}
                onClick={openDeleteAccountDialog}
                _active={{ backgroundColor: 'secondary.50' }}
                backgroundColor={'surface'}
                variant="outline-error"
                w={'100%'}
                mt={'10px'}
              >
                Excluir conta
              </Button>
              <Button
                h={'50px'}
                onClick={signOut}
                _active={{ backgroundColor: 'secondary.50' }}
                backgroundColor={'surface'}
                variant="outline"
                w={'100%'}
                mt={'10px'}
              >
                Sair
              </Button>
            </Flex>
          </TabList>

          <TabPanels>
            <TabPanel p={0}>
              <PersonalDataForm />
            </TabPanel>

            <TabPanel p={0}>
              <ProfessionalDataForm />
            </TabPanel>

            <TabPanel p={0}>
              <AttendancePlaceForm />
            </TabPanel>

            <TabPanel p={0}>
              <ChangePasswordForm />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Hide>

      <Show below={'lg'}>
        <Accordion allowToggle display={'flex'} flexDirection={'column'} gap={'8px'} mt={'32px'} index={accordionIndex}>
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionFormButton
                  isExpanded={isExpanded}
                  text={'Dados Pessoais'}
                  isDisabled={false}
                  onClick={() => {
                    if (accordionIndex === FORM_INDEX.PERSONAL_DATA) {
                      setAccordionIndex(FORM_INDEX.NONE);
                      return;
                    }
                    setAccordionIndex(FORM_INDEX.PERSONAL_DATA);
                  }}
                />

                {isExpanded && (
                  <AccordionPanel p={'20px'} backgroundColor={'surface'} borderRadius={'0 0 10px 10px'}>
                    <PersonalDataForm onEdit={resetAccordion} />
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>

          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionFormButton
                  isExpanded={isExpanded}
                  text={'Dados profissionais'}
                  isDisabled={false}
                  onClick={() => {
                    if (accordionIndex === FORM_INDEX.PROFESSIONAL_DATA) {
                      setAccordionIndex(FORM_INDEX.NONE);
                      return;
                    }
                    setAccordionIndex(FORM_INDEX.PROFESSIONAL_DATA);
                  }}
                />
                {isExpanded && (
                  <AccordionPanel p={'20px'} backgroundColor={'surface'} borderRadius={'0 0 10px 10px'}>
                    <ProfessionalDataForm onEdit={resetAccordion} />
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>

          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionFormButton
                  isExpanded={isExpanded}
                  text={'Local de atendimento'}
                  isDisabled={false}
                  onClick={() => {
                    if (accordionIndex === FORM_INDEX.ATTENDANCE_DATA) {
                      setAccordionIndex(FORM_INDEX.NONE);
                      return;
                    }
                    setAccordionIndex(FORM_INDEX.ATTENDANCE_DATA);
                  }}
                />
                {isExpanded && (
                  <AccordionPanel p={'20px'} backgroundColor={'surface'} borderRadius={'0 0 10px 10px'}>
                    <AttendancePlaceForm onEdit={resetAccordion} />
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>

          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionFormButton
                  isExpanded={isExpanded}
                  text={'Segurança'}
                  isDisabled={false}
                  onClick={() => {
                    if (accordionIndex === FORM_INDEX.CHANGE_PASSWORD) {
                      setAccordionIndex(FORM_INDEX.NONE);
                      return;
                    }
                    setAccordionIndex(FORM_INDEX.CHANGE_PASSWORD);
                  }}
                />
                {isExpanded && (
                  <AccordionPanel p={'20px'} backgroundColor={'surface'} borderRadius={'0 0 10px 10px'}>
                    <ChangePasswordForm onEdit={resetAccordion} />
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>

          <Flex gap={'10px'} flexDirection={'column'} mb={'20px'}>
            <Button
              h={'50px'}
              onClick={openDeleteAccountDialog}
              _active={{ backgroundColor: 'secondary.50' }}
              backgroundColor={'surface'}
              variant="outline-error"
              w={'100%'}
              mt={'10px'}
            >
              Excluir conta
            </Button>
            <Button
              h={'50px'}
              onClick={signOut}
              _active={{ backgroundColor: 'secondary.50' }}
              backgroundColor={'surface'}
              variant="outline"
              w={'100%'}
              mt={'10px'}
            >
              Sair
            </Button>
          </Flex>
        </Accordion>
      </Show>
    </AnimatedRoute>
  );
}
