import { useBreakpointValue } from '@chakra-ui/react';

import { Flex } from 'src/components/UI/Flex';
import DoctorAvatarIcon from 'src/components/Icons/DoctorAvatarIcon';
import Typography from 'src/components/Typography';

import { usePrescriberContext } from 'src/providers/PrescriberProvider';

import { getFirstAndLastWords } from 'src/helpers/getFirstAndLastWords';
import { HeaderSkeleton } from './skeletons/HeaderSkeleton';

export function Header() {
  const { prescriberData } = usePrescriberContext();

  const currentBreakpoint = useBreakpointValue(
    {
      base: 'base',
      sm: 'sm',
      md: 'md',
    },
    {
      ssr: false,
    }
  );

  if (!prescriberData) {
    return <HeaderSkeleton />;
  }

  const doctorAvatarIconSize = currentBreakpoint === 'base' ? 124 : 50;
  const prescriberName =
    currentBreakpoint === 'md' ? prescriberData.fullName : getFirstAndLastWords(prescriberData.fullName);

  return (
    <Flex
      gap={'30px'}
      mt={'28px'}
      alignItems={'center'}
      flexDirection={{
        base: 'column',
        sm: 'row',
      }}
    >
      <DoctorAvatarIcon width={doctorAvatarIconSize} />

      <Typography model={'h1'} fontSize={30} textAlign="center">
        {prescriberName}
      </Typography>
    </Flex>
  );
}
