import { api } from 'src/api';

export const PatientService = {
  async createPartialPatient(payload: CreatePartialPatientPayload, prescriberId: string): Promise<Patient> {
    const { data } = await api.post(`/prescriber/${prescriberId}/patient`, payload);
    return data.data;
  },
  async updatePatient(payload: CreatePartialPatientPayload, prescriberId: string, patientId: string): Promise<void> {
    const { data } = await api.put(`/prescriber/${prescriberId}/patient/${patientId}`, payload);
    return data;
  },
  async deletePatient(prescriberId: string, patientId: string): Promise<void> {
    const { data } = await api.delete(`/prescriber/${prescriberId}/patient/${patientId}`);
    return data;
  },
  async getDiagnostics(icd: string): Promise<Icd[]> {
    const params = {
      query: icd,
    };

    const { data } = await api.get('/get-diagnostics', { params });
    return data.data;
  },
  async getAllergies(allergy: string): Promise<Allergy[]> {
    const params = {
      query: allergy,
    };

    const { data } = await api.get('/get-allergies', { params });
    return data.data;
  },
  async getPatient(prescriberId: string, patientId: string): Promise<FullPatientData> {
    const { data } = await api.get(`/prescriber/${prescriberId}/patient/${patientId}`);
    return data.data;
  },
  async getStructuredPatientData(prescriberId: string, patientId: string): Promise<StructuredPatientData> {
    const { data } = await api.get(`/prescriber/${prescriberId}/patient/${patientId}/structured-data`);
    return data.data;
  },
  async updatePatientAdditionalInformation(
    prescriberId: string,
    payload: UpdateAdditionalDataPatientPayload
  ): Promise<void> {
    const { data } = await api.put(
      `/prescriber/${prescriberId}/patient/${payload.patientId}/additional-information`,
      payload
    );
    return data.data;
  },

  async updatePatientClinicalData(prescriberId: string, payload: UpdateClinicalDataPayload): Promise<void> {
    const { data } = await api.put(`/prescriber/${prescriberId}/patient/${payload.patientId}/clinical-data`, payload);
    return data.data;
  },
};

interface CreatePartialPatientPayload {
  fullName: string;
  document: string;
  documentType: string;
  cellphoneNumber: string;
  birthDate?: Date;
}

export interface Patient {
  fullName: string;
  document: string;
  documentType: string;
  cellphoneNumber: string;
  birthDate: Date;
  active: boolean;
  sex: GENDER;
  lastAttendance: Date;
  _id: string;
}

export interface Allergy {
  id: number;
  name: string;
  mdxalg: string;
}

export interface Icd {
  id: number;
  subcategory: string;
  description: string;
}

export interface FullPatientData {
  fullName: string;
  document: string;
  documentType: string;
  cellphoneNumber: string;
  birthDate: string;
  active: boolean;
  sex: GENDER;
  lastAttendance: string;
  createdAt: string;
  _id: string;
  patientAllergies: Allergy[];
  patientDiagnostics: Icd[];
}

export interface UpdateAdditionalDataPatientPayload {
  patientEmail: string;
  parentName: string;
  postalCode: string;
  address: string;
  houseNumber: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  patientId: string;
}

export interface UpdateClinicalDataPayload {
  patientId: string;
  CNS: string;
  ethnicity: string;
  height: string;
  lactating: boolean | null;
  allergies: Allergy[];
  diagnostics: Icd[];
  pregnant: boolean | null;
  sex: GENDER;
  smoker: boolean | null;
  weight: string;
}

export interface StructuredPatientData {
  basicData: PatientBasicData;
  additionInformation: PatientAdditionalData;
  clinicalData: PatientClinicalData;
}

export interface PatientBasicData {
  fullName: string;
  document: string;
  documentType: string;
  cellphoneNumber: string;
  birthDate: string;
  active: boolean;
  sex: GENDER;
  lastAttendance: string;
  _id: string | null;
}

export interface PatientAddress {
  address: string;
  city: string;
  complement: string;
  district: string;
  houseNumber: string;
  postalCode: string;
  state: string;
}
export interface PatientAdditionalData {
  patientId: string;
  patientEmail: string;
  parentName: string;
  patientAddress: PatientAddress;
}

export interface PatientClinicalData {
  patientId: string;
  CNS: string;
  ethnicity: string;
  height: string;
  lactating: boolean | null;
  patientAllergies: Allergy[];
  patientDiagnostics: Icd[];
  pregnant: boolean | null;
  sex: GENDER;
  smoker: boolean | null;
  weight: string;
}

export enum GENDER {
  MASCULINE = 'Masculino',
  FEMININE = 'Feminino',
  NOT_INFORMED = '',
}
