import React from 'react';
import { FormLabel as ChakraFormLabel, FormLabelProps as ChakraFormLabelProps } from '@chakra-ui/react';

export function FormLabel(props: ChakraFormLabelProps) {
  return (
    <ChakraFormLabel
      fontFamily={'Ambit-Bold'}
      fontSize={props.fontSize || '10px'}
      color={props.color || 'primary.1000'}
      {...props}
    />
  );
}
