import React from 'react';
import CheckIcon from 'src/components/Icons/CheckIcon';
import Typography from 'src/components/Typography';
import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { Flex } from 'src/components/UI/Flex';

interface InitPrescriptionDialogProps {
  onConfirm(): void;
  onCancel(): void;
}

export function InitPrescriptionDialog(props: InitPrescriptionDialogProps) {
  return (
    <Box bg={'surface'}>
      <Flex justifyContent={'center'} mb={'30px'} mt={'10px'}>
        <CheckIcon width={120} variantColor="secondary.main" />
      </Flex>
      <Box mb={'10px'}>
        <Typography model="h2" weight="bold" textAlign="center">
          Paciente cadastrado!
        </Typography>
      </Box>
      <Box mb={'20px'}>
        <Typography model="body" size="large" weight="regular" color="support.dark" textAlign="center">
          Deseja prescrever para esse paciente?
        </Typography>
      </Box>
      <Flex justifyContent={'space-between'}>
        <Button onClick={props.onCancel} variant={'outline'} w={'100%'} h={'60px'} mr={'10px'} tabIndex={-1}>
          Não
        </Button>
        <Button onClick={props.onConfirm} w={'100%'} h={'60px'} tabIndex={0}>
          Sim
        </Button>
      </Flex>
    </Box>
  );
}
