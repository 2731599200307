import React, { ChangeEvent } from 'react';
import Downshift from 'downshift';
import { Input } from './Input/Input';
import { List } from '@chakra-ui/react';
import { ListItem } from './ListItem';
import { Box } from './Box';
import { isEmpty } from 'lodash';
import { InputGroup } from './Input/InputGroup';
import { InputRightElement } from './Input/InputRightElement';
import { Spinner } from './Spinner';
import ExitIcon from '../Icons/ExitIcon';
import Typography from '../Typography';

const listStyle = {
  borderRadius: '10px',
  overflow: 'auto',
  background: '#fff',
  zIndex: 10,
  width: '100%',
  marginTop: '3px',
  border: '1px solid',
  borderColor: '#DEE2E6',
};

type LIST_POSITION = 'TOP' | 'BOTTOM';

const getListStyle = (listPosition: LIST_POSITION) => {
  if (listPosition === 'TOP') {
    return { ...listStyle, top: 'auto', bottom: '100%', marginBottom: '5px' };
  }
  return listStyle;
};

const listItemStyle = {
  paddingTop: '0.4rem',
  paddingBottom: '0.4rem',
  paddingLeft: '0.8rem',
  cursor: 'pointer',
};

interface AutoCompleteProps {
  onInputChange(event: ChangeEvent<HTMLInputElement>): void;
  inputValue: string;
  options: Array<any>;
  onSelect(value: any): void;
  isMenuOpen: boolean;
  noOptionsText?: string;
  placeholder?: string;
  getOptionLabel(option: any): string;
  menuMaxHeigh?: string;
  onClearInput(): void;
  isLoading: boolean;
  listPosition?: 'TOP' | 'BOTTOM';
  onFocus?(): void;
  onBlur?(event: React.FocusEvent<HTMLElement>): void;
  onCloseMenu(): void;
  testId?: string;
  name?: string;
  autoFocus?: boolean;
}

function AutoComplete(props: AutoCompleteProps) {
  const {
    onInputChange,
    inputValue,
    options,
    onSelect,
    isMenuOpen,
    noOptionsText,
    placeholder,
    getOptionLabel,
    menuMaxHeigh,
    onClearInput,
    isLoading,
    listPosition,
    onFocus,
    onBlur,
    onCloseMenu,
    testId,
    autoFocus,
  } = props;

  return (
    <Box position={'relative'}>
      <Downshift onChange={onSelect} itemToString={(item) => (item ? item.value : '')}>
        {({ getInputProps, getItemProps, getMenuProps, highlightedIndex }) => (
          <div>
            <InputGroup>
              <Input
                pr={'28px'}
                w={'100%'}
                {...getInputProps()}
                value={inputValue}
                onBlur={(e) => {
                  onCloseMenu();

                  if (!onBlur) return;
                  onBlur(e);
                }}
                onChange={onInputChange}
                placeholder={placeholder}
                onFocus={(e) => {
                  e.target.select();

                  if (!onFocus) return;
                  onFocus();
                }}
                data-testid={testId}
                name={props.name}
                autoFocus={autoFocus}
              />
              <InputRightElement>
                {isLoading ? (
                  <Spinner size={'sm'} color={'primary.main'} />
                ) : (
                  <Box data-testid={'clear-input'} onClick={onClearInput}>
                    <ExitIcon width={20} variantColor={'primary.main'} />
                  </Box>
                )}
              </InputRightElement>
            </InputGroup>

            {isMenuOpen && !isLoading && !isEmpty(options) && (
              <List
                {...getMenuProps({
                  style: {
                    ...getListStyle(listPosition || 'BOTTOM'),
                    position: 'absolute',
                    maxHeight: menuMaxHeigh || '200px',
                  },
                })}
              >
                {options.map((item, index) => (
                  <ListItem
                    {...getItemProps({
                      key: item.id,
                      index,
                      item,
                      style: {
                        ...listItemStyle,
                        backgroundColor: highlightedIndex === index ? '#F1F3F5' : 'white',
                      },
                    })}
                  >
                    <Typography fontSize={12} weight={'regular'}>
                      {getOptionLabel(item)}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            )}

            {isMenuOpen && !isLoading && isEmpty(options) && noOptionsText && (
              <Box pos={'absolute'} {...listItemStyle} {...getListStyle(listPosition || 'BOTTOM')}>
                <Typography fontSize={12}>{noOptionsText}</Typography>
              </Box>
            )}
          </div>
        )}
      </Downshift>
    </Box>
  );
}

export default AutoComplete;
