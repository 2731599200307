import { ChangeEvent, DragEvent, useRef, useState } from 'react';

import CameraIcon from 'src/components/Icons/CameraIcon';
import TrashStripedIcon from 'src/components/Icons/TrashStripedIcon';
import Typography from 'src/components/Typography';
import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { Flex } from 'src/components/UI/Flex';
import { HStack } from 'src/components/UI/HStack';
import { IconButton } from 'src/components/UI/IconButton';
import { ConfirmDeleteDialog } from 'src/components/ConfirmDeleteDialog';

import { validateFileSize } from 'src/helpers/validateFileSize';

import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { DialogWrappedComponentProps, useFeedbackContext } from 'src/providers/FeedbackProvider';

import { ImageService } from 'src/services/image';
import { PrescriberService } from 'src/services/prescriber';

const MAX_SIZE_IN_KB = 512;

interface UploadLogoDropZoneProps {
  currentLogoUrl: string;
  onChangeLogoUrl: (logoUrl: string) => void;
}

export function UploadLogoDropZone({ currentLogoUrl, onChangeLogoUrl }: UploadLogoDropZoneProps) {
  const [selectedLogo, setSelectedLogo] = useState<string | null>(currentLogoUrl);
  const inputRef = useRef<HTMLInputElement>(null);
  const { openToast, openBaseDialog } = useFeedbackContext();
  const { prescriberData } = usePrescriberContext();

  function handleDragOver(event: DragEvent) {
    event.preventDefault();
  }

  async function validateAndUploadFile(file: File, prescriberId: string) {
    try {
      const fileSizeIsValid = validateFileSize(file, MAX_SIZE_IN_KB);

      if (!fileSizeIsValid) {
        return openToast(`O arquivo excede o limite máximo de ${MAX_SIZE_IN_KB} KB`, {
          type: 'error',
          duration: 4000,
        });
      }

      let formData = new FormData();

      formData.append('file', file);
      formData.append('height', '80');

      const { url } = await ImageService.uploadImage(formData, prescriberId);

      onChangeLogoUrl(url);
      setSelectedLogo(url);
    } catch (error) {
      openToast(`Ocorreu um erro ao realizar o upload da imagem`, {
        type: 'error',
        duration: 4000,
      });
    }
  }

  async function handleDrop(event: DragEvent<HTMLDivElement>) {
    try {
      event.preventDefault();

      if (!event.dataTransfer.files) return;

      const selectedFile = event.dataTransfer.files[0];

      if (!selectedFile) return;

      await validateAndUploadFile(selectedFile, prescriberData?._id ?? '');
    } catch (error) {
      openToast(`Ocorreu um erro ao realizar o upload da imagem`, {
        type: 'error',
        duration: 4000,
      });
    }
  }

  async function handleSelectFile(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return;

    const selectedFile = event.target.files[0];

    if (!selectedFile) return;

    await validateAndUploadFile(selectedFile, prescriberData?._id ?? '');
  }

  async function handleRemoveLogo() {
    if (!prescriberData) {
      return;
    }

    await openBaseDialog(
      (dialogProps: DialogWrappedComponentProps) => (
        <Box width={'100%'} h={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <ConfirmDeleteDialog
            text="Ao escolher excluir, o logo atual será permanentemente excluído."
            onCancel={dialogProps.onClose}
            onConfirm={async () => {
              PrescriberService.removeLogo(prescriberData._id);
              dialogProps.onClose();
            }}
          />
        </Box>
      ),
      {
        width: '270px',
        height: '356px',
      }
    );

    setSelectedLogo(null);

    openToast('Logo removido com sucesso!', {
      type: 'success',
    });
  }

  const shouldRenderRemoveLogoButton = !!selectedLogo;

  return (
    <Box bgColor={'support.background'} py={'10px'} borderRadius={'20px'}>
      <Flex
        flexDirection={'column'}
        gap={'12px'}
        mx={'auto'}
        w={{
          base: 'auto',
          xl: '352px',
        }}
        px={'16px'}
      >
        <Box bgPosition={'center'} bgRepeat={'no-repeat'} bgImage={selectedLogo ?? ''}>
          <Flex
            alignItems={'center'}
            justifyContent={'center'}
            flexDirection={'column'}
            gap={'10px'}
            borderRadius={'20px'}
            py={'18px'}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            bgColor={'rgba(61, 14, 107, 0.4)'}
          >
            <HStack>
              <input type="file" onChange={handleSelectFile} accept="image/png, image/jpeg" ref={inputRef} hidden />

              <Button
                aria-label={'Carregar Logo'}
                variant={'outline'}
                borderColor={'white'}
                bgColor={'transparent'}
                color={'white'}
                transition={'0.2s'}
                _hover={{
                  filter: 'brightness(0.8)',
                }}
                leftIcon={<CameraIcon width={20} />}
                onClick={() => inputRef.current?.click()}
              >
                Carregar Logo
              </Button>

              {shouldRenderRemoveLogoButton && (
                <IconButton
                  data-testid={'remove-logo-button'}
                  aria-label={'Excluir Logo'}
                  variant={'outline'}
                  borderColor={'white'}
                  borderRadius={'full'}
                  bgColor={'transparent'}
                  color={'white'}
                  transition={'0.2s'}
                  _hover={{
                    filter: 'brightness(0.8)',
                  }}
                  _focus={{
                    backgroundColor: 'transparent',
                  }}
                  icon={<TrashStripedIcon width={20} />}
                  onClick={handleRemoveLogo}
                />
              )}
            </HStack>

            <Typography model={'body'} size={'large'} weight={'regular'} textAlign={'center'} color={'support.surface'}>
              Arraste e solte a imagem aqui
            </Typography>
          </Flex>
        </Box>

        <Typography model={'body'} size={'large'} weight={'regular'} textAlign={'center'} color={'primary.800'}>
          A imagem deve ter no máximo 512kB e idealmente a proporção de 16x5 em jpg ou png
        </Typography>
      </Flex>
    </Box>
  );
}
