import React from 'react';
import { Box } from './Box';
import { Text } from './Text';

import { IconButton } from './IconButton';
import { ReactComponent as RoundClose } from '../../assets/icons/round-close.svg';

interface BaseToastProps {
  backgroundColor: string;
  color: string;
  children: React.ReactNode;
  outlined?: boolean;
  onClose(): void;
}

export function BaseToast(props: BaseToastProps) {
  return (
    <Box
      p={'8px'}
      borderRadius={'10px'}
      backgroundColor={props.backgroundColor}
      borderWidth={'2px'}
      borderColor={props.outlined ? props.color : props.backgroundColor}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Text letterSpacing={'1px'} lineHeight={'15px'} fontWeight={700} fontSize={'14px'} color={props.color}>
        {props.children}
      </Text>

      <IconButton
        onClick={props.onClose}
        _focus={{}}
        _hover={{}}
        borderRadius={'50%'}
        aria-label="close"
        size={'xs'}
        fill={props.color}
        variant={'ghost'}
      >
        <RoundClose />
      </IconButton>
    </Box>
  );
}
