import { createContext, useCallback, useContext, useState, ReactNode, FC } from 'react';
import { useToast } from '@chakra-ui/react';
import { BaseDialog } from '../components/UI/Dialog/BaseAlertDialog';
import { BaseToast } from '../components/UI/BaseToast';

const toastConfigs = {
  success: {
    color: 'white',
    backgroundColor: 'notification.success',
    outlined: false,
  },
  'success-outline': {
    color: 'notification.success',
    backgroundColor: 'white',
    outlined: true,
  },
  error: {
    color: 'white',
    backgroundColor: 'notification.error',
    outlined: false,
  },
  'error-outline': {
    color: 'notification.error',
    backgroundColor: 'white',
    outlined: true,
  },
};

const defaultToastConfig = {
  color: 'red',
  backgroundColor: 'purple',
  outlined: false,
};

interface FeedbackContextProps {
  openBaseDialog(WrappedComponent: FC<DialogWrappedComponentProps>, options?: DialogOptions): void;
  openToast(message: string, options: { type: TOAST_TYPES; position?: TOAST_POSITION; duration?: number }): void;
}

export const FeedbackContext = createContext<FeedbackContextProps>({
  openBaseDialog: (WrappedComponent: FC<any>, options?: DialogOptions) => {},
  openToast: (message: string, options: { type: TOAST_TYPES; position?: TOAST_POSITION; duration?: number }) => {},
});

export interface FeedbackProviderInterface {
  children: ReactNode;
}

export function FeedbackProvider(props: FeedbackProviderInterface) {
  const [dialogs, setDialogs] = useState<Array<FC>>([]);

  const toast = useToast();

  const openBaseDialog = useCallback((WrappedComponent: FC<any>, options?: DialogOptions): Promise<void> => {
    return new Promise((resolve) => {
      const Component = () => {
        const [isOpen, setIsOpen] = useState(true);

        const onClose = () => {
          setIsOpen(false);
          resolve();

          if (options?.onCloseCallback) {
            options.onCloseCallback();
          }
        };

        const onChange = (value: boolean) => {
          setIsOpen(value);

          if (!value) {
            resolve();
            onClose();
          }
        };

        const baseStyles = {
          maxWidth: options?.maxWidth ?? options?.width ?? '414px',
          width: options?.width ?? '414px',
          height: options?.height || '414px',
          maxHeight: options?.maxHeight ?? options?.height ?? '414px',
          overflow: options?.overflow ?? 'hidden',
          padding: options?.padding || '20px',
          backgroundColor: options?.backgroundColor || '#fff',
          boxShadow: options?.backgroundColor ? 'none' : '0px 0px 15px 5px rgba(0,0,0,0.22)',
          borderRadius: options?.borderRadius || '30px',
        };

        return (
          <BaseDialog
            isOpen={isOpen}
            onChange={onChange}
            persist={options?.persist}
            width={options?.width}
            height={options?.height}
            showCloseButton={options?.showCloseButton}
            customStyles={baseStyles}
          >
            <WrappedComponent onClose={onClose} />
          </BaseDialog>
        );
      };

      setDialogs((prevState) => [...prevState, Component]);
    });
  }, []);

  const openToast = useCallback(
    (message: string, options: { type: TOAST_TYPES; position?: TOAST_POSITION; duration?: number }) => {
      const closeToast = () => {
        toast.closeAll();
      };

      const selectedConfig = toastConfigs[options.type] || defaultToastConfig;

      return toast({
        position: options.position || 'top-right',
        isClosable: true,
        duration: options.duration || 3000,
        render: () => (
          <BaseToast
            onClose={closeToast}
            color={selectedConfig.color}
            backgroundColor={selectedConfig.backgroundColor}
            outlined={selectedConfig.outlined}
          >
            {message}
          </BaseToast>
        ),
      });
    },
    [toast]
  );

  return (
    <FeedbackContext.Provider
      value={{
        openBaseDialog,
        openToast,
      }}
    >
      <>
        {props.children}
        {dialogs.map((Dialog, index) => (
          <Dialog key={index} />
        ))}
      </>
    </FeedbackContext.Provider>
  );
}

export function useFeedbackContext() {
  const feedbackContext = useContext(FeedbackContext);

  return feedbackContext;
}

export interface DialogWrappedComponentProps {
  onClose(): void;
}

export type TOAST_TYPES = 'success' | 'success-outline' | 'error' | 'error-outline';
export type TOAST_POSITION = 'top' | 'top-right' | 'top-left' | 'bottom' | 'bottom-right' | 'bottom-left';
export interface DialogOptions {
  persist?: boolean;
  width?: string;
  maxWidth?: string;
  height?: string;
  maxHeight?: string;
  showCloseButton?: boolean;
  onCloseCallback?(): void;
  backgroundColor?: string;
  padding?: string;
  borderRadius?: string;
  overflow?: string;
}
