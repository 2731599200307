import { Flex } from '@chakra-ui/react';
import MedicineStripe from 'src/components/Icons/MedicineStripe';
import Typography from 'src/components/Typography';
import { PrescriptionMedicine } from 'src/services/prescriptionHistory';

interface MedicineProps {
  medicine: PrescriptionMedicine;
}

export function Medicine(props: MedicineProps) {
  const { medicine } = props;

  return (
    <Flex gap="10px">
      <Flex alignSelf="flex-start">
        <MedicineStripe medicine={medicine} />
      </Flex>
      <Flex grow={1} flexDirection="column" gap="10px">
        <Typography model="body" size="large" weight="bold">
          {medicine.Label}
        </Typography>
        <Typography model="body" size="large" weight="regular">
          {medicine.posology}
        </Typography>
        <Typography model="body" size="normal" weight="regular">
          {medicine.formattedUnitAndQuantity}
        </Typography>
      </Flex>
    </Flex>
  );
}
