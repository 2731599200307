import { Flex } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Button } from 'src/components/UI/Button';
import { PRESCRIPTION_STATUS } from 'src/services/prescriptionHistory';

interface PrescriptionFooterProps {
  prescriptionStatus: PRESCRIPTION_STATUS;
  onCancel: () => void;
  // onResend: () => void;
  // onContinue: () => void;
  // onRenew: () => void;
}

export function PrescriptionFooter(props: PrescriptionFooterProps) {
  const { prescriptionStatus, onCancel } = props;

  const showCancel = useMemo(() => {
    return prescriptionStatus !== PRESCRIPTION_STATUS.CANCELED;
  }, [prescriptionStatus]);

  // const showResend = useMemo(() => {
  //   return prescriptionStatus === PRESCRIPTION_STATUS.EMITTED;
  // }, [prescriptionStatus]);

  // const showContinue = useMemo(() => {
  //   return prescriptionStatus === PRESCRIPTION_STATUS.INITIATED;
  // }, [prescriptionStatus]);

  // const showRenew = useMemo(() => {
  //   return prescriptionStatus !== PRESCRIPTION_STATUS.INITIATED;
  // }, [prescriptionStatus]);

  return (
    <Flex
      gap="10px"
      justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
      flexDirection={{ base: 'column', sm: 'row' }}
    >
      {showCancel && (
        <Button
          data-testid="prescription-footer-button"
          variant="outline-error"
          size="lg"
          height="60px"
          justifyContent={{ base: 'flex-end', sm: 'center' }}
          onClick={onCancel}
        >
          Cancelar
        </Button>
      )}
      {/* {showResend && (
        <Button
          data-testid="prescription-footer-button"
          variant="outline"
          size="lg"
          height="60px"
          justifyContent={{ base: 'space-between', sm: 'center' }}
          onClick={onResend}
        >
          {isMobile && <ForwardingIcon width={16} variantColor="secondary.400" />}
          Reenviar
        </Button>
      )}
      {showContinue && (
        <Button
          data-testid="prescription-footer-button"
          variant="outline"
          size="lg"
          height="60px"
          justifyContent={{ base: 'space-between', sm: 'center' }}
          onClick={onContinue}
        >
          {isMobile && <EditIcon width={16} variantColor="support.blue" />}
          Continuar
        </Button>
      )}
      {showRenew && (
        <Button
          data-testid="prescription-footer-button"
          size="lg"
          height="60px"
          justifyContent={{ base: 'space-between', sm: 'center' }}
          onClick={onRenew}
        >
          {isMobile && <BuyAgainIcon width={16} baseColor="support.green" />}
          Renovar
        </Button>
      )} */}
    </Flex>
  );
}
