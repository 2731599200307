import React from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { Patient } from 'src/services/patient';
import { PatientCard } from './PatientCard';
import { NoResultsFound } from './Search/NoResultsFound';
import { Flex } from './UI/Flex';
import { Button } from './UI/Button';
import { AnimatePresence, motion } from 'framer-motion';

interface PatientListProps {
  showActionButtons?: boolean;
  onEdit?(patientId: string): void;
  onInitPrescription?(patientId: string): Promise<void>;
  onDelete?(patientId: string): Promise<void>;
  onClickCallback(patient: Patient): Promise<void>;
  patientList: Patient[] | null;
  isLoading: boolean;
  onLoadMore(): void;
  hasNextPage: boolean;
}

export function PatientList(props: PatientListProps) {
  const {
    showActionButtons,
    onEdit,
    onInitPrescription,
    onDelete,
    onClickCallback,
    patientList,
    isLoading,
    onLoadMore,
    hasNextPage,
  } = props;

  if (patientList && (patientList || []).length === 0) {
    return <NoResultsFound />;
  }
  return (
    <AnimatePresence>
      <SimpleGrid columns={{ base: 1, sm: 1, md: 3 }} gap={'10px'} padding={'2px'} m={'10px 0'}>
        {(patientList || []).map((patient) => {
          return (
            <motion.div
              initial={{ opacity: 0, position: 'absolute' }}
              animate={{ opacity: 1, position: 'relative' }}
              key={patient._id}
              transition={{
                type: 'spring',
                stiffness: 100,
                mass: 0.3,
              }}
            >
              <PatientCard
                key={patient._id}
                showActionButtons={showActionButtons}
                patient={patient}
                onClickCallback={onClickCallback}
                onEdit={onEdit && onEdit}
                onDelete={onDelete && onDelete}
                onInitPrescription={onInitPrescription && onInitPrescription}
              />
            </motion.div>
          );
        })}
      </SimpleGrid>

      {hasNextPage && (
        <Flex justifyContent={'center'}>
          <Button onClick={onLoadMore} w={'100%'} m={'10px 0'} variant="outline" maxW={'300px'} isLoading={isLoading}>
            Ver mais
          </Button>
        </Flex>
      )}
    </AnimatePresence>
  );
}
