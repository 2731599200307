import { useCallback, useState } from 'react';

const useLoading = (): [boolean, Function] => {
  const [loading, setLoading] = useState(false);

  const runWithLoading = useCallback(async (callback: Function) => {
    try {
      setLoading(true);
      await callback();
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  }, []);

  return [loading, runWithLoading];
};

export default useLoading;
