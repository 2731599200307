import React, { useState } from 'react';
import ExitIcon from 'src/components/Icons/ExitIcon';
import MevoInput from 'src/components/Input';
import MevoPill from 'src/components/Pill/Pill';
import Typography from 'src/components/Typography';
import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { Flex } from 'src/components/UI/Flex';
import useLoading from 'src/helpers/useLoading';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';
import { AuthService } from 'src/services/AuthService';

interface ConfirmDeleteDialogProps {
  onConfirm(): void;
  onCancel(): void;
  prescriberEmail: string;
}

export function DeleteAccountDialog(props: ConfirmDeleteDialogProps) {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [loading, runWithLoading] = useLoading();

  const { openToast } = useFeedbackContext();

  const validateUserIdentity = async () => {
    if (!password || password.length <= 0) {
      setPasswordError(true);
      return;
    }

    setPasswordError(false);

    runWithLoading(async () => {
      try {
        await AuthService.signIn(props.prescriberEmail, password);
        props.onConfirm();
      } catch (error) {
        openToast('Ocorreu um erro ao excluir esta conta. Verifique sua senha e tente novamente.', {
          type: 'error',
          duration: 2000,
        });
      }
    });
  };

  return (
    <Box bg={'surface'} p={'30px'} borderRadius={'30px'} maxWidth={'400px'}>
      <Flex justifyContent={'flex-end'}>
        <ExitIcon variantColor="secondary.main" onClick={props.onCancel} />
      </Flex>
      <Flex justifyContent={'center'} mb={'30px'} mt={'10px'}>
        <MevoPill pillColor="pink" faceStyle="crazy" pillWidth={100} faceWidth={37} />
      </Flex>
      <Box mb={'10px'}>
        <Typography model="h2" weight="bold">
          Tem certeza que quer excluir?
        </Typography>
      </Box>
      <Box mb={'20px'}>
        <Typography model="body" size="large" weight="regular">
          Informe sua senha para continuar com o processo de exclusão da sua conta Mevo.
        </Typography>
      </Box>
      <Box mb={'20px'}>
        <MevoInput
          labelText="Informe sua senha"
          placeholder="Digite sua senha"
          onChange={(e) => setPassword(e.target.value)}
          isInvalid={passwordError}
          type="password"
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              e.target.blur();
              validateUserIdentity();
            }
          }}
        ></MevoInput>
      </Box>
      <Flex justifyContent={'space-between'}>
        <Button onClick={validateUserIdentity} variant={'outline-error'} w={'100%'} h={'60px'} isLoading={loading}>
          Confirmar
        </Button>
      </Flex>
    </Box>
  );
}
