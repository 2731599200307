import React from 'react';
import { Image, Show, SimpleGrid } from '@chakra-ui/react';
import { NavLink, useNavigate } from 'react-router-dom';

import UserMenu from './UserMenu';
import Typography from '../Typography';

import { Flex } from '../UI/Flex';
import { Button } from '../UI/Button';
import { Box } from 'src/components/UI/Box';

import DoctorIcon from '../Icons/DoctorIcon';
import PillsIcon from '../Icons/PillsIcon';
import UserIcon from '../Icons/UserIcon';
import ExitIcon from '../Icons/ExitIcon';
import CallCenterIcon from '../Icons/CallCenterIcon';
// import ShareIcon from '../Icons/ShareIcon';
// import HistoricIcon from '../Icons/HistoricIcon';
// import DocPaperMultipleIcon from '../Icons/DocPaperMultipleIcon';

import { useIsMobile } from 'src/helpers/useIsMobile';

import { ReactComponent as Logo } from 'src/assets/images/logo.svg';
import HistoricIcon from '../Icons/HistoricIcon';
import TutorialIcon from '../Icons/TutorialIcon';
import { Tracker } from 'src/shared/tracker/Tracker';

interface SidebarButtonProps {
  children: React.ReactNode[];
  pathTo: string;
}

const SidebarButton = (props: SidebarButtonProps) => {
  const isMobile = useIsMobile();

  const baseStyle = {
    width: '100%',
    height: isMobile ? '137px' : '62px',
    borderRadius: '20px',
    padding: '20px',
    boxShadow: isMobile ? '3px 3px 10px rgba(61, 14, 107, 0.1)' : 'none',
    backgroundColor: '#fff',
    borderWidth: isMobile ? '0px' : '2px',
    borderColor: '#FFE4EB',
    transition: 'all 0.3s',
  };

  const activeStyle = {
    ...baseStyle,
    backgroundColor: '#FFE4EB',
  };

  return (
    <NavLink to={props.pathTo} style={({ isActive }) => (isActive ? activeStyle : baseStyle)}>
      <Flex alignItems={'center'} justifyContent={'flex-start'} h={'100%'} w={'100%'} flexDirection={'row'}>
        {props.children}
      </Flex>
    </NavLink>
  );
};

interface SidebarProps {
  onClose?(): void;
}

export const SIDE_BAR_WIDTH = '190px';

export function Sidebar(props: SidebarProps) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const iconWidth = isMobile ? 40 : 20;

  const handleNavigateToTutorials = () => {
    navigate('/inicio/tutorial');

    Tracker.trackEvent({
      event: 'Tutorial Page Clicked',
    });
  };

  return (
    <Box
      bg={'surface'}
      w={SIDE_BAR_WIDTH}
      h={'full'}
      p={'20px'}
      borderRadius={'0px 20px 20px 0px'}
      position={'fixed'}
      overflow={'auto'}
    >
      <Show below="sm">
        <Flex mb={'80px'} alignItems={'center'} justifyContent={'space-between'} pt={'10px'} h={'50px'}>
          <UserMenu />
          <ExitIcon variantColor="secondary" onClick={props.onClose} />
        </Flex>
      </Show>

      <Flex justifyContent={'center'}>
        <Logo fill={'#3D0E6B'} width={'100%'} height={'25px'} />
      </Flex>

      <Flex
        h={'calc(100% - 55px)'}
        w={'100%'}
        flexDirection={'column'}
        mt={'30px'}
        justifyContent={'space-between'}
        gap={'10px'}
      >
        <SimpleGrid w={'full'} columns={1} spacing={'10px'}>
          <SidebarButton pathTo="/inicio/receita">
            <Flex mr={'10px'}>
              <PillsIcon variantColor="support.blue" width={iconWidth} />
            </Flex>
            <Typography
              whiteSpace={'pre-wrap'}
              model={'body'}
              size={'normal'}
              textAlign={isMobile ? 'center' : 'left'}
              weight="bold"
            >
              Iniciar prescrição
            </Typography>
          </SidebarButton>
          <SidebarButton pathTo="/inicio/gerenciar-pacientes">
            <Flex mr={'10px'} mb={0}>
              <UserIcon variantColor="notification.success" width={iconWidth} />
            </Flex>
            <Typography
              whiteSpace={'pre-wrap'}
              model={'body'}
              size={'normal'}
              textAlign={isMobile ? 'center' : 'left'}
              weight="bold"
            >
              Meus pacientes
            </Typography>
          </SidebarButton>
          {/* <SidebarButton>
          <Flex mr={isMobile ? 0 : '10px'} mb={isMobile ? '20px' : 0}>
            <DocPaperMultipleIcon variantColor="support.yellow" width={iconWidth} />
          </Flex>
          <Typography
            whiteSpace={'pre-wrap'}
            model={'body'}
            size={'normal'}
            textAlign={isMobile ? 'center' : 'left'}
            weight="bold"
          >
            Meus modelos
          </Typography>
        </SidebarButton> */}
          <SidebarButton pathTo="/inicio/historico">
            <Flex mr={isMobile ? 0 : '10px'} mb={isMobile ? '20px' : 0}>
              <HistoricIcon variantColor="support.blue" width={iconWidth} />
            </Flex>
            <Typography
              whiteSpace={'pre-wrap'}
              model={'body'}
              size={'normal'}
              textAlign={isMobile ? 'center' : 'left'}
              weight="bold"
            >
              Histórico
            </Typography>
          </SidebarButton>
          <SidebarButton pathTo="/inicio/meus-dados">
            <Flex mr={'10px'} mb={0}>
              <DoctorIcon variantColor="support.blue" width={iconWidth} />
            </Flex>
            <Typography model={'body'} size={'normal'} textAlign={isMobile ? 'center' : 'left'} weight="bold">
              Meus dados
            </Typography>
          </SidebarButton>
          {/* <SidebarButton>
          <Flex mr={isMobile ? 0 : '10px'} mb={isMobile ? '20px' : 0}>
            <ShareIcon variantColor="notification.success" width={iconWidth} />
          </Flex>
          <Typography
            whiteSpace={'pre-wrap'}
            model={'body'}
            size={'normal'}
            textAlign={isMobile ? 'center' : 'left'}
            weight="bold"
          >
            Convidar profissionais
          </Typography>
        </SidebarButton> */}
        </SimpleGrid>

        <Box position={'unset'} bottom={'20px'} left={'20px'} w={'100%'}>
          <Button
            w={'100%'}
            borderRadius={'30px'}
            px={'20px'}
            bg={'surface'}
            variant={isMobile ? 'ghost' : 'outline-secondary'}
            minH={{ base: '60px', sm: '62px' }}
            boxShadow={'none'}
            display={'flex'}
            justifyContent={'flex-start'}
            onClick={handleNavigateToTutorials}
            gap={'10px'}
            mb={'5px'}
          >
            <TutorialIcon variantColor={'support.blue'} width={20} />

            <Typography whiteSpace={'pre-wrap'} model={'body'} size={'normal'} textAlign={'left'} weight="bold">
              Tutoriais
            </Typography>
          </Button>
          <Button
            w={'100%'}
            borderRadius={'30px'}
            px={'20px'}
            bg={'surface'}
            variant={isMobile ? 'ghost' : 'outline-secondary'}
            minH={'62px'}
            boxShadow={'none'}
            display={'flex'}
            justifyContent={'flex-start'}
            onClick={() => navigate('/inicio/ajuda')}
            gap={'10px'}
          >
            <CallCenterIcon variantColor={'support.blue'} width={20} />

            <Typography whiteSpace={'pre-wrap'} model={'body'} size={'normal'} textAlign={'left'} weight="bold">
              Precisando de ajuda?
            </Typography>
          </Button>
        </Box>
      </Flex>
    </Box>
  );
}
