import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Hide, Show, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import { AdditionalDataAndAddressForm } from './forms/AdditionalDataAndAddressForm';
import { ClinicalDataForm } from './forms/ClinicalDataForm/ClinicalDataForm';

import { Header } from './Header';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import { AccordionFormButton } from 'src/components/AccordionFormButton';
import { useIsMobile } from 'src/helpers/useIsMobile';
import useLoading from 'src/helpers/useLoading';
import { BasicDataForm } from './forms/BasicDataForm';
import { GENDER, PatientService, StructuredPatientData } from 'src/services/patient';
import { PrescriberContext } from 'src/providers/PrescriberProvider';
import { AnimatedRoute } from 'src/router/AnimatedRoute';
import { Accordion, AccordionItem, AccordionPanel } from 'src/components/UI/Accordion';
import { TabFormButton } from 'src/components/TabFormButton';

enum FORM_INDEX {
  BASIC_DATA = 0,
  ADDITIONAL_DATA = 1,
  CLINIC_DATA = 2,
  NONE = -1,
}

export function ManagePatient() {
  const routeParams = useParams();

  const currentEditingPatientId = useMemo(() => {
    return get(routeParams, 'patientId', '');
  }, [routeParams]);

  const { prescriberId } = useContext(PrescriberContext);

  const [tabIndex, setTabIndex] = useState<FORM_INDEX>(FORM_INDEX.BASIC_DATA);

  const [accordionIndex, setAccordionIndex] = useState<FORM_INDEX>(
    !currentEditingPatientId ? FORM_INDEX.BASIC_DATA : FORM_INDEX.NONE
  );

  const [patient, setPatient] = useState<StructuredPatientData | null>(null);

  const [loading, runWithLoading] = useLoading();

  const isMobile = useIsMobile();

  const shouldBlockFormNavigation = useMemo(() => {
    if (loading) return true;

    return !currentEditingPatientId && !patient;
  }, [currentEditingPatientId, patient, loading]);

  const handleTabsChange = (index: FORM_INDEX) => {
    setTabIndex(index);
  };

  const fetchPatient = useCallback(
    (patientId: string) => {
      if (!prescriberId) return;

      runWithLoading(async () => {
        const patient = await PatientService.getStructuredPatientData(prescriberId, patientId);

        setPatient(patient);
      });

      return;
    },
    [prescriberId, runWithLoading]
  );

  const resetAccordion = () => {
    setAccordionIndex(FORM_INDEX.NONE);
  };

  const handleSetPatient = (patientId: string) => {
    fetchPatient(patientId);

    if (isMobile) {
      resetAccordion();
      return;
    }

    setTabIndex(FORM_INDEX.ADDITIONAL_DATA);
  };

  const handleSetAdditionalData = () => {
    if (isMobile) {
      resetAccordion();
      return;
    }

    setTabIndex(FORM_INDEX.CLINIC_DATA);
  };

  const handleSetClinicalData = () => {
    if (isMobile) {
      resetAccordion();
      return;
    }
  };

  const patientId = patient?.basicData._id || null;

  const basicFormData = useMemo(() => {
    if (!patient) return null;

    return patient.basicData;
  }, [patient]);

  const additionalFormData = useMemo(() => {
    if (!patient || !patientId) return null;

    return { ...patient.additionInformation, patientId };
  }, [patient, patientId]);

  const clinicalFormData = useMemo(() => {
    if (!patient || !patientId) return null;

    return { ...patient.clinicalData, patientId, sex: patient.basicData.sex };
  }, [patient, patientId]);

  const updatePatient = (name: string, gender: GENDER) => {
    setPatient((prev) => {
      if (!prev) return prev;

      return { ...prev, basicData: { ...prev.basicData, fullName: name, sex: gender } };
    });

    resetAccordion();
  };

  useEffect(() => {
    if (currentEditingPatientId) {
      fetchPatient(currentEditingPatientId);

      return;
    }
  }, [runWithLoading, currentEditingPatientId, fetchPatient]);

  return (
    <AnimatedRoute>
      <Header isLoadingData={loading} patientName={patient?.basicData.fullName || null} />

      <Hide below={'lg'}>
        <Tabs
          index={tabIndex}
          variant={'unstyled'}
          mt={'32px'}
          display={'flex'}
          gap={'40px'}
          flexDirection={'row'}
          onChange={handleTabsChange}
        >
          <TabList display={'flex'} flexDirection={'column'} gap={'5px'}>
            <TabFormButton text={'Dados básicos'} isDisabled={false} />
            <TabFormButton text={'Adicionais e endereço'} isDisabled={shouldBlockFormNavigation} />
            <TabFormButton text={'Dados clínicos'} isDisabled={shouldBlockFormNavigation} />
          </TabList>

          <TabPanels>
            <TabPanel p={0}>
              <BasicDataForm
                formData={basicFormData}
                isLoadingData={loading}
                onEditPatient={updatePatient}
                onCreatePatient={handleSetPatient}
              />
            </TabPanel>

            <TabPanel p={0}>
              <AdditionalDataAndAddressForm formData={additionalFormData} onChange={handleSetAdditionalData} />
            </TabPanel>

            <TabPanel p={0}>
              <ClinicalDataForm formData={clinicalFormData} onChange={handleSetClinicalData} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Hide>

      <Show below={'lg'}>
        <Accordion allowToggle display={'flex'} flexDirection={'column'} gap={'8px'} mt={'32px'} index={accordionIndex}>
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionFormButton
                  isExpanded={isExpanded}
                  text={'Dados básicos'}
                  isDisabled={false}
                  onClick={async () => {
                    if (accordionIndex === FORM_INDEX.BASIC_DATA) {
                      setAccordionIndex(FORM_INDEX.NONE);
                      return;
                    }

                    setAccordionIndex(FORM_INDEX.BASIC_DATA);
                  }}
                />
                {isExpanded && (
                  <AccordionPanel p={'20px'} backgroundColor={'surface'} borderRadius={'0 0 10px 10px'}>
                    <BasicDataForm
                      isLoadingData={loading}
                      onEditPatient={updatePatient}
                      onCreatePatient={handleSetPatient}
                      formData={basicFormData}
                    />
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>

          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionFormButton
                  isExpanded={isExpanded}
                  text={'Adicionais e endereço'}
                  isDisabled={shouldBlockFormNavigation}
                  onClick={async () => {
                    if (accordionIndex === FORM_INDEX.ADDITIONAL_DATA) {
                      setAccordionIndex(FORM_INDEX.NONE);
                      return;
                    }
                    setAccordionIndex(FORM_INDEX.ADDITIONAL_DATA);
                  }}
                />

                {isExpanded && (
                  <AccordionPanel p={'20px'} backgroundColor={'surface'} borderRadius={'0 0 10px 10px'}>
                    <AdditionalDataAndAddressForm formData={additionalFormData} onChange={handleSetAdditionalData} />
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>

          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionFormButton
                  isExpanded={isExpanded}
                  text={'Dados clínicos'}
                  isDisabled={shouldBlockFormNavigation}
                  onClick={async () => {
                    if (accordionIndex === FORM_INDEX.CLINIC_DATA) {
                      setAccordionIndex(FORM_INDEX.NONE);
                      return;
                    }
                    setAccordionIndex(FORM_INDEX.CLINIC_DATA);
                  }}
                />

                {isExpanded && (
                  <AccordionPanel p={'20px'} backgroundColor={'surface'} borderRadius={'0 0 10px 10px'}>
                    <ClinicalDataForm formData={clinicalFormData} onChange={handleSetClinicalData} />
                  </AccordionPanel>
                )}
              </>
            )}
          </AccordionItem>
        </Accordion>
      </Show>
    </AnimatedRoute>
  );
}
