import { MD5 } from 'object-hash';

export const capitalize = (val: string, exclude = ['de']) => {
  return val
    .split(' ')
    .map((piece) => {
      const pieceInLowerCase = piece.toLowerCase();
      if (exclude.includes(piece.toLowerCase())) {
        return pieceInLowerCase.toLowerCase();
      }

      const firstChar = pieceInLowerCase.charAt(0).toUpperCase();
      return firstChar + pieceInLowerCase.slice(1);
    })
    .join(' ');
};

export const capitalizeFirstLetter = (val: string) => val.charAt(0).toUpperCase() + val.toLowerCase().slice(1);

export const onlyDigits = (val: string) => {
  const match = val.match(/\d/g);
  if (match) return match.join('');
  return val;
};

export const formatCellphone = (telefone: string) => {
  const regex = /(\d{2})(\d{4,5})(\d{4})/;
  const match = telefone.match(regex);
  if (!match || match.length < 4) return telefone;
  return `(${match[1]}) ${match[2]}-${match[3]}`;
};

export const formatCpf = (cpf: string) => {
  const regex = /(\d{3})(\d{3})(\d{3})(\d{2})/;
  const match = cpf.match(regex);
  if (!match || match.length === 11) return cpf;
  return `${match[1]}.${match[2]}.${match[3]}-${match[4]}`;
};

export const firstLetterInLowerCase = (text: string) => {
  if (text.length === 0) return text;
  return text.charAt(0).toLowerCase() + text.slice(1);
};

export const removeAccentuation = (value: string): string => value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const encryptToMd5 = (message: string) => MD5(message);
