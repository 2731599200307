const hasCPFLength = (cpf: string): void | boolean => {
  return cpf.length === 11;
};

const allDigitsAreEqual = (digits: string): boolean => {
  return new Set(digits.split('')).size === 1;
};

const calcFirstChecker = (firstNineDigits: string): number => {
  let sum = 0;

  for (let i = 0; i < 9; ++i) {
    sum += Number(firstNineDigits.charAt(i)) * (10 - i);
  }

  const lastSumChecker = sum % 11;
  return lastSumChecker < 2 ? 0 : 11 - lastSumChecker;
};

const calcSecondChecker = (cpfWithChecker1: string): number => {
  let sum = 0;

  for (let i = 0; i < 10; ++i) {
    sum += Number(cpfWithChecker1.charAt(i)) * (11 - i);
  }

  const lastSumChecker2 = sum % 11;
  return lastSumChecker2 < 2 ? 0 : 11 - lastSumChecker2;
};

export const validateCPF = (value: string): boolean => {
  if (typeof value !== 'string') {
    return false;
  }

  const cleanCPF = String(value).replace(/[\s.-]/g, '');
  const firstNineDigits = cleanCPF.slice(0, 9);
  const checker = cleanCPF.slice(9, 11);

  if (!hasCPFLength(cleanCPF) || allDigitsAreEqual(cleanCPF)) {
    return false;
  }

  const checker1 = calcFirstChecker(firstNineDigits);
  const checker2 = calcSecondChecker(`${firstNineDigits}${checker1}`);

  return checker === `${checker1}${checker2}`;
};
