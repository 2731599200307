import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormikContext } from 'formik';
import { debounce, isEqual } from 'lodash';
import { safeGetFromSessionStorage, safeSetInSessionStorage } from './window';

interface FormikPersistProps {
  formName: string;
}

export const FormikPersist = (props: FormikPersistProps) => {
  const { values, setValues } = useFormikContext();
  const prefValuesRef: any = useRef();

  const onSave = useCallback(
    (values: any) => {
      safeSetInSessionStorage(props.formName, JSON.stringify(values));
    },
    [props.formName]
  );

  const debouncedSubmit = useMemo(() => debounce(onSave, 500), [onSave]);

  useEffect(() => {
    const savedForm = safeGetFromSessionStorage(props.formName);

    if (savedForm) {
      const parsedForm = JSON.parse(savedForm);

      prefValuesRef.current = parsedForm;
      setValues(parsedForm);
    }
  }, [props.formName, setValues]);

  useEffect(() => {
    if (!isEqual(prefValuesRef.current, values)) {
      debouncedSubmit(values);
    }
  }, [debouncedSubmit, values]);

  useEffect(() => {
    prefValuesRef.current = values;
  }, [values]);

  return null;
};
