import { extendTheme } from '@chakra-ui/react';
import { colors } from './colors';

const baseButtonStyle = {
  borderRadius: '40px',
  fontSize: '14px',
  transition: 'all 0.5s',
};

const breakpoints = {
  sm: '530px',
  md: '850px',
  lg: '1000px',
  xl: '1200px',
  '2xl': '1536px',
};

export const theme = extendTheme({
  fonts: {
    body: 'Ambit-Bold, Inter, sans-serif',
  },
  breakpoints,
  colors: colors,
  components: {
    Button: {
      variants: {
        solid: {
          ...baseButtonStyle,
          backgroundColor: colors.primary['1000'],
          color: colors.secondary['100'],
          fill: colors.secondary['100'],
          _hover: {
            backgroundColor: colors.primary['700'],
            _disabled: {
              background: colors.primary['700'],
            },
          },
          _loading: {
            display: 'flex',
            justifyItems: 'center',
            backgroundColor: colors.primary['1000'],
            _hover: {
              backgroundColor: colors.primary['700'],
            },
          },
        },
        'solid-secondary': {
          ...baseButtonStyle,
          backgroundColor: colors.secondary['400'],
          color: colors.primary['1000'],
          fill: colors.primary['1000'],
          _hover: {
            backgroundColor: colors.secondary['200'],
          },
          _loading: {
            display: 'flex',
            justifyItems: 'center',
            backgroundColor: colors.secondary['400'],
            _hover: {
              backgroundColor: colors.secondary['200'],
            },
          },
        },
        'solid-error': {
          ...baseButtonStyle,
          backgroundColor: colors.notification.error,
          color: colors.white,
          fill: colors.white,
          _hover: {},
          _loading: {
            display: 'flex',
            justifyItems: 'center',
            backgroundColor: colors.notification.error,
            _hover: {
              backgroundColor: colors.notification.error,
            },
          },
        },
        outline: {
          ...baseButtonStyle,
          backgroundColor: colors.white,
          borderWidth: '2px',
          borderColor: colors.primary['1000'],
          color: colors.primary['1000'],
          _hover: {
            backgroundColor: colors.primary['50'],
          },
          _loading: {
            display: 'flex',
            justifyItems: 'center',
            backgroundColor: colors.white,
            _hover: {
              backgroundColor: colors.primary['50'],
            },
          },
        },
        'outline-primary-light': {
          ...baseButtonStyle,
          backgroundColor: colors.white,
          borderWidth: '2px',
          borderColor: colors.primary['100'],
          color: colors.primary['200'],
          _hover: {
            backgroundColor: colors.primary['50'],
          },
          _loading: {
            display: 'flex',
            justifyItems: 'center',
            backgroundColor: colors.white,
            _hover: {
              backgroundColor: colors.primary['50'],
            },
          },
        },
        'outline-secondary': {
          ...baseButtonStyle,
          backgroundColor: colors.white,
          borderWidth: '2px',
          borderColor: colors.secondary['100'],
          color: colors.primary['1000'],
          _hover: {
            backgroundColor: colors.background,
          },
          _loading: {
            display: 'flex',
            justifyItems: 'center',
            backgroundColor: colors.white,
            _hover: {
              backgroundColor: colors.primary['50'],
            },
          },
        },
        'outline-error': {
          ...baseButtonStyle,
          backgroundColor: colors.white,
          borderWidth: '2px',
          borderColor: colors.notification.error,
          color: colors.notification.error,
          _hover: {
            backgroundColor: colors.secondary['50'],
          },
          _loading: {
            display: 'flex',
            justifyItems: 'center',
            backgroundColor: colors.white,
            _hover: {},
          },
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            backgroundColor: colors.white,
            borderWidth: '1px',
            borderColor: colors.primary['100'],
            color: colors.primary['1000'],
            fontWeight: 400,
            fontSize: '12px',
            borderRadius: '10px',
            transition: 'border 0.3s',
            fontFamily: 'Ambit',
            _placeholder: {
              color: colors.primary['1000'],
              opacity: '0.5',
            },
            _focus: {
              boxShadow: 'none',
              borderColor: colors.primary['100'],
            },
            _hover: {
              borderColor: colors.primary['100'],
            },
          },
        },
        'outline-secondary': {
          field: {
            backgroundColor: colors.background,
            borderWidth: '1px',
            borderColor: colors.primary['100'],
            color: colors.primary['1000'],
            fontWeight: 400,
            fontSize: '12px',
            borderRadius: '10px',
            transition: 'border 0.3s',
            fontFamily: 'Ambit',
            _placeholder: {
              color: colors.primary['1000'],
            },
            _focus: {
              boxShadow: 'none',
              borderColor: colors.primary['100'],
            },
            _hover: {
              borderColor: colors.primary['100'],
            },
            _disabled: {},
          },
        },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            backgroundColor: colors.white,
            borderWidth: '1px',
            borderColor: colors.primary['100'],
            color: colors.primary['1000'],
            borderRadius: '10px',
            fontFamily: 'Ambit',
            fontSize: '12px',
            _hover: {
              borderColor: colors.primary['100'],
            },
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        icon: {
          color: 'white',
        },
        control: {
          boxShadow: 'none',
          border: '2px',
          borderColor: colors.primary['1000'],
          borderRadius: '4px',
          _disabled: {
            borderColor: 'gray.300',
            bg: 'gray.200',
          },
          _checked: {
            backgroundColor: colors.primary['1000'],
          },
        },
      },
    },
  },
});

export type BUTTON_VARIANTS =
  | 'solid'
  | 'solid-secondary'
  | 'solid-error'
  | 'outline'
  | 'outline-primary-light'
  | 'outline-secondary'
  | 'outline-secondary'
  | 'outline-error'
  | 'ghost';

export type INPUT_VARIANTS = 'outline' | 'outline-secondary';
