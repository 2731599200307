import * as React from 'react';
import { getStringColor } from 'src/theme/colors';
import styled from 'styled-components';
import './css/styles.css';
import { TypographyProps } from './types';

// Para usar os tipos predefinidos no Figma, é necessário preencher:
// Caso 1: model?: 'h1' | 'h2' | 'h3' | 'caption' | 'code'  e  weight?: 'bold' | 'regular';
// Caso 2: model?: 'body' e  weight?: 'bold' | 'regular'  e size?: 'small' | 'normal' | 'large';
// Para cor deve ser usado o caminho do tema da aplicação

const Typography: React.FunctionComponent<TypographyProps> = (props) => {
  const { model, weight, size, children, htmlText } = props;

  const builtModel = React.useMemo(() => {
    if (!model) return;

    if (['h1', 'h2', 'h3', 'caption', 'code'].includes(model) && weight) {
      return `font-${model}-${weight}`;
    } else if (model === 'body' && weight && size) {
      return `font-${model}-${weight}-${size}`;
    }
  }, [model, weight, size]);

  return (
    <StyledBlock {...props} className={`mevo-typography ${builtModel}`}>
      {htmlText ? <span dangerouslySetInnerHTML={{ __html: htmlText }} /> : children}
    </StyledBlock>
  );
};

const StyledBlock = styled.div<TypographyProps>`
  ${(props) => (props.fontSize ? `font-size: ${props.fontSize}px !important` : '')};
  ${(props) => (props.underline ? `text-decoration: underline !important;` : '')}
  ${(props) => (props.italic ? `font-style: italic !important;` : '')}
  ${(props) => (props.textTransform ? `text-transform: ${props.textTransform} !important;` : '')}
  ${(props) => (props.risked ? `text-decoration-line: underline !important;` : '')}
  ${(props) => (props.textAlign ? `text-align: ${props.textAlign} !important;` : '')}
  ${(props) => (props.display ? `display: ${props.display} !important;` : '')}
  ${(props) => (props.whiteSpace ? `white-space: ${props.whiteSpace} !important;` : '')}
  color: ${(props) => getStringColor(props.color)};
`;

export default Typography;
