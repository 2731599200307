import { createContext, useCallback, useEffect, useState, ReactNode, useContext } from 'react';
import { setAuthorizationHeader } from 'src/api';
import { PrescriptionViewer } from 'src/components/PrescriptionViewer';
import { DEFAULT_CREATE_PRESCRIBER_FORM_DATA } from 'src/helpers/defaultValues';
import { encryptToMd5 } from 'src/helpers/string';
import useLoading from 'src/helpers/useLoading';
import { AuthService, USER_DOES_NOT_HAVE_SESSION } from 'src/services/AuthService';
import { Patient } from 'src/services/patient';
import { CreatePrescriberPayload, Prescriber, PrescriberService } from 'src/services/prescriber';
import { Tracker } from 'src/shared/tracker/Tracker';
import { DialogWrappedComponentProps, useFeedbackContext } from './FeedbackProvider';
import { isRunningInWebview } from 'src/helpers/isRunningInWebview';
import { appFailureSignedIn } from 'src/helpers/window';
import { BOTTOM_TAB_PADDING_BOTTOM } from 'src/components/Layout/BottomTabNavigation/BottomTabNavigation';
import { useAuthContext } from './AuthProvider';
import { get } from 'lodash';

export const NOT_OPEN_BOTTOM_BAR_HEIGHT = 88 + BOTTOM_TAB_PADDING_BOTTOM;
export const OPEN_BOTTOM_BAR_HEIGHT = 180 + BOTTOM_TAB_PADDING_BOTTOM;

const B2C_PARTNER_ID = 331;

interface PrescriberContextProps {
  updateCreatePrescriberFormData(data: Partial<CreatePrescriberPayload>): void;
  createPrescriberFormData: CreatePrescriberPayload;
  prescriberId: string | null;
  setPrescriberId(prescriberId: string | null): void;
  prescriberData: Prescriber | null;
  setPrescriberData(prescriberData: Prescriber | null): void;
  retrievePrescriberData(): Promise<void>;
  startPrescrition(patientId: string): Promise<void>;
  loadingPrescription: boolean;
  patients: Patient[] | null;
  setPatients(data: Patient[] | null): void;
  loadingPrescriberData: boolean;
  isUserOnApp: boolean;
  bottomBarHeight: number;
  setBottomBarHeight(value: number): void;
  clickOnFirstPatientFromList(): void;
  userDoesNotHaveAnyPatients: boolean;
  setUserDoesNotHaveAnyPatients(value: boolean): void;
}

export const PrescriberContext = createContext<PrescriberContextProps>({
  updateCreatePrescriberFormData: (data: Partial<CreatePrescriberPayload>) => {},
  createPrescriberFormData: DEFAULT_CREATE_PRESCRIBER_FORM_DATA(),
  prescriberId: null,
  setPrescriberId: (prescriberId: string | null) => null,
  prescriberData: null,
  setPrescriberData: (prescriberData: Prescriber | null) => null,
  retrievePrescriberData: async () => {},
  startPrescrition: async (patientId: string) => {},
  loadingPrescription: false,
  patients: null,
  setPatients: (data: Patient[] | null) => {},
  loadingPrescriberData: false,
  isUserOnApp: false,
  bottomBarHeight: NOT_OPEN_BOTTOM_BAR_HEIGHT,
  setBottomBarHeight: (value: number) => {},
  clickOnFirstPatientFromList: () => {},
  userDoesNotHaveAnyPatients: false,
  setUserDoesNotHaveAnyPatients: (value: boolean) => {},
});

export interface PrescriberProviderInterface {
  children: ReactNode;
}

export function PrescriberProvider(props: PrescriberProviderInterface) {
  const [prescriberId, setPrescriberId] = useState<string | null>(null);

  const [loadingPrescriberData, runWithLoadingPrescriberData] = useLoading();
  const [isUserOnApp, setIsUserOnApp] = useState(false);
  const [prescriberData, setPrescriberData] = useState<Prescriber | null>(null);

  const { openToast, openBaseDialog } = useFeedbackContext();

  const [createPrescriberFormData, setCreatePrescriberFormData] = useState<CreatePrescriberPayload>(
    DEFAULT_CREATE_PRESCRIBER_FORM_DATA()
  );

  const { setSession } = useAuthContext();

  const [loadingPrescription, runWithloadingPrescription] = useLoading();
  const [prescriptionLink, setPrescriptionLink] = useState<string | null>(null);

  const [patients, setPatients] = useState<Patient[] | null>(null);
  const [bottomBarHeight, setBottomBarHeight] = useState(NOT_OPEN_BOTTOM_BAR_HEIGHT);

  const [userDoesNotHaveAnyPatients, setUserDoesNotHaveAnyPatients] = useState<boolean>(false);

  const updateCreatePrescriberFormData = useCallback((data: Partial<CreatePrescriberPayload>) => {
    setCreatePrescriberFormData((prevState) => {
      if (!prevState) return prevState;
      return {
        ...prevState,
        ...data,
      };
    });
  }, []);

  const startPrescrition = useCallback(
    async (patientId: string) => {
      runWithloadingPrescription(async () => {
        if (!prescriberId) return;
        try {
          const data = await PrescriberService.startPrescrition(prescriberId, patientId);

          setPrescriptionLink(data.modalUrl);
        } catch (error) {
          openToast('Erro ao iniciar prescrição. Tente novamente em instantes.', { type: 'error', duration: 2000 });
        }
      });
    },
    [prescriberId, runWithloadingPrescription, openToast]
  );

  const handlePrescriberSession = useCallback(async () => {
    try {
      const cognitoUserData = await AuthService.getCurrentAuthenticatedUser();
      const userSession = await AuthService.getSession();

      if (!cognitoUserData) return;

      setAuthorizationHeader(userSession.getAccessToken().getJwtToken());
      setSession(userSession);
      const cognitoUserId = cognitoUserData.find((item) => item.Name === 'custom:userId');
      if (!cognitoUserId) return;

      setPrescriberId(cognitoUserId.Value);

      const userData = await PrescriberService.getPrescriber(cognitoUserId.Value);
      setPrescriberData(userData);

      const crm = encryptToMd5(userData.professionalRecord.number);

      Tracker.identify(crm);
      Tracker.register({
        CRM: crm,
      });

      Tracker.trackEvent({
        event: 'Login',
        parameters: {
          Nome: userData.fullName,
          email: userData.email,
          'Data login': new Date(),
        },
      });

      const userGuiding = get(window, 'userGuiding', null) as any;

      if (userGuiding) {
        userGuiding.identify(userData._id, {
          name: userData.fullName,
          email: userData.email,
          partner: B2C_PARTNER_ID,
        });
      }
    } catch (error) {
      if (isUserOnApp) {
        appFailureSignedIn();
        Tracker.trackEvent({
          event: 'Erro - Abrir Webview',
          parameters: {
            data: error,
          },
        });
      }

      if (error === USER_DOES_NOT_HAVE_SESSION) {
        // a proteção de rotas irá deslogar o usuario
        return;
      }

      openToast('Algo deu errado. Tente novamente em instantes.', { type: 'error', duration: 2000 });
    }
  }, [isUserOnApp, openToast, setSession]);

  const retrievePrescriberData = useCallback(() => {
    return new Promise<void>(async (resolve, reject) => {
      return runWithLoadingPrescriberData(async () => {
        try {
          await handlePrescriberSession();

          resolve();
          return;
        } catch (error) {
          reject();
        }
      });
    });
  }, [handlePrescriberSession, runWithLoadingPrescriberData]);

  const clickOnFirstPatientFromList = useCallback(() => {
    const firstPatientId = get(patients, '[0]._id', '');

    if (!firstPatientId) return;

    const element = document.getElementById(`patient-card-${firstPatientId}`);
    if (!element) return;

    element.focus();
    element.click();
  }, [patients]);

  useEffect(() => {
    const run = () => {
      retrievePrescriberData();
    };

    run();
  }, [retrievePrescriberData]);

  useEffect(() => {
    setIsUserOnApp(isRunningInWebview());
  }, []);

  useEffect(() => {
    const run = async () => {
      if (!prescriptionLink) return;

      await openBaseDialog(
        (dialogProps: DialogWrappedComponentProps) => (
          <PrescriptionViewer
            onClose={() => {
              dialogProps.onClose();
              setPrescriptionLink(null);
            }}
            url={prescriptionLink}
            isUserOnApp={isUserOnApp}
          />
        ),
        {
          width: '100%',
          height: '100%',
          borderRadius: '0px',
          padding: '0px',
          persist: true,
          backgroundColor: 'transparent',
        }
      );
    };

    run();
  }, [prescriptionLink, openBaseDialog, isUserOnApp]);

  return (
    <PrescriberContext.Provider
      value={{
        updateCreatePrescriberFormData,
        createPrescriberFormData,
        prescriberId,
        setPrescriberId,
        prescriberData,
        setPrescriberData,
        retrievePrescriberData,
        startPrescrition,
        loadingPrescription,
        patients,
        setPatients,
        loadingPrescriberData,
        isUserOnApp,
        bottomBarHeight,
        setBottomBarHeight,
        clickOnFirstPatientFromList,
        userDoesNotHaveAnyPatients,
        setUserDoesNotHaveAnyPatients,
      }}
    >
      <>{props.children}</>
    </PrescriberContext.Provider>
  );
}

export function usePrescriberContext() {
  const prescriberContext = useContext(PrescriberContext);

  return prescriberContext;
}
