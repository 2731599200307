import { useMemo } from 'react';
import { IconButton } from '@chakra-ui/react';
import { Outlet, useNavigate } from 'react-router-dom';

import UserMenu from '../../SidebarMenu/UserMenu';
import { Sidebar, SIDE_BAR_WIDTH } from '../../SidebarMenu/SidebarMenu';

import { Box } from '../../UI/Box';
import { Flex } from '../../UI/Flex';
import { Spinner } from '../../UI/Spinner';

import ArrowIcon from '../../Icons/ArrowIcon';
import { ReactComponent as Help } from 'src/assets/icons/help.svg';

import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { FullLayoutProps } from './FullLayout';

const FullLayoutDesktop = (props: FullLayoutProps) => {
  const { loadingPrescription, isUserOnApp } = usePrescriberContext();

  const navigate = useNavigate();

  const Header = useMemo(() => {
    return (
      <Box w={'100%'}>
        <Flex
          w={'100%'}
          h={'50px'}
          justifyContent={props.showGoBack ? 'space-between' : 'flex-end'}
          p={'0 15px'}
          alignItems={'center'}
        >
          {props.showGoBack && (
            <IconButton size={'sm'} aria-label="go-back" variant={'ghost'} onClick={() => navigate(-1)}>
              <Flex w={'10px'} alignItems={'center'} gap={'16px'}>
                <ArrowIcon direction="left" variantColor="primary" width={10} />
                Voltar
              </Flex>
            </IconButton>
          )}

          {!isUserOnApp && (
            <Flex alignItems={'center'}>
              <UserMenu />
              <IconButton aria-label="help" onClick={() => navigate(`/ajuda`)} variant={'ghost'}>
                <Help />
              </IconButton>
            </Flex>
          )}
        </Flex>
      </Box>
    );
  }, [isUserOnApp, navigate, props.showGoBack]);

  return (
    <Flex justifyContent={'space-between'}>
      {loadingPrescription && (
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          bg={'background'}
          zIndex={3}
          top={0}
          right={0}
          bottom={0}
          left={0}
          position={'absolute'}
          opacity={'0.5'}
        >
          <Spinner size={'xl'} color={'primary.1000'}></Spinner>
        </Box>
      )}
      <Box w={SIDE_BAR_WIDTH} zIndex={1}>
        <Sidebar />
      </Box>

      <Box w={'100%'} maxW={`calc(100vw - ${SIDE_BAR_WIDTH})`}>
        <Flex w={'100%'} h={'50px'} justifyContent={'flex-end'} p={'0 15px'} alignItems={'center'}>
          {Header}
        </Flex>
        <Box p={'20px'}>
          <Outlet />
        </Box>
      </Box>
    </Flex>
  );
};

export default FullLayoutDesktop;
