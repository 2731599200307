import { DateTime } from 'luxon';

interface Duration {
  days: number;
  hours: number;
  months: number;
  years: number;
}

export function calcAgeFromDateOfBirth(birthDate: Date): Duration | null {
  try {
    const today = DateTime.now();

    const age = today.diff(DateTime.fromJSDate(birthDate), ['years', 'months', 'days', 'hours']);

    return { days: age.days, hours: age.hours, months: age.months, years: age.years };
  } catch (error) {
    return null;
  }
}

export function makeAgeCalculation(birthDate: Date) {
  const ageCalc = calcAgeFromDateOfBirth(birthDate);

  if (!ageCalc) return null;

  const result: string[] = [];
  if (ageCalc) {
    if (ageCalc.years >= 2) {
      result.push(`${ageCalc.years} anos`);
    }

    if (ageCalc.years < 2 && ageCalc.years > 0 && ageCalc.months <= 11 && ageCalc.days <= 29) {
      result.push(`${ageCalc.years}`);
      result.push(ageCalc.years > 1 ? `anos` : 'ano');
      result.push('e');
      result.push(`${ageCalc.months}`);
      result.push(ageCalc.months > 1 ? `meses` : 'mês');
    }

    if (ageCalc.years < 1 && ageCalc.months <= 11 && ageCalc.days <= 29) {
      result.push(`${ageCalc.months}`);
      result.push(ageCalc.months > 1 ? `meses` : 'mês');
    }
  }

  return result.join(' ').trim();
}
