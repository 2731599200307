import React, { useEffect, useMemo, useState } from 'react';
import { isNil } from 'lodash';
import { FormLabel } from './UI/FormLabel';
import { Select } from './UI/Select/Select';

interface BooleanSelectProps {
  value: boolean | null | undefined;
  onChange(val: boolean | null): void;
  error?: boolean;
  label: string;
}

const VALUES = {
  YES: true,
  NO: false,
};

const SELECT_INTERNAL_VALUES = {
  YES: 'Sim',
  NO: 'Não',
};

const MAP = {
  [SELECT_INTERNAL_VALUES.YES]: VALUES.YES,
  [SELECT_INTERNAL_VALUES.NO]: VALUES.NO,
};

function BooleanSelect(props: BooleanSelectProps) {
  const [value, setValue] = useState<string | null>(null);

  const onChange = (value: string) => {
    props.onChange(MAP[value as keyof typeof MAP]);
  };

  useEffect(() => {
    setValue(props.value ? SELECT_INTERNAL_VALUES.YES : SELECT_INTERNAL_VALUES.NO);
  }, [props.value]);

  const mappedValue = useMemo(() => {
    if (isNil(value)) {
      return 'Não Informado';
    }
    return MAP[value as keyof typeof MAP] ? SELECT_INTERNAL_VALUES.YES : SELECT_INTERNAL_VALUES.NO;
  }, [value]);

  return (
    <>
      <FormLabel>{props.label}</FormLabel>

      <Select
        h={'45px'}
        m={0}
        p={0}
        value={mappedValue}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        data-testid={'boolean-select'}
      >
        <option value={'Sim'} data-testid={'true-option'}>
          Sim
        </option>
        <option value={'Não'} data-testid={'no-option'}>
          Não
        </option>
        <option value={'Não Informado'} disabled data-testid={'not-informed-option'}>
          Não Informado
        </option>
      </Select>
    </>
  );
}

export default React.memo(BooleanSelect);
