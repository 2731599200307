import React from 'react';
import { Box } from 'src/components/UI/Box';
import { Img, Show } from '@chakra-ui/react';
import basicAbstractBackgroundImage from 'src/assets/images/basic-abstract-background.svg';
import { AnimatedRoute } from 'src/router/AnimatedRoute';

interface BasicAbstractBackgroundProps {
  children: React.ReactNode;
}

export function BasicAbstractBackground(props: BasicAbstractBackgroundProps) {
  return (
    <AnimatedRoute>
      <Box w={'100%'}>
        <Box zIndex={1} pos={'relative'}>
          {props.children}
        </Box>

        <Show above="sm">
          <Img
            src={basicAbstractBackgroundImage}
            pos={'absolute'}
            bottom={0}
            right={0}
            zIndex={0}
            userSelect={'none'}
          />
        </Show>
      </Box>
    </AnimatedRoute>
  );
}
