import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { createSearchParams, useNavigate } from 'react-router-dom';
import NavButton from 'src/components/Helpers/NavButton';
import MevoInput from 'src/components/Input';
import FlatLayout from 'src/components/Layout/FlatLayout';
import Typography from 'src/components/Typography';
import { Button } from 'src/components/UI/Button';
import FormBaseCard from 'src/components/UI/FormBaseCard';
import useLoading from 'src/helpers/useLoading';
import { Yup } from 'src/helpers/Yup';
import { AuthService } from 'src/services/AuthService';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Informe um email válido.').required('Informe um email válido (ex.: email@email.com).'),
});

interface ForgotPasswordProps {}

export const ForgotPassword: React.FunctionComponent<ForgotPasswordProps> = () => {
  const [loading, runWithLoading] = useLoading();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: LoginSchema,
    validateOnChange: false,
    async onSubmit(values) {
      runWithLoading(() => {
        try {
          AuthService.forgotPassword(values.email);

          navigate({
            pathname: '/esqueceu-sua-senha/email-enviado',
            search: createSearchParams({
              email: values.email,
            }).toString(),
          });
        } catch (e) {
          console.error(e);
        }
      });
    },
  });

  return (
    <FlatLayout>
      <Flex alignItems="center" justifyContent="center" flex={1}>
        <FormBaseCard>
          <Box>
            <Typography model="h1" weight="bold">
              Esqueceu sua senha?
            </Typography>
            <Box my="20px">
              <Typography model="body" size="large" weight="regular">
                Digite seu melhor email que enviaremos um link para você definir uma nova senha.
              </Typography>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <MevoInput
                id="forgot-pasword:email"
                data-testid="forgot-password-email"
                labelText="Email"
                h={'45px'}
                placeholderText={'Digite o email'}
                autoFocus
                name={'email'}
                type={'email'}
                onChange={formik.handleChange}
                value={formik.values.email}
                onFocus={() => formik.setFieldError('email', '')}
                onBlur={() => formik.validateField('email')}
                errorText={formik.errors.email}
                isInvalid={!!formik.errors.email}
              />
              <NavButton>
                <Button
                  isLoading={loading}
                  id="forgot-password-submit"
                  width="100%"
                  height="60px"
                  type="submit"
                  mt="16px"
                >
                  Recuperar
                </Button>
              </NavButton>
            </form>
          </Box>
        </FormBaseCard>
      </Flex>
    </FlatLayout>
  );
};
