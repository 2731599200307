import { useMemo, useState } from 'react';
import { InputLeftAddon } from '@chakra-ui/react';
import { Formik, useFormikContext } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';
import { isAxiosError } from 'axios';

import { NextButton } from './NextButton';
import { TabsHeader } from './TabsHeader';
import { TABS_INDEX } from './SignUp';

import Typography from 'src/components/Typography';
import { FormControl } from 'src/components/UI/FormControl';
import { FormErrorMessage } from 'src/components/UI/FormErrorMessage';
import { Box } from 'src/components/UI/Box';
import { Flex } from 'src/components/UI/Flex';
import { Select } from 'src/components/UI/Select/Select';
import { Text } from 'src/components/UI/Text';
import { InputGroup } from 'src/components/UI/Input/InputGroup';
import { MaskedInput } from 'src/components/UI/Input/MaskedInput';

import { Yup } from 'src/helpers/Yup';
import { counsels } from 'src/helpers/professionalCounsels';
import useLoading from 'src/helpers/useLoading';

import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';

import { PrescriberService } from 'src/services/prescriber';
import { FormikPersist } from 'src/helpers/FormikPersist';
import SelectState from 'src/components/SelectState';
import { Tracker } from 'src/shared/tracker/Tracker';
import { isEmpty } from 'lodash';
import { useSearchParams } from 'react-router-dom';

interface ProfessionalDataForm {
  counsel: string;
  UFRegister: string;
  registerNumber: string;
}

const ProfessionalDataSchema = Yup.object().shape({
  counsel: Yup.string().required('Informe o conselho profissional.'),
  UFRegister: Yup.string().required('Informe uma UF do Registro válida.'),
  registerNumber: Yup.string()
    .required('Informe um Número de Registro válido.')
    .matches(/^(NX(\d){1,5}|(\d){1,7})$/i, 'Informe um Número de Registro válido.'),
});

interface ProfessionalDataProps {
  onNext(): void;
  isStepCompleted: boolean;
  onGoBack(index: number): void;
}

interface RegisterNumberInputProps {
  errorMessage: string;
}

const initialValues: ProfessionalDataForm = {
  counsel: 'CRM',
  UFRegister: '',
  registerNumber: '',
};

function RegisterNumberInput({ errorMessage }: RegisterNumberInputProps) {
  const { values, handleChange, errors } = useFormikContext<ProfessionalDataForm>();

  if (values.counsel === 'CRM' && values.UFRegister === 'RJ') {
    return (
      <FormControl isInvalid={!!errors.registerNumber || !!errorMessage}>
        <InputGroup>
          <InputLeftAddon
            borderTopLeftRadius={'10px'}
            borderBottomLeftRadius={'10px'}
            bg={'primary.100'}
            color={'primary.1000'}
            children="52"
            h={'45px'}
            borderColor={'primary.100'}
          />

          <MaskedInput
            name={'registerNumber'}
            borderLeft={0}
            borderTopLeftRadius={0}
            borderBottomLeftRadius={0}
            h={'45px'}
            placeholder={'0000'}
            onChange={handleChange}
            value={values.registerNumber}
            mask={'**99999'}
            maskChar={''}
          />
        </InputGroup>
        <FormErrorMessage>{errors.registerNumber}</FormErrorMessage>
      </FormControl>
    );
  }

  return (
    <FormControl isInvalid={!!errors.registerNumber || !!errorMessage}>
      <MaskedInput
        name={'registerNumber'}
        h={'45px'}
        type={'tel'}
        placeholder={'0000'}
        onChange={handleChange}
        value={values.registerNumber}
        mask={'**99999'}
        maskChar={''}
      />
      <FormErrorMessage>{errors.registerNumber || errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

function ProfessionalData(props: ProfessionalDataProps) {
  const [apiResponseError, setApiResponseError] = useState('');

  const { updateCreatePrescriberFormData } = usePrescriberContext();
  const { openToast } = useFeedbackContext();

  const [loading, runWithLoading] = useLoading();

  const [params] = useSearchParams();

  const utmData = useMemo(() => {
    const utmSource = params.get('utm_source');
    const utmMedium = params.get('utm_medium');
    const utmCampaign = params.get('utm_campaign');

    if (!utmSource || !utmMedium || !utmCampaign) return;

    return {
      utmSource,
      utmMedium,
      utmCampaign,
    };
  }, [params]);

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      initialValues={initialValues}
      validationSchema={ProfessionalDataSchema}
      onSubmit={async (values) => {
        runWithLoading(async () => {
          try {
            await PrescriberService.validateCrm({
              number: values.registerNumber,
              state: values.UFRegister,
              issuer: values.counsel,
              validations: {
                cfm: true,
                email: false,
              },
            });

            updateCreatePrescriberFormData({
              professionalRecord: {
                number: values.registerNumber,
                state: values.UFRegister,
                issuer: values.counsel,
              },
              ...(utmData && { utm: utmData }),
            });

            Tracker.trackEvent({
              event: 'cadastroPrescritorSegundoPassoConcluido',
            });

            props.onNext();
          } catch (e) {
            if (isAxiosError(e)) {
              const validationErrorMessage = e.response!.data.message.split('.')[0];

              Tracker.trackEvent({
                event: 'erroCadastroPrescritorSegundoPasso',
                parameters: {
                  error: validationErrorMessage,
                },
              });

              if (validationErrorMessage === 'Dados do profissional já utilizados') {
                return setApiResponseError('CRM já cadastrado.');
              }

              return setApiResponseError('Informe um n° de Registro válido.');
            }

            const genericError =
              'Ops.. Um problema inesperado ocorreu. Aguarde alguns minutos e tente novamente. Caso o problema persista, fale com a gente.';

            Tracker.trackEvent({
              event: 'erroCadastroPrescritorSegundoPasso',
              parameters: {
                error: genericError,
              },
            });

            openToast(genericError, {
              type: 'error',
            });
          }
        });
      }}
    >
      {(formikProps) => {
        const submit = async () => {
          const errors = await formikProps.validateForm();

          if (isEmpty(errors)) {
            return formikProps.submitForm();
          }

          Tracker.trackEvent({
            event: 'erroCadastroPrescritorPrimeiroPasso',
            parameters: errors,
          });
        };

        return (
          <form
            name={'professional-data'}
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
          >
            {formikProps.isSubmitting && <ConnectedFocusError />}
            <TabsHeader
              tabClick={(index) => {
                if (index === TABS_INDEX.PROFESSIONAL_DATA) return;

                if (index > TABS_INDEX.PROFESSIONAL_DATA) {
                  submit();
                  return;
                }

                props.onGoBack(index);
              }}
              currentIndex={TABS_INDEX.PROFESSIONAL_DATA}
              isFirstStepCompleted
              isSecondStepCompleted={props.isStepCompleted}
            />
            <Box>
              <Box mb={'20px'}>
                <Typography model={'body'} size={'large'} weight={'bold'}>
                  Dados profissionais
                </Typography>
              </Box>

              <Box>
                <FormControl isInvalid={!!formikProps.errors.counsel}>
                  <Text mb={'10px'} textTransform={'uppercase'} fontSize={'10px'}>
                    Conselho profissional*
                  </Text>
                  <Select
                    h={'45px'}
                    m={0}
                    p={0}
                    value={formikProps.values.counsel}
                    placeholder="Selecione o conselho profissional"
                    name="counsel"
                    onChange={formikProps.handleChange}
                    onBlur={() => formikProps.validateField('counsel')}
                    data-testid={'counsel-select'}
                  >
                    {counsels.map((counsel) => (
                      <option value={counsel.initials} key={counsel.initials}>
                        {counsel.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formikProps.errors.counsel}</FormErrorMessage>
                </FormControl>
              </Box>
              <Flex mt={'20px'} mb={{ base: 0, sm: '16px' }}>
                <FormControl w={'50%'} mr={'20px'} isInvalid={!!formikProps.errors.UFRegister}>
                  <SelectState
                    label={'UF do registro*'}
                    placeholder={'Selecione o estado)'}
                    value={formikProps.values.UFRegister}
                    onChange={(value) => formikProps.setFieldValue('UFRegister', value)}
                    onBlur={() => formikProps.validateField('UFRegister')}
                    errorMessage={formikProps.errors.UFRegister}
                  />
                </FormControl>

                <Box w={'50%'}>
                  <Text mb={'10px'} textTransform={'uppercase'} fontSize={'10px'}>
                    N˚ do registro*
                  </Text>

                  <RegisterNumberInput errorMessage={apiResponseError} />
                </Box>
              </Flex>

              <NextButton
                currentIndex={TABS_INDEX.PROFESSIONAL_DATA}
                position={{ base: 'fixed', sm: 'static' }}
                padding={{ base: '0 20px', sm: '0' }}
                isLoading={loading}
              />
            </Box>
            <FormikPersist formName={'professional-data-form'} />
          </form>
        );
      }}
    </Formik>
  );
}

export default ProfessionalData;
