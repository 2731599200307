import * as React from 'react';
import { getStringColor } from 'src/theme/colors';
import { ReactComponent as AddIconSVG } from '../../assets/icons/mevo/ic_add.svg';
import IconPattern, { IconProps } from './_IconPattern';

interface AddIconProps extends IconProps {
  outlined: boolean; // padrão true
}

const AddIcon: React.FunctionComponent<AddIconProps> = (props) => {
  const variantStyle = React.useMemo(() => {
    return !props.outlined
      ? `& > path:not(:nth-of-type(2)):not(:nth-of-type(3)) {
        fill: ${getStringColor(props.background?.color || 'support.surface')} !important;
      }`
      : undefined;
  }, [props.outlined, props.background]);

  const variantPlaces = React.useMemo(() => {
    return props.outlined ? 1 : [2, 3];
  }, [props.outlined]);

  return <IconPattern component={AddIconSVG} variantPlaces={variantPlaces} {...props} customStyle={variantStyle} />;
};

export default AddIcon;
