import React, { useState } from 'react';
import { Formik } from 'formik';
import { ConnectedFocusError } from 'focus-formik-error';

import { NextButton } from './NextButton';
import { TabsHeader } from './TabsHeader';
import { TABS_INDEX } from './SignUp';

import { Box } from 'src/components/UI/Box';
import { FormControl } from 'src/components/UI/FormControl';
import { Flex } from 'src/components/UI/Flex';
import { Button } from 'src/components/UI/Button';
import { MASKS } from 'src/components/UI/Input/MaskedInput';
import MevoInput from 'src/components/Input';
import Typography from 'src/components/Typography';

import { Yup } from 'src/helpers/Yup';
import { onlyDigits } from 'src/helpers/string';
import { FormikPersist } from 'src/helpers/FormikPersist';

import { CEPService } from 'src/services/cep';

import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import SelectState from 'src/components/SelectState';
import { Tracker } from 'src/shared/tracker/Tracker';
import { isEmpty } from 'lodash';

interface PlaceOfServiceForm {
  placeName: string;
  phoneNumber: string;
  postalCode: string;
  address: string;
  houseNumber: string;
  district: string;
  complement: string;
  city: string;
  state: string;
}

const initialValues: PlaceOfServiceForm = {
  placeName: '',
  phoneNumber: '',
  postalCode: '',
  address: '',
  houseNumber: '',
  district: '',
  complement: '',
  city: '',
  state: '',
};

const PlaceOfServiceSchema = Yup.object().shape({
  placeName: Yup.string().required('Informe o nome do local de atendimento.'),
  phoneNumber: Yup.string()
    .required('Informe um número de telefone válido.')
    .test('validate phoneNumber', 'Informe um número de telefone válido.', function (phoneNumber) {
      if (!phoneNumber) return false;
      return onlyDigits(phoneNumber).length === 10 || onlyDigits(phoneNumber).length === 11;
    }),
  postalCode: Yup.string()
    .required('Informe um CEP válido.')
    .test('validate postal code', 'Informe um CEP válido.', function (postalCode) {
      return onlyDigits(postalCode || '').length === 8;
    }),
  address: Yup.string().required('Informe um endereço válido.'),
  houseNumber: Yup.string().required('Informe um número válido.'),
  district: Yup.string().required('Informe um bairro válido.'),
  complement: Yup.string().nullable(),
  city: Yup.string().required('Informe uma cidade válida.'),
  state: Yup.string().required('Informe uma UF válida.'),
});

interface PlaceOfServiceProps {
  onNext(): void;
  isStepCompleted: boolean;
  onGoBack(index: number): void;
}

export function PlaceOfService(props: PlaceOfServiceProps) {
  const { updateCreatePrescriberFormData } = usePrescriberContext();
  const [mask, setMask] = useState(MASKS.BRAZIL_LANDLINE_PHONE_NINE_DIGITS);

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      validateOnMount={false}
      initialValues={initialValues}
      validationSchema={PlaceOfServiceSchema}
      onSubmit={(values, formik) => {
        props.onNext();

        Tracker.trackEvent({
          event: 'cadastroPrescritorTerceiroPassoConcluido',
        });

        updateCreatePrescriberFormData({
          attendanceAddress: {
            ...values,
            logoUrl: '',
            postalCode: onlyDigits(values.postalCode),
            phoneNumber: onlyDigits(values.phoneNumber),
          },
        });
        formik.setSubmitting(false);
      }}
    >
      {(formikProps) => {
        const clearAddressFields = () => {
          formikProps.validateField('postalCode');
          formikProps.setFieldValue('address', '');
          formikProps.setFieldValue('district', '');
          formikProps.setFieldValue('complement', '');
          formikProps.setFieldValue('city', '');
          formikProps.setFieldValue('state', '');
          formikProps.setFieldValue('houseNumber', '');
        };

        const searchPostalCodeData = async (postalCode: string) => {
          if (onlyDigits(postalCode).length < 8) {
            clearAddressFields();
            return;
          }

          try {
            const postalCodeData = await CEPService.searchForCepInViaCep(postalCode);

            if (!postalCodeData) return;

            formikProps.setFieldValue('address', postalCodeData.logradouro);
            formikProps.setFieldValue('district', postalCodeData.bairro);
            formikProps.setFieldValue('complement', postalCodeData.complemento);
            formikProps.setFieldValue('city', postalCodeData.localidade);
            formikProps.setFieldValue('state', postalCodeData.uf);
          } catch (e: any) {
            if (e.message === 'CEP inválido') {
              return;
            }

            formikProps.setFieldError('postalCode', 'Falha ao buscar CEP. Digite seu endereço abaixo.');
          }
        };

        const submit = async () => {
          const errors = await formikProps.validateForm();

          if (isEmpty(errors)) {
            return formikProps.submitForm();
          }

          Tracker.trackEvent({
            event: 'erroCadastroPrescritorTerceiroPasso',
            parameters: errors,
          });
        };

        return (
          <form
            name={'place-of-service'}
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
            style={{ width: '100%' }}
          >
            {formikProps.isSubmitting && <ConnectedFocusError />}

            <TabsHeader
              tabClick={(index) => {
                if (index === TABS_INDEX.PLACE_OF_SERVICE) return;

                if (index > TABS_INDEX.PLACE_OF_SERVICE) {
                  submit();
                  return;
                }

                props.onGoBack(index);
              }}
              isFirstStepCompleted
              isSecondStepCompleted
              currentIndex={TABS_INDEX.PLACE_OF_SERVICE}
            />

            <Box>
              <Box mb={'20px'}>
                <Typography model={'body'} size={'large'} weight={'bold'}>
                  Local de atendimento
                </Typography>
              </Box>

              <Box>
                <FormControl isInvalid={!!formikProps.errors.placeName} mb={'20px'}>
                  <MevoInput
                    id="placeName"
                    labelText="NOME DO LOCAL*"
                    h={'45px'}
                    type={'text'}
                    placeholderText={'Digite o nome do local de atendimento'}
                    name={'placeName'}
                    value={formikProps.values.placeName}
                    onChange={formikProps.handleChange}
                    errorText={formikProps.errors.placeName}
                    onBlur={() => formikProps.validateField('placeName')}
                    autoFocus
                  />
                </FormControl>

                <FormControl isInvalid={!!formikProps.errors.phoneNumber} mb={'20px'}>
                  <MevoInput
                    id="phoneNumber"
                    labelText="Telefone comercial*"
                    h={'45px'}
                    type={'tel'}
                    placeholderText={'(00) 0000-0000'}
                    name={'phoneNumber'}
                    value={formikProps.values.phoneNumber}
                    onChange={formikProps.handleChange}
                    errorText={formikProps.errors.phoneNumber}
                    mask={mask}
                    onBlur={(e) => {
                      formikProps.validateField('phoneNumber');
                      if (onlyDigits(e.target.value).length === 10) {
                        setMask(MASKS.BRAZIL_LANDLINE_PHONE);
                      }
                      if (onlyDigits(e.target.value).length === 11) {
                        setMask(MASKS.BRAZIL_LANDLINE_PHONE_NINE_DIGITS);
                      }
                    }}
                    onFocus={() => {
                      setMask('(99) 999999999');
                    }}
                  />
                </FormControl>

                <Flex alignItems={'center'} mb={'20px'}>
                  <FormControl w={'50%'} isInvalid={!!formikProps.errors.postalCode}>
                    <MevoInput
                      id="postalCode"
                      labelText="CEP*"
                      h={'45px'}
                      type={'tel'}
                      placeholderText={'Busque seu CEP'}
                      name={'postalCode'}
                      value={formikProps.values.postalCode}
                      onChange={(e) => {
                        formikProps.setFieldValue('postalCode', e.target.value);
                      }}
                      onBlur={(e) => {
                        formikProps.validateField('postalCode');
                        searchPostalCodeData(e.target.value);
                      }}
                      errorText={formikProps.errors.postalCode}
                      mask={MASKS.BRAZIL_POSTAL_CODE}
                      maskChar={''}
                    />
                  </FormControl>
                  <Button
                    mt={'10px'}
                    w={'50%'}
                    variant="ghost"
                    color={'primary.1000'}
                    textDecoration={'underline'}
                    fontSize={'14px'}
                    textAlign={'center'}
                    onClick={() => {
                      window.open('https://buscacepinter.correios.com.br/app/endereco/index.php', '_blank', 'noopener');
                    }}
                  >
                    Não sei meu cep
                  </Button>
                </Flex>

                <Flex alignItems={'center'} mb={'20px'}>
                  <FormControl mr={'10px'} w={'70%'} isInvalid={!!formikProps.errors.address} h={'70px'}>
                    <MevoInput
                      id="address"
                      labelText="ENDEREÇO*"
                      h={'45px'}
                      placeholderText={'Digite o nome da rua, avenida, etc'}
                      name={'address'}
                      value={formikProps.values.address}
                      onChange={formikProps.handleChange}
                      onBlur={() => formikProps.validateField('address')}
                      errorText={formikProps.errors.address}
                    />
                  </FormControl>
                  <FormControl w={'30%'} isInvalid={!!formikProps.errors.houseNumber} h={'70px'}>
                    <MevoInput
                      id="houseNumber"
                      labelText="N*"
                      type={'tel'}
                      h={'45px'}
                      placeholderText={'000000'}
                      name={'houseNumber'}
                      value={formikProps.values.houseNumber}
                      onChange={formikProps.handleChange}
                      onBlur={() => formikProps.validateField('houseNumber')}
                      errorText={formikProps.errors.houseNumber}
                      mask={'99999'}
                      maskChar={''}
                      maskPlaceholder={''}
                    />
                  </FormControl>
                </Flex>

                <FormControl isInvalid={!!formikProps.errors.district} mb={'20px'}>
                  <MevoInput
                    id="district"
                    labelText="BAIRRO*"
                    h={'45px'}
                    placeholderText={'Digite o bairro'}
                    name={'district'}
                    value={formikProps.values.district}
                    onChange={formikProps.handleChange}
                    onBlur={() => formikProps.validateField('district')}
                    errorText={formikProps.errors.district}
                  />
                </FormControl>

                <FormControl isInvalid={!!formikProps.errors.complement} mb={'20px'}>
                  <MevoInput
                    id="complement"
                    labelText="COMPLEMENTO"
                    h={'45px'}
                    placeholderText={'Digite o complemento'}
                    name={'complement'}
                    value={formikProps.values.complement}
                    onChange={formikProps.handleChange}
                    onBlur={() => formikProps.validateField('complement')}
                    errorText={formikProps.errors.complement}
                  />
                </FormControl>

                <Flex alignItems={'center'}>
                  <FormControl mr={'10px'} w={'70%'} isInvalid={!!formikProps.errors.city} h={'85px'}>
                    <MevoInput
                      id="city"
                      labelText="CIDADE*"
                      h={'45px'}
                      placeholderText={'Digite a cidade'}
                      name={'city'}
                      value={formikProps.values.city}
                      onChange={formikProps.handleChange}
                      onBlur={() => formikProps.validateField('city')}
                      errorText={formikProps.errors.city}
                    />
                  </FormControl>
                  <FormControl w={'30%'} isInvalid={!!formikProps.errors.state} h={'85px'}>
                    <SelectState
                      label={'UF*'}
                      placeholder={'Selecione o estado (UF)'}
                      value={formikProps.values.state}
                      onChange={(value) => formikProps.setFieldValue('state', value)}
                      onBlur={() => formikProps.validateField('state')}
                      errorMessage={formikProps.errors.state}
                    />
                  </FormControl>
                </Flex>
              </Box>

              <NextButton
                currentIndex={TABS_INDEX.PLACE_OF_SERVICE}
                position={{ base: 'fixed', sm: 'static' }}
                padding={{ base: '0 20px', sm: '0' }}
              />
            </Box>
            
            <FormikPersist formName={'place-service-form'} />
          </form>
        );
      }}
    </Formik>
  );
}
