import { Flex } from '@chakra-ui/react';
import TextObservationIcon from 'src/components/Icons/TextObservationIcon';
import Typography from 'src/components/Typography';

interface ObservationProps {
  text: string;
}

export function Observation(props: ObservationProps) {
  const { text } = props;

  return (
    <Flex flexDirection="column" mt="20px">
      <Typography model="h3" weight="bold">
        Observação
      </Typography>
      <Flex mt="20px" alignItems="flex-start" gap="15px">
        <Flex>
          <TextObservationIcon width={18} variantColor="secondary.400" />
        </Flex>
        <Flex flexGrow={1}>
          <Typography model="body" size="large" weight="regular">
            {text}
          </Typography>
        </Flex>
      </Flex>
    </Flex>
  );
}
