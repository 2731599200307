import { motion } from 'framer-motion';
import { useIsMobile } from 'src/helpers/useIsMobile';
interface AnimatedRouteProps {
  children: React.ReactNode;
}
export const AnimatedRoute = (props: AnimatedRouteProps) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <motion.div
        initial={{ x: '-50px', opacity: 0 }}
        animate={{
          x: 0,
          opacity: 1,
        }}
        transition={{
          ease: 'easeInOut',
        }}
      >
        {props.children}
      </motion.div>
    );
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }}>
      {props.children}
    </motion.div>
  );
};
