import { useCallback, useMemo } from 'react';

import { MenuItem } from './UI/Menu/MenuItem';

import MenuThreePointsIcon from 'src/components/Icons/MenuThreePointsIcon';
import EditIcon from 'src/components/Icons/EditIcon';
import PillsIcon from 'src/components/Icons/PillsIcon';
import PlaceholderAvatarIcon from 'src/components/Icons/PlaceholderAvatarIcon';
import TrashIcon from 'src/components/Icons/TrashIcon';
import Typography from 'src/components/Typography';
import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { Flex } from 'src/components/UI/Flex';
import { Menu } from 'src/components/UI/Menu/Menu';
import { MenuButton } from 'src/components/UI/Menu/MenuButton';
import { MenuList } from 'src/components/UI/Menu/MenuList';
import { Spinner } from 'src/components/UI/Spinner';

import useLoading from 'src/helpers/useLoading';
import { makeAgeCalculation } from 'src/helpers/calcAgeFromDateOfBirth';
import { getFirstAndLastWords } from 'src/helpers/getFirstAndLastWords';
import { removeSpacesAndSpecialChars } from 'src/helpers/removeSpacesAndSpecialChars';
import { formatCpf } from 'src/helpers/string';
import { Patient } from 'src/services/patient';

import { Tracker } from 'src/shared/tracker/Tracker';

interface PatientCardProps {
  patient: Patient;
  showActionButtons?: boolean;
  onEdit?(patientId: string): void;
  onInitPrescription?(patientId: string): Promise<void>;
  onDelete?(patientId: string): Promise<void>;
  onClickCallback(patient: Patient): Promise<void>;
}

export function PatientCard(props: PatientCardProps) {
  const { patient, showActionButtons, onEdit, onDelete, onInitPrescription, onClickCallback } = props;

  const [loading, runWithLoading] = useLoading();

  const handleOnClick = useCallback(async () => {
    await onClickCallback(patient);
  }, [patient, onClickCallback]);

  const handleDelete = useCallback(() => {
    if (!onDelete) return;

    runWithLoading(async () => {
      await onDelete(patient._id);
    }, []);
  }, [onDelete, runWithLoading, patient._id]);

  const handleEdit = useCallback(() => {
    if (!onEdit) return;

    Tracker.trackEvent({
      event: 'Editar Paciente',
      parameters: {
        data: new Date(),
      },
    });

    onEdit(patient._id);
  }, [patient, onEdit]);

  const handleOnInitPrescription = useCallback(async () => {
    if (!onInitPrescription) return;

    Tracker.trackEvent({
      event: 'Receita Iniciada',
      parameters: {
        data: new Date(),
      },
    });

    await onInitPrescription(patient._id);
  }, [patient, onInitPrescription]);

  const ActionsMenu = useMemo(() => {
    if (!showActionButtons) return;

    return (
      <Menu>
        <MenuButton
          p={'5px 10px'}
          borderRadius={'10px'}
          ml={'5px'}
          data-testid={'patient-actions-menu'}
          _focusVisible={{
            boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
          }}
          type="button"
        >
          <MenuThreePointsIcon width={20} />
        </MenuButton>
        <MenuList
          boxShadow={'12px 12px 40px rgba(61, 14, 107, 0.1)'}
          minWidth={'130px !important'}
          bg={'surface'}
          borderRadius={'20px'}
          border={'none'}
          p={'10px'}
        >
          <MenuItem
            p={0}
            m={0}
            borderRadius={'20px'}
            mb={'5px'}
            _focusVisible={{
              boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
            }}
            onClick={handleEdit}
          >
            <Button w={'100%'} variant="outline-secondary" display={'flex'} justifyContent={'space-between'}>
              Editar
              <Box w={'16px'}>
                <EditIcon variantColor="secondary" />
              </Box>
            </Button>
          </MenuItem>
          <MenuItem
            p={0}
            m={0}
            borderRadius={'20px'}
            mb={'5px'}
            _focusVisible={{
              boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
            }}
            onClick={handleOnInitPrescription}
          >
            <Button w={'100%'} variant="outline-secondary" display={'flex'} justifyContent={'space-between'}>
              Iniciar prescrição
              <Box ml={'10px'} w={'16px'}>
                <PillsIcon variantColor="support.blue" />
              </Box>
            </Button>
          </MenuItem>
          <MenuItem
            p={0}
            m={0}
            borderRadius={'20px'}
            _focusVisible={{
              boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.6)',
            }}
            onClick={handleDelete}
          >
            <Button w={'100%'} variant="outline-error" display={'flex'} justifyContent={'space-between'}>
              Excluir
              <Box w={'16px'}>
                <TrashIcon variantColor="notification.error" />
              </Box>
            </Button>
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }, [showActionButtons, handleDelete, handleEdit, handleOnInitPrescription]);

  const PatientInformations = useMemo(() => {
    const doesPatientHaveAdditionalInfo = patient.birthDate || patient.document;

    return (
      <Flex justifyContent={'center'} flexDirection={'column'}>
        <Box mb={doesPatientHaveAdditionalInfo ? '5px' : 0}>
          <Typography model={'h2'} weight={'bold'} whiteSpace={'pre-line'}>
            {removeSpacesAndSpecialChars(getFirstAndLastWords(patient.fullName))}
          </Typography>
        </Box>

        {doesPatientHaveAdditionalInfo && (
          <Typography model={'body'} size={'normal'} weight={'regular'} whiteSpace={'pre-line'}>
            {patient.birthDate && makeAgeCalculation(new Date(patient.birthDate))}
            {patient.document && `, ${formatCpf(patient.document)}`}
          </Typography>
        )}
      </Flex>
    );
  }, [patient.document, patient.birthDate, patient.fullName]);

  return (
    <Flex
      bg={'surface'}
      position={'relative'}
      w={'100%'}
      borderRadius={'10px'}
      boxShadow={'3px 3px 10px rgba(61, 14, 107, 0.1)'}
    >
      {loading && (
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          borderRadius={'10px'}
          position={'absolute'}
          top={0}
          right={0}
          bottom={0}
          left={0}
          userSelect={'none'}
          background={'gray.100'}
          opacity={'0.5'}
          zIndex={1}
        >
          <Spinner color={'primary.1000'} />
        </Flex>
      )}
      <Button
        variant="ghost"
        alignItems={'center'}
        justifyContent={'space-between'}
        bg={'surface'}
        w={'100%'}
        textAlign={'start'}
        h={'100%'}
        id={`patient-card-${patient._id}`}
        onClick={handleOnClick}
        p={'20px'}
        borderRadius={'10px'}
      >
        <Flex overflow={'hidden'}>
          <Flex w={'30px'} mr={'10px'}>
            <PlaceholderAvatarIcon variantColor={'secondary'} />
          </Flex>
          {PatientInformations}
        </Flex>
      </Button>
      {ActionsMenu}
    </Flex>
  );
}
