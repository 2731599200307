import { debounce, get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import useLoading from 'src/helpers/useLoading';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';
import { Icd, PatientService } from 'src/services/patient';
import AutoComplete from './UI/AutoComplete';
import { FormControl } from './UI/FormControl';
import { FormErrorMessage } from './UI/FormErrorMessage';
import { FormLabel } from './UI/FormLabel';

interface SearchIcdProps {
  label?: string;
  hasError?: boolean;
  errorMessage?: string;
  onSelectIcd(icd: Icd | null): void;
  clearAfterSelect?: boolean;
  menuMaxHeight?: string;
  placeholder?: string;
  onBlur?(event: React.FocusEvent<HTMLElement>): void;
  autoFocus?: boolean;
  name?: string;
}

export function SearchIcd(props: SearchIcdProps) {
  const { hasError, label, errorMessage, onSelectIcd, clearAfterSelect, menuMaxHeight, placeholder, autoFocus, name } =
    props;

  const [icdOptions, setIcdOptions] = useState<Icd[]>([]);
  const [value, setValue] = useState<string | null>('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, runWithLoading] = useLoading();

  const { openToast } = useFeedbackContext();

  const findIcd = useMemo(
    () =>
      debounce(async (icd: string) => {
        if (icd === '') return;

        try {
          runWithLoading(async () => {
            const icdData = await PatientService.getDiagnostics(icd);
            setIcdOptions(icdData);
            if (!isMenuOpen) {
              setIsMenuOpen(true);
            }
          });
        } catch (e) {
          openToast('Não foi possível buscar os diagnósticos, tente novamente.', { type: 'error' });
        }
      }, 400),
    [runWithLoading, isMenuOpen, openToast]
  );

  const onInputIcd = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventTargetValue = get(event, 'target.value', null);

    if (eventTargetValue === '') {
      setValue('');
      setIsMenuOpen(false);
      return;
    }

    findIcd(eventTargetValue || '');
    setValue(eventTargetValue);
  };

  const onSelect = (icd: Icd) => {
    onSelectIcd(icd);
    setValue(`${icd.subcategory} - ${icd.description}`);
    setIsMenuOpen(false);

    if (clearAfterSelect) {
      setValue(``);
    }
  };

  useEffect(() => {
    if (!value) {
      setIsMenuOpen(false);
      return;
    }
  }, [value]);

  const onBlur = (event: React.FocusEvent<HTMLElement>) => {
    setIsMenuOpen(false);
    if (props.onBlur) props.onBlur(event);
  };

  return (
    <FormControl isInvalid={hasError || false}>
      {label && <FormLabel textTransform={'uppercase'}>{label}</FormLabel>}
      <AutoComplete
        onInputChange={onInputIcd}
        inputValue={value || ''}
        options={icdOptions}
        onSelect={onSelect}
        isMenuOpen={isMenuOpen}
        onBlur={onBlur}
        onCloseMenu={() => setIsMenuOpen(false)}
        noOptionsText={'Nenhum diagnóstico encontrado'}
        placeholder={placeholder || 'Informe o diagnóstico'}
        getOptionLabel={(option: Icd) => `${option.subcategory} - ${option.description}`}
        menuMaxHeigh={menuMaxHeight || '100px'}
        onClearInput={() => {
          setValue('');
        }}
        isLoading={loading}
        autoFocus={autoFocus}
        name={name}
      />

      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}
