import { NotFound } from 'src/containers/Errors/NotFound';
import { Login } from 'src/containers/Login/Login';
import { SignUp } from 'src/containers/SignUp/SignUp';
import { ConfirmEmail } from 'src/containers/ConfirmEmail/ConfirmEmail';
import { EmailConfirmed } from 'src/containers/ConfirmEmail/EmailConfirmed';
import { ForgotPassword } from 'src/containers/ForgotPassword/ForgotPassword';
import { EmailSent } from 'src/containers/ForgotPassword/EmailSent';
import { ForgotPasswordNewPassword } from 'src/containers/ForgotPassword/NewPassword';
import { Home } from 'src/containers/Home/Home';
import { ManagePatients } from 'src/containers/ManagePatients/ManagePatients';
import { ManageProfessionalData } from 'src/containers/ManageProfessionalData/ManageProfessionalData';
import { ManagePatient } from 'src/containers/ManagePatients/ManagePatient/ManagePatient';
import { CreatePartialPatient } from 'src/containers/CreatePartialPatient/CreatePartialPatient';
import { PrescriptionDetailsPage } from 'src/containers/History/PrescriptionDetails/PrescriptionDetails';
import { History } from 'src/containers/History/History';
import { TutorialPage } from 'src/containers/Tutorial/Tutorial';
import { HelpPage } from 'src/containers/Help/Help';

const publicRoutes = [
  {
    path: '/',
    component: Login,
    index: true,
  },
  {
    path: '/entrar',
    component: Login,
  },
  {
    path: '/cadastro',
    component: SignUp,
  },
  {
    path: '/confirmaremail',
    component: ConfirmEmail,
  },
  {
    path: '/emailconfirmado',
    component: EmailConfirmed,
  },
  {
    path: '/esqueceu-sua-senha',
    component: ForgotPassword,
  },
  {
    path: '/esqueceu-sua-senha/email-enviado',
    component: EmailSent,
  },
  {
    path: '/esqueceu-sua-senha/nova-senha',
    component: ForgotPasswordNewPassword,
  },
];

const protectedRoutes = [
  {
    path: '/inicio/receita',
    component: Home,
  },
  {
    path: '/inicio/gerenciar-pacientes',
    component: ManagePatients,
  },
  {
    path: '/inicio/meus-dados',
    component: ManageProfessionalData,
  },
  {
    path: '/inicio/historico',
    component: History,
  },
  {
    path: '/inicio/tutorial',
    component: TutorialPage,
  },
  {
    path: '/inicio/ajuda',
    component: HelpPage,
  },
];

const returnableProtectedRoutes = [
  {
    path: '/inicio/cadastro-rapido-paciente',
    component: CreatePartialPatient,
  },
  {
    path: '/inicio/cadastro-completo-paciente',
    component: ManagePatient,
  },
  {
    path: '/inicio/editar-paciente/:patientId',
    component: ManagePatient,
  },
  {
    path: '/inicio/historico/:historyId',
    component: PrescriptionDetailsPage,
  },
];

const errorRoutes = [
  {
    path: '*',
    component: NotFound,
  },
];

export { publicRoutes, protectedRoutes, errorRoutes, returnableProtectedRoutes };
