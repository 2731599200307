import * as React from 'react';
import { Header } from './Header';
import abstractBackground from 'src/assets/images/background.png';
import { useNavigate } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';

interface FlatLayoutProps {
  children: React.ReactNode;
  onGoBack?: () => void;
}

const FlatLayout: React.FC<FlatLayoutProps> = ({ onGoBack, children }) => {
  const navigate = useNavigate();

  const handleGoBack = React.useCallback(() => {
    if (onGoBack) return onGoBack();

    navigate('/');
  }, [navigate, onGoBack]);

  return (
    <Flex
      w={'100%'}
      h={'100vh'}
      bg={'background'}
      overflowY={'auto'}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}
      flexDirection="column"
      backgroundImage={{ base: '', sm: `url(${abstractBackground})` }}
    >
      <>
        <Flex>
          <Header onGoBack={handleGoBack} />
        </Flex>
        <Flex flex={1} alignItems={{ base: 'flex-start', sm: 'center' }}>
          {children}
        </Flex>
      </>
    </Flex>
  );
};

export default FlatLayout;
