import { Flex } from '@chakra-ui/react';
import DocBoxIcon from 'src/components/Icons/DocBoxIcon';
import Typography from 'src/components/Typography';
import { MedicalReport } from 'src/services/prescriptionHistory';

interface ReportItemProps {
  item: MedicalReport;
}

export function ReportItem(props: ReportItemProps) {
  const { item } = props;

  return (
    <Flex gap="10px">
      <Flex alignSelf="flex-start">
        <DocBoxIcon variantColor="secondary.400" width={23} background={{ color: 'support.surface', padding: 4 }} />
      </Flex>
      <Flex grow={1} flexDirection="column" gap="10px">
        <Typography model="body" size="large" weight="bold">
          {item.title}
        </Typography>
        <Typography model="body" size="large" weight="regular">
          {item.text}
        </Typography>
      </Flex>
    </Flex>
  );
}
