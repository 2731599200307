import { TABS_INDEX } from './SignUp';

import { Box } from 'src/components/UI/Box';
import { Flex } from 'src/components/UI/Flex';
import { Button } from 'src/components/UI/Button';
import Typography from 'src/components/Typography';

import { colors } from 'src/theme/colors';

import { ReactComponent as Check } from 'src/assets/icons/check.svg';

interface StyledTabProps {
  children: React.ReactNode;
  onClick(): void;
  isSelected: boolean;
}

const StyledTab = (props: StyledTabProps) => {
  return (
    <Button
      p={'5px'}
      mr={'0px'}
      borderRadius={'20px'}
      _selected={{
        backgroundColor: 'secondary.400',
        boxShadow: '10px 10px 30px rgba(61, 14, 107, 0.15)',
      }}
      backgroundColor={props.isSelected ? 'secondary.400' : 'surface'}
      color={'primary.1000'}
      textTransform={'uppercase'}
      _focus={{
        boxShadow: 'none',
      }}
      onClick={props.onClick}
      h={'auto'}
      _hover={{}}
    >
      <Flex>{props.children}</Flex>
    </Button>
  );
};

interface TabsHeaderProps {
  isFirstStepCompleted?: boolean;
  isSecondStepCompleted?: boolean;
  isThirdStepCompleted?: boolean;
  tabClick(index: number): void;
  currentIndex: number;
}

export function TabsHeader(props: TabsHeaderProps) {
  const { tabClick, isFirstStepCompleted, isSecondStepCompleted, isThirdStepCompleted } = props;

  const handleTabClick = (tabIndex: number) => {
    tabClick(tabIndex);
  };

  return (
    <Flex
      bg={'surface'}
      border={'1px solid'}
      borderColor={{ base: 'surface', sm: 'primary.50' }}
      p={'10px'}
      borderRadius={'20px'}
      mt={'20px'}
      mb={'30px'}
      justifyContent={'space-between'}
    >
      <StyledTab
        isSelected={props.currentIndex === TABS_INDEX.PERSONAL_DATA}
        onClick={() => handleTabClick(TABS_INDEX.PERSONAL_DATA)}
      >
        <Box mr={'5px'}>
          <Check fill={isFirstStepCompleted ? colors.notification.success : colors.primary['1000']} />
        </Box>
        <Typography model={'caption'} weight={'bold'}>
          Passo 1
        </Typography>
      </StyledTab>
      <StyledTab
        isSelected={props.currentIndex === TABS_INDEX.PROFESSIONAL_DATA}
        onClick={() => handleTabClick(TABS_INDEX.PROFESSIONAL_DATA)}
      >
        <Box mr={'5px'}>
          <Check fill={isSecondStepCompleted ? colors.notification.success : colors.primary['1000']} />
        </Box>
        <Typography model={'caption'} weight={'bold'}>
          Passo 2
        </Typography>
      </StyledTab>
      <StyledTab
        isSelected={props.currentIndex === TABS_INDEX.PLACE_OF_SERVICE}
        onClick={() => handleTabClick(TABS_INDEX.PLACE_OF_SERVICE)}
      >
        <Box mr={'5px'}>
          <Check fill={isThirdStepCompleted ? colors.notification.success : colors.primary['1000']} />
        </Box>
        <Typography model={'caption'} weight={'bold'}>
          Passo 3
        </Typography>
      </StyledTab>
      <StyledTab
        isSelected={props.currentIndex === TABS_INDEX.CREATE_PASSWORD}
        onClick={() => handleTabClick(TABS_INDEX.CREATE_PASSWORD)}
      >
        <Box mr={'5px'}>
          <Check fill={colors.primary['1000']} />
        </Box>
        <Typography model={'caption'} weight={'bold'}>
          Passo 4
        </Typography>
      </StyledTab>
    </Flex>
  );
}
