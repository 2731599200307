import { Address } from 'src/services/cep';
import { GENDER, Patient } from 'src/services/patient';
import { CreatePrescriberPayload, Prescriber } from 'src/services/prescriber';
import { PrescriptionMedicine } from 'src/services/prescriptionHistory';

export const DEFAULT_CREATE_PRESCRIBER_FORM_DATA = (): CreatePrescriberPayload => ({
  fullName: '',
  document: '',
  documentType: '',
  cellphoneNumber: '',
  email: '',
  password: '',
  birthDate: '',
  attendanceAddress: {
    logoUrl: '',
    placeName: '',
    postalCode: '',
    address: '',
    houseNumber: '',
    district: '',
    complement: '',
    city: '',
    state: '',
    phoneNumber: '',
  },
  acceptedNewsLetter: true,
  professionalRecord: {
    number: '',
    state: '',
    issuer: '',
  },
  consentTerm: {
    termUrl: '',
    accepted: false,
    version: '',
    termReleaseDate: '',
  },
  createdThroughApp: false,
});

export const DEFAULT_ADDRESS = (): Address => ({
  cep: '05593-970',
  logradouro: 'Avenida Comendador Alberto Bonfiglioli',
  complemento: '737',
  bairro: 'Jardim Bonfiglioli',
  localidade: 'São Paulo',
  uf: 'SP',
  ibge: '3550308',
  gia: '1004',
  ddd: '11',
  siafi: '7107',
});

export const DEFAULT_AUTHENTICATED_USER = (): Array<{ Name: string; Value: string }> => [
  {
    Name: 'sub',
    Value: '5513e20e-9962-4b90-9236-0f61ef6b4f34',
  },
  {
    Name: 'email_verified',
    Value: 'true',
  },
  {
    Name: 'email',
    Value: 'teste@teste.com',
  },
  {
    Name: 'custom:userId',
    Value: '123123',
  },
];

export const DEFAULT_SESSION = (): any => ({
  idToken: {
    jwtToken: '123123',
  },
  refreshToken: {
    token: '123123',
  },
  accessToken: {
    jwtToken: '123123',
  },
  getAccessToken() {
    return {
      getJwtToken() {
        return '123123';
      },
    };
  },
});

export const DEFAULT_PATIENT = (): Patient => ({
  fullName: 'José Leonardo Ferreira',
  document: '191.228.299-20',
  documentType: 'cpf',
  cellphoneNumber: '(99) 9 9999-9999',
  birthDate: new Date('1999-12-29T02:00:00.000Z'),
  active: true,
  sex: GENDER.FEMININE,
  lastAttendance: new Date('2023-01-20T18:53:32.895Z'),
  _id: '63cae32cb77817dc7c127888',
});

export const DEFAULT_PRESCRIBER_DATA = (): Prescriber => ({
  _id: '123123',
  fullName: 'Jorge Yago Diogo Nogueira',
  document: '947.267.919-66',
  documentType: 'CPF',
  cellphoneNumber: '(99) 9 9999-9999',
  email: 'jorgeyagonogueira@debiasi.com.br',
  birthDate: '1999-12-29T02:00:00.000Z',
  sex: 'Masculino',
  CNS: '199768309280008',
  professionalRecord: {
    number: '1231231',
    state: 'RJ',
    issuer: 'CRM',
  },
  attendanceAddress: {
    logoUrl: '',
    placeName: 'Escritório',
    postalCode: '68909-659',
    address: 'Avenida das Oliveiras',
    district: 'Brasil Novo',
    complement: '',
    city: 'Macapá',
    state: 'AP',
    houseNumber: '9999999',
    phoneNumber: '(35) 999999999',
    CNES: '9999999',
  },
});

export const DEFAULT_MEVO_WHATSAPP_NUMBER = () => '11973258051';
export const DEFAULT_MEVO_PHONE_NUMBER = () => '1135800110';
export const MEVO_PRIVACY_URL = 'https://mevosaude.com.br/privacy-policy/mevo-receita-digital';
export const MEVO_HELP_PORTAL_URL = 'https://mevosaude.com.br/faq/';

export const DEFAULT_STRUCTURED_PATIENT_DATA = {
  basicData: {
    fullName: 'TESTE21',
    document: '44026572021',
    documentType: 'cpf',
    cellphoneNumber: '43242332433',
    birthDate: '29/12/1999',
    active: true,
    sex: GENDER.FEMININE,
    lastAttendance: '2023-04-13T14:03:44.645Z',
    createdAt: '2023-04-13T14:03:44.646Z',
    _id: '64380bc0e984a3dbcf72ff27',
  },
  additionInformation: {
    patientEmail: 'teste@teste.com.br',
    parentName: 'mae do paciente teste',
    patientAddress: {
      address: 'Rua Milena de Souza',
      city: 'Criciúma',
      complement: '',
      district: 'Vila Nova Esperança',
      houseNumber: '550',
      postalCode: '88806-078',
      state: 'SC',
    },
  },
  clinicalData: {
    CNS: '846.4526.3038.0004',
    height: '169',
    weight: '105',
    pregnant: true,
    lactating: true,
    smoker: true,
    ethnicity: 'Branca',
    patientDiagnostics: [
      {
        id: 11,
        subcategory: 'A02.2',
        description: 'Infecções localizadas por salmonela',
      },
      {
        id: 3,
        subcategory: 'A00.9',
        description: 'Cólera não especificada',
      },
      {
        id: 1,
        subcategory: 'A00.0',
        description: 'Cólera devida a Vibrio cholerae 01, biótipo cholerae',
      },
    ],
    patientAllergies: [
      {
        id: 1093,
        name: 'DIPIRONA',
        mdxalg: '7701219',
      },
    ],
  },
};

export const DEFAULT_MEDICINE = (): PrescriptionMedicine => ({
  posology: 'Injetar via intravenosa',
  comment: '',
  quantity: 1,
  isContinuos: false,
  isPopularPharmacy: false,
  administrationWayName: 'INTRAVENOSA',
  twoWayPrinting: false,
  medicineId: 29350,
  name: 'ORENCIA',
  unity: 'MG/ML',
  medicineType: 'BIOLOGICO',
  medicineTypeWithAccents: 'BIOLÓGICO',
  pharmaceuticalForm: 'Solucao Injetavel',
  pharmaceuticalNameWithAccents: 'Solução Injetável',
  antimicrobial: false,
  antimicrobialDescription: 'Não é antimicrobiano',
  LME: true,
  ordinance344Id: 0,
  administrationWayId: 2,
  MIP: false,
  Label: 'ORENCIA 125 MG/ML, Solução injetável',
  drugLabel: 'ABATACEPTE',
  formattedUnitAndQuantity: '1 caixa com 1 unidade',
});
