import { Button } from '@chakra-ui/react';

interface DocumentTabProps {
  text: string;
  selected: boolean;
  onClick: () => void;
}

export function DocumentTab(props: DocumentTabProps) {
  const { text, selected, onClick } = props;

  return (
    <Button
      variant={'ghost'}
      bg={selected ? 'primary.1000' : 'surface'}
      color={selected ? 'secondary.100' : 'primary.1000'}
      borderRadius={'20px'}
      border={'2px solid'}
      borderColor={'primary.1000'}
      onClick={onClick}
      _hover={{}}
      fontSize={'14px'}
      data-testid={selected ? 'tab-button-active' : 'tab-button'}
    >
      {text}
    </Button>
  );
}
