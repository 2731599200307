import { Box, Flex } from '@chakra-ui/react';
import { CertificateItem } from './MedicalCertificateItem';
import { PrescriptionMedicalStatement } from 'src/services/prescriptionHistory';

interface CertificateProps {
  items: PrescriptionMedicalStatement[];
}

export function Certificate(props: CertificateProps) {
  const { items } = props;
  return (
    <Box>
      <Flex flexDirection="column" gap="20px">
        {items.map((item) => (
          <CertificateItem item={item} key={`${item.text}-${item.type}`} />
        ))}
      </Flex>
    </Box>
  );
}
