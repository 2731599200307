import { useCallback } from 'react';
import Typography from 'src/components/Typography';
import { Flex } from 'src/components/UI/Flex';

interface HelpCardProps {
  text: string;
  onClick?(): void;
  Icon: React.ReactNode;
}

export function HelpCard(props: HelpCardProps) {
  const { text, onClick, Icon } = props;

  const handleOnClick = useCallback(() => {
    if (!onClick) return;
    onClick();
  }, [onClick]);

  return (
    <Flex
      w={'100%'}
      p="20px"
      backgroundColor="support.surface"
      borderRadius="20px"
      cursor="pointer"
      onClick={handleOnClick}
      flexDirection={'column'}
      justifyContent={'space-between'}
      border={'1px solid #F2EEFB'}
    >
      <Flex mb={'10px'}>{Icon}</Flex>
      <Typography model="body" size="large" weight="semi">
        {text}
      </Typography>
    </Flex>
  );
}
