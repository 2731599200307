import { Box, Flex } from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NavButton from 'src/components/Helpers/NavButton';
import FlatLayout from 'src/components/Layout/FlatLayout';
import Typography from 'src/components/Typography';
import { Button } from 'src/components/UI/Button';
import FormBaseCard from 'src/components/UI/FormBaseCard';
import { FormControl } from 'src/components/UI/FormControl';
import { ProtectedPassword } from 'src/components/UI/Input/ProtectedPassword';
import useLoading from 'src/helpers/useLoading';
import { Yup } from 'src/helpers/Yup';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';
import { AuthService } from 'src/services/AuthService';

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .required('Informe uma senha válida.')
    .min(6, 'Sua senha deve ter no mínimo 6 caracteres')
    .max(16, 'Sua senha deve ter no máximo 16 caracteres')
    .test('validate password', 'Senha não atende os padrões de segurança.', function (password, context): boolean {
      return context.parent.isPasswordProtected;
    }),
  newPassword: Yup.string()
    .required('Informe uma senha de confirmação válida.')
    .min(6, 'Sua senha deve ter no mínimo 6 caracteres')
    .max(16, 'Sua senha deve ter no máximo 16 caracteres')
    .oneOf([Yup.ref('password'), ''], 'A senha e senha de confirmação devem ser iguais.'),
});

interface ForgotPasswordNewPasswordProps {}

export const ForgotPasswordNewPassword: React.FunctionComponent<ForgotPasswordNewPasswordProps> = () => {
  const [loading, runWithLoading] = useLoading();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { openToast } = useFeedbackContext();

  const formik = useFormik({
    initialValues: {
      email: searchParams.get('email') || '',
      password: '',
      isPasswordProtected: false,
      newPassword: '',
      verfificationCode: searchParams.get('code') || '',
    },
    validationSchema: LoginSchema,
    validateOnChange: false,
    async onSubmit(values) {
      runWithLoading(() => {
        try {
          AuthService.confirmPassword({
            email: values.email,
            newPassword: values.newPassword,
            verificationCode: values.verfificationCode,
          });

          openToast('Senha atualizada com sucesso', { type: 'success', duration: 2000 });
          navigate({ pathname: '/' });
        } catch (e) {
          openToast('Não foi possível atualizar sua senha, tente novamente.', { type: 'error', duration: 2000 });
        }
      });
    },
  });

  const setFieldValue = React.useMemo(() => formik.setFieldValue, [formik.setFieldValue]);
  const validateField = React.useMemo(() => formik.validateField, [formik.validateField]);

  const updatePasswordFormValidation = React.useCallback(
    async (value: boolean) => {
      await setFieldValue('isPasswordProtected', value);
      await validateField('password');
    },
    [setFieldValue, validateField]
  );

  return (
    <FlatLayout>
      <Flex alignItems="center" justifyContent="center" flex={1}>
        <FormBaseCard>
          <Box width={'100%'}>
            <Typography model="h1" weight="bold">
              Definir nova senha
            </Typography>
            <Box my="20px">
              <Typography model="body" size="large" weight="regular">
                Digite sua nova senha
              </Typography>
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <FormControl mb="20px" isInvalid={!!formik.errors.password}>
                <ProtectedPassword
                  label={'Nova senha'}
                  errorMessage={formik.errors.password || formik.errors.isPasswordProtected}
                  isInvalid={!!formik.errors.password || !!formik.errors.isPasswordProtected}
                  placeholder={'Digite aqui a senha'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  showPasswordProtectionAttributes={true}
                  onProtected={updatePasswordFormValidation}
                  name={'password'}
                  onBlur={() => formik.validateField('password')}
                  autoFocus
                />
              </FormControl>
              <FormControl mb="20px" isInvalid={!!formik.errors.newPassword}>
                <ProtectedPassword
                  label={'Confirmar nova senha'}
                  errorMessage={formik.errors.newPassword}
                  isInvalid={!!formik.errors.newPassword}
                  placeholder={'Digite aqui a confirmação da senha'}
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  name={'newPassword'}
                  onBlur={() => formik.validateField('newPassword')}
                />
              </FormControl>

              <NavButton>
                <Button
                  id="forgot-password:submit"
                  isLoading={loading}
                  width="100%"
                  height="60px"
                  type="submit"
                  mt="16px"
                >
                  Salvar nova senha
                </Button>
              </NavButton>
            </form>
          </Box>
        </FormBaseCard>
      </Flex>
    </FlatLayout>
  );
};
