import { useMemo } from 'react';
import { Menu } from '../UI/Menu/Menu';
import { MenuList } from '../UI/Menu/MenuList';
import { MenuButton } from '../UI/Menu/MenuButton';
import { MenuItem } from '../UI/Menu/MenuItem';
import PlaceholderAvatarIcon from '../Icons/PlaceholderAvatarIcon';
import ArrowIcon from '../Icons/ArrowIcon';
import LogoutIcon from '../Icons/LogoutIcon';
import { Flex } from '../UI/Flex';
import { Box } from '../UI/Box';
import Typography from '../Typography';
import { Button } from '../UI/Button';
import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { useNavigate } from 'react-router-dom';
import { AuthService } from 'src/services/AuthService';
import { getFirstTwoWords } from 'src/helpers/getFirstTwoWords';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';
import { Show } from '@chakra-ui/media-query';
import { USER_STATUS, useAuthContext } from 'src/providers/AuthProvider';

function AvatarIcon() {
  return (
    <Box mr={{ base: '10px', sm: 0 }}>
      <PlaceholderAvatarIcon variantColor="support.blue" />
    </Box>
  );
}

function UserMenu() {
  const { prescriberData, setPrescriberData, setPrescriberId } = usePrescriberContext();
  const { setSession, setUserStatus } = useAuthContext();
  const { openToast } = useFeedbackContext();

  const navigate = useNavigate();

  const signOut = async () => {
    try {
      await AuthService.signOut();
      setPrescriberId(null);
      setPrescriberData(null);
      setUserStatus(USER_STATUS.NOT_AUTHENTICATED);
      setSession(null);

      navigate('/');
    } catch (e) {
      openToast('Algo deu errado. Tente novamente em instantes.', { type: 'error', duration: 2000 });
    }
  };

  const prescriberName = useMemo(() => {
    if (!prescriberData) return;

    return getFirstTwoWords(prescriberData.fullName);
  }, [prescriberData]);

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            w={'200px'}
            borderRadius={'20px 20px 0 0'}
            bg={isOpen ? 'white' : 'background'}
            h={'40px'}
            p={{ base: 0, sm: '0 10px' }}
            m={0}
          >
            <Flex alignItems={'center'} justifyContent={'space-between'} p={'0 5px'}>
              <Show below="sm">
                <AvatarIcon />
              </Show>

              <Typography model={'body'} size={'large'} weight={'regular'}>
                {prescriberName}
              </Typography>
              <Box w={'5px'} m={'0 10px'}>
                <ArrowIcon direction={isOpen ? 'up' : 'down'} />
              </Box>

              <Show above="sm">
                <AvatarIcon />
              </Show>
            </Flex>
          </MenuButton>
          <MenuList
            transform={'translate3d(20px, 20px, 0px)'}
            mt={'-10px'}
            p={0}
            minW={'200px'}
            w={'192px'}
            bg={'white'}
            borderRadius={'0 0 20px 20px'}
            border={'none'}
            _hover={{}}
            _active={{}}
            _expanded={{}}
            zIndex={3}
          >
            <MenuItem h={'70px'} bg={'white'} borderRadius={'0 0 20px 20px'} _hover={{}} _active={{}} _expanded={{}}>
              <Button
                onClick={signOut}
                variant="outline-error"
                w={'192px'}
                display={'flex'}
                justifyContent={'space-between'}
                as={'span'}
              >
                Sair
                <Box w={'16px'}>
                  <LogoutIcon variantColor="notification.error" />
                </Box>
              </Button>
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
}

export default UserMenu;
