import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './transitions.css';

import './fonts/Ambit/Ambit-Bold.woff';
import './fonts/Ambit/Ambit-Bold.woff2';
import './fonts/Ambit/Ambit-Regular.woff';
import './fonts/Ambit/Ambit-Regular.woff2';
import './fonts/Ambit/Ambit-SemiBold.woff';
import './fonts/Ambit/Ambit-SemiBold.woff2';
import './fonts/Ambit/Ambit-Thin.woff';
import './fonts/Ambit/Ambit-Thin.woff2';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
