import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { Spinner } from '@chakra-ui/react';
import { AnimatedRoute } from 'src/router/AnimatedRoute';
import { appSuccesfullySignedIn } from 'src/helpers/window';
import { sleep } from 'src/helpers/sleep';
import { Flex } from 'src/components/UI/Flex';
import { useAuthContext } from 'src/providers/AuthProvider';

export function AuthPlaceholder() {
  const { prescriberData } = usePrescriberContext();
  const { userStatus } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    const run = async () => {
      if (prescriberData) {
        await sleep(1000);
        appSuccesfullySignedIn();
        navigate('/inicio/receita');
        return;
      }
    };

    run();
  }, [navigate, prescriberData, userStatus]);

  return (
    <AnimatedRoute>
      <Flex justifyContent={'center'} alignItems={'center'} h={window.innerHeight}>
        <Spinner />
      </Flex>
    </AnimatedRoute>
  );
}
