import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { SimpleGrid } from '@chakra-ui/react';
import { isEqual } from 'lodash';

import { ProfessionalDataFormSkeleton } from './skeletons/ProfessionalDataFormSkeleton';

import Typography from 'src/components/Typography';
import MevoInput from 'src/components/Input';
import { Flex } from 'src/components/UI/Flex';
import { Button } from 'src/components/UI/Button';
import { Select } from 'src/components/UI/Select/Select';
import { FormControl } from 'src/components/UI/FormControl';
import { FormErrorMessage } from 'src/components/UI/FormErrorMessage';

import { Yup } from 'src/helpers/Yup';
import { counsels } from 'src/helpers/professionalCounsels';

import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';

import { PrescriberService } from 'src/services/prescriber';
import SelectState from 'src/components/SelectState';
import { Box } from 'src/components/UI/Box';

interface ProfessionalDataFormFields {
  counsel: string;
  UFRegister: string;
  registerNumber: string | number;
  RQE: string;
}

const ProfessionalDataFormSchema = Yup.object().shape({
  counsel: Yup.string().required('Informe o conselho profissional.'),
  UFRegister: Yup.string().required('Informe uma UF do Registro válida.'),
  registerNumber: Yup.string().required('Informe um Número de Registro válido.'),
  RQE: Yup.string().optional(),
});

const initialValues: ProfessionalDataFormFields = {
  counsel: '',
  UFRegister: '',
  registerNumber: '',
  RQE: 'Não exibir',
};

interface ProfessionalDataFormProps {
  onEdit?(): void;
}

export function ProfessionalDataForm(props: ProfessionalDataFormProps) {
  const [defaultValues, setDefaultValues] = useState<ProfessionalDataFormFields>(initialValues);
  const { prescriberData, setPrescriberData } = usePrescriberContext();
  const { openToast } = useFeedbackContext();

  const { values, errors, isSubmitting, handleChange, setValues, handleSubmit, setFieldValue, validateField } =
    useFormik<ProfessionalDataFormFields>({
      initialValues: initialValues,
      validationSchema: ProfessionalDataFormSchema,
      validateOnChange: false,
      onSubmit: onSubmit,
    });

  async function onSubmit(data: ProfessionalDataFormFields) {
    try {
      if (!prescriberData) return;

      await PrescriberService.updatePrescriber(prescriberData._id, {
        ...prescriberData,
        professionalRecord: {
          ...prescriberData.professionalRecord,
          RQE: data.RQE,
        },
      });

      setPrescriberData({
        ...prescriberData,
        professionalRecord: {
          ...prescriberData.professionalRecord,
          RQE: data.RQE,
        },
      });

      openToast('Dados salvos com sucesso.', {
        type: 'success',
        duration: 2000,
      });

      if (props.onEdit) {
        props.onEdit();
      }
    } catch (error) {
      openToast('Ocorreu um erro ao salvar seus dados. Tente novamente em instantes.', {
        type: 'error',
        duration: 2000,
      });
    }
  }

  useEffect(() => {
    if (!prescriberData) return;

    setValues({
      counsel: prescriberData.professionalRecord.issuer,
      UFRegister: prescriberData.professionalRecord.state,
      registerNumber: prescriberData.professionalRecord.number,
      RQE: prescriberData.professionalRecord.RQE ?? '',
    });

    setDefaultValues({
      counsel: prescriberData.professionalRecord.issuer,
      UFRegister: prescriberData.professionalRecord.state,
      registerNumber: prescriberData.professionalRecord.number,
      RQE: prescriberData.professionalRecord.RQE ?? '',
    });
  }, [prescriberData, setValues]);

  const isSameData = isEqual(defaultValues, values);

  if (!prescriberData) {
    return <ProfessionalDataFormSkeleton />;
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        height: '100%',
      }}
      data-testId={'professional-data-form'}
    >
      <Flex
        flexDirection={'column'}
        gap={'20px'}
        bgColor={{
          base: 'transparent',
          lg: 'white',
        }}
        borderRadius={'20px'}
        p={{ base: '0px', sm: '32px' }}
        h={'full'}
      >
        <Box display={{ base: 'none', sm: 'block' }}>
          <Typography model={'h2'} size={'large'} weight={'bold'}>
            Dados profissionais
          </Typography>
        </Box>

        <FormControl isInvalid={!!errors.counsel}>
          <Typography model={'caption'} size={'normal'} weight={'bold'} textTransform={'uppercase'}>
            Conselho profissional*
          </Typography>

          <Select
            name={'counsel'}
            placeholder="Selecione o conselho profissional"
            h={'45px'}
            mt={'10px'}
            value={values.counsel}
            isDisabled={true}
            data-testId={'select-counsel'}
          >
            {counsels.map((counsel) => (
              <option value={counsel.initials} key={counsel.initials}>
                {counsel.name}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.counsel}</FormErrorMessage>
        </FormControl>

        <SimpleGrid columns={2} gap={'20px'}>
          <FormControl isInvalid={!!errors.UFRegister}>
            <SelectState
              label={'UF do registro*'}
              placeholder={'Selecione o estado (UF)'}
              value={values.UFRegister}
              onChange={(value) => setFieldValue('UFRegister', value)}
              onBlur={() => validateField('UFRegister')}
              errorMessage={errors.UFRegister}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.registerNumber}>
            <MevoInput
              name={'registerNumber'}
              placeholder={'XPTO161'}
              labelText={'N˚ do registro*'}
              value={values.registerNumber}
              isDisabled={true}
              m={0}
            />
            <FormErrorMessage>{errors.registerNumber}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <FormControl isInvalid={!!errors.RQE}>
          <Typography model={'caption'} size={'normal'} weight={'bold'} textTransform={'uppercase'}>
            RQE
          </Typography>

          <Select name={'RQE'} value={values.RQE} onChange={handleChange} h={'45px'} mt={'10px'}>
            <option value={'Não exibir'} selected>
              Não exibir
            </option>
          </Select>
          <FormErrorMessage>{errors.RQE}</FormErrorMessage>
        </FormControl>

        <Button
          type={'submit'}
          isLoading={isSubmitting}
          isDisabled={isSameData}
          w={{
            base: 'full',
            md: '150px',
          }}
          h={'60px'}
          alignSelf={'flex-end'}
          mt={'auto'}
        >
          Salvar
        </Button>
      </Flex>
    </form>
  );
}
