import React from 'react';
import {
  FormErrorMessage as ChakraFormErrorMessage,
  FormErrorMessageProps as ChakraFormErrorMessageProps,
} from '@chakra-ui/react';

export function FormErrorMessage(props: ChakraFormErrorMessageProps) {
  return (
    <ChakraFormErrorMessage
      fontFamily={'Ambit-Bold'}
      fontSize={props.fontSize || '10px'}
      color={props.color || 'notification.error'}
      {...props}
    />
  );
}
