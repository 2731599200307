import { AbstractTracker, TrackEventParameters } from './AbstractTracker';
import { MixPanelEmitter, MixPanelFactory } from './mixpanel-factory';

export class MixpanelModule extends AbstractTracker {
  settings = {};

  mixPanelEmitter: MixPanelEmitter;

  constructor() {
    super();
    this.mixPanelEmitter = MixPanelFactory.create();
  }

  get isLoaded() {
    return this.mixPanelEmitter.get_distinct_id();
  }

  trackEvent = this.runIfLoaded(({ parameters, event }: TrackEventParameters): void => {
    this.mixPanelEmitter.track(event, parameters);
  });

  peopleSet = this.runIfLoaded((event: string, parameter: any): void => {
    this.mixPanelEmitter.people.set(event, parameter);
  });

  peopleSetOnce = this.runIfLoaded((event: string, parameter: any): void => {
    this.mixPanelEmitter.people.set_once(event, parameter);
  });

  identify = this.runIfLoaded((identifier: string): void => {
    this.mixPanelEmitter.identify(identifier);
  });

  register = this.runIfLoaded((parameters: object): void => {
    this.mixPanelEmitter.register(parameters);
  });

  runIfLoaded(callback: Function) {
    return (params: any) => {
      if (!this.isLoaded) return;
      callback(params);
    };
  }
}
