import React, { useEffect, useRef, useState } from 'react';
import { AlertDialog as BaseAlertDialog } from '../Dialog/AlertDialog';
import { AlertDialogOverlay } from '../Dialog/AlertDialogOverlay';
import { AlertDialogContent } from '../Dialog/AlertDialogContent';
import { Flex } from '../Flex';
import { Box } from '../Box';
import { AlertDialogCloseButton } from '../Dialog/AlertDialogCloseButton';

interface BaseDialogProps {
  isOpen: boolean;
  onChange(open: boolean): void;
  children: React.ReactNode;
  persist?: boolean;
  width?: string;
  height?: string;
  onClose?(): void;
  showCloseButton?: boolean;
  customStyles?: { [key: string]: string };
}

export function BaseDialog(props: BaseDialogProps) {
  const cancelRef = useRef(null);

  const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const onClose = () => {
    props.onChange(false);

    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <BaseAlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      onOverlayClick={() => !props.persist && props.onChange(false)}
      isOpen={isOpen}
      isCentered
      closeOnOverlayClick={!props.persist}
      // esta prop resolve problema de scroll em casos que o dialog
      // é utilizado com 100vw/vh
      // esta prop não interfere em dialogs que não sejam fullscreen
      size={props.customStyles?.maxHeight ?? 'full'}
    >
      <AlertDialogOverlay bg={'rgba(62, 62, 147, 0.5)'} />
      <AlertDialogContent style={{ ...props.customStyles }}>
        {props.showCloseButton && (
          <Box h={'20px'}>
            <AlertDialogCloseButton />
          </Box>
        )}
        <Flex justifyContent={'center'} width={'100%'} h={'100%'}>
          {props.children}
        </Flex>
      </AlertDialogContent>
    </BaseAlertDialog>
  );
}
