import { AbstractTracker, TrackEventParameters } from './AbstractTracker';
import { ReactGAEventEmitter, ReactGaFactory } from './react-ga-factory';

export class GAModule extends AbstractTracker {
  settings = {};

  gaEventEmitter: ReactGAEventEmitter;

  constructor() {
    super();
    this.gaEventEmitter = ReactGaFactory.create();
  }

  trackEvent(parameters: TrackEventParameters): void {
    this.gaEventEmitter.event({
      category: 'Event',
      action: parameters.event,
    });
  }

  peopleSet(event: string, parameter: any): void {}

  peopleSetOnce(event: string, parameter: any): void {}

  identify(identifier: string): void {}

  register(parameters: object): void {}
}
