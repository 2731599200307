import { Box, Flex } from '@chakra-ui/react';
import { ReferralItem } from './MedicalReferralItem';
import { PrescriptionReferral } from 'src/services/prescriptionHistory';

interface ReferralProps {
  items: PrescriptionReferral[];
}

export function Referral(props: ReferralProps) {
  const { items } = props;
  return (
    <Box>
      <Flex flexDirection="column" gap="20px">
        {items.map((item) => (
          <ReferralItem item={item} key={`${item.clinicalHistory}-${item.conduct}`} />
        ))}
      </Flex>
    </Box>
  );
}
