import { useEffect, useState } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';

import MevoInput from 'src/components/Input';
import Typography from 'src/components/Typography';
import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { Flex } from 'src/components/UI/Flex';
import { MASKS } from 'src/components/UI/Input/MaskedInput';
import { FormControl } from 'src/components/UI/FormControl';

import { Yup } from 'src/helpers/Yup';
import { onlyDigits } from 'src/helpers/string';

import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';

import SelectState from 'src/components/SelectState';
import AlertIcon from 'src/components/Icons/AlertIcon';
import { CEPService } from 'src/services/cep';
import { PatientAdditionalData, PatientService, UpdateAdditionalDataPatientPayload } from 'src/services/patient';

const addressFormSchema = Yup.object().shape({
  patientEmail: Yup.string().email('Informe um e-mail válido').nullable(),
  parentName: Yup.string().nullable(),
  patientAddress: Yup.object().shape({
    postalCode: Yup.string()
      .nullable()
      .test('validate postal code', 'Informe um CEP válido.', function (postalCode) {
        if (!postalCode) return true;

        return onlyDigits(postalCode || '').length === 8;
      }),
    address: Yup.string().nullable(),
    city: Yup.string().nullable(),
    complement: Yup.string().nullable(),
    district: Yup.string().nullable(),
    houseNumber: Yup.string().nullable(),
    state: Yup.string().nullable(),
  }),
});

const initialValues: PatientAdditionalData = {
  patientEmail: '',
  parentName: '',
  patientAddress: {
    address: '',
    city: '',
    complement: '',
    district: '',
    houseNumber: '',
    postalCode: '',
    state: '',
  },
  patientId: '',
};

interface AdditionalDataAndAddressFormProps {
  onChange(): void;
  formData: PatientAdditionalData | null;
}

export function AdditionalDataAndAddressForm(props: AdditionalDataAndAddressFormProps) {
  const [defaultValues, setDefaultValues] = useState<PatientAdditionalData>(initialValues);
  const { openToast } = useFeedbackContext();
  const { prescriberId } = usePrescriberContext();

  const {
    values,
    errors,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    setValues,
    validateField,
    handleChange,
    setFieldError,
  } = useFormik<PatientAdditionalData>({
    initialValues: initialValues,
    validationSchema: addressFormSchema,
    validateOnChange: false,
    onSubmit: onSubmit,
  });

  async function onSubmit(data: PatientAdditionalData) {
    if (!prescriberId) return;

    try {
      const payload: UpdateAdditionalDataPatientPayload = {
        parentName: data.parentName,
        patientEmail: data.patientEmail,
        patientId: data.patientId,
        ...data.patientAddress,
        postalCode: onlyDigits(data.patientAddress.postalCode),
      };

      await PatientService.updatePatientAdditionalInformation(prescriberId, payload);

      openToast('Dados salvos com sucesso.', {
        type: 'success',
        duration: 2000,
      });

      props.onChange();
    } catch (error) {
      openToast('Ocorreu um erro ao salvar os dados do paciente. Tente novamente em instantes.', {
        type: 'error',
        duration: 2000,
      });
    }
  }

  const clearAddressFields = () => {
    validateField('postalCode');
    setFieldValue('address', '');
    setFieldValue('complement', '');
    setFieldValue('district', '');
    setFieldValue('city', '');
    setFieldValue('state', '');
    setFieldValue('houseNumber', '');
  };

  const searchPostalCodeData = async (postalCode: string) => {
    if (onlyDigits(postalCode).length < 8) {
      clearAddressFields();
      return;
    }

    try {
      const postalCodeData = await CEPService.searchForCepInViaCep(postalCode);

      if (!postalCodeData) return;

      setFieldValue('patientAddress.address', postalCodeData.logradouro);
      setFieldValue('patientAddress.district', postalCodeData.bairro);
      setFieldValue('patientAddress.complement', postalCodeData.complemento);
      setFieldValue('patientAddress.city', postalCodeData.localidade);
      setFieldValue('patientAddress.state', postalCodeData.uf);
    } catch (e: any) {
      if (e.message === 'CEP inválido') {
        return;
      }

      setFieldError('patientAddress.postalCode', 'Falha ao buscar CEP. Digite seu endereço abaixo.');
    }
  };

  useEffect(() => {
    if (!props.formData) return;

    setValues({
      patientEmail: props.formData.patientEmail,
      parentName: props.formData.parentName,
      patientAddress: {
        address: props.formData.patientAddress.address,
        city: props.formData.patientAddress.city,
        complement: props.formData.patientAddress.complement,
        district: props.formData.patientAddress.district,
        houseNumber: props.formData.patientAddress.houseNumber,
        postalCode: props.formData.patientAddress.postalCode,
        state: props.formData.patientAddress.state,
      },
      patientId: props.formData.patientId,
    });

    setDefaultValues({
      patientEmail: props.formData.patientEmail,
      parentName: props.formData.parentName,
      patientAddress: {
        address: props.formData.patientAddress.address,
        city: props.formData.patientAddress.city,
        complement: props.formData.patientAddress.complement,
        district: props.formData.patientAddress.district,
        houseNumber: props.formData.patientAddress.houseNumber,
        postalCode: props.formData.patientAddress.postalCode,
        state: props.formData.patientAddress.state,
      },
      patientId: props.formData.patientId,
    });
  }, [setValues, props.formData]);

  const isSameData = isEqual(defaultValues, values);

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        height: '100%',
      }}
      data-testId={'patient-address-form'}
    >
      <Flex
        flexDirection={'column'}
        gap={'20px'}
        bgColor={{
          base: 'transparent',
          lg: 'white',
        }}
        borderRadius={'20px'}
        p={{ base: '0px', sm: '32px' }}
        h={'full'}
      >
        <Box display={{ base: 'none', sm: 'block' }}>
          <Typography model={'h2'} size={'large'} weight={'bold'}>
            Adicionais
          </Typography>
        </Box>

        <SimpleGrid
          columns={{
            base: 1,
            xl: 2,
          }}
          gap={'20px'}
        >
          <FormControl isInvalid={!!errors.patientEmail}>
            <MevoInput
              name={'patientEmail'}
              placeholder={'Digite o email'}
              labelText={'Email do paciente'}
              value={values.patientEmail}
              type={'email'}
              onChange={handleChange}
              onBlur={() => validateField('patientEmail')}
              errorText={errors.patientEmail}
              m={0}
              autoFocus
            />
          </FormControl>

          <FormControl isInvalid={!!errors.parentName}>
            <MevoInput
              name={'parentName'}
              placeholder={'Digite o nome da mãe'}
              labelText={'Nome da mãe do paciente'}
              value={values.parentName}
              onChange={handleChange}
              onBlur={() => validateField('parentName')}
              errorText={errors.parentName}
              m={0}
            />
          </FormControl>
        </SimpleGrid>

        <Typography model={'h2'} size={'large'} weight={'bold'}>
          Endereço
        </Typography>

        <Flex alignItems={'center'}>
          <FormControl w={{ base: '50%', md: '155px' }} isInvalid={!!errors.patientAddress?.postalCode}>
            <MevoInput
              name={'patientAddress.postalCode'}
              placeholder={'00000-000'}
              labelText={'CEP'}
              value={values.patientAddress?.postalCode}
              mask={MASKS.BRAZIL_POSTAL_CODE}
              onChange={handleChange}
              onBlur={(e) => {
                validateField('patientAddress.postalCode');
                searchPostalCodeData(e.target.value);
              }}
              errorText={errors.patientAddress?.postalCode}
              m={0}
            />
          </FormControl>
          <Button
            mt={'10px'}
            w={{ base: '50%', md: 'auto' }}
            variant="ghost"
            color={'primary.1000'}
            textDecoration={'underline'}
            fontSize={'14px'}
            textAlign={'center'}
            onClick={() => {
              window.open('https://buscacepinter.correios.com.br/app/endereco/index.php', '_blank', 'noopener');
            }}
          >
            Não sei meu cep
          </Button>
        </Flex>

        <Flex gap={'20px'}>
          <FormControl w={{ base: '100%', md: '70%' }} isInvalid={!!errors.patientAddress?.address}>
            <MevoInput
              name={'patientAddress.address'}
              placeholder={'Digite o nome da sua rua, avenida, etc'}
              labelText={'Endereço'}
              value={values.patientAddress?.address}
              onChange={handleChange}
              onBlur={() => validateField('patientAddress.address')}
              errorText={errors.patientAddress?.address}
              m={0}
            />
          </FormControl>

          <FormControl w={{ base: '100%', md: '30%' }} isInvalid={!!errors.patientAddress?.houseNumber}>
            <MevoInput
              name={'patientAddress.houseNumber'}
              placeholder={'000000'}
              labelText={'número'}
              mask={'999999'}
              maskChar={''}
              maskPlaceholder={''}
              value={values.patientAddress?.houseNumber}
              onChange={handleChange}
              onBlur={() => validateField('patientAddress.houseNumber')}
              errorText={errors.patientAddress?.houseNumber}
              m={0}
            />
          </FormControl>
        </Flex>

        <SimpleGrid
          columns={{
            base: 1,
            xl: 2,
          }}
          gap={'20px'}
        >
          <FormControl isInvalid={!!errors.patientAddress?.complement}>
            <MevoInput
              name={'patientAddress.complement'}
              placeholder={'Digite o complemento'}
              labelText={'Complemento'}
              value={values.patientAddress?.complement}
              onChange={handleChange}
              onBlur={() => validateField('patientAddress.complement')}
              errorText={errors.patientAddress?.complement}
              m={0}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.patientAddress?.district}>
            <MevoInput
              name={'patientAddress.district'}
              placeholder={'Digite o nome do bairro'}
              labelText={'Bairro'}
              value={values.patientAddress?.district}
              onChange={handleChange}
              onBlur={() => validateField('patientAddress.district')}
              errorText={errors.patientAddress?.district}
              m={0}
            />
          </FormControl>
        </SimpleGrid>

        <SimpleGrid
          columns={{
            base: 1,
            xl: 2,
          }}
          gap={'20px'}
        >
          <FormControl isInvalid={!!errors.patientAddress?.city}>
            <MevoInput
              name={'patientAddress.city'}
              placeholder={'Digite a cidade'}
              labelText={'Cidade'}
              value={values.patientAddress?.city}
              onChange={handleChange}
              onBlur={() => validateField('patientAddress.city')}
              errorText={errors.patientAddress?.city}
              m={0}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.patientAddress?.state}>
            <SelectState
              label="UF"
              value={values.patientAddress?.state}
              onChange={(value) => setFieldValue('patientAddress.state', value)}
            />
          </FormControl>
        </SimpleGrid>

        <Flex flexDirection={{ base: 'column', sm: 'row' }} justifyContent={'space-between'}>
          <Flex
            w={{ base: '100%', md: '180px' }}
            mr={'10px'}
            border={'1px solid'}
            borderColor={'notification.alert'}
            borderRadius={'10px'}
            alignItems={'center'}
            justifyContent={'center'}
            mb={{ base: '20px', sm: 0 }}
            padding={'10px'}
          >
            <Box mr={'5px'}>
              <AlertIcon variantColor="notification.alert" width={12} />
            </Box>
            <Typography model="body" size="small" weight="regular">
              Ao escolher voltar, os dados preenchidos serão perdidos!
            </Typography>
          </Flex>
          <Button
            type={'submit'}
            isLoading={isSubmitting}
            isDisabled={isSameData}
            variant={isSameData ? 'outline-primary-light' : 'solid'}
            w={{
              base: 'full',
              md: '150px',
            }}
            h={'60px'}
            alignSelf={'flex-end'}
            mt={'auto'}
          >
            Salvar
          </Button>
        </Flex>
      </Flex>
    </form>
  );
}
