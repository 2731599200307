import { Flex } from '@chakra-ui/react';
import MedicineStripe from 'src/components/Icons/MedicineStripe';
import Typography from 'src/components/Typography';
import { ManualMedicine as ManualMedicineInterface } from 'src/services/prescriptionHistory';

interface ManualMedicineProps {
  manualMedicine: ManualMedicineInterface;
}

export function ManualMedicine(props: ManualMedicineProps) {
  const { manualMedicine } = props;

  return (
    <Flex gap="10px">
      <Flex alignSelf="flex-start">
        <MedicineStripe medicine={null} isManual={true} />
      </Flex>
      <Flex grow={1} flexDirection="column" gap="10px">
        <Typography model="body" size="large" weight="bold">
          {manualMedicine.label}
        </Typography>
        <Typography model="body" size="large" weight="regular">
          {manualMedicine.posology}
        </Typography>
      </Flex>
    </Flex>
  );
}
