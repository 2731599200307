import Typography from 'src/components/Typography';
import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { Text } from 'src/components/UI/Text';
import { Image } from 'src/components/UI/Image';
import { Flex } from 'src/components/UI/Flex';
import { HubTopics } from '../../HubTopics';
import { ReactComponent as Logo } from 'src/assets/images/logo.svg';
import ArrowRight from 'src/components/Icons/ArrowIcon';
import ArrowRightFilledIcon from 'src/components/Icons/ArrowIconFilledRight';
import StandalonePreview from 'src/assets/images/stand-alone-tablet.png';
import { DefaultPageProps } from '../defaultPageProps';
import 'src/components/Helpers/animations.css';
import { AvailableOnAppStore } from 'src/components/AvailableOnAppStore';
import { AvailableOnPlayStore } from 'src/components/AvailableOnPlayStore';

export function MevoMobile(props: DefaultPageProps) {
  return (
    <Box data-testid={'mevo-mobile'}>
      <Flex alignItems={'center'} justifyContent={'space-between'} pt={'12px '} pb={'20px'}>
        <Logo width={'120px'} fill={'white'} />

        <Button
          variant={'ghost'}
          borderRadius={'20px'}
          gap={'8px'}
          color={'primary.1000'}
          bgColor={'surface'}
          fontSize={'14px'}
          fontWeight={'semibold'}
          _focus={{
            boxShadow: 'none',
          }}
          _hover={{
            bgColor: 'secondary.200',
          }}
          onClick={props.onRegister}
        >
          Me cadastrar
          <ArrowRight width={6} variantColor={'primary.1000'} direction={'right'} />
        </Button>
      </Flex>

      <Box pb={'20px'}>
        <Flex flexDirection={'column'}>
          <Flex flexDirection={'column'} gap={'12px'}>
            <Typography model={'h1'} fontSize={24} weight={'bold'} color="secondary.400">
              A receita digital usada nos melhores hospitais do país
              <Text as="span" color={'#FFDE49'}>
                {' '}
                ;)
              </Text>
            </Typography>

            <Typography model={'h3'} fontSize={16} weight={'regular'} color={'surface'}>
              Agora disponível para você prescrever quando, como e onde quiser,{' '}
              <Text color={'#FFDE49'} as={'span'}>
                gratuitamente.
              </Text>
            </Typography>
          </Flex>

          <Flex flexDirection={'column'} position={'relative'} m={'15px 0'}>
            <Image src={StandalonePreview} alt={'Preview da aplicação'} mb={'10px'} />

            <Button
              gap={'10px'}
              className={'secondary-pulse-animation'}
              justifyContent={'space-between'}
              onClick={props.onLogin}
              backgroundColor={'secondary.400'}
              color={'primary.1000'}
            >
              Fazer login
              <ArrowRightFilledIcon width={16} />
            </Button>
          </Flex>

          <Box maxW={'300px'} w={'100%'} mb={'10px'}>
            <Typography model={'h3'} fontSize={16} weight={'regular'} color={'surface'}>
              Use a Mevo também no seu celular.
            </Typography>
            <Typography model={'h3'} fontSize={16} weight={'regular'} color={'surface'}>
              <b>Baixe agora o app.</b>
            </Typography>
          </Box>
          <Flex gap={'10px'} mb={'30px'}>
            <AvailableOnAppStore />
            <AvailableOnPlayStore />
          </Flex>
        </Flex>

        <HubTopics />
      </Box>
    </Box>
  );
}
