import React from 'react';
import { GENDER } from 'src/services/patient';
import Typography from '../Typography';
import { Box } from './Box';
import { FormErrorMessage } from './FormErrorMessage';
import { Select } from './Select/Select';

interface SelectGenderProps {
  value: string;
  onChange(val: string): void;
  error?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  errorMessage?: string;
}

function SelectGender(props: SelectGenderProps) {
  return (
    <>
      {props.label && (
        <Box mb={'10px'}>
          <Typography model="caption" weight="bold" textTransform="uppercase">
            {props.label}
          </Typography>
        </Box>
      )}
      <Select
        h={'45px'}
        m={0}
        p={0}
        value={props.value}
        placeholder={props.placeholder || 'Selecione o sexo do paciente'}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
      >
        <option value={GENDER.MASCULINE}>Masculino</option>
        <option value={GENDER.FEMININE}>Feminino</option>
      </Select>
      <FormErrorMessage fontWeight={'bold'} textTransform={'uppercase'}>
        {props.errorMessage}
      </FormErrorMessage>
    </>
  );
}

export default React.memo(SelectGender);
