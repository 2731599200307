export function validateCNS(cns: string) {
  if (cns.startsWith('1') || cns.startsWith('2')) {
    return validateCNSThatStartsWithOneOrTwo(cns);
  }

  return validateCNSThatStartsWithSevenOrEightOrNine(cns);
}

export function validateCNSThatStartsWithOneOrTwo(cns: string) {
  const cleanCNS = cns.split('.').join('');

  let sum: number;
  let rest: number;
  let dv: number;
  let pis: string;
  let result: string;
  let tamCNS = cleanCNS.length;
  if (tamCNS !== 15) {
    // invalid cns
    return false;
  }
  pis = cleanCNS.substring(0, 11);
  sum =
    Number(pis.substring(0, 1)) * 15 +
    Number(pis.substring(1, 2)) * 14 +
    Number(pis.substring(2, 3)) * 13 +
    Number(pis.substring(3, 4)) * 12 +
    Number(pis.substring(4, 5)) * 11 +
    Number(pis.substring(5, 6)) * 10 +
    Number(pis.substring(6, 7)) * 9 +
    Number(pis.substring(7, 8)) * 8 +
    Number(pis.substring(8, 9)) * 7 +
    Number(pis.substring(9, 10)) * 6 +
    Number(pis.substring(10, 11)) * 5;
  rest = sum % 11;
  dv = 11 - rest;
  if (dv === 11) {
    dv = 0;
  }
  if (dv === 10) {
    sum =
      Number(pis.substring(0, 1)) * 15 +
      Number(pis.substring(1, 2)) * 14 +
      Number(pis.substring(2, 3)) * 13 +
      Number(pis.substring(3, 4)) * 12 +
      Number(pis.substring(4, 5)) * 11 +
      Number(pis.substring(5, 6)) * 10 +
      Number(pis.substring(6, 7)) * 9 +
      Number(pis.substring(7, 8)) * 8 +
      Number(pis.substring(8, 9)) * 7 +
      Number(pis.substring(9, 10)) * 6 +
      Number(pis.substring(10, 11)) * 5 +
      2;
    rest = sum % 11;
    dv = 11 - rest;
    result = pis + '001' + String(dv);
  } else {
    result = pis + '000' + String(dv);
  }
  if (cleanCNS !== result) {
    // invalid cns
    return false;
  } else {
    //valid cns
    return true;
  }
}

function validateCNSThatStartsWithSevenOrEightOrNine(cns: string) {
  const cleanCNS = cns.split('.').join('');

  let sum: number;
  let rest: number;
  let pis: string;

  pis = cleanCNS.substring(0, 15);

  if (pis === '') {
    return false;
  }

  if (cleanCNS.substring(0, 1) !== '7' && cleanCNS.substring(0, 1) !== '8' && cleanCNS.substring(0, 1) !== '9') {
    // invalid cns
    return false;
  }

  sum =
    parseInt(pis.substring(0, 1), 10) * 15 +
    parseInt(pis.substring(1, 2), 10) * 14 +
    parseInt(pis.substring(2, 3), 10) * 13 +
    parseInt(pis.substring(3, 4), 10) * 12 +
    parseInt(pis.substring(4, 5), 10) * 11 +
    parseInt(pis.substring(5, 6), 10) * 10 +
    parseInt(pis.substring(6, 7), 10) * 9 +
    parseInt(pis.substring(7, 8), 10) * 8 +
    parseInt(pis.substring(8, 9), 10) * 7 +
    parseInt(pis.substring(9, 10), 10) * 6 +
    parseInt(pis.substring(10, 11), 10) * 5 +
    parseInt(pis.substring(11, 12), 10) * 4 +
    parseInt(pis.substring(12, 13), 10) * 3 +
    parseInt(pis.substring(13, 14), 10) * 2 +
    parseInt(pis.substring(14, 15), 10) * 1;

  rest = sum % 11;

  if (rest === 0) {
    return true;
  } else {
    // invalid cns
    return false;
  }
}
