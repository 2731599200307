/**
 * @param text
 * @description Essa função remove os espaços no início e final, os espaços duplos e os caracteres especiais (,.!@:;?$()#%&*_-) da string recebida como parâmetro
 * @example removeSpacesAndSpecialChars(" @Caio?  .Vinícius! ") return "Caio Vinícius"
 */

export function removeSpacesAndSpecialChars(text: string) {
  return text
    .replace(/^\s+|\s+$|[,.!@:;?$()#%&*_-]/g, '')
    .replace(/\s+/g, ' ')
    .trim();
}
