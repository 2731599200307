import { Flex } from 'src/components/UI/Flex';
import { Box } from 'src/components/UI/Box';
import Typography from 'src/components/Typography';
import { Button } from 'src/components/UI/Button';
import { Text } from 'src/components/UI/Text';
import abstractBackground from 'src/assets/images/logo-purple.png';
import argumentStand from 'src/assets/images/mockup-argumentos-stand.png';
import { DefaultPageProps } from '../defaultPageProps';
import { Image } from 'src/components/UI/Image';
import { AvailableOnAppStore } from 'src/components/AvailableOnAppStore';
import { AvailableOnPlayStore } from 'src/components/AvailableOnPlayStore';

export function Mevo(props: DefaultPageProps) {
  return (
    <Flex
      display={'flex'}
      flexDirection={'column'}
      backgroundSize={'contain'}
      backgroundRepeat={'no-repeat'}
      backgroundPosition={'right'}
      backgroundImage={`url(${abstractBackground})`}
      backgroundColor={'primary.1000'}
      w={'100%'}
      h={'100%'}
      px={'80px'}
      py={'70px'}
      data-testid={'mevo'}
    >
      <Flex h={'full'} flexDirection={'column'} justifyContent={'space-between'}>
        <Box maxW={'70%'}>
          <Typography model={'h1'} fontSize={40} weight={'bold'} color="secondary.400">
            A receita digital usada nos
          </Typography>

          <Typography model={'h1'} fontSize={40} weight={'bold'} color="secondary.400">
            melhores hospitais do país{' '}
            <Text as="span" color={'#FFDE49'}>
              ;)
            </Text>
          </Typography>

          <Box pt={'12px'}>
            <Typography model={'h3'} fontSize={18} weight={'regular'} color={'surface'}>
              Agora disponível para você prescrever quando,
            </Typography>
            <Typography model={'h3'} fontSize={18} weight={'regular'} color={'surface'}>
              como e onde quiser,{' '}
              <Text color={'#FFDE49'} as={'span'}>
                gratuitamente.
              </Text>
            </Typography>
          </Box>
        </Box>
        <Flex
          flex={1}
          bgImage={argumentStand}
          bgPosition={'center'}
          bgSize={'contain'}
          bgRepeat={'no-repeat'}
          mt={'50px'}
          minH={'70%'}
        ></Flex>

        <Flex alignItems={'center'}>
          <Box maxW={'300px'} w={'100%'}>
            <Typography model={'h3'} fontSize={16} weight={'regular'} color={'surface'}>
              Use a Mevo também no seu celular.
            </Typography>
            <Typography model={'h3'} fontSize={16} weight={'regular'} color={'surface'}>
              <b>Baixe agora o app.</b>
            </Typography>
          </Box>

          <Flex gap={'10px'}>
            <AvailableOnAppStore />
            <AvailableOnPlayStore />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
