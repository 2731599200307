import { Box } from 'src/components/UI/Box';
import Typography from 'src/components/Typography';
import { BasicAbstractBackground } from 'src/components/BasicAbstractLayout';
import { TutorialCard } from './TutorialCard';
import { Grid } from 'src/components/UI/Grid';
import { useIsMobile } from 'src/helpers/useIsMobile';
import { GridItem } from '@chakra-ui/react';
import TutorialVideo from './TutorialVideo';
import { useCallback, useContext } from 'react';
import { Tracker } from 'src/shared/tracker/Tracker';
import { DialogWrappedComponentProps, FeedbackContext } from 'src/providers/FeedbackProvider';

export interface TutorialItem {
  title: string;
  url: string;
}

export const TUTORIAL_ITEMS: TutorialItem[] = [
  {
    title: 'Saiba como cadastrar um paciente e iniciar uma receita',
    url: 'https://www.youtube.com/embed/FpR36s924_w',
  },
  {
    title: 'Aprenda a editar o logo de suas prescrições',
    url: 'https://www.youtube.com/embed/cxH2J1aRC_U',
  },
  {
    title: 'Descubra como criar Modelos de Prescrição',
    url: '',
  },
  {
    title: 'Veja como acessar o Histórico de Prescrições',
    url: '',
  },
  {
    title: 'Personalize o PDF de suas prescrições',
    url: '',
  },
  {
    title: 'Saiba como alterar sua senha',
    url: '',
  },
];

export function TutorialPage() {
  const isMobile = useIsMobile();
  const { openBaseDialog } = useContext(FeedbackContext);

  const handleOpenVideo = useCallback(
    async (item: TutorialItem) => {
      Tracker.trackEvent({
        event: `Tutorial Video Opened - ${item.title}`,
      });
      await openBaseDialog((dialogProps: DialogWrappedComponentProps) => <TutorialVideo item={item} />, {
        width: '1280px',
        maxWidth: '90vw',
        height: isMobile ? '40vh' : '720px',
        maxHeight: '90vh',
        overflow: 'visible',
        padding: '0',
        showCloseButton: false,
        backgroundColor: 'transparent',
      });
    },
    [isMobile, openBaseDialog]
  );

  return (
    <BasicAbstractBackground>
      <Box>
        <Box my={'20px'}>
          <Typography model="h1" weight="bold" textAlign={'left'}>
            Conheça nossas funcionalidades e tire suas dúvidas!
          </Typography>
        </Box>
        <Box mx={isMobile ? '' : '100px'} maxWidth="1000px" margin="auto">
          <Grid templateColumns={`repeat(${isMobile ? 1 : 3}, 1fr)`} gap="10px">
            {TUTORIAL_ITEMS.map((item) => (
              <GridItem key={item.title}>
                {/* Condição temporária até todos os videos estarem prontos */}
                {item.url && <TutorialCard {...item} onOpen={() => handleOpenVideo(item)} />}
              </GridItem>
            ))}
          </Grid>
        </Box>
      </Box>
    </BasicAbstractBackground>
  );
}
