import { useState } from 'react';
import { Box } from 'src/components/UI/Box';
import Typography from 'src/components/Typography';
import { useIsMobile } from 'src/helpers/useIsMobile';
import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { useNavigate } from 'react-router-dom';
import { BasicAbstractBackground } from 'src/components/BasicAbstractLayout';
import { Tracker } from 'src/shared/tracker/Tracker';
import { TutorialTag } from 'src/components/Helpers/TutorialTag';
import { AnimatePresence, motion } from 'framer-motion';
import { scrollToContainer } from 'src/helpers/window';
import { PatientSearchList } from 'src/components/PatientSearchList';

export function Home() {
  const { startPrescrition, clickOnFirstPatientFromList } = usePrescriberContext();

  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const handleCreatePatient = () => {
    Tracker.trackEvent({
      event: 'Cadastrar Paciente',
      parameters: {
        data: new Date(),
      },
    });

    scrollToContainer({ containerId: 'mobile-layout-body' });
    navigate('/inicio/cadastro-rapido-paciente');
  };

  return (
    <BasicAbstractBackground>
      <AnimatePresence>
        {!shouldHideHeader && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
              height: 0,
            }}
            style={{ display: 'flex' }}
          >
            <Box mb={'20px'}>
              <Box mb={'10px'}>
                <Typography model="h1" weight="bold" textAlign={'left'}>
                  Inicie selecionando um paciente.
                </Typography>
              </Box>

              <Typography model="body" size={'large'} weight="regular" textAlign={'left'}>
                Selecione ou busque para qual paciente você deseja iniciar uma prescrição.
              </Typography>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>

      <PatientSearchList
        onCreatePatient={handleCreatePatient}
        onEnterCallback={clickOnFirstPatientFromList}
        onFocus={() => isMobile && setShouldHideHeader(true)}
        onBlur={() => setShouldHideHeader(false)}
        onClickCallback={async (patient) => {
          Tracker.trackEvent({
            event: 'Receita Iniciada',
            parameters: {
              data: new Date(),
            },
          });

          await startPrescrition(patient._id);
        }}
        showActionButtons={false}
      />

      {!isMobile && <TutorialTag />}
    </BasicAbstractBackground>
  );
}
