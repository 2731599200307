import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '../Box';
import { FormErrorMessage } from '../FormErrorMessage';
import { FormLabel } from '../FormLabel';
import { IconButton } from '../IconButton';
import { Input } from './Input';
import { InputGroup } from './InputGroup';
import { InputRightElement } from './InputRightElement';

import { ReactComponent as Eye } from 'src/assets/icons/eye.svg';
import { ReactComponent as EyeClosed } from 'src/assets/icons/eye-closed.svg';
import { FormControl } from '../FormControl';
import { Flex } from '../Flex';
import Typography from 'src/components/Typography';

interface ProtectedPasswordProps {
  label: string;
  errorMessage: string | undefined;
  isInvalid: boolean;
  placeholder: string;
  value: string;
  onChange(evt: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(): void;
  showPasswordProtectionAttributes?: boolean;
  onProtected?(isProtected: boolean): void;
  name: string;
  autoFocus?: boolean;
}

interface CompletedStepProps {
  isCompleted: boolean;
  value: string;
}

const CompletedStep = (props: CompletedStepProps) => {
  if (props.isCompleted) {
    return (
      <Box
        w={'10px'}
        h={'10px'}
        bg={'notification.success'}
        borderRadius={'50%'}
        mr={'7px'}
        transition={'all 1s ease'}
        data-testid={`completed-${props.value}`}
      />
    );
  }

  return (
    <Box
      w={'10px'}
      h={'10px'}
      bg={'transparent'}
      borderRadius={'50%'}
      borderWidth={'2px'}
      borderColor={'primary.1000'}
      mr={'7px'}
      data-testid={`not-completed-${props.value}`}
    />
  );
};

export function ProtectedPassword(props: ProtectedPasswordProps) {
  const { onProtected, onChange, onBlur } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [isProtected, setIsProtected] = useState(false);

  const ProtectionAttributes = useMemo(() => {
    const isEightDigit = props.value.length >= 8;
    const isAtLeastOneLetter = /[a-zA-Z]/.test(props.value);
    const isAtLeastOneNumber = /\d/.test(props.value);
    const isAtLeastOneSpecialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(props.value);

    const isProtectedConditions = [isEightDigit, isAtLeastOneLetter, isAtLeastOneNumber, isAtLeastOneSpecialChar];
    const isPasswordProtected = isProtectedConditions.every(Boolean);
    setIsProtected(isPasswordProtected);

    return (
      <Flex flexDirection={'column'} mt={'10px'}>
        <Flex mb={'5px'} alignItems={'center'}>
          <CompletedStep isCompleted={isEightDigit} value={'six-digit'} />
          <Typography model={'body'} size={'small'} weight={'regular'}>
            8 dígitos
          </Typography>
        </Flex>
        <Flex mb={'5px'} alignItems={'center'}>
          <CompletedStep isCompleted={isAtLeastOneLetter} value={'least-one-letter'} />
          <Typography model={'body'} size={'small'} weight={'regular'}>
            pelo menos 1 letra
          </Typography>
        </Flex>
        <Flex mb={'5px'} alignItems={'center'}>
          <CompletedStep isCompleted={isAtLeastOneNumber} value={'least-number'} />
          <Typography model={'body'} size={'small'} weight={'regular'}>
            pelo menos 1 número
          </Typography>
        </Flex>
        <Flex alignItems={'center'}>
          <CompletedStep isCompleted={isAtLeastOneSpecialChar} value={'least-simbol'} />
          <Typography model={'body'} size={'small'} weight={'regular'}>
            pelo menos 1 símbolo
          </Typography>
        </Flex>
      </Flex>
    );
  }, [props.value]);

  useEffect(() => {
    if (!onProtected || props.value.length <= 7) return;

    onProtected(isProtected);
  }, [onProtected, isProtected, props.value.length]);

  const handleBlur = useCallback(() => {
    if (!onBlur) return;
    onBlur();
  }, [onBlur]);

  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      onChange(evt);
    },
    [onChange]
  );

  return (
    <FormControl isInvalid={props.isInvalid}>
      <FormLabel htmlFor="password" textTransform={'uppercase'}>
        {props.label}
      </FormLabel>
      <InputGroup>
        <Input
          value={props.value}
          h={'45px'}
          id={props.name}
          type={showPassword ? 'text' : 'password'}
          placeholder={props.placeholder}
          onBlur={handleBlur}
          onChange={handleChange}
          name={props.name}
          autoComplete="off"
          autoFocus={props.autoFocus || false}
        />

        <InputRightElement h={'100%'}>
          <IconButton
            _focus={{}}
            onClick={() => setShowPassword(!showPassword)}
            mr={'10px'}
            variant={'ghost'}
            borderRadius={'50%'}
            size={'sm'}
            aria-label="show-password"
          >
            {showPassword ? <Eye /> : <EyeClosed />}
          </IconButton>
        </InputRightElement>
      </InputGroup>
      {props.showPasswordProtectionAttributes && ProtectionAttributes}
      <FormErrorMessage textTransform={'uppercase'}>{props.errorMessage}</FormErrorMessage>
    </FormControl>
  );
}
