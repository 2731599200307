import { Box, Flex, Input, InputGroup, InputLeftElement, InputProps, InputRightElement } from '@chakra-ui/react';
import * as React from 'react';
import { getStringColor } from 'src/theme/colors';
import EyeRegularIcon from '../Icons/EyeRegularIcon';
import EyeSlashIcon from '../Icons/EyeSlashIcon';
import Typography from '../Typography';
import { MaskedInput, MASKS_TYPES } from '../UI/Input/MaskedInput';

interface MevoInputProps extends InputProps {
  placeholderText?: string;
  labelText?: string;
  errorText?: string;
  LeftElement?: React.FunctionComponent<any>;
  RightElement?: React.FunctionComponent<any>;
  helperText?: string;
  mask?: string | MASKS_TYPES;
  maskChar?: string;
  maskPlaceholder?: string;
  disabled?: boolean;
}

const MevoInput: React.FunctionComponent<MevoInputProps> = (props) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const inputType = React.useMemo(() => {
    if (props.type !== 'password') return props.type;

    return showPassword ? 'text' : 'password';
  }, [props.type, showPassword]);

  const PasswordRightElement = React.useMemo(() => {
    const Element = (
      <Flex display="flex" alignItems="center" justifyContent="center" height="100%" mt={'5px'} cursor={'pointer'}>
        {showPassword ? (
          <EyeRegularIcon variantColor="secondary.400" width={16} />
        ) : (
          <EyeSlashIcon variantColor="secondary.400" width={16} />
        )}
      </Flex>
    );
    return <InputRightElement children={Element} onClick={() => setShowPassword(!showPassword)} />;
  }, [showPassword]);

  const LeftElement = React.useMemo(() => {
    if (!props.LeftElement) return;

    const Component = props.LeftElement;
    return <InputLeftElement pointerEvents="none" children={<Component />} />;
  }, [props.LeftElement]);

  const RightElement = React.useMemo(() => {
    if (props.type === 'password') return PasswordRightElement;
    if (!props.RightElement) return;

    const Component = props.RightElement;
    return <InputRightElement pointerEvents="none" children={<Component />} />;
  }, [props.RightElement, PasswordRightElement, props.type]);

  return (
    <Box width={'100%'}>
      <Box mb={'10px'}>
        <Typography model="caption" weight="bold" textTransform="uppercase">
          {props.labelText}
        </Typography>
      </Box>
      <InputGroup>
        {LeftElement}

        {props.mask ? (
          <MaskedInput
            placeholder={props.placeholderText}
            border={'1px solid'}
            borderColor={getStringColor('primary.100')}
            borderRadius={'10px'}
            _placeholder={{ color: getStringColor('support.dark'), opacity: 0.5 }}
            color="support.dark"
            backgroundColor="support.surface"
            height={'45px'}
            focusBorderColor="primary.100"
            fontFamily={'Ambit'}
            fontSize={'12px'}
            errorBorderColor="notification.error"
            mask={props.mask}
            name={props.name || ''}
            {...props}
          />
        ) : (
          <Input
            placeholder={props.placeholderText}
            border={'1px solid'}
            borderColor={getStringColor('primary.100')}
            borderRadius={'10px'}
            _placeholder={{ color: getStringColor('support.dark'), opacity: 0.5 }}
            color="support.dark"
            backgroundColor="support.surface"
            height={'45px'}
            focusBorderColor="primary.100"
            fontFamily={'Ambit'}
            fontSize={'12px'}
            errorBorderColor="notification.error"
            {...props}
            type={inputType}
          />
        )}

        {RightElement}
      </InputGroup>
      {!!props.errorText ? (
        <Box mt={'10px'}>
          <Typography model="caption" weight="bold" textTransform="uppercase" color="notification.error">
            {props.errorText}
          </Typography>
        </Box>
      ) : (
        <Box mt={'10px'}>
          <Typography model="body" weight="regular" size="normal" color="primary.700">
            {props.helperText}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default MevoInput;
