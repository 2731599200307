import { get } from 'lodash';

export const colors = {
  white: '#FFFFFF',
  primary: {
    50: '#EEE7FF',
    100: '#CBBFE6',
    200: '#BAAADB',
    300: '#AA95D0',
    400: '#9A80C5',
    500: '#8A6AB8',
    600: '#7B55AA',
    700: '#6B409C',
    800: '#5C2E8C',
    900: '#4C1D7C',
    1000: '#3D0E6B', //main
  },
  secondary: {
    50: '#FFE4EB',
    100: '#FFD3DE',
    200: '#FFC6D1',
    300: '#FFBCC7',
    400: '#FFB6BF', // main
    500: '#E0A0B1',
    600: '#C28AA1',
    700: '#A3758E',
    800: '#855F79',
    900: '#664961',
  },
  background: '#FFF8F9',
  surface: '#FFFFFF',
  notification: {
    success: '#5CD17E',
    error: '#FF6161',
    alert: '#FFDE49',
  },
  support: {
    blue: '#718AF0',
    blueton: '#95AAFF',
    green: '#5CD17E',
    greenton: '#7BE89A',
    yellow: '#FFDE49',
    yellowton: '#FFEA89',
    pink: '#FF87EB',
    pinkton: '#FFA6F0',
    pinkred: '#FF7192',
    pinkredton: '#FFA4B9',
    error: '#FF6161',
    errorton: '#FF9292',
    dark: '#16002C',
    surface: '#FFFFFF',
    transparent: 'transparent',
    background: '#FFF8F9',
    manual: 'grey',
  },
  gradients: {
    pinkSoft: 'linear-gradient(180deg, #FFB6BF 21.88%, #EE9CA7 100%)',
    purplePink: 'linear-gradient(127.25deg, #8F40CE -54.03%, #3D0E6B 83.62%)',
    redLabel: 'linear-gradient(180deg, #FF8D8D 0%, #FF6161 99%);',
    blackLabel: 'linear-gradient(90deg, #343A40 2.78%, #545C62 57.87%)',
    blueLabel: 'linear-gradient(90deg, #343A40 2.78%, #545C62 57.87%)',
  },
  info: {
    lightest: '#F1F7FF',
    light: '#9CC5FF',
    main: '#3484FA',
    dark: '#2352A4',
    darkest: '#1B3B7A',
  },
  success: {
    lightest: '#DFF6F0',
    light: '#85DBC5',
    main: '#21AA91',
    dark: '#127874',
    darkest: '#0C5A5D',
  },
  error: {
    lightest: '#FBEAE5',
    light: '#FEAD9A',
    main: '#D33618',
    dark: '#BF0711',
    darkest: '#330101',
  },
  warning: {
    lightest: '#FCEBDB',
    light: '#FFC58B',
    main: '#F49342',
    dark: '#C05717',
    darkest: '#4A1504',
  },
  alert: {
    lightest: '#FCF1CD',
    light: '#FFEA8A',
    main: '#EEC200',
    dark: '#8A6116',
    darkest: '#573B00',
  },
  gray: {
    100: '#F8F9FA',
    200: '#F1F3F5',
    300: '#E9ECEE',
    400: '#DEE2E6',
    500: '#CED4DA',
    600: '#ADB5BD',
    700: '#868E96',
    800: '#545C62',
    900: '#343A40',
    1000: '#1E252B',
  },
};

export const getStringColor = (color?: string) => {
  let colorArray = [];
  if (!color || color.search('.') === -1) {
    colorArray = ['primary', 1000];
  } else {
    colorArray = color.split('.');
  }

  if (colorArray.length > 1) {
    return (colors as any)[colorArray[0]][colorArray[1]];
  }

  const selectedColor = get(colorArray, '[0]', '');

  return (colors as any)[selectedColor];
};
