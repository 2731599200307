import { Flex } from '@chakra-ui/react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tracker } from 'src/shared/tracker/Tracker';
import TutorialIcon from '../Icons/TutorialIcon';
import Typography from '../Typography';

export function TutorialTag() {
  const navigate = useNavigate();

  const handleRedirectToTutorials = React.useCallback(() => {
    Tracker.trackEvent({
      event: 'Tutorial Shortcut Clicked',
    });
    navigate('/inicio/tutorial');
  }, [navigate]);

  return (
    <Flex
      borderRadius="10px 0px 0px 10px"
      border="1px solid #FFE4EB"
      backgroundColor="support.surface"
      p="10px"
      position="fixed"
      bottom="20px"
      right="-150px"
      alignItems="center"
      gap="10px"
      transition="all 0.5s"
      _hover={{
        right: 0,
        animation: 'unset',
      }}
      cursor="pointer"
      data-testid="tutorial-tag"
      onClick={handleRedirectToTutorials}
      className="shadow-layer-3 secondary-pulse-animation"
    >
      <TutorialIcon variantColor="support-blue" width={20} />
      <Typography model="body" size="small" weight="bold">
        Como Iniciar uma Prescrição
      </Typography>
    </Flex>
  );
}
