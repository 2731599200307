import * as React from 'react';

import { ReactComponent as BluePill } from '../../assets/pills/pill_blue.svg';
import { ReactComponent as GreenPill } from '../../assets/pills/pill_green.svg';
import { ReactComponent as PinkPill } from '../../assets/pills/pill_pink.svg';
import { ReactComponent as RedPill } from '../../assets/pills/pill_red.svg';
import { ReactComponent as YellowPill } from '../../assets/pills/pill_yellow.svg';

import { ReactComponent as CrazyEyeFace } from '../../assets/pills/face_crazy_eye.svg';
import { ReactComponent as HappyFace } from '../../assets/pills/face_happy.svg';
import { ReactComponent as KissFace } from '../../assets/pills/face_kiss.svg';
import { ReactComponent as NiceFace } from '../../assets/pills/face_nice.svg';
import { ReactComponent as RelaxFace } from '../../assets/pills/face_relax.svg';
import { ReactComponent as ShockFace } from '../../assets/pills/face_shock.svg';
import { ReactComponent as SleepingFace } from '../../assets/pills/face_sleeping.svg';
import { ReactComponent as SquintFace } from '../../assets/pills/face_squint.svg';
import { ReactComponent as Supriseface } from '../../assets/pills/face_surprise.svg';

import { MevoPillProps } from './types';
import { PillContainer, StyledFace, StyledPill } from './container';

const MevoPill: React.FunctionComponent<MevoPillProps> = ({
  pillColor,
  pillWidth,
  faceWidth,
  facePosition,
  faceStyle,
  offset,
  shadow,
}) => {
  const PillComponent = React.useMemo(() => {
    switch (pillColor) {
      case 'blue':
        return BluePill;
      case 'green':
        return GreenPill;
      case 'red':
        return RedPill;
      case 'yellow':
        return YellowPill;
      default:
        return PinkPill;
    }
  }, [pillColor]);

  const FaceComponent = React.useMemo(() => {
    switch (faceStyle) {
      case 'crazy':
        return CrazyEyeFace;
      case 'happy':
        return HappyFace;
      case 'kiss':
        return KissFace;
      case 'nice':
        return NiceFace;
      case 'relax':
        return RelaxFace;
      case 'shock':
        return ShockFace;
      case 'sleeping':
        return SleepingFace;
      case 'squint':
        return SquintFace;
      default:
        return Supriseface;
    }
  }, [faceStyle]);

  const parsedFacePosition = React.useMemo(() => {
    switch (facePosition) {
      case 'top':
        return 'flex-start';
      case 'bottom':
        return 'flex-end';
      default:
        return 'center';
    }
  }, [facePosition]);

  const parsedPadding = React.useMemo(() => {
    switch (facePosition) {
      case 'top':
        return `top: ${offset || 5}px`;
      case 'bottom':
        return `bottom: ${offset || 5}px`;
      default:
        return '';
    }
  }, [facePosition, offset]);

  return (
    <PillContainer>
      <StyledFace faceWidth={faceWidth} parsedPadding={parsedPadding} parsedFacePosition={parsedFacePosition}>
        <FaceComponent />
      </StyledFace>
      <StyledPill pillWidth={pillWidth}>
        <PillComponent className={shadow} />
      </StyledPill>
    </PillContainer>
  );
};

export default MevoPill;
