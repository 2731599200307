import { Box } from '@chakra-ui/react';
import ExitIcon from 'src/components/Icons/ExitIcon';
import { IconButton } from 'src/components/UI/IconButton';
import { Tag } from 'src/components/UI/Tag/Tag';
import { TagLabel } from 'src/components/UI/Tag/TagLabel';
import { Tooltip } from 'src/components/UI/Tooltip';
import { Icd } from 'src/services/patient';

interface IcdTagProps {
  icd: Icd;
  onClick(icdId: number): void;
}

export function IcdTag(props: IcdTagProps) {
  const handleDelete = () => {
    props.onClick(props.icd.id);
  };

  return (
    <Tooltip label={props.icd.description} p={'5px 10px'} borderRadius={'10px'} lineHeight={'16px'}>
      <Box>
        <Tag bgColor={'support.blue'} color={'surface'} borderRadius={'20px'} p={'5px'}>
          <IconButton
            onClick={handleDelete}
            _hover={{}}
            aria-label={'close'}
            variant={'ghost'}
            p={'0'}
            minW={'13px'}
            h={'13px'}
            mr={'5px'}
          >
            <ExitIcon width={13} variantColor={'support.blue'} baseColor={'white'} />
          </IconButton>
          <TagLabel fontSize={'10px'} fontWeight={700} whiteSpace={'nowrap'}>
            CID {props.icd.subcategory}
          </TagLabel>
        </Tag>
      </Box>
    </Tooltip>
  );
}
