import React from 'react';
import { Tooltip as ChakraTooltip, TooltipProps as ChakraTooltipProps } from '@chakra-ui/react';

export function Tooltip(props: ChakraTooltipProps) {
  return (
    <ChakraTooltip
      {...props}
    />
  );
}
