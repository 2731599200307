import { Box } from '@chakra-ui/react';
import * as React from 'react';
import { useIsMobile } from 'src/helpers/useIsMobile';

interface NavButtonProps {
  children: React.ReactNode;
}

const NavButton: React.FunctionComponent<NavButtonProps> = ({ children }) => {
  const isMobile = useIsMobile();

  const boxProps: any = React.useMemo(() => {
    if (isMobile)
      return {
        position: 'absolute',
        bottom: '20px',
      };

    return {
      position: 'relative',
    };
  }, [isMobile]);

  return (
    <Box {...boxProps} width={isMobile ? 'calc(100% - 40px)' : '100%'}>
      {children}
    </Box>
  );
};

export default NavButton;
