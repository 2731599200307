import React, { useCallback, useEffect, useState } from 'react';
import { Patient } from 'src/services/patient';
import { Box } from './UI/Box';
import { Search } from './Search/Search';
import { PatientList } from './PatientList';
import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { ORDER_BY, PrescriberService, SearchParams } from 'src/services/prescriber';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';
import { ORDER_TYPES } from 'src/services/prescriptionHistory';
import { Form, Formik } from 'formik';
import { Yup } from 'src/helpers/Yup';
import { testIfStringOnlyHasNumber } from 'src/helpers/number';
import useLoading from 'src/helpers/useLoading';
import useAsyncSetFieldValue from 'src/helpers/useAsyncSetFieldValue';
import { EmptyPatientView } from './EmptyPatientView';

interface PatientListProps {
  onEnterCallback?(): void;
  onCreatePatient(): void;
  onFocus(): void;
  onBlur(): void;
  onClickCallback(patient: Patient): Promise<void>;
  showActionButtons: boolean;

  onEdit?(patientId: string): void;
  onInitPrescription?(patientId: string): Promise<void>;
  onDelete?(patientId: string): Promise<void>;
}

const SearchSchema = Yup.object().shape({
  searchString: Yup.string(),
  shouldCallAction: Yup.bool().notRequired(),
});

const DEFAULT_SEARCH_PAYLOAD = {
  orderBy: ORDER_BY.CREATE_AT,
  orderType: ORDER_TYPES.DESC,
  page: 1,
  limit: 20,
};

export function PatientSearchList(props: PatientListProps) {
  const { prescriberId, patients, setPatients, setUserDoesNotHaveAnyPatients, userDoesNotHaveAnyPatients } =
    usePrescriberContext();
  const { openToast } = useFeedbackContext();
  const [loading, runWithLoading] = useLoading();

  const [asyncSetShouldPaginate] = useAsyncSetFieldValue('shouldPaginate');
  const [asyncSetPage] = useAsyncSetFieldValue('page');

  const [hasNextPage, setHasNextPage] = useState(false);

  const searchPatients = useCallback(
    async (shouldPaginate: boolean, searchParams?: SearchParams) => {
      if (!prescriberId) return null;

      runWithLoading(async () => {
        try {
          const payload = searchParams || DEFAULT_SEARCH_PAYLOAD;

          const data = await PrescriberService.searchPatients(prescriberId, payload);

          setHasNextPage(data.hasNextPage);

          if (shouldPaginate) {
            const oldPatients = patients || [];
            setPatients([...oldPatients, ...(data.data.patients || [])]);
          } else {
            setPatients(data.data.patients);
          }

          return;
        } catch (error) {
          openToast('Erro ao buscar pacientes. Tente novamente em instantes.', { type: 'error', duration: 2000 });
          return null;
        }
      });
    },
    [prescriberId, runWithLoading, patients, setPatients, openToast]
  );

  useEffect(() => {
    if (!prescriberId) return;

    const run = async () => {
      runWithLoading(async () => {
        try {
          const data = await PrescriberService.searchPatients(prescriberId, DEFAULT_SEARCH_PAYLOAD);

          setPatients(data.data.patients);
          setHasNextPage(data.hasNextPage);

          if (!data.data.patients || data.data.patients.length === 0) {
            setUserDoesNotHaveAnyPatients(true);
          }
        } catch (error) {}
      });
    };

    run();
  }, [prescriberId, runWithLoading, setPatients, setUserDoesNotHaveAnyPatients]);

  if (userDoesNotHaveAnyPatients) {
    return <EmptyPatientView onClick={props.onCreatePatient} />;
  }

  return (
    <Box mt={'10px'}>
      <Formik
        initialValues={{
          searchString: '',
          shouldCallAction: false,
          orderType: ORDER_TYPES.DESC,
          orderBy: ORDER_BY.CREATE_AT,
          selectedFilter: {
            label: 'Mais recentes primeiro',
            arrowDirection: 'down',
          },
          page: 1,
          limit: 20,
          shouldPaginate: false,
        }}
        validateOnChange={false}
        validationSchema={SearchSchema}
        onSubmit={async (values, { setFieldValue }) => {
          {
            if (values.searchString.length === 0) {
              await searchPatients(values.page > 1, {
                ...DEFAULT_SEARCH_PAYLOAD,
                orderBy: values.orderBy,
                orderType: values.orderType,
                page: values.page,
              });

              return;
            }

            if (values.searchString.length < 2 && !values.shouldPaginate) return;

            let searchParams: Omit<SearchParams, 'selectedFilter'> = {
              orderBy: values.orderBy,
              orderType: values.orderType,
              page: values.page,
              limit: values.limit,
            };

            if (values.searchString) {
              if (testIfStringOnlyHasNumber(values.searchString)) {
                searchParams = {
                  ...searchParams,
                  document: values.searchString,
                  fullName: '',
                };
              } else {
                searchParams = {
                  ...searchParams,
                  document: '',
                  fullName: values.searchString,
                };
              }
            }

            await searchPatients(values.shouldPaginate, searchParams);

            if (values.shouldPaginate) {
              setFieldValue('shouldPaginate', false);
            }

            if (props.onEnterCallback && (patients || []).length === 1 && values.shouldCallAction) {
              props.onEnterCallback();
              setFieldValue('shouldCallAction', false);

              return;
            }
          }
        }}
      >
        {(formikProps) => {
          return (
            <Form>
              <Box position={'sticky'} top={'0px'} zIndex={10}>
                <Search
                  onEnterCallback={() => {
                    formikProps.setFieldValue('shouldCallAction', true);
                  }}
                  onCreatePatient={props.onCreatePatient}
                  onFocus={props.onFocus}
                  onBlur={props.onBlur}
                />
              </Box>

              <PatientList
                patientList={patients}
                isLoading={loading}
                onClickCallback={props.onClickCallback}
                showActionButtons={props.showActionButtons}
                onDelete={props.onDelete}
                onEdit={props.onEdit}
                onInitPrescription={props.onInitPrescription}
                onLoadMore={async () => {
                  await asyncSetShouldPaginate(true, formikProps.setFieldValue);
                  await asyncSetPage(formikProps.values.page + 1, formikProps.setFieldValue);
                  formikProps.handleSubmit();
                }}
                hasNextPage={hasNextPage}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
