import { Box, Flex } from '@chakra-ui/react';
import Typography from 'src/components/Typography';
import { ActiveArea } from './ActiveArea';
import { DocumentTabs, DOCUMENT_TABS_LABEL } from './DocumentTabs/DocumentTabs';
import { PrescriptionFooter } from './PrescriptionFooter/PrescriptionFooter';
import { PrescriptionStatusBadge } from '../PrescriptionStatusBadge';
import { useLocation } from 'react-router-dom';
import { PRESCRIPTION_STATUS, PrescriptionHistory, PrescriptionHistoryService } from 'src/services/prescriptionHistory';
import { useState } from 'react';
import { DateFromISOToBrazilianFormat } from 'src/helpers/Date';
import { DialogWrappedComponentProps, useFeedbackContext } from 'src/providers/FeedbackProvider';
import { CancelPrescriptionDialog } from '../CancelPrescriptionDialog';

export function PrescriptionDetailsPage() {
  const { openBaseDialog, openToast } = useFeedbackContext();

  const [selected, setSelected] = useState(DOCUMENT_TABS_LABEL.MEDICINE);

  // const handleOnResend = useCallback(() => {}, []);
  // const handleOnContinue = useCallback(() => {}, []);
  // const handleOnRenew = useCallback(() => {}, []);

  const location = useLocation();

  const [prescription, setPrescription] = useState<PrescriptionHistory>(location.state.prescription);

  const onCancelPrescription = async () => {
    try {
      await PrescriptionHistoryService.cancelPrescriptionHistory(prescription.prescriptionId);

      setPrescription((prev) => {
        return { ...prev, prescriptionStatus: PRESCRIPTION_STATUS.CANCELED };
      });
    } catch (error) {
      openToast('Erro ao cancelar prescrição. Tente novamente em instantes.', { type: 'error', duration: 2000 });
    }
  };

  const handleCancelPrescription = async () => {
    await openBaseDialog(
      (dialogProps: DialogWrappedComponentProps) => (
        <CancelPrescriptionDialog
          validationCode={prescription.validationCode}
          onCancel={async () => {
            await onCancelPrescription();
            dialogProps.onClose();
          }}
          onGoBack={dialogProps.onClose}
        />
      ),
      {
        width: '270px',
        height: '340px',
      }
    );
  };

  return (
    <Box mt="30px">
      <Flex flexDirection="column" gap="5px" mb="10px">
        <Typography model="body" size="normal" weight="regular">
          Nome do paciente
        </Typography>
        <Typography model="h2" weight="bold">
          {prescription.patientName}
        </Typography>
      </Flex>
      <Flex gap="55px">
        <Flex flexDirection="column" gap="5px" mb="10px">
          <Typography model="body" size="normal" weight="regular">
            Token da Receita
          </Typography>
          <Typography model="h2" weight="bold">
            {prescription.validationCode}
          </Typography>
        </Flex>
        <Flex flexDirection="column" gap="5px" mb="10px">
          <Typography model="body" size="normal" weight="regular">
            Data da Receita
          </Typography>
          <Typography model="h2" weight="bold">
            {DateFromISOToBrazilianFormat(prescription.modificationDate)}
          </Typography>
        </Flex>
      </Flex>
      <Box maxW={{ base: '100%', md: '255px' }} mb={'25px'}>
        <PrescriptionStatusBadge showLabel status={prescription.prescriptionStatus} showStatusColor />
      </Box>
      <DocumentTabs prescription={prescription} onChange={(value) => setSelected(value)} />
      <ActiveArea prescription={prescription} tabActive={selected} />
      <PrescriptionFooter
        prescriptionStatus={prescription.prescriptionStatus}
        onCancel={handleCancelPrescription}
        // onResend={handleOnResend}
        // onContinue={handleOnContinue}
        // onRenew={handleOnRenew}
      />
    </Box>
  );
}
