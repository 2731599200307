import { Flex } from '@chakra-ui/react';
import * as React from 'react';
import { useIsMobile } from 'src/helpers/useIsMobile';

interface FormBaseCardProps {
  children: React.ReactNode;
  width?: string;
}

const FormBaseCard: React.FunctionComponent<FormBaseCardProps> = ({ children, width = '435px' }) => {
  const isMobile = useIsMobile();

  return (
    <Flex
      width={isMobile ? '100vw' : width}
      maxWidth={'100vw'}
      height="fit-content"
      justifyContent={'center'}
      alignItems={'center'}
      direction={'column'}
      className={isMobile ? '' : 'shadow-layer-3'}
      padding={isMobile ? '20px' : '50px'}
      borderRadius="30px"
      backgroundColor={isMobile ? '' : 'surface'}
    >
      {children}
    </Flex>
  );
};

export default FormBaseCard;
