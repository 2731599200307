import { Box, Card, CardBody, Image } from '@chakra-ui/react';
import * as React from 'react';
import { TutorialItem } from './Tutorial';
import Typography from 'src/components/Typography';
import TutorialVideoPNG from 'src/assets/images/tutorial_video.png';
import { Tracker } from 'src/shared/tracker/Tracker';

interface TutorialCardProps extends TutorialItem {
  onOpen: () => void;
}

export function TutorialCard({ onOpen, title }: TutorialCardProps) {
  const handleCardClick = React.useCallback(() => {
    Tracker.trackEvent({ event: `Tutorial Click - ${title}` });
    onOpen();
  }, [onOpen, title]);

  return (
    <Card height="100%" onClick={handleCardClick} cursor="pointer" borderRadius="20px" data-testid="tutorial-card">
      <CardBody>
        <Box position="relative">
          <Image src={TutorialVideoPNG} alt={title} width="100%" />
          <Box
            position="absolute"
            width="100%"
            height="100%"
            bgColor="white"
            top={0}
            opacity={0}
            transition="all 0.4s"
            _hover={{ opacity: 0.4 }}
          />
        </Box>
        <Box mt="10px">
          <Typography model="h3" weight="bold">
            {title}
          </Typography>
        </Box>
      </CardBody>
    </Card>
  );
}
