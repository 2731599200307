import { Flex, SimpleGrid } from '@chakra-ui/react';
import Typography from 'src/components/Typography';
import { useIsMobile } from 'src/helpers/useIsMobile';
import HelpFooter from './Footer';
import { HelpCard } from './HelpCard';
import { Box } from 'src/components/UI/Box';
import { useCallback, useEffect, useState } from 'react';
import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import WhatsappIcon from 'src/components/Icons/WhatsappIcon';
import PhoneContactIconOutline from 'src/components/Icons/PhoneContactIconOutline';
import CallCenterIcon from 'src/components/Icons/CallCenterIcon';
import HelpIcon from 'src/components/Icons/HelpIcon';
import {
  DEFAULT_MEVO_PHONE_NUMBER,
  DEFAULT_MEVO_WHATSAPP_NUMBER,
  MEVO_HELP_PORTAL_URL,
  MEVO_PRIVACY_URL,
} from 'src/helpers/defaultValues';
import { TermsOfUseService } from 'src/services/termsOfUse';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';
import { openWhatsApp } from 'src/helpers/openWhatsApp';
import { getFirstAndLastWords } from 'src/helpers/getFirstAndLastWords';
import HiChat from 'src/components/HiChat/HiChat';
import ShieldIcon from 'src/components/Icons/Shield';

export function HelpPage() {
  const isMobile = useIsMobile();
  const [termsOfUseUrl, setTermsOfUseUrl] = useState<string>('');
  const { openToast } = useFeedbackContext();
  const { prescriberData } = usePrescriberContext();
  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleHelpPortal = () => {
    window.open(MEVO_HELP_PORTAL_URL, '_blank', 'noopener');
  };

  const handleTerms = () => {
    window.open(termsOfUseUrl, '_blank', 'noopener');
  };

  const handlePrivacy = () => {
    window.open(MEVO_PRIVACY_URL, '_blank', 'noopener');
  };

  const handlePhoneCall = () => {
    window.open(`tel:${DEFAULT_MEVO_PHONE_NUMBER()}`, '_blank', 'noopener');
  };

  const handleWhatsApp = useCallback(() => {
    let message = '';

    if (prescriberData) {
      message = `Olá! Sou ${getFirstAndLastWords(prescriberData.fullName)}, portador do CRM ${
        prescriberData.professionalRecord.number
      }-${prescriberData.professionalRecord.state} e preciso de ajuda.`;
    } else {
      message = 'Olá! Preciso de ajuda para acessar a Mevo Receita Digital.';
    }

    openWhatsApp(DEFAULT_MEVO_WHATSAPP_NUMBER(), message);
  }, [prescriberData]);

  useEffect(() => {
    const run = async () => {
      try {
        const termsOfUse = await TermsOfUseService.getTermsOfUse();

        setTermsOfUseUrl(termsOfUse.termUrl);
      } catch (error) {
        openToast('Erro ao buscar os Termos de Uso. Tente novamente em instantes.', {
          type: 'error-outline',
          duration: 4000,
        });
      }
    };

    run();
  }, [openToast]);

  return (
    <Flex direction="column" position={'relative'}>
      <Flex flexDirection={{ base: 'column', md: 'row' }} p={{ base: 0, md: 'calc(100vh /2 - 125px) 0px 0 50px' }}>
        <Box mr={'30px'} mb={{ base: '30px', md: 0 }} maxW={{ base: '100%', md: '30%' }}>
          <Typography model="h1" weight="bold">
            Ajuda
          </Typography>
          <Typography model="body" weight="regular" size="large">
            Não fique com dúvida! Acesse o nosso portal de ajuda ou fale diretamente com a gente através do <b>Chat</b>,
            <b> Telefone</b> ou <b>WhatsApp</b>.
          </Typography>
        </Box>

        <SimpleGrid columns={{ base: 2, md: 2, lg: 4 }} gap={'10px'}>
          <HelpCard
            text="Acessar portal de ajuda"
            onClick={handleHelpPortal}
            Icon={<HelpIcon width={20} variantColor={'support.blue'} />}
          />
          <HelpCard
            text="Conversar por chat"
            onClick={() => setIsChatOpen(!isChatOpen)}
            Icon={<CallCenterIcon width={20} variantColor={'secondary.400'} />}
          />

          <HelpCard
            text="Ligar para (11) 3580-0110"
            onClick={handlePhoneCall}
            Icon={<PhoneContactIconOutline width={20} />}
          />

          <Flex
            w={'100%'}
            p="20px"
            backgroundColor="notification.success"
            borderRadius="20px"
            cursor="pointer"
            flexDirection={'column'}
            justifyContent={'space-between'}
            onClick={handleWhatsApp}
          >
            <Flex mb={'10px'}>
              <WhatsappIcon variantColor="surface" width={20} />
            </Flex>
            <Typography model="body" color="surface" size="large" weight="semi">
              Conversar por Whatsapp
            </Typography>
          </Flex>
          {isMobile && (
            <HelpCard text="Política de Privacidade" onClick={handlePrivacy} Icon={<ShieldIcon width={20} />} />
          )}
        </SimpleGrid>
      </Flex>

      {!isMobile && (
        <Flex position={'fixed'} bottom={0} right={0} left={0}>
          <HelpFooter handleHelpPortal={handleHelpPortal} handlePrivacity={handlePrivacy} handleTerms={handleTerms} />
        </Flex>
      )}
      <HiChat isOpen={isChatOpen} onClose={() => setIsChatOpen(false)} />
    </Flex>
  );
}
