import { AnimatePresence, motion } from 'framer-motion';
import ExitIcon from '../../components/Icons/ExitIcon';
import { IconButton } from '../../components/UI/IconButton';
import { Tag } from '../../components/UI/Tag/Tag';
import { TagLabel } from '../../components/UI/Tag/TagLabel';

interface ToggleButtonProps {
  onClick(): void;
  isActive: boolean;
  hideIcon?: boolean;
  label: string | React.ReactNode;
}

export function ToggleButton(props: ToggleButtonProps) {
  return (
    <Tag
      bgColor={props.isActive ? 'secondary.400' : 'white'}
      color={'primary.1000'}
      borderWidth={'2px'}
      borderColor={props.isActive ? 'secondary.400' : 'primary.200'}
      borderRadius={'20px'}
      cursor={'pointer'}
      onClick={props.onClick}
      transition={'all 0.3s'}
      lineHeight={'1'}
      minH={'28px'}
      w={'100%'}
      display={'flex'}
      justifyContent={'space-between'}
      gap={'10px'}
      minW={'initial'}
    >
      <TagLabel fontSize={'10px'} fontWeight={700} whiteSpace={'nowrap'} p={'0 !important'}>
        {props.label}
      </TagLabel>

      <AnimatePresence>
        {props.isActive && (
          <motion.div
            initial={{ opacity: 0, width: 0, x: '-30px' }}
            animate={{ opacity: 1, width: '13px', x: 0 }}
            exit={{
              opacity: 0,
              width: 0,
              y: 0,
              x: '-30px',
              transition: {
                delay: 0.2,
                opacity: {
                  duration: 0.2,
                },
              },
            }}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <IconButton _hover={{}} aria-label={'close'} variant={'ghost'} p={'0'} minW={'13px'} h={'13px'}>
              <ExitIcon width={13} variantColor={'notification.error'} />
            </IconButton>
          </motion.div>
        )}
      </AnimatePresence>
    </Tag>
  );
}
