import { Portal, Td, Tr, useBreakpointValue } from '@chakra-ui/react';
import Typography from 'src/components/Typography';
import { Flex } from 'src/components/UI/Flex';
import { Tag } from 'src/components/UI/Tag/Tag';
import { PrescriptionStatusBadge } from './PrescriptionStatusBadge';
import { useMemo } from 'react';
import { Menu } from 'src/components/UI/Menu/Menu';
import { MenuButton } from 'src/components/UI/Menu/MenuButton';
import MenuThreePointsIcon from 'src/components/Icons/MenuThreePointsIcon';
import { MenuList } from 'src/components/UI/Menu/MenuList';
import { MenuItem } from 'src/components/UI/Menu/MenuItem';
import { Button } from 'src/components/UI/Button';
import { DateFromISOToBrazilianFormat, getHourAndMinuteFromIso } from 'src/helpers/Date';
import { Box } from 'src/components/UI/Box';
import PlaceholderAvatarIcon from 'src/components/Icons/PlaceholderAvatarIcon';
import QRCodeIcon from 'src/components/Icons/QRCodeIcon';
import { useIsMobile } from 'src/helpers/useIsMobile';
import { PRESCRIPTION_STATUS, PrescriptionHistory } from 'src/services/prescriptionHistory';
import { DOCUMENT_TABS_LABEL } from './PrescriptionDetails/DocumentTabs/DocumentTabs';
import { uniq } from 'lodash';
import { getFirstAndLastWords } from 'src/helpers/getFirstAndLastWords';

interface HistoryItemProps {
  onViewDetails(data: PrescriptionHistory): void;
  prescriptionData: PrescriptionHistory;
  onCancelPrescription(validationCode: string, prescriptionId: number): void;
}

export function HistoryItem(props: HistoryItemProps) {
  const { prescriptionData, onViewDetails, onCancelPrescription } = props;

  const isMobile = useIsMobile();

  const numberOfChipsAvailableToRender = useBreakpointValue({ base: 0, sm: 0, md: 3, xl: 5 }, { ssr: false });

  const prescriptionDocuments = useMemo(() => {
    const { medicines, manualMedicines, exams, prescriptionMedicalStatements, medicalReports, prescriptionReferral } =
      prescriptionData;

    const documentItems = [
      { items: medicines, label: DOCUMENT_TABS_LABEL.MEDICINE },
      { items: manualMedicines, label: DOCUMENT_TABS_LABEL.MEDICINE },
      { items: exams, label: DOCUMENT_TABS_LABEL.EXAM },
      { items: prescriptionMedicalStatements, label: DOCUMENT_TABS_LABEL.CERTIFICATE },
      { items: medicalReports, label: DOCUMENT_TABS_LABEL.REPORT },
      { items: prescriptionReferral, label: DOCUMENT_TABS_LABEL.REFERRAL },
    ];
    const availableDocuments = uniq(documentItems.filter((doc) => doc.items.length > 0).map((type) => type.label));

    if (numberOfChipsAvailableToRender && availableDocuments.length > numberOfChipsAvailableToRender) {
      availableDocuments.splice(numberOfChipsAvailableToRender);
      availableDocuments.push(DOCUMENT_TABS_LABEL.AND_OTHER_THINGS);
    }
    return availableDocuments;
  }, [prescriptionData, numberOfChipsAvailableToRender]);

  const ActionsMenu = useMemo(() => {
    return (
      <Menu>
        <MenuButton data-testid={'history-menu-button'}>
          <MenuThreePointsIcon width={20} />
        </MenuButton>
        <Portal>
          <MenuList
            boxShadow={'12px 12px 40px rgba(61, 14, 107, 0.1)'}
            minWidth={'130px !important'}
            bg={'surface'}
            borderRadius={'20px'}
            border={'none'}
            p={'10px'}
            gap={'5px'}
            display={'flex'}
            flexDirection={'column'}
          >
            <MenuItem p={0} m={0}>
              <Button w={'100%'} onClick={() => onViewDetails(prescriptionData)} variant="outline-secondary">
                Ver detalhes
              </Button>
            </MenuItem>
            {/* <MenuItem p={0} m={0}>
            <Button w={'100%'}>Reenviar</Button>
          </MenuItem>
          <MenuItem p={0} m={0}>
            <Button w={'100%'}>Renovar</Button>
          </MenuItem> */}

            {prescriptionData.prescriptionStatus !== PRESCRIPTION_STATUS.CANCELED && (
              <MenuItem p={0} m={0}>
                <Button
                  w={'100%'}
                  variant="outline-error"
                  onClick={() => onCancelPrescription(prescriptionData.validationCode, prescriptionData.prescriptionId)}
                >
                  Cancelar
                </Button>
              </MenuItem>
            )}
          </MenuList>
        </Portal>
      </Menu>
    );
  }, [onViewDetails, prescriptionData, onCancelPrescription]);

  return (
    <Tr bg={'surface'}>
      <Td w={'50px'}>
        <Typography model="body" size="normal" weight="bold">
          {DateFromISOToBrazilianFormat(prescriptionData.modificationDate)}
        </Typography>
        <Typography model="body" size="normal" weight="regular">
          {getHourAndMinuteFromIso(prescriptionData.modificationDate)}
        </Typography>
      </Td>
      <Td maxW={{ base: '50%', md: '150px' }}>
        <Flex alignItems={'center'} gap={'5px'} flexWrap={'nowrap'} overflow={'hidden'}>
          <PlaceholderAvatarIcon width={20} variantColor="secondary.main" />
          <Typography model="body" size="normal" weight="regular" color={'support.dark'} textTransform="capitalize">
            {getFirstAndLastWords(prescriptionData.patientName)}
          </Typography>
        </Flex>
      </Td>
      <Td display={{ base: 'none', md: 'table-cel' }}>
        <Flex gap={'8px'}>
          {prescriptionDocuments.map((document) => (
            <Tag
              key={document}
              p={'5px 10px'}
              borderRadius={'20px'}
              bg={'primary.50'}
              color={'primary.1000'}
              fontSize={'10px'}
              fontWeight={700}
            >
              {document}
            </Tag>
          ))}
        </Flex>
      </Td>

      <Td>
        <Flex gap={'10px'} alignItems={'center'} justifyContent={'flex-end'}>
          <Tag
            p={'5px 10px'}
            borderRadius={'20px'}
            bg={'secondary.50'}
            color={'primary.1000'}
            fontSize={'14px'}
            fontWeight={700}
            fontFamily={'Roboto Mono'}
            display={{ base: 'none', md: 'flex' }}
          >
            <Box mr={'5px'}>
              <QRCodeIcon width={12} variantColor="info.main" />
            </Box>
            {prescriptionData.validationCode}
          </Tag>

          <Box
            w={{
              base: 'auto',
              sm: '100px',
            }}
          >
            <PrescriptionStatusBadge
              showLabel={!isMobile}
              showStatusColor
              status={prescriptionData.prescriptionStatus}
            />
          </Box>
          {ActionsMenu}
        </Flex>
      </Td>
    </Tr>
  );
}
