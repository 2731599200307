import * as React from 'react';
import Typography from 'src/components/Typography';
import { Button } from 'src/components/UI/Button';
import { Flex } from 'src/components/UI/Flex';
import { useIsMobile } from 'src/helpers/useIsMobile';

interface HelpFooterProps {
  handleHelpPortal: () => void;
  handleTerms: () => void;
  handlePrivacity: () => void;
}

const HelpFooter: React.FunctionComponent<HelpFooterProps> = (props) => {
  const isMobile = useIsMobile();

  return (
    <Flex
      w="100%"
      p="10px"
      justifyContent={isMobile ? 'center' : 'flex-end'}
      alignItems="center"
      bg={isMobile ? 'transparent' : 'white'}
      gap={isMobile ? '5px' : '10px'}
      flexDirection={isMobile ? 'column-reverse' : 'row'}
      mb={isMobile ? '10px' : 0}
    >
      <Button variant="ghost" width={isMobile ? '100%' : 'auto'} height="55px" onClick={props.handlePrivacity}>
        <Typography model="body" size="large" weight="bold" underline>
          Aviso de Privacidade
        </Typography>
      </Button>
      <Button variant="ghost" width={isMobile ? '100%' : 'auto'} height="55px" onClick={props.handleTerms}>
        <Typography model="body" size="large" weight="bold" underline>
          Termos de Uso
        </Typography>
      </Button>
      <Button
        variant="solid-secondary"
        height="55px"
        width={isMobile ? '100%' : '180px'}
        onClick={props.handleHelpPortal}
      >
        Portal de ajuda
      </Button>
    </Flex>
  );
};

export default HelpFooter;
