import { Box } from 'src/components/UI/Box';
import { PRESCRIPTION_STATUS_COLOR, PRESCRIPTION_STATUS_LABEL } from './PrescriptionStatusFilter';
import { Tag } from 'src/components/UI/Tag/Tag';
import Typography from 'src/components/Typography';
import { PRESCRIPTION_STATUS } from 'src/services/prescriptionHistory';

interface PrescriptionStatusBadgeProps {
  status: PRESCRIPTION_STATUS;
  showLabel?: boolean;
  showStatusColor?: boolean;
}

export function PrescriptionStatusBadge(props: PrescriptionStatusBadgeProps) {
  const prescriptionStatusColor = PRESCRIPTION_STATUS_COLOR[props.status as keyof typeof PRESCRIPTION_STATUS_COLOR];

  return (
    <Tag
      bg={'transparent'}
      padding={props.showLabel ? '5px' : 'initial'}
      alignItems={'center'}
      justifyContent={props.showLabel ? 'left' : 'center'}
      borderWidth={props.showLabel && props.showStatusColor ? '2px' : '0'}
      borderColor={props.showStatusColor ? prescriptionStatusColor : 'inherit'}
      borderRadius={'20px'}
      w={'100%'}
      gap={'5px'}
      data-testid={`prescription-tag-${props.status}`}
    >
      <Box w={'8px'} h={'8px'} borderRadius={'50%'} bg={prescriptionStatusColor} />
      {props.showLabel && (
        <Typography
          model="body"
          size="small"
          weight="bold"
          color={props.showStatusColor ? prescriptionStatusColor : 'primary.1000'}
        >
          {PRESCRIPTION_STATUS_LABEL[props.status as keyof typeof PRESCRIPTION_STATUS_LABEL]}
        </Typography>
      )}
    </Tag>
  );
}
