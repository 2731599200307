import { Box, Flex } from '@chakra-ui/react';
import { ReportItem } from './MedicalReportItem';
import { MedicalReport } from 'src/services/prescriptionHistory';

interface ReportProps {
  items: MedicalReport[];
}

export function Report(props: ReportProps) {
  const { items } = props;
  return (
    <Box>
      <Flex flexDirection="column" gap="20px">
        {items.map((item) => (
          <ReportItem item={item} key={`${item.text}-${item.title}`} />
        ))}
      </Flex>
    </Box>
  );
}
