import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex } from 'src/components/UI/Flex';
import { Spinner } from 'src/components/UI/Spinner';
import {
  OS_TYPES,
  openAppStore,
  openPlayStore,
  redirectPlayStore,
  redirectToAppStore,
  verifyOSSystem,
} from 'src/helpers/window';

export function RedirectApp() {
  const navigate = useNavigate();

  useEffect(() => {
    const os = verifyOSSystem();

    if (os === OS_TYPES.ANDROID) {
      redirectPlayStore();
      return;
    }

    if (os === OS_TYPES.IOS) {
      redirectToAppStore();
      return;
    }
    navigate('/');
  }, [navigate]);

  return (
    <Flex justifyContent={'center'} alignItems={'center'} flex={1} height={'100%'} bg={'secondary.100'}>
      <Spinner size={'lg'} color="primary.1000" />
    </Flex>
  );
}
