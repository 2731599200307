import Typography from 'src/components/Typography';
import { Box } from 'src/components/UI/Box';
import { Text } from 'src/components/UI/Text';
import { Button } from 'src/components/UI/Button';
import { Image } from 'src/components/UI/Image';
import { Flex } from 'src/components/UI/Flex';
import { HubTopics } from '../../HubTopics';
import { ReactComponent as Logo } from 'src/assets/images/logo.svg';
import ArrowRight from 'src/components/Icons/ArrowIcon';
import ArrowRightFilledIcon from 'src/components/Icons/ArrowIconFilledRight';
import StandalonePreview from 'src/assets/images/stand-alone-tablet.png';
import { DefaultPageProps } from '../defaultPageProps';
import { AvailableOnPlayStore } from 'src/components/AvailableOnPlayStore';
import { AvailableOnAppStore } from 'src/components/AvailableOnAppStore';
import { ReactComponent as MedicoExponencialLogo } from 'src/assets/images/partners/medico_exponencial.svg';
import { ReactComponent as EMSLogo } from 'src/assets/images/partners/ems.svg';

export function EMSMobile(props: DefaultPageProps) {
  return (
    <Box data-testid={'ems-mobile'}>
      <Flex alignItems={'center'} justifyContent={'space-between'} pt={'12px '} pb={'20px'}>
        <Logo width={'120px'} fill={'white'} />

        <Button
          variant={'ghost'}
          borderRadius={'20px'}
          gap={'8px'}
          color={'primary.1000'}
          bgColor={'surface'}
          fontSize={'14px'}
          fontWeight={'semibold'}
          _focus={{
            boxShadow: 'none',
          }}
          _hover={{
            bgColor: 'secondary.200',
          }}
          onClick={props.onRegister}
        >
          Me cadastrar
          <ArrowRight width={6} variantColor={'primary.1000'} direction={'right'} />
        </Button>
      </Flex>

      <Box pb={'20px'}>
        <Flex flexDirection={'column'}>
          <Box>
            <Typography model={'h2'} fontSize={18} weight={'bold'} color={'secondary.400'}>
              Em parceria com a EMS, oferecemos um ambiente de acesso exclusivo para os médicos da comunidade Médico
              Exponencial.
            </Typography>
            <Box pt={'12px'}>
              <Typography model={'h3'} fontSize={18} weight={'regular'} color={'surface'}>
                Agora disponível para você prescrever quando,
              </Typography>
              <Typography model={'h3'} fontSize={18} weight={'regular'} color={'surface'}>
                como e onde quiser,{' '}
                <Text color={'#FFDE49'} as={'span'}>
                  gratuitamente.
                </Text>
              </Typography>
            </Box>

            <Flex gap={'10px'} pt={'20px'} justifyContent={'center'}>
              <Box mb={'10px'}>
                <MedicoExponencialLogo data-testid={'medico-exponencial-logo'} />
              </Box>
              <EMSLogo data-testid={'ems-logo'} height={'46px'} />
            </Flex>
          </Box>

          <Flex flexDirection={'column'} position={'relative'} m={'15px 0'}>
            <Image src={StandalonePreview} alt={'Preview da aplicação'} mb={'10px'} />

            <Button
              gap={'10px'}
              className={'secondary-pulse-animation'}
              justifyContent={'space-between'}
              onClick={props.onLogin}
              backgroundColor={'secondary.400'}
              color={'primary.1000'}
            >
              Fazer login
              <ArrowRightFilledIcon width={16} />
            </Button>
          </Flex>

          <Box maxW={'300px'} w={'100%'} mb={'10px'}>
            <Typography model={'h3'} fontSize={16} weight={'regular'} color={'surface'}>
              Use a Mevo também no seu celular.
            </Typography>
            <Typography model={'h3'} fontSize={16} weight={'regular'} color={'surface'}>
              <b>Baixe agora o app.</b>
            </Typography>
          </Box>
          <Flex gap={'10px'} mb={'30px'}>
            <AvailableOnAppStore />
            <AvailableOnPlayStore />
          </Flex>
        </Flex>

        <HubTopics />
      </Box>
    </Box>
  );
}
