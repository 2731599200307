import { SimpleGrid } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';

import { PatientAlreadyCreatedDialog } from './PatientAlreadyCreatedDialog';

import MevoInput from 'src/components/Input';
import Typography from 'src/components/Typography';
import SelectGender from 'src/components/UI/SelectGender';
import AlertIcon from 'src/components/Icons/AlertIcon';
import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { Checkbox } from 'src/components/UI/Checkbox';
import { Flex } from 'src/components/UI/Flex';
import { FormControl } from 'src/components/UI/FormControl';
import { MASKS } from 'src/components/UI/Input/MaskedInput';
import { SIDE_BAR_WIDTH } from 'src/components/SidebarMenu/SidebarMenu';

import { Yup } from 'src/helpers/Yup';
import { validateCPF } from 'src/helpers/cpf';
import { onlyDigits } from 'src/helpers/string';
import { validateDateFromBrazilianForm } from 'src/helpers/Date';
import { removeSpacesAndSpecialChars } from 'src/helpers/removeSpacesAndSpecialChars';

import useLoading from 'src/helpers/useLoading';

import { DialogWrappedComponentProps, useFeedbackContext } from 'src/providers/FeedbackProvider';
import { usePrescriberContext } from 'src/providers/PrescriberProvider';

import { PatientService } from 'src/services/patient';
import { scrollToContainer } from 'src/helpers/window';
import { InitPrescriptionDialog } from './InitPrescriptionDialog';

interface CreatePartialPatientFormFields {
  fullName: string;
  cellphoneNumber: string;
  sex: 'Masculino' | 'Feminino' | '';
  document: string;
  documentType: string;
  hasDocument: boolean;
  patientId: string | null;
}

const CreatePartialPatientSchema = Yup.object().shape({
  fullName: Yup.string().required('Informe o nome completo.'),
  hasDocument: Yup.bool().nullable(),
  document: Yup.string().when('hasDocument', {
    is: true,
    then: (schema: any) =>
      schema
        .required('Informe um número de CPF válido.')
        .test('validate cpf', 'Informe um número de CPF válido.', (schema: string) => {
          return validateCPF(schema || '');
        }),
    otherwise: (schema: any) => schema.nullable(),
  }),
  cellphoneNumber: Yup.string()
    .required('Informe um número de telefone válido.')
    .test('validate phoneNumber', 'Informe um número de celular válido.', function (phoneNumber) {
      if (!phoneNumber) return false;
      return onlyDigits(phoneNumber).length === 11;
    }),
  birthDate: Yup.string().test('match', 'Informe uma data de nascimento válida.', (birthDate) => {
    if (!birthDate) return true;

    return validateDateFromBrazilianForm(birthDate);
  }),
  sex: Yup.string().nullable(),
});

const initialValues: CreatePartialPatientFormFields = {
  fullName: '',
  cellphoneNumber: '',
  sex: '',
  document: '',
  documentType: 'cpf',
  hasDocument: true,
  patientId: null,
};

export function CreatePartialPatient() {
  const { prescriberId, startPrescrition, setUserDoesNotHaveAnyPatients } = usePrescriberContext();
  const { openToast, openBaseDialog } = useFeedbackContext();

  const [loading, runWithLoading] = useLoading();

  const navigate = useNavigate();

  const formik = useFormik<CreatePartialPatientFormFields>({
    initialValues: initialValues,
    validationSchema: CreatePartialPatientSchema,
    validateOnChange: false,
    async onSubmit(values) {
      runWithLoading(async () => {
        try {
          if (!prescriberId) return;
          const document = onlyDigits(values.document);
          const fullName = removeSpacesAndSpecialChars(values.fullName);

          const createdPatient = await PatientService.createPartialPatient(
            { ...values, document, fullName },
            prescriberId
          );

          await openBaseDialog(
            (dialogProps: DialogWrappedComponentProps) => (
              <InitPrescriptionDialog
                onCancel={() => {
                  dialogProps.onClose();
                }}
                onConfirm={() => {
                  startPrescrition(createdPatient._id);
                  dialogProps.onClose();
                }}
              />
            ),
            {
              width: '300px',
              height: '330px',
            }
          );

          setUserDoesNotHaveAnyPatients(false);

          navigate('/inicio/receita');
        } catch (error: any) {
          const status = get(error, 'response.status', null);
          const message = get(error, 'response.data.message', '');

          if (status === 412 && message.includes('Paciente já cadastrado')) {
            await openBaseDialog(
              (dialogProps: DialogWrappedComponentProps) => (
                <PatientAlreadyCreatedDialog
                  onCancel={dialogProps.onClose}
                  onConfirm={() => {
                    navigate('/inicio/receita');
                    dialogProps.onClose();
                  }}
                />
              ),
              {
                width: '270px',
                height: '356px',
              }
            );
            return;
          }

          openToast(
            'Ops.. Um problema inesperado ocorreu. Aguarde alguns minutos e tente novamente. Caso o problema persista, fale com a gente.',
            { type: 'error', duration: 2000 }
          );
        }
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex
        flexDirection={'column'}
        position={{
          base: 'unset',
          sm: 'absolute',
        }}
        inset={'0'}
        top={'75px'}
        bottom={{ base: 0, sm: '50px' }}
        left={{ base: '0', sm: `calc(${SIDE_BAR_WIDTH}  + 50px)` }}
        right={{ base: '20px', sm: '42px' }}
      >
        <Box mb={'20px'}>
          <Typography model="h1" weight="bold">
            Cadastre seu paciente
          </Typography>

          <Typography model="body" size={'large'} weight="regular">
            Informe os dados básicos do seu paciente
          </Typography>
        </Box>

        <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 2 }}>
          <Box>
            <FormControl isInvalid={!!formik.errors.fullName} mb={'20px'}>
              <MevoInput
                id="fullName"
                labelText="Nome do paciente*"
                h={'45px'}
                placeholderText={'Digite o nome'}
                autoFocus
                value={formik.values.fullName}
                name={'fullName'}
                onChange={formik.handleChange}
                errorText={formik.errors.fullName}
                maxLength={200}
                onFocus={() => scrollToContainer({ containerId: 'mobile-layout-body' })}
              />
            </FormControl>
            <Flex mt={'30px'} gap={'10px'} flexDirection={{ base: 'column', sm: 'row' }}>
              <FormControl isInvalid={!!formik.errors.cellphoneNumber} mb={'20px'}>
                <MevoInput
                  id="cellphoneNumber"
                  labelText="Celular*"
                  h={'45px'}
                  type={'tel'}
                  placeholderText={'(99) 9 9999-9999'}
                  name={'cellphoneNumber'}
                  value={formik.values.cellphoneNumber}
                  onChange={formik.handleChange}
                  errorText={formik.errors.cellphoneNumber}
                  mask={MASKS.BRAZIL_PHONE}
                  onBlur={() => {
                    formik.validateField('cellphoneNumber');
                  }}
                />
              </FormControl>
              <Box w={'100%'} mb={'20px'} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                <FormControl isInvalid={!!formik.errors.document}>
                  <MevoInput
                    id="document"
                    labelText="CPF*"
                    type={'tel'}
                    h={'45px'}
                    placeholder={'000.000.000-00'}
                    name={'document'}
                    value={formik.values.document}
                    onChange={formik.handleChange}
                    onBlur={() => formik.validateField('document')}
                    errorText={formik.errors.document}
                    mask={MASKS.CPF}
                    disabled={!formik.values.hasDocument}
                  />
                </FormControl>
                <Checkbox
                  marginTop={formik.errors.document && '10px'}
                  name="hasDocument"
                  isChecked={!formik.values.hasDocument}
                  onChange={(e) => {
                    formik.setFieldValue('hasDocument', !e.target.checked);
                    if (e.target.checked) {
                      formik.setFieldValue('document', '');
                      formik.setErrors({ ...formik.errors, document: '' });
                      return;
                    }
                  }}
                >
                  <Box mt={'2px'}>
                    <Typography model="body" size="normal" weight="regular">
                      Não possui CPF
                    </Typography>
                  </Box>
                </Checkbox>
              </Box>
            </Flex>

            <Flex mt={'20px'} gap={'10px'} flexDirection={{ base: 'column', sm: 'row' }}>
              <FormControl isInvalid={!!formik.errors.sex}>
                <SelectGender
                  label={'SEXO'}
                  value={formik.values.sex}
                  onChange={(value) => formik.setFieldValue('sex', value)}
                  errorMessage={'Informe o sexo do paciente.'}
                />
              </FormControl>
            </Flex>
          </Box>
        </SimpleGrid>

        <Flex
          as={'footer'}
          justifyContent={'space-between'}
          alignItems={'center'}
          marginTop={'auto'}
          padding={{
            base: '20px 10px 0',
            sm: '0 10px 10px',
          }}
          mb={{ base: '20px', sm: 0 }}
          flexDirection={{ base: 'column', sm: 'row' }}
        >
          <Flex
            w={'100%'}
            mr={'10px'}
            border={'1px solid'}
            borderColor={'notification.alert'}
            borderRadius={'10px'}
            alignItems={'center'}
            justifyContent={'center'}
            maxW={{ base: '100%', sm: '308px' }}
            mb={{ base: '20px', sm: 0 }}
            padding={'10px'}
          >
            <Box mr={'5px'}>
              <AlertIcon variantColor="notification.alert" width={12} />
            </Box>
            <Typography model="body" size="small" weight="regular">
              Ao escolher voltar, os dados preenchidos serão perdidos!
            </Typography>
          </Flex>
          <Box w={{ base: '100%', sm: '170px' }}>
            <Button h={'60px'} type={'submit'} width={'100%'} isLoading={loading}>
              Cadastrar paciente
            </Button>
          </Box>
        </Flex>
      </Flex>
    </form>
  );
}
