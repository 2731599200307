/* eslint-disable max-lines */
import { useMemo } from 'react';
import { Box, InputRightAddon, Show } from '@chakra-ui/react';
import { Flex } from 'src/components/UI/Flex';
import { Input } from 'src/components/UI/Input/Input';
import SearchIcon from 'src/components/Icons/SearchIcon';
import { useIsMobile } from 'src/helpers/useIsMobile';
import { InputGroup } from 'src/components/UI/Input/InputGroup';
import ExitIcon from 'src/components/Icons/ExitIcon';
import { Spinner } from 'src/components/UI/Spinner';
import { Button } from 'src/components/UI/Button';
import { Menu } from 'src/components/UI/Menu/Menu';
import { MenuButton } from 'src/components/UI/Menu/MenuButton';
import { MenuList } from 'src/components/UI/Menu/MenuList';
import { ToggleButton } from './ToggleButton';
import { AdvancedFilters } from './AdvancedFilters';
import { FormFilters, RETROATIVE_DAYS } from './History';
import { AnimatePresence, motion } from 'framer-motion';

interface SearchProps {
  isLoading: boolean;
  searchString: string;
  clearSearch(): void;
  clearFilters(): void;
  filters: FormFilters;
  onChangeFilters(filters: FormFilters): void;
  onChangeSearchString(value: string): void;
}

export function Search(props: SearchProps) {
  const isMobile = useIsMobile();

  const { onChangeSearchString, onChangeFilters, filters, isLoading, searchString, clearFilters, clearSearch } = props;

  const InputIconAddornment = useMemo(() => {
    if (isLoading) {
      return <Spinner color={'primary.1000'} />;
    }

    return searchString.trim().length >= 1 ? (
      <ExitIcon
        width={20}
        variantColor={'primary.main'}
        onClick={() => {
          clearSearch();
        }}
      />
    ) : (
      <SearchIcon width={20} />
    );
  }, [isLoading, searchString, clearSearch]);

  const handleRetroativeDaysChange = (value: RETROATIVE_DAYS) => {
    if (value === filters.retroativeDays) {
      onChangeFilters({ ...filters, retroativeDays: null });
      return;
    }

    onChangeFilters({ ...filters, retroativeDays: value });
  };

  const filtersAmount = useMemo(() => {
    const { documentType, prescriptionStatus, retroativeDays, initialDate, endDate } = filters;
    const additionalFilters = [retroativeDays, initialDate, endDate].filter(Boolean).length;
    return documentType.length + prescriptionStatus.length + additionalFilters;
  }, [filters]);

  return (
    <Box mt={'20px'}>
      <InputGroup size="sm" h={'52px'}>
        <Input
          autoComplete="off"
          autoFocus={!isMobile}
          border={'none'}
          h={'52px'}
          placeholder={'Busque pelo Nome do paciente ou Token da Receita'}
          value={searchString}
          name={'searchString'}
          onChange={(e) => onChangeSearchString(e.target.value)}
        />
        <InputRightAddon
          bg={'surface'}
          h={'100%'}
          borderRightRadius={'10px'}
          border={'none'}
          children={InputIconAddornment}
        />
      </InputGroup>

      <Flex mt={'10px'} w={'100%'} alignItems={'center'} justifyContent={'space-between'}>
        <Flex gap={'10px'}>
          <ToggleButton
            onClick={() => handleRetroativeDaysChange(RETROATIVE_DAYS.SEVEN_DAYS)}
            isActive={filters.retroativeDays === RETROATIVE_DAYS.SEVEN_DAYS}
            label="7 dias"
          />
          <ToggleButton
            onClick={() => handleRetroativeDaysChange(RETROATIVE_DAYS.FIFTEEN_DAYS)}
            isActive={filters.retroativeDays === RETROATIVE_DAYS.FIFTEEN_DAYS}
            label="15 dias"
          />
          <ToggleButton
            onClick={() => handleRetroativeDaysChange(RETROATIVE_DAYS.THIRTY_DAYS)}
            isActive={filters.retroativeDays === RETROATIVE_DAYS.THIRTY_DAYS}
            label="30 dias"
          />
        </Flex>

        <Flex gap={'10px'} flexDirection={{ base: 'column-reverse', md: 'row' }}>
          <AnimatePresence>
            {filtersAmount > 0 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{
                  opacity: 0,
                }}
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <Button
                  variant="outline"
                  p={'5px 10px'}
                  fontSize={'10px'}
                  h={'auto'}
                  fontWeight={700}
                  whiteSpace={'nowrap'}
                  borderRadius={'20px'}
                  onClick={clearFilters}
                >
                  Limpar filtros
                </Button>
                <Show above="md">
                  <Box w={'1px'} h={'10px'} bg={'primary.100'} />
                </Show>
              </motion.div>
            )}
          </AnimatePresence>

          <Menu isLazy>
            {({ onClose }) => (
              <>
                <MenuButton
                  p={'5px 10px'}
                  fontSize={'10px'}
                  h={'auto'}
                  fontWeight={700}
                  whiteSpace={'nowrap'}
                  borderRadius={'20px'}
                  bg={'primary.50'}
                  color={'primary.1000'}
                >
                  Filtro avançado ({filtersAmount})
                </MenuButton>
                <MenuList
                  boxShadow={'12px 12px 40px rgba(61, 14, 107, 0.1)'}
                  minWidth={'270px !important'}
                  maxW={'310px'}
                  bg={'surface'}
                  borderRadius={'20px'}
                  border={'none'}
                  p={'30px 20px 20px'}
                >
                  <AdvancedFilters
                    filters={filters}
                    onClose={onClose}
                    resetFilters={clearFilters}
                    onChangeFilters={(newFilters) => onChangeFilters(newFilters)}
                  />
                </MenuList>
              </>
            )}
          </Menu>
        </Flex>
      </Flex>
    </Box>
  );
}
