import { ReactElement } from 'react';
import { SimpleGrid } from '@chakra-ui/react';

import { Flex } from 'src/components/UI/Flex';
import Typography from 'src/components/Typography';

import CellPhoneIcon from 'src/components/Icons/CellPhoneIcon';
import DocPaperMultipleIcon from 'src/components/Icons/DocPaperMultipleIcon';
import LightIcon from 'src/components/Icons/LightIcon';
import DocPaperIcon from 'src/components/Icons/DocPaperIcon';
import { Box } from 'src/components/UI/Box';

interface Topics {
  text: string;
  icon: ReactElement;
}

const defaultIconSize = 20;

const hubTopics: Topics[] = [
  {
    text: 'É mais praticidade e segurança para prescritores e pacientes.',
    icon: (
      <Box bg={'surface'} borderRadius={'10px'} p={'5px'}>
        <LightIcon width={defaultIconSize} variantColor={'support.blue'} />
      </Box>
    ),
  },
  {
    text: 'Receitas, atestados, exames e encaminhamentos médicos em um só lugar.',
    icon: (
      <Box bg={'surface'} borderRadius={'10px'} p={'5px'}>
        <DocPaperMultipleIcon width={defaultIconSize} variantColor={'support.blue'} />{' '}
      </Box>
    ),
  },
  {
    text: 'Documentos enviados direto ao celular dos pacientes, com opção de compra online de medicamentos.',
    icon: (
      <Box bg={'surface'} borderRadius={'10px'} p={'5px'}>
        <CellPhoneIcon width={defaultIconSize} variantColor={'support.blue'} />
      </Box>
    ),
  },
  {
    text: 'Opção de salvar modelos de receitas para ganhar tempo na prescrição.',
    icon: (
      <Box bg={'surface'} borderRadius={'10px'} p={'5px'}>
        <DocPaperIcon width={defaultIconSize} variantColor={'support.blue'} />
      </Box>
    ),
  },
];

export function HubTopics() {
  return (
    <SimpleGrid columns={1} spacing={'10px'}>
      {hubTopics.map((topic) => (
        <Flex key={topic.text} align={'center'} gap={'12px'} p={'12px'} bgColor={'primary.900'} borderRadius={'16px'}>
          {topic.icon}
          <Typography model={'body'} size={'large'} weight={'regular'} fontSize={12} color="surface">
            {topic.text}
          </Typography>
        </Flex>
      ))}
    </SimpleGrid>
  );
}
