import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { DateTime } from 'luxon';
import { createContext, useState, ReactNode, useContext, useEffect, useRef, useCallback } from 'react';
import { setAuthorizationHeader } from 'src/api';
import useLoading from 'src/helpers/useLoading';
import { userPool } from 'src/services/AuthService';
import { usePrescriberContext } from './PrescriberProvider';

export enum USER_STATUS {
  AUTHENTICATED = 'authenticated',
  NOT_AUTHENTICATED = 'notAuthenticated',
  NOT_VALIDATED = 'notValidated',
}

interface AuthContextProps {
  userStatus: USER_STATUS;
  setUserStatus(status: USER_STATUS): void;
  setSession(session: CognitoUserSession | null): void;
  loadingAuth: boolean;
  runWithLoadingAuth: Function;
}

export const AuthContext = createContext<AuthContextProps>({
  userStatus: USER_STATUS.NOT_VALIDATED,
  setUserStatus: (status: USER_STATUS) => USER_STATUS.NOT_VALIDATED,
  setSession: (session: CognitoUserSession | null) => {},
  loadingAuth: false,
  runWithLoadingAuth: () => {},
});

export interface AuthProviderInterface {
  children: ReactNode;
}

const THIRTY_MINUTES_SECONDS = 1800;

export function AuthProvider(props: AuthProviderInterface) {
  const [userStatus, setUserStatus] = useState<USER_STATUS>(USER_STATUS.NOT_VALIDATED);
  const [session, setSession] = useState<CognitoUserSession | null>(null);

  const [loadingAuth, runWithLoadingAuth] = useLoading();
  const timeoutId = useRef<any | null>(null);

  const { isUserOnApp } = usePrescriberContext();

  const renewAccessToken = useCallback(async (user: CognitoUser, session: CognitoUserSession) => {
    if (!user) return;

    user.refreshSession(session.getRefreshToken(), (err, newSession) => {
      if (err) {
        console.error('Erro ao atualizar o token de acesso:', err);
      } else {
        setSession(newSession);
        setAuthorizationHeader(newSession.getAccessToken().getJwtToken());
      }
    });
  }, []);

  useEffect(() => {
    if (userStatus !== USER_STATUS.AUTHENTICATED || !session || isUserOnApp) return;

    const user = userPool.getCurrentUser();

    if (!user) return;

    const tokenExpTime = session.getAccessToken().getExpiration();
    const tokenExpTimeInSeconds = DateTime.fromSeconds(tokenExpTime);

    if (
      Math.floor(tokenExpTimeInSeconds.toSeconds()) <= THIRTY_MINUTES_SECONDS ||
      Math.floor(tokenExpTimeInSeconds.toSeconds()) <= 0
    ) {
      renewAccessToken(user, session);
      return;
    }

    const runFunctionIn = Math.floor(tokenExpTimeInSeconds.minus({ hour: 23, minutes: 30 }).toMillis() - Date.now());

    timeoutId.current = setTimeout(() => {
      renewAccessToken(user, session);
    }, runFunctionIn);

    return () => {
      if (!timeoutId.current) return;
      clearTimeout(timeoutId.current);
    };
  }, [userStatus, renewAccessToken, session, isUserOnApp]);

  return (
    <AuthContext.Provider
      value={{
        userStatus,
        setUserStatus,
        loadingAuth,
        runWithLoadingAuth,
        setSession,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  const authContext = useContext(AuthContext);

  return authContext;
}
