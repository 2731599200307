import { useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { USER_STATUS, useAuthContext } from 'src/providers/AuthProvider';

import { AuthService } from 'src/services/AuthService';

interface ProtectedRouteProps {
  children: JSX.Element;
}

export function ProtectedRoute(props: ProtectedRouteProps) {
  const { setUserStatus, userStatus } = useAuthContext();

  const Route = useMemo(() => {
    if (userStatus === USER_STATUS.NOT_AUTHENTICATED) {
      return <Navigate to={'/entrar'} replace />;
    }
    return props.children;
  }, [userStatus, props.children]);

  useEffect(() => {
    const run = async () => {
      if (userStatus === USER_STATUS.AUTHENTICATED) return;
      try {
        const session = await AuthService.getSession();

        if (session.isValid()) {
          setUserStatus(USER_STATUS.AUTHENTICATED);
          return;
        }
        setUserStatus(USER_STATUS.NOT_AUTHENTICATED);
      } catch (error) {
        setUserStatus(USER_STATUS.NOT_AUTHENTICATED);
      }
    };

    run();
  }, [setUserStatus, userStatus]);

  return Route;
}
