import { UaEventOptions } from 'react-ga4/types/ga4';
import ReactGA from 'react-ga4';

export type ReactGAEventEmitter = {
  event(optionsOrName: string | UaEventOptions, params?: any): void;
};

export class ReactGaFactory {
  static create(): ReactGAEventEmitter {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID || '');
    ReactGA.send(window.location.pathname + window.location.search);
    return ReactGA;
  }
}
