import { openAppStore } from 'src/helpers/window';
import { Button } from './UI/Button';
import { Image } from './UI/Image';
import availableOnAppStore from 'src/assets/images/available-on-appstore.png';

export function AvailableOnAppStore() {
  return (
    <Button
      variant="ghost"
      border={'1px solid'}
      borderColor={'surface'}
      borderRadius={'10px'}
      p={'15px'}
      h={'54px'}
      _hover={{}}
      onClick={openAppStore}
      data-testid={'available-on-appstore'}
    >
      <Image src={availableOnAppStore} />
    </Button>
  );
}
