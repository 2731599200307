import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import ProfessionalData from './ProfessionalData';
import { PersonalData } from './PersonalData';
import { PlaceOfService } from './PlaceOfService';
import { CreatePassword } from './CreatePassword';

import { Box } from '../../components/UI/Box';
import { Flex } from 'src/components/UI/Flex';
import Typography from 'src/components/Typography';
import { Header } from 'src/components/Layout/Header';

import abstractBackground from 'src/assets/images/background.png';
import { PARTNERS } from '../Login/LoginBanner/LoginBanner';
import { EMSSignupBanner } from './PartnerBanner/EMSSignupBanner';
import { useNavigateSearch } from 'src/helpers/useNavigateSearch';

export enum TABS_INDEX {
  PERSONAL_DATA = 0,
  PROFESSIONAL_DATA = 1,
  PLACE_OF_SERVICE = 2,
  CREATE_PASSWORD = 3,
}

export function SignUp() {
  const [tabIndex, setTabIndex] = useState<TABS_INDEX>(TABS_INDEX.PERSONAL_DATA);
  const [isFirstStepCompleted, setIsFirstStepCompleted] = useState(false);
  const [isSecondStepCompleted, setIsSecondStepCompleted] = useState(false);
  const [isThirdStepCompleted, setIsThirdStepCompleted] = useState(false);

  const navigateWithSearch = useNavigateSearch();

  const [searchParams] = useSearchParams();

  const partner = searchParams.get('partner');

  const handleChangeTab = useCallback((index: number) => {
    setTabIndex(index);

    switch (index) {
      case TABS_INDEX.PROFESSIONAL_DATA:
        setIsFirstStepCompleted(true);
        return;
      case TABS_INDEX.PLACE_OF_SERVICE:
        setIsSecondStepCompleted(true);
        return;
      case TABS_INDEX.CREATE_PASSWORD:
        setIsThirdStepCompleted(true);
        return;
      default:
        return;
    }
  }, []);

  const handleGoBack = () => {
    navigateWithSearch('/');

    try {
      localStorage.removeItem('personal-data-form');
      localStorage.removeItem('professional-data-form');
      localStorage.removeItem('place-service-form');
    } catch (e) {
      console.error(e);
    }
  };

  const PartnerBanner = {
    [PARTNERS.EMS]: <EMSSignupBanner />,
    DEFAULT: null,
  };

  return (
    <Box
      w={'100%'}
      h={'100vh'}
      bg={'background'}
      overflowY={'auto'}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}
      backgroundImage={{ base: '', sm: `url(${abstractBackground})` }}
    >
      <Header onGoBack={handleGoBack} />
      <Flex
        w={'100%'}
        flexDir={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        p={{ base: '20px 20px 150px 20px', sm: '20px 20px' }}
      >
        {PartnerBanner[partner as keyof typeof PartnerBanner] || PartnerBanner[PARTNERS.DEFAULT]}
        <Flex
          w={{ base: '100%', sm: '450px' }}
          bg={{ base: 'background', sm: 'surface' }}
          borderRadius={'30px'}
          p={{ base: 0, sm: '50px' }}
          flexDir={'column'}
          mt={{ base: '26px', sm: '10px' }}
          boxShadow={{ base: 'none', sm: '12px 12px 40px rgba(61, 14, 107, 0.1)' }}
        >
          <Typography model={'h1'} weight={'bold'}>
            Mevo Receita Digital
          </Typography>
          <Tabs isFitted index={tabIndex} variant={'unstyled'} mb={{ base: '106px', sm: 0 }} isLazy>
            <TabPanels>
              <TabPanel p={0} m={0}>
                <PersonalData
                  onNext={() => {
                    setIsFirstStepCompleted(true);
                    handleChangeTab(TABS_INDEX.PROFESSIONAL_DATA);
                  }}
                  isStepCompleted={isFirstStepCompleted}
                />
              </TabPanel>

              <TabPanel p={0} m={0}>
                <ProfessionalData
                  onNext={() => {
                    setIsSecondStepCompleted(true);
                    handleChangeTab(TABS_INDEX.PLACE_OF_SERVICE);
                  }}
                  onGoBack={(index) => {
                    handleChangeTab(index);
                  }}
                  isStepCompleted={isSecondStepCompleted}
                />
              </TabPanel>

              <TabPanel p={0} m={0}>
                <PlaceOfService
                  onNext={() => {
                    setIsFirstStepCompleted(true);
                    handleChangeTab(TABS_INDEX.CREATE_PASSWORD);
                  }}
                  onGoBack={(index) => {
                    handleChangeTab(index);
                  }}
                  isStepCompleted={isThirdStepCompleted}
                />
              </TabPanel>

              <TabPanel p={0} m={0}>
                <CreatePassword
                  onGoBack={(index) => {
                    handleChangeTab(index);
                  }}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
    </Box>
  );
}
