import { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { FocusError } from 'focus-formik-error';
import { useNavigate } from 'react-router-dom';

import { ConfirmEmailDialog } from './ConfirmEmailDialog';

import MevoInput from 'src/components/Input';
import { Box } from 'src/components/UI/Box';
import { Flex } from 'src/components/UI/Flex';
import { FormControl } from 'src/components/UI/FormControl';
import { Button } from 'src/components/UI/Button';
import { IconButton } from 'src/components/UI/IconButton';
import { Text } from 'src/components/UI/Text';
import Typography from 'src/components/Typography';

import { Yup } from 'src/helpers/Yup';
import useLoading from 'src/helpers/useLoading';

import { ReactComponent as Logo } from 'src/assets/images/logo.svg';
import { ReactComponent as Help } from 'src/assets/icons/help.svg';

import { AuthService } from 'src/services/AuthService';

import { DialogWrappedComponentProps, useFeedbackContext } from 'src/providers/FeedbackProvider';
import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { Tracker } from 'src/shared/tracker/Tracker';

import { LoginBanner } from './LoginBanner/LoginBanner';
import { useNavigateSearch } from 'src/helpers/useNavigateSearch';
import { sleep } from 'src/helpers/sleep';
import { USER_STATUS, useAuthContext } from 'src/providers/AuthProvider';

const LoginSchema = Yup.object().shape({
  user: Yup.string().email('Informe um email válido (ex.: email@email.com).').required('Informe um email válido.'),
  password: Yup.string().required('Informe uma senha válida.'),
});

export function Login() {
  const { openToast, openBaseDialog } = useFeedbackContext();
  const { loadingPrescriberData, retrievePrescriberData } = usePrescriberContext();

  const { setUserStatus, loadingAuth, runWithLoadingAuth, setSession } = useAuthContext();
  const [loading, runWithLoading] = useLoading();

  const navigate = useNavigate();
  const navigateWithSearch = useNavigateSearch();

  const loginContainerWidth = { base: '100%', sm: '70%', md: '45%', lg: '30%' };

  const formik = useFormik({
    initialValues: {
      user: '',
      password: '',
    },
    validationSchema: LoginSchema,
    validateOnChange: false,
    async onSubmit(values) {
      runWithLoading(async () => {
        try {
          await AuthService.signIn(values.user, values.password);
          await retrievePrescriberData();
          setUserStatus(USER_STATUS.AUTHENTICATED);
          setSession(await AuthService.getSession());

          openToast('Login realizado', { type: 'success', duration: 4000 });
          navigate('/inicio/receita');
        } catch (e: any) {
          setUserStatus(USER_STATUS.NOT_AUTHENTICATED);

          if (e.message === 'Incorrect username or password.') {
            openToast('Email e/ou senha incorretos.', { type: 'error', duration: 4000 });
            return;
          }

          if (e.message === 'User is not confirmed.') {
            openBaseDialog(
              (dialogProps: DialogWrappedComponentProps) => (
                <ConfirmEmailDialog email={values.user} onResendConfirmationEmail={dialogProps.onClose} />
              ),
              {
                padding: '0px',
                width: '435px',
                height: '435px',
              }
            );
            return;
          }

          openToast(
            'Ops.. Um problema inesperado ocorreu. Aguarde alguns minutos e tente novamente. Caso o problema persista, fale com a gente.',
            { type: 'error', duration: 2000 }
          );
        }
      });
    },
  });

  const isFieldsFilled = useMemo(() => {
    return formik.values.user.length > 0 && formik.values.password.length > 0;
  }, [formik.values]);

  function handleNavigateToSignUp() {
    Tracker.trackEvent({
      event: 'Cadastro Iniciado',
      parameters: {
        'Data cadastro': new Date(),
      },
    });

    navigateWithSearch('/cadastro');
  }

  useEffect(() => {
    const run = () => {
      runWithLoadingAuth(async () => {
        try {
          const session = await AuthService.getSession();

          if (session.isValid()) {
            await sleep(1500);

            navigate('/inicio/receita');
            return;
          }
        } catch (error) {}
      }, []);
    };

    run();
  }, [navigate, runWithLoadingAuth]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        height: '100%',
      }}
    >
      <FocusError formik={formik} />

      <Flex h={'100%'} justifyContent={'center'}>
        <LoginBanner />

        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          bg={'background'}
          w={loginContainerWidth}
          p={{
            base: '20px',
            md: '20px 48px',
          }}
          position={'relative'}
        >
          <Flex position={'absolute'} top={'20px'} right={'20px'}>
            <IconButton aria-label={'help'} variant={'ghost'} onClick={() => navigate(`/ajuda`)}>
              <Help />
            </IconButton>
          </Flex>

          <Flex w={'100%'} justifyContent={'center'} mt={'40px'} mb={'40px'}>
            <Logo fill={'#3D0E6B'} />
          </Flex>

          <Flex
            borderRadius={'20px'}
            p={{
              base: '0',
              sm: '30px',
            }}
            bg={{
              base: 'transparent',
              sm: 'surface',
            }}
            flexDirection={'column'}
            boxShadow={{
              base: 'none',
              sm: '12px 12px 40px rgba(61, 14, 107, 0.1)',
            }}
          >
            <Box>
              <FormControl h={'90px'} isInvalid={!!formik.errors.user} mb={'20px'}>
                <MevoInput
                  id="user"
                  labelText="USUÁRIO"
                  h={'45px'}
                  placeholderText={'Digite o email'}
                  name={'user'}
                  type={'email'}
                  onChange={formik.handleChange}
                  onBlur={() => formik.validateField('user')}
                  errorText={formik.errors.user}
                  disabled={loadingPrescriberData}
                />
              </FormControl>

              <FormControl h={'90px'} isInvalid={!!formik.errors.password}>
                <MevoInput
                  id="password"
                  labelText="SENHA"
                  h={'45px'}
                  placeholderText={'Digite a senha'}
                  name={'password'}
                  type={'password'}
                  onChange={formik.handleChange}
                  onBlur={() => formik.validateField('password')}
                  errorText={formik.errors.password}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      e.target.blur();
                      formik.handleSubmit();
                    }
                  }}
                  disabled={loadingPrescriberData}
                />
              </FormControl>
            </Box>

            <Flex justifyContent={'flex-end'} mt={'11px'}>
              <Typography model={'body'} size={'large'} weight={'bold'} underline>
                <Text as={'span'} cursor={'pointer'} onClick={() => navigate(`/esqueceu-sua-senha`)}>
                  Esqueceu sua senha?
                </Text>
              </Typography>
            </Flex>

            <Button
              variant={isFieldsFilled ? 'solid' : 'outline-primary-light'}
              isDisabled={!isFieldsFilled}
              w={'100%'}
              h={'60px'}
              mt={{
                base: '40px',
                md: '18px',
              }}
              type={'submit'}
              isLoading={loading || loadingPrescriberData || loadingAuth}
            >
              Entrar
            </Button>
          </Flex>

          <Flex justifyContent={'center'} mt={'21px'} cursor={'pointer'} onClick={handleNavigateToSignUp}>
            <Box mr={'5px'}>
              <Typography model={'body'} size={'large'} weight={'regular'}>
                Novo por aqui?
              </Typography>
            </Box>
            <Typography model={'body'} size={'large'} weight={'bold'}>
              <u>Crie sua conta.</u>
            </Typography>
          </Flex>
        </Box>
      </Flex>
    </form>
  );
}
