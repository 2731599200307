import { SkeletonCircle } from '@chakra-ui/react';

import { Flex } from 'src/components/UI/Flex';
import { Skeleton } from 'src/components/UI/Skeleton';

export function HeaderSkeleton() {
  return (
    <Flex
      gap={'30px'}
      mt={'28px'}
      alignItems={'center'}
      flexDirection={{
        base: 'column',
        sm: 'row',
      }}
    >
      <SkeletonCircle
        size={{
          base: '124px',
          sm: '50px',
        }}
      />

      <Skeleton h={'30px'} w={'250px'} />
    </Flex>
  );
}
