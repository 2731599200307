import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { setAuthorizationHeader } from 'src/api';

export const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
  ClientId: process.env.REACT_APP_CLIENT_ID || '',
});

export const USER_DOES_NOT_HAVE_SESSION = 'USER_DOES_NOT_HAVE_SESSION';

export const AuthService = {
  signIn(
    email: string,
    password: string
  ): Promise<{ authResult: CognitoUserSession; userAttrResult: CognitoUserAttribute[] | undefined }> {
    return new Promise<{ authResult: CognitoUserSession; userAttrResult: CognitoUserAttribute[] | undefined }>(
      (resolve, reject) => {
        const cognitoUser = new CognitoUser({
          Username: email,
          Pool: userPool,
        });

        cognitoUser.authenticateUser(
          new AuthenticationDetails({
            Username: email,
            Password: password,
          }),
          {
            onSuccess(authResult) {
              setAuthorizationHeader(authResult.getAccessToken().getJwtToken());

              cognitoUser.getUserAttributes((error, userAttrResult) => {
                if (error) reject(error);
                resolve({ authResult, userAttrResult });
              });
            },
            onFailure(err) {
              reject(err);
            },
          }
        );
      }
    );
  },
  async getCurrentAuthenticatedUser(): Promise<CognitoUserAttribute[] | undefined> {
    return new Promise<CognitoUserAttribute[] | undefined>((resolve, reject) => {
      const user = userPool.getCurrentUser();

      if (user) {
        user.getSession((error: any, session: any) => {
          if (error) {
            reject(error);
          }

          if (!session) {
            return reject(USER_DOES_NOT_HAVE_SESSION);
          }

          user.getUserAttributes((err, result) => {
            if (err) {
              reject(err);
            }
            resolve(result);
          });
        });

        return;
      }
      reject(USER_DOES_NOT_HAVE_SESSION);
    });
  },
  async getSession(): Promise<CognitoUserSession> {
    return new Promise<CognitoUserSession>((resolve, reject) => {
      const user = userPool.getCurrentUser();

      if (user) {
        user.getSession((error: any, session: any) => {
          if (error) reject(error);
          resolve(session);
        });
      }

      reject(USER_DOES_NOT_HAVE_SESSION);
    });
  },
  async resendEmail(email: string) {
    return new Promise<void>((resolve, reject) => {
      var cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
      });

      cognitoUser.resendConfirmationCode(function (err, result) {
        if (err) return reject();
        resolve();
      });
    });
  },
  async forgotPassword(email: string) {
    return new Promise<void>((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
      });

      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  },
  async confirmPassword(payload: confirmPasswordPayload) {
    return new Promise<void>((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: payload.email,
        Pool: userPool,
      });

      cognitoUser.confirmPassword(payload.verificationCode, payload.newPassword, {
        onSuccess() {
          resolve();
        },
        onFailure(err) {
          reject(err);
        },
      });
    });
  },
  async signOut() {
    return new Promise<void>((resolve, reject) => {
      const user = userPool.getCurrentUser();

      if (!user) return;

      try {
        user.signOut();
        resolve();
        return;
      } catch (error) {
        reject();
        return;
      }
    });
  },
  async changePassword(currentPassword: string, newPassword: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();

      if (!cognitoUser) return;

      cognitoUser.getSession((err: any) => {
        if (err) {
          reject(err);
        }
      });

      cognitoUser.changePassword(currentPassword, newPassword, (err) => {
        if (err) {
          reject(err);
        }

        resolve();
      });
    });
  },
  async getRefreshToken() {
    return new Promise<string>((resolve, reject) => {
      const user = userPool.getCurrentUser();

      user?.getSession((error: any, session: CognitoUserSession) => {
        if (error) {
          reject(error);
        }

        resolve(session.getIdToken().getJwtToken());
      });
    });
  },
};

interface confirmPasswordPayload {
  email: string;
  newPassword: string;
  verificationCode: string;
}
