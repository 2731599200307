import MevoPill from 'src/components/Pill/Pill';
import Typography from 'src/components/Typography';
import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { Flex } from 'src/components/UI/Flex';
import { Text } from 'src/components/UI/Text';
import useLoading from 'src/helpers/useLoading';

interface CancelPrescriptionDialogProps {
  validationCode: string;
  onCancel(): Promise<void>;
  onGoBack(): void;
}

export function CancelPrescriptionDialog(props: CancelPrescriptionDialogProps) {
  const [loadingCancelPrescription, runWithLoadingCancelPrescription] = useLoading();

  const onCancelPrescription = async () => {
    runWithLoadingCancelPrescription(async () => {
      await props.onCancel();
    });
  };

  return (
    <Box>
      <Flex justifyContent={'center'}>
        <MevoPill pillColor="red" faceStyle="crazy" pillWidth={100} faceWidth={37} />
      </Flex>
      <Flex direction={'column'} gap={'10px'} mt={'20px'} mb={'20px'}>
        <Typography model="h2" weight="bold">
          Deseja realmente cancelar a receita?
        </Typography>
        <Typography model="body" size="large" weight="regular">
          Ao escolher cancelar, a situação da receita{' '}
          <Text as={'b'} fontFamily={'Roboto Mono'}>
            {props.validationCode}
          </Text>{' '}
          será alterada permanentemente.
        </Typography>
      </Flex>
      <Flex w={'100%'} gap={'10px'}>
        <Button w={'100%'} h={'60px'} onClick={props.onGoBack}>
          Voltar
        </Button>
        <Button
          w={'100%'}
          h={'60px'}
          variant="outline-error"
          onClick={onCancelPrescription}
          isLoading={loadingCancelPrescription}
        >
          Cancelar
        </Button>
      </Flex>
    </Box>
  );
}
