import { Flex } from '@chakra-ui/react';
import DocBoxIcon from 'src/components/Icons/DocBoxIcon';
import Typography from 'src/components/Typography';
import { PrescriptionReferral } from 'src/services/prescriptionHistory';

interface ReferralItemProps {
  item: PrescriptionReferral;
}

export function ReferralItem(props: ReferralItemProps) {
  const { item } = props;

  return (
    <Flex gap="10px">
      <Flex alignSelf="flex-start">
        <DocBoxIcon variantColor="secondary.400" width={23} background={{ color: 'support.surface', padding: 4 }} />
      </Flex>
      <Flex grow={1} flexDirection="column" gap="10px">
        <Typography model="body" size="large" weight="bold">
          {item.reason}
        </Typography>
        <Typography model="body" size="large" weight="regular">
          {item.clinicalHistory}
        </Typography>
      </Flex>
    </Flex>
  );
}
