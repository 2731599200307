import React from 'react';
import Typography from '../Typography';
import { Box } from '../UI/Box';
import { Flex } from '../UI/Flex';
import { SEARCH_HEIGHT } from './Search';

export function NoResultsFound() {
  return (
    <Flex
      w={'100%'}
      h={`calc(100vh - ${SEARCH_HEIGHT * 2}px)`}
      flexDir={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box mb={'5px'}>
        <Typography model={'h1'} textAlign={'center'} weight={'bold'}>
          Paciente não encontrado!
        </Typography>
      </Box>
      <Box>
        <Typography model={'body'} size={'large'} textAlign={'center'} weight={'regular'}>
          Refaça a busca ou crie um novo paciente.
        </Typography>
      </Box>
    </Flex>
  );
}
