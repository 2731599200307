import React from 'react';
import ExitIcon from 'src/components/Icons/ExitIcon';
import MevoPill from 'src/components/Pill/Pill';
import Typography from 'src/components/Typography';
import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { Flex } from 'src/components/UI/Flex';

interface ConfirmAccountDeletionDialogProps {
  onConfirm(): void;
  onCancel(): void;
}

export function ConfirmAccountDeletionDialog(props: ConfirmAccountDeletionDialogProps) {
  return (
    <Box bg={'surface'} p={'30px'} borderRadius={'30px'} maxWidth={'400px'}>
      <Flex justifyContent={'flex-end'}>
        <ExitIcon variantColor="secondary.main" onClick={props.onCancel} />
      </Flex>
      <Flex justifyContent={'center'} mb={'30px'} mt={'10px'}>
        <MevoPill pillColor="red" faceStyle="crazy" pillWidth={100} faceWidth={37} />
      </Flex>
      <Box mb={'10px'}>
        <Typography model="h2" weight="bold">
          Leia com atenção!
        </Typography>
      </Box>
      <Box mb={'20px'}>
        <Typography model="body" size="large" weight="regular">
          Ao escolher excluir sua conta você perderá todo o histórico de prescrições e o cadastro de todos os seus
          pacientes permanentemente
        </Typography>
      </Box>

      <Box>
        <Button onClick={props.onConfirm} variant={'outline-error'} w={'100%'} h={'60px'} mb={'10px'} tabIndex={-1}>
          Confirmar e excluir
        </Button>
        <Button onClick={props.onCancel} w={'100%'} h={'60px'}>
          Não excluir minha conta!
        </Button>
      </Box>
    </Box>
  );
}
