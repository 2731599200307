import { GAModule } from './GAModule';
import { MixpanelModule } from './MixpanelModule';
import { AbstractTracker, TrackEventParameters } from './AbstractTracker';

/**
 * Essa daqui é a classe que deve ser utilizar para realizar o tracking dos eventos
 * Basicamente a ideia é concentrar vários trackers diferentes que precisarmos, para que possamos chamar o método
 * apenas uma vez e enviá-lo para mais de um lugar ao mesmo tempo
 */
class LogTracker extends AbstractTracker {
  trackers: Array<AbstractTracker> = [];

  init() {
    const gaModule = new GAModule();
    this.trackers.push(gaModule);

    const mixpanelModule = new MixpanelModule();

    this.trackers.push(mixpanelModule);
  }

  trackEvent(parameters: TrackEventParameters) {
    this.trackers.forEach((tracker) => tracker.trackEvent(parameters));
  }

  peopleSet(event: string, parameter: any): void {
    this.trackers.forEach((tracker) => tracker.peopleSet(event, parameter));
  }

  peopleSetOnce(event: string, parameter: any): void {
    this.trackers.forEach((tracker) => tracker.peopleSetOnce(event, parameter));
  }

  identify(identifier: string): void {
    this.trackers.forEach((tracker) => tracker.identify(identifier));
  }

  register(parameters: object): void {
    this.trackers.forEach((tracker) => tracker.register(parameters));
  }
}

export const Tracker = new LogTracker();

Tracker.init();
