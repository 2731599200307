import { Box, Flex } from '@chakra-ui/react';
import { Medicine } from './Medicine';
import { PrescriptionMedicine, ManualMedicine as ManualMedicineInterface } from 'src/services/prescriptionHistory';
import { ManualMedicine } from './ManualMedicine';
interface PrescriptionProps {
  medicines: PrescriptionMedicine[];
  manualMedicines: ManualMedicineInterface[];
}

export function Prescription(props: PrescriptionProps) {
  const { medicines, manualMedicines } = props;

  return (
    <Box>
      <Flex flexDirection="column" gap="20px">
        {medicines.map((item) => (
          <Medicine medicine={item} key={item.Label} />
        ))}
        {manualMedicines.map((item) => (
          <ManualMedicine manualMedicine={item} key={item.manualMedicineId} />
        ))}
      </Flex>
    </Box>
  );
}
