import { getStringColor } from 'src/theme/colors';
import styled from 'styled-components';

interface StyledIconContainerProps {
  hasClick: boolean;
  useBackground: string;
  width: number;
  disabled: boolean;
  fullColorStyle: string;
  styledPlaces: string;
  parsedVariantColor: string;
  fullColor: boolean;
  styledBase: string;
  baseColor: string | undefined;
  customStyle: string;
}

export const StyledIconContainer = styled.div<StyledIconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.hasClick ? 'pointer' : 'normal')};
  ${(props) => props.useBackground && `background: ${props.useBackground};`}

  & > svg {
    ${(props) => props.width && `width: ${props.width}px;`}
    height: auto;
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
    ${(props) => props.fullColorStyle}

    & > ${(props) => props.styledPlaces} {
      fill: ${(props) => props.parsedVariantColor};
      opacity: ${(props) => (props.fullColor ? 0.5 : 1)};
    }

    & > ${(props) => props.styledBase} {
      fill: ${(props) => getStringColor(props.baseColor)};
    }

    ${(props) => props.customStyle}
  }
`;
