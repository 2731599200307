/**
 *
 * @param fullName
 * @example getFirstAndLastWords("Caio Vinícius de Souza Pereira") return "Caio Pereira"
 */

export const getFirstAndLastWords = (text: string) => {
  if (!text) return '';
  return text.split(/(\s).+\s/).join('');
};
