import { useState } from 'react';
import ArrowIcon from '../Icons/ArrowIcon';
import Typography from '../Typography';
import { Flex } from '../UI/Flex';
import { IconButton } from '../UI/IconButton';
import { Spinner } from '../UI/Spinner';
import './index.css';

interface HiChatProps {
  isOpen: boolean;
  onClose(): void;
}

export default function HiChat(props: HiChatProps) {
  const [loading, setLoading] = useState(true);

  return (
    <Flex
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent="center"
      className={`hi-chat-container ${props.isOpen ? 'opened' : 'closed'}`}
      bottom={'12px'}
      right={'12px'}
    >
      <Flex
        borderTopEndRadius={'10px'}
        borderTopLeftRadius={'10px'}
        w={'100%'}
        h={'40px'}
        bg={'secondary.100'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Typography>Ajuda</Typography>
        <IconButton
          size={'xs'}
          aria-label={'hide-chat'}
          variant={'ghost'}
          bg={'#ffd3de'}
          borderRadius={'50%'}
          position={'absolute'}
          right={'10px'}
          onClick={props.onClose}
        >
          <ArrowIcon direction="down" width={7} variantColor={'primary.main'} />
        </IconButton>
      </Flex>

      {loading && (
        <Flex className="hi-chat-loading">
          <Spinner color={'secondary.100'} size="md" />
        </Flex>
      )}

      <iframe
        src="https://www8.directtalk.com.br/chat31/?idd=AE4B694792BA1712803A"
        frameBorder={0}
        className="hi-chat"
        id="hi-chat-iframe"
        title="hi-chat"
        onLoad={() => setLoading(false)}
      />
    </Flex>
  );
}
