import { Tracker } from 'src/shared/tracker/Tracker';

import React, { useEffect, useState } from 'react';
import { Flex } from './UI/Flex';
import { Spinner } from './UI/Spinner';
import { sendEventOpenWhatsapp } from 'src/helpers/window';

export enum PRESCRIPTION_POST_MESSAGE_TYPES {
  cancel = 'cancel',
  excluded = 'excluded',
  prescricao = 'prescricao',
  whatsapp = 'whatsapp',
}

interface PrescriptionViewerProps {
  url: string;
  onClose(eventType: PRESCRIPTION_POST_MESSAGE_TYPES): void;
  isUserOnApp: boolean;
}

export function PrescriptionViewer(props: PrescriptionViewerProps) {
  const { url, onClose } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const eventListener = (event: any) => {
      const validEvents = ['cancel', 'excluded', 'prescricao', 'whatsapp'];
      if (validEvents.includes(event.data.type)) {
        const trackEventValue = event.data.type === 'excluded' ? 'Receita Cancelada' : 'Receita emitida';

        if (event.data.type === 'whatsapp' && props.isUserOnApp) {
          sendEventOpenWhatsapp(event.data.paramsUrl);
        }

        Tracker.trackEvent({
          event: trackEventValue,
          parameters: {
            data: new Date(),
            isOnApp: props.isUserOnApp,
          },
        });

        onClose(event.data.type);
      }
    };
    window.addEventListener('message', eventListener, false);
    return () => {
      window.removeEventListener('message', eventListener);
    };
  }, [onClose, props.isUserOnApp]);

  return (
    <Flex flex={1} bg={'transparent'} padding={{ base: 0, md: '20px' }}>
      <Flex overflow={'hidden'} backgroundColor={'surface'} borderRadius={{ base: 0, md: '30px' }} flex={1}>
        {loading && (
          <Flex
            pos={'absolute'}
            top={0}
            right={0}
            bottom={0}
            left={0}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Spinner color={'primary.1000'} size="xl" />
          </Flex>
        )}

        <iframe
          title="Mevo Prescritores"
          src={url}
          style={{
            width: '100%',
            height: '100%',
            margin: 0,
            display: 'block',
            border: 'none',
          }}
          onLoad={() => setLoading(false)}
        />
      </Flex>
    </Flex>
  );
}
