import { useNavigate } from 'react-router-dom';

import { TABS_INDEX } from './SignUp';

import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { Text } from 'src/components/UI/Text';

type buttonPosition = 'fixed' | 'static';

interface ResponsivePositionStyle {
  base: buttonPosition;
  sm: buttonPosition;
}

interface ResponsivePaddingStyle {
  base: string;
  sm: string;
}

interface NextButtonProps {
  isLoading?: boolean;
  position?: ResponsivePositionStyle;
  padding?: ResponsivePaddingStyle;
  currentIndex: TABS_INDEX;
}

export function NextButton(props: NextButtonProps) {
  const navigate = useNavigate();

  return (
    <Box
      w={'100%'}
      pos={props.position ? { ...props.position } : 'absolute'}
      left={0}
      right={0}
      bottom={'0px'}
      bg={{ base: 'background', sm: 'surface' }}
      zIndex={10}
      p={props.padding ? { ...props.padding } : 0}
    >
      <Button h={'60px'} w={'100%'} mb={'22px'} type={'submit'} isLoading={props.isLoading}>
        Continuar
      </Button>

      {props.currentIndex === TABS_INDEX.PERSONAL_DATA && (
        <Button bottom={'10px'} variant="ghost" p={0} m={0} h={'20px'} w={'100%'}>
          <Text
            cursor={'pointer'}
            onClick={() => navigate(`/`)}
            textAlign={'center'}
            fontSize={'14px'}
            fontWeight={400}
            fontFamily={'Ambit'}
          >
            Já sou cadastrado.
            <Text ml={'5px'} as={'u'} fontSize={'14px'} fontFamily={'Ambit-Bold'}>
              Quero fazer login!
            </Text>
          </Text>
        </Button>
      )}
    </Box>
  );
}
