import React from 'react';
import { ReactComponent as Logo } from '../../assets/icons/powered_mevo.svg';
import { Box } from '../../components/UI/Box';
import { useIsMobile } from '../../helpers/useIsMobile';
import { Text } from '../../components/UI/Text';

export function NotFound() {
  const isMobile = useIsMobile();

  return (
    <Box
      width={'100%'}
      height={'100%'}
      bg={'primary.50'}
      color={'white'}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      flexDirection={'column'}
    >
      <Box h={'80px'} bg={'#fff'} w={'100%'}></Box>

      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
        <Text
          lineHeight={isMobile ? '140px' : '200px'}
          fontFamily={'Ambit'}
          fontSize={isMobile ? '120px' : '200px'}
          fontWeight={700}
          color={'primary.1000'}
        >
          404
        </Text>
        <Text fontFamily={'Ambit'} fontSize={isMobile ? '16px' : '28px'} fontWeight={600} color={'primary.1000'}>
          Ops.. Parece que não há nada aqui!
        </Text>
      </Box>

      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} h={'80px'} bg={'#fff'} w={'100%'}>
        <Logo />
      </Box>
    </Box>
  );
}
