import * as React from 'react';
import { ReactComponent as ArrowIconSVG } from '../../assets/icons/mevo/ic_arrow.svg';
import { ReactComponent as ArrowIconFilledSVG } from '../../assets/icons/mevo/ic_arrow_filled.svg';
import IconPattern, { IconProps } from './_IconPattern';

interface ArrowIconProps extends IconProps {
  direction?: 'up' | 'left' | 'right' | 'down'; // padrão 'left'
  filled?: boolean;
}

const ArrowIcon: React.FunctionComponent<ArrowIconProps> = (props) => {
  const directionStyle = React.useMemo(() => {
    switch (props.direction) {
      case 'up':
        return `transform: rotate(90deg);`;
      case 'right':
        return `transform: scaleX(-1); ${props.filled ? '' : 'margin-left: 2px;'}`;
      case 'down':
        return `transform: rotate(-90deg);`;
      default:
        return `${props.filled ? '' : 'margin-right: 2px;'}`;
    }
  }, [props.direction, props.filled]);

  return (
    <IconPattern
      component={props.filled ? ArrowIconFilledSVG : ArrowIconSVG}
      variantPlaces={1}
      {...props}
      customStyle={directionStyle}
    />
  );
};
export default ArrowIcon;
