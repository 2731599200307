import { api } from 'src/api';

export const ImageService = {
  async uploadImage(image: FormData, prescriberId: string): Promise<{ url: string }> {
    const { data } = await api.put(`/prescriber/${prescriberId}/upload-image`, image, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data;
  },
};
