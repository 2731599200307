import { Tracker } from 'src/shared/tracker/Tracker';

enum POST_MESSAGE_TYPES {
  SUCCESFULLY_SIGNED_IN = 'SUCCESFULLY_SIGNED_IN',
  FAILURE_SIGNED_IN = 'FAILURE_SIGNED_IN',
  USER_SHOULD_LOGOUT = 'USER_SHOULD_LOGOUT',
  OPEN_WHATSAPP = 'OPEN_WHATSAPP',
}

export enum OS_TYPES {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  UNKNOWN = 'UNKNOWN',
}

export const APP_STORE_URL = 'https://apps.apple.com/br/app/mevo-profissionais/id6468836074';
export const GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.developersmevo.mevoreceitadigitalmobileapp';

export const safeGetFromLocalStorage = (key: string): string | null => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

export const safeSetInLocalStorage = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    //  não precisamos fazer nada aqui, se deu erro provavelmente está em janela anonima ou cookie bloqueado
  }
};

export const safeGetFromSessionStorage = (key: string): string | null => {
  try {
    return sessionStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

export const safeSetInSessionStorage = (key: string, value: string) => {
  try {
    sessionStorage.setItem(key, value);
  } catch (e) {
    //  não precisamos fazer nada aqui, se deu erro provavelmente está em janela anonima ou cookie bloqueado
  }
};

export const appSuccesfullySignedIn = () => {
  const win: any = window;

  try {
    win.ReactNativeWebView.postMessage(POST_MESSAGE_TYPES.SUCCESFULLY_SIGNED_IN);
  } catch (e) {}
};

export const sendEventOpenWhatsapp = (paramsUrl: string) => {
  const win: any = window;

  try {
    win.ReactNativeWebView.postMessage(JSON.stringify({ type: POST_MESSAGE_TYPES.OPEN_WHATSAPP, paramsUrl }));
  } catch (e) {}
};

export const appFailureSignedIn = () => {
  const win: any = window;

  try {
    win.ReactNativeWebView.postMessage(POST_MESSAGE_TYPES.FAILURE_SIGNED_IN);
  } catch (e) {}
};

export const appShouldLogoutUser = () => {
  const win: any = window;

  try {
    win.ReactNativeWebView.postMessage(POST_MESSAGE_TYPES.USER_SHOULD_LOGOUT);
  } catch (e) {}
};

export const scrollToContainer = (props?: { containerId?: string; containerName?: string }) => {
  try {
    let container: any = window;

    if (!props) {
      container.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      return;
    }

    if (props.containerId) {
      container = document.getElementById(props.containerId);
    }

    if (props.containerName) {
      container = document.getElementById(props.containerName);
    }

    container.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  } catch (error) {}
};

export const verifyOSSystem = () => {
  var userAgent = navigator.userAgent;

  if (/android/i.test(userAgent)) {
    return OS_TYPES.ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return OS_TYPES.IOS;
  }

  return OS_TYPES.UNKNOWN;
};

export const openAppStore = () => {
  Tracker.trackEvent({
    event: 'Proceed to App Store',
    parameters: {
      Loja: 'Apple',
    },
  });

  window.open(APP_STORE_URL, '_blank', 'noopener');
};

//precisamos deste metodo pois os browsers atualmente nao deixam executar window.open por motivos de seguranca
export const redirectToAppStore = () => {
  Tracker.trackEvent({
    event: 'Proceed to App Store',
    parameters: {
      Loja: 'Apple',
    },
  });

  window.location.href = APP_STORE_URL;
};

export const openPlayStore = () => {
  Tracker.trackEvent({
    event: 'Proceed to App Store',
    parameters: {
      Loja: 'Google',
    },
  });

  window.open(GOOGLE_PLAY_URL, '_blank', 'noopener');
};

//precisamos deste metodo pois os browsers atualmente nao deixam executar window.open por motivos de seguranca
export const redirectPlayStore = () => {
  Tracker.trackEvent({
    event: 'Proceed to App Store',
    parameters: {
      Loja: 'Google',
    },
  });

  window.location.href = GOOGLE_PLAY_URL;
};
