import { Fade, useBreakpointValue } from '@chakra-ui/react';

import { Flex } from 'src/components/UI/Flex';
import AvatarIcon from 'src/components/Icons/DoctorAvatarIcon';
import Typography from 'src/components/Typography';
import { HeaderSkeleton } from './skeletons/HeaderSkeleton';

interface HeaderProps {
  isLoadingData: boolean;
  patientName: string | null;
}

export function Header(props: HeaderProps) {
  const avatarSize = useBreakpointValue({ base: 124, sm: 50 }, { ssr: true });

  if (props.isLoadingData) {
    return <HeaderSkeleton />;
  }

  return (
    <Fade in={!!props.patientName} unmountOnExit>
      <Flex
        gap={'30px'}
        mt={'28px'}
        alignItems={'center'}
        flexDirection={{
          base: 'column',
          sm: 'row',
        }}
      >
        <AvatarIcon width={avatarSize} />

        <Typography model={'h1'} fontSize={30} textAlign="center">
          {props.patientName}
        </Typography>
      </Flex>
    </Fade>
  );
}
