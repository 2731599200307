export const ETHNICITIES = [
  { label: 'Não Informado', value: 'Não Informado' },
  { label: 'Branca', value: 'Branca' },
  { label: 'Preta', value: 'Preta' },
  { label: 'Parda', value: 'Parda' },
  { label: 'Amarela', value: 'Amarela' },
  { label: 'Indígena', value: 'Indígena' },
];

export const DEFAULT_ETHNICITY = 'Não Informado';
