import { publicApi } from 'src/publicApi';

export const TermsOfUseService = {
  async getTermsOfUse(): Promise<TermsOfUse> {
    const { data } = await publicApi.get('/consentterm');
    return data.data;
  },
};

export interface TermsOfUse {
  _id: string;
  termUrl: string;
  version: string;
  termReleaseDate: string;
  active: boolean;
}
