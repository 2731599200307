import { Tab } from '@chakra-ui/react';

import Typography from 'src/components/Typography';
import ArrowRightFilledIcon from 'src/components/Icons/ArrowIconFilledRight';
import { Tooltip } from './UI/Tooltip';

interface TabFormButtonProps {
  text: string;
  isDisabled: boolean;
}

export function TabFormButton(props: TabFormButtonProps) {
  return (
    <Tooltip
      label={
        props.isDisabled && (
          <Typography model={'body'} size="normal" color="primary.500" weight="regular">
            Preencha os dados básicos para prosseguir
          </Typography>
        )
      }
      backgroundColor={'white'}
      p={'5px 10px'}
      borderRadius={'10px'}
    >
      <Tab
        bgColor={'white'}
        borderRadius={'10px'}
        boxShadow={'3px 3px 10px #3D0E6B1A'}
        minW={{
          base: 'auto',
          sm: 'auto',
          md: '335px',
        }}
        h={'80px'}
        p={'0 30px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        border={'1px'}
        borderColor={'transparent'}
        _selected={{
          backgroundColor: 'surface',
          border: '1px',
          borderColor: 'secondary.400',
        }}
        isDisabled={props.isDisabled}
        _disabled={{
          opacity: '0.5',
          boxShadow: 'none',
          cursor: 'not-allowed',
          borderColor: 'primary.100',
        }}
      >
        <Typography fontSize={18}>{props.text}</Typography>
        <ArrowRightFilledIcon width={20} />
      </Tab>
    </Tooltip>
  );
}
