import { useEffect, useState } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';

import { PersonalDataFormSkeleton } from './skeletons/PersonalDataFormSkeleton';

import MevoInput from 'src/components/Input';
import Typography from 'src/components/Typography';
import SelectGender from 'src/components/UI/SelectGender';
import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { Flex } from 'src/components/UI/Flex';
import { MASKS } from 'src/components/UI/Input/MaskedInput';
import { FormControl } from 'src/components/UI/FormControl';

import { Yup } from 'src/helpers/Yup';
import { validateCPF } from 'src/helpers/cpf';
import { onlyDigits } from 'src/helpers/string';
import { validateCNS } from 'src/helpers/validateCNS';
import { DateFromFormat, DateFromISOToBrazilianFormat, validateDateFromBrazilianForm } from 'src/helpers/Date';
import { removeSpacesAndSpecialChars } from 'src/helpers/removeSpacesAndSpecialChars';

import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';

import { PrescriberService } from 'src/services/prescriber';
import { DateTime } from 'luxon';

interface PersonalDataFormFields {
  fullName: string;
  document: string;
  cellphoneNumber: string;
  email: string;
  birthDate: string;
  sex: 'Masculino' | 'Feminino';
  CNS: string;
}

const personalDataFormSchema = Yup.object().shape({
  fullName: Yup.string().required('Informe seu nome completo.'),
  document: Yup.string()
    .required('Informe um número de CPF válido.')
    .test('validate cpf', 'Informe um número de CPF válido.', (document) => {
      return validateCPF(document || '');
    }),
  cellphoneNumber: Yup.string()
    .required('Informe um número de celular válido.')
    .test('validate phoneNumber', 'Informe um número de celular válido.', (cellphoneNumber) => {
      if (!cellphoneNumber) return false;
      return onlyDigits(cellphoneNumber).length === 11;
    }),
  email: Yup.string().email('Informe um email válido.').required('Informe um email válido.'),
  birthDate: Yup.string().test('match', 'Informe uma data de nascimento válida.', (birthDate) => {
    return validateDateFromBrazilianForm(birthDate);
  }),
  sex: Yup.string(),
  CNS: Yup.string().test('validate CNS', 'Informe um CNS válido', (doctorCNS) => {
    if (!doctorCNS) return true;

    return validateCNS(doctorCNS);
  }),
});

const initialValues: PersonalDataFormFields = {
  fullName: '',
  document: '',
  cellphoneNumber: '',
  email: '',
  birthDate: '',
  sex: 'Masculino',
  CNS: '',
};

interface PersonalDataFormProps {
  onEdit?(): void;
}

export function PersonalDataForm(props: PersonalDataFormProps) {
  const [defaultValues, setDefaultValues] = useState<PersonalDataFormFields>(initialValues);
  const { prescriberData, setPrescriberData } = usePrescriberContext();
  const { openToast } = useFeedbackContext();

  const { values, errors, isSubmitting, handleSubmit, setFieldValue, setValues, validateField, handleChange } =
    useFormik<PersonalDataFormFields>({
      initialValues: initialValues,
      validationSchema: personalDataFormSchema,
      validateOnChange: false,
      onSubmit: onSubmit,
    });

  async function onSubmit(data: PersonalDataFormFields) {
    try {
      if (!prescriberData) return;

      const date = DateFromFormat(values.birthDate || '', 'dd/MM/yyyy');

      await PrescriberService.updatePrescriber(prescriberData._id, {
        ...prescriberData,
        ...data,
        fullName: removeSpacesAndSpecialChars(data.fullName),
        birthDate: DateTime.fromISO(date).toJSDate(),
      });

      setPrescriberData({
        ...prescriberData,
        ...data,
        fullName: removeSpacesAndSpecialChars(data.fullName),
        birthDate: date,
      });

      openToast('Dados salvos com sucesso.', {
        type: 'success',
        duration: 2000,
      });

      if (props.onEdit) {
        props.onEdit();
      }
    } catch (error) {
      openToast('Ocorreu um erro ao salvar seus dados. Tente novamente em instantes.', {
        type: 'error',
        duration: 2000,
      });
    }
  }

  useEffect(() => {
    if (!prescriberData) return;

    setValues({
      fullName: prescriberData.fullName,
      document: prescriberData.document,
      cellphoneNumber: prescriberData.cellphoneNumber,
      email: prescriberData.email,
      birthDate: prescriberData.birthDate ? DateFromISOToBrazilianFormat(String(prescriberData.birthDate)) : '',
      sex: prescriberData.sex,
      CNS: prescriberData.CNS,
    });

    setDefaultValues({
      fullName: prescriberData.fullName,
      document: prescriberData.document,
      cellphoneNumber: prescriberData.cellphoneNumber,
      email: prescriberData.email,
      birthDate: prescriberData.birthDate ? DateFromISOToBrazilianFormat(String(prescriberData.birthDate)) : '',
      sex: prescriberData.sex,
      CNS: prescriberData.CNS,
    });
  }, [prescriberData, setValues]);

  const isSameData = isEqual(defaultValues, values);

  if (!prescriberData) {
    return <PersonalDataFormSkeleton />;
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        height: '100%',
      }}
      data-testId={'personal-data-form'}
    >
      <Flex
        flexDirection={'column'}
        gap={'20px'}
        bgColor={{
          base: 'transparent',
          lg: 'white',
        }}
        borderRadius={'20px'}
        p={{ base: '0px', sm: '32px' }}
        h={'full'}
      >
        <Box display={{ base: 'none', sm: 'block' }}>
          <Typography model={'h2'} size={'large'} weight={'bold'}>
            Dados pessoais
          </Typography>
        </Box>

        <FormControl isInvalid={!!errors.fullName}>
          <MevoInput
            name={'fullName'}
            placeholder={'Digite seu nome'}
            labelText={'Nome Completo*'}
            value={values.fullName}
            onChange={handleChange}
            onBlur={() => validateField('fullName')}
            errorText={errors.fullName}
            m={0}
            autoFocus
          />
        </FormControl>

        <SimpleGrid
          columns={{
            base: 1,
            xl: 2,
          }}
          gap={'20px'}
        >
          <FormControl isInvalid={!!errors.document}>
            <MevoInput
              name={'document'}
              placeholder={'000.000.000-00'}
              labelText={'CPF*'}
              value={values.document}
              mask={MASKS.CPF}
              onChange={handleChange}
              onBlur={() => validateField('document')}
              errorText={errors.document}
              isDisabled={true}
              m={0}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.cellphoneNumber}>
            <MevoInput
              name={'cellphoneNumber'}
              placeholder={'(+55) 0 0000-0000'}
              labelText={'Celular*'}
              mask={MASKS.BRAZIL_PHONE}
              value={values.cellphoneNumber}
              onChange={handleChange}
              onBlur={() => validateField('cellphoneNumber')}
              errorText={errors.cellphoneNumber}
              m={0}
            />
          </FormControl>
        </SimpleGrid>

        <SimpleGrid
          columns={{
            base: 1,
            xl: 2,
          }}
          gap={'20px'}
        >
          <FormControl isInvalid={!!errors.email}>
            <MevoInput
              name={'email'}
              placeholder={'Digite seu email'}
              labelText={'Email*'}
              type={'email'}
              value={values.email}
              onChange={handleChange}
              onBlur={() => validateField('email')}
              errorText={errors.email}
              isDisabled={true}
              m={0}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.birthDate}>
            <MevoInput
              name={'birthDate'}
              placeholder={'Digite sua data de nascimento'}
              labelText={'Data de nascimento'}
              mask={MASKS.BIRTH_DATE}
              maskChar={''}
              value={values.birthDate}
              onChange={handleChange}
              onBlur={() => validateField('birthDate')}
              errorText={errors.birthDate}
              m={0}
            />
          </FormControl>
        </SimpleGrid>

        <SimpleGrid
          columns={{
            base: 1,
            xl: 2,
          }}
          gap={'20px'}
        >
          <Box>
            <FormControl isInvalid={!!errors.sex}>
              <SelectGender
                label={'Sexo'}
                placeholder={'Selecione o sexo'}
                value={values.sex}
                onChange={(value) => setFieldValue('sex', value)}
                errorMessage={errors.sex}
              />
            </FormControl>
          </Box>

          <FormControl isInvalid={!!errors.CNS}>
            <MevoInput
              name={'CNS'}
              placeholder={'000.000.000.000.000'}
              labelText={'Cartão nacional de saúde (CNS)'}
              mask={MASKS.CNS}
              value={values.CNS}
              onChange={handleChange}
              onBlur={() => validateField('CNS')}
              errorText={errors.CNS}
              m={0}
            />
          </FormControl>
        </SimpleGrid>

        <Button
          type={'submit'}
          isLoading={isSubmitting}
          isDisabled={isSameData}
          w={{
            base: 'full',
            md: '150px',
          }}
          h={'60px'}
          alignSelf={'flex-end'}
          mt={'auto'}
        >
          Salvar
        </Button>
      </Flex>
    </form>
  );
}
