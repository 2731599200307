import React from 'react';
import { useLocation } from 'react-router-dom';
import { Flex } from '../../UI/Flex';
import HistoricIcon from '../../Icons/HistoricIcon';
import UserIcon from '../../Icons/UserIcon';
import PillsIcon from '../../Icons/PillsIcon';
import Typography from '../../Typography';
import { Box } from '../../UI/Box';
import MenuSandwichIcon from '../../Icons/MenuSandwichIcon';
import { Button } from '../../UI/Button';
import {
  NOT_OPEN_BOTTOM_BAR_HEIGHT,
  OPEN_BOTTOM_BAR_HEIGHT,
  usePrescriberContext,
} from 'src/providers/PrescriberProvider';
import DoctorIcon from '../../Icons/DoctorIcon';
import TutorialIcon from '../../Icons/TutorialIcon';
import CallCenterIcon from '../../Icons/CallCenterIcon';
import { AnimatePresence, motion } from 'framer-motion';
import { BottomTabButton } from './BottomTabButton';

export const BOTTOM_TAB_PADDING_BOTTOM = 5;

export function BottomTabNavigation() {
  const { search } = useLocation();

  const { bottomBarHeight, setBottomBarHeight } = usePrescriberContext();

  const isBottomBarOpened = bottomBarHeight === OPEN_BOTTOM_BAR_HEIGHT;

  const handleCloseBottomBar = async () => {
    setBottomBarHeight(NOT_OPEN_BOTTOM_BAR_HEIGHT);
  };

  return (
    <Box
      w={'100%'}
      zIndex={3}
      backgroundColor={'surface'}
      borderRadius={'20px 20px 0px 0px'}
      boxShadow={'0px -15px 100px 0px rgba(255, 182, 191, 0.3)'}
      p={'5px 5px 15px 5px'}
      pos={'fixed'}
      bottom={0}
    >
      <Flex justifyContent={'space-between'} alignItems={'center'} gap={'5px'}>
        <BottomTabButton
          pathTo={`/inicio/receita${search}`}
          onClick={handleCloseBottomBar}
          label={
            <Box>
              <PillsIcon variantColor="support.blue" width={20} />
              <Box mt={'5px'}>
                <Typography whiteSpace={'pre-wrap'} model={'body'} size={'normal'} textAlign={'center'} weight="bold">
                  Prescrição
                </Typography>
              </Box>
            </Box>
          }
        />
        <BottomTabButton
          pathTo={`/inicio/gerenciar-pacientes${search}`}
          onClick={handleCloseBottomBar}
          label={
            <Box>
              <UserIcon variantColor="support.blue" width={20} />
              <Box mt={'5px'}>
                <Typography whiteSpace={'pre-wrap'} model={'body'} size={'normal'} textAlign={'center'} weight="bold">
                  Paciente
                </Typography>
              </Box>
            </Box>
          }
        />
        <BottomTabButton
          pathTo={`/inicio/historico${search}`}
          onClick={handleCloseBottomBar}
          label={
            <Box>
              <HistoricIcon variantColor="support.blue" width={20} />
              <Box mt={'5px'}>
                <Typography whiteSpace={'pre-wrap'} model={'body'} size={'normal'} textAlign={'center'} weight="bold">
                  Historico
                </Typography>
              </Box>
            </Box>
          }
        />
        {!isBottomBarOpened && (
          <Button
            variant="ghost"
            w={'100%'}
            height={'100%'}
            borderRadius={'20px'}
            onClick={() => setBottomBarHeight(OPEN_BOTTOM_BAR_HEIGHT)}
          >
            <Box>
              <MenuSandwichIcon variantColor="support.blue" width={20} />
              <Box mt={'5px'}>
                <Typography whiteSpace={'pre-wrap'} model={'body'} size={'normal'} textAlign={'center'} weight="bold">
                  mais
                </Typography>
              </Box>
            </Box>
          </Button>
        )}
      </Flex>

      <AnimatePresence>
        {isBottomBarOpened && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
              height: 0,
            }}
            transition={{
              opacity: {
                duration: 0.2,
              },
              height: {
                duration: 0.3,
              },
            }}
            style={{ display: 'flex', gap: '5px', overflow: 'hidden' }}
          >
            <BottomTabButton
              pathTo={`/inicio/meus-dados${search}`}
              onClick={handleCloseBottomBar}
              label={
                <Box>
                  <DoctorIcon variantColor="support.blue" width={20} />
                  <Box mt={'5px'}>
                    <Typography
                      whiteSpace={'pre-wrap'}
                      model={'body'}
                      size={'normal'}
                      textAlign={'center'}
                      weight="bold"
                    >
                      Meus dados
                    </Typography>
                  </Box>
                </Box>
              }
            />

            <BottomTabButton
              pathTo={`/inicio/tutorial${search}`}
              onClick={handleCloseBottomBar}
              label={
                <Box>
                  <TutorialIcon variantColor={'support.blue'} width={20} />
                  <Box mt={'5px'}>
                    <Typography
                      whiteSpace={'pre-wrap'}
                      model={'body'}
                      size={'normal'}
                      textAlign={'center'}
                      weight="bold"
                    >
                      Tutoriais
                    </Typography>
                  </Box>
                </Box>
              }
            />
            <BottomTabButton
              pathTo={`/inicio/ajuda${search}`}
              onClick={handleCloseBottomBar}
              label={
                <Box>
                  <CallCenterIcon variantColor={'support.blue'} width={20} />
                  <Box mt={'5px'}>
                    <Typography
                      whiteSpace={'pre-wrap'}
                      model={'body'}
                      size={'normal'}
                      textAlign={'center'}
                      weight="bold"
                    >
                      Precisando de ajuda?
                    </Typography>
                  </Box>
                </Box>
              }
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
}
