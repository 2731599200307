import { SimpleGrid } from '@chakra-ui/react';

import { Flex } from 'src/components/UI/Flex';
import { Skeleton } from 'src/components/UI/Skeleton';

export function ProfessionalDataFormSkeleton() {
  return (
    <Flex
      flexDirection={'column'}
      gap={'20px'}
      bgColor={{
        base: 'transparent',
        xl: 'white',
      }}
      borderRadius={'20px'}
      p={{ base: '0px', sm: '32px' }}
      h={'full'}
    >
      <Skeleton h={'30px'} w={'30%'} />

      <Flex flexDirection={'column'} gap={'10px'}>
        <Skeleton h={'10px'} w={'120px'} />

        <Skeleton h={'50px'} borderRadius={'lg'} />
      </Flex>

      <SimpleGrid
        columns={{
          base: 1,
          lg: 2,
        }}
        gap={'20px'}
      >
        <Flex flexDirection={'column'} gap={'10px'}>
          <Skeleton h={'10px'} w={'120px'} />

          <Skeleton h={'50px'} borderRadius={'lg'} />
        </Flex>

        <Flex flexDirection={'column'} gap={'10px'}>
          <Skeleton h={'10px'} w={'120px'} />

          <Skeleton h={'50px'} borderRadius={'lg'} />
        </Flex>
      </SimpleGrid>

      <Flex flexDirection={'column'} gap={'10px'}>
        <Skeleton h={'10px'} w={'120px'} />

        <Skeleton h={'50px'} borderRadius={'lg'} />
      </Flex>

      <Skeleton
        w={{
          base: 'full',
          sm: '150px',
        }}
        h={'60px'}
        alignSelf={'flex-end'}
        mt={'auto'}
        borderRadius={'full'}
      />
    </Flex>
  );
}
