import { Button } from './UI/Button';
import { Image } from './UI/Image';
import availableOnGooglePlay from 'src/assets/images/available-on-googleplay.png';
import { openPlayStore } from 'src/helpers/window';

export function AvailableOnPlayStore() {
  return (
    <Button
      variant="ghost"
      border={'1px solid'}
      borderColor={'surface'}
      borderRadius={'10px'}
      p={'15px'}
      h={'54px'}
      _hover={{}}
      onClick={openPlayStore}
      data-testid={'available-on-playstore'}
    >
      <Image src={availableOnGooglePlay} />
    </Button>
  );
}
