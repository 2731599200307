import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from '../theme/theme';
import { FeedbackProvider } from './FeedbackProvider';
import { PrescriberProvider } from './PrescriberProvider';
import { AuthProvider } from './AuthProvider';

interface CombinedProvidersProps {
  children: JSX.Element;
}

function CombinedProviders(props: CombinedProvidersProps) {
  return (
    <ChakraProvider theme={theme}>
      <FeedbackProvider>
        <AuthProvider>
          <PrescriberProvider>{props.children}</PrescriberProvider>
        </AuthProvider>
      </FeedbackProvider>
    </ChakraProvider>
  );
}

export default CombinedProviders;
