import { Flex } from '@chakra-ui/react';
import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { TutorialItem } from './Tutorial';
import { ReactComponent as Logo } from 'src/assets/images/logo.svg';

interface TutorialVideoProps {
  item?: TutorialItem;
}

const LOADING_TRANSITION = 'all 1s';

export function TutorialVideo({ item }: TutorialVideoProps) {
  const [videoIsLoading, setVideoIsLoading] = React.useState(true);

  const handleVideoLoaded = React.useCallback(() => {
    setVideoIsLoading(false);
  }, []);

  return (
    <Flex flexDirection="column" width="100%" height="100%" bgColor="transparent">
      <Flex position="relative" height="100%" flexDirection="column">
        <Flex
          position="absolute"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          transition={LOADING_TRANSITION}
          opacity={videoIsLoading ? 1 : 0}
          zIndex={1}
        >
          <MevoLogoLoading>
            <Logo fill={'#3D0E6B'} />
          </MevoLogoLoading>
        </Flex>
        <Flex
          width="100%"
          height="100%"
          borderRadius="20px"
          overflow="hidden"
          opacity={videoIsLoading ? 0 : 1}
          transition={LOADING_TRANSITION}
          zIndex={2}
        >
          <iframe
            data-testid="tutorial-iframe"
            width="100%"
            height="100%"
            src={item?.url}
            title={item?.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            onLoad={handleVideoLoaded}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TutorialVideo;

const MevoLogoAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;
const MevoLogoLoading = styled.div`
  background-image: linear-gradient(-45deg, #3d0e6b 0%, #3d0e6b 25%, #3d0e6b 51%, #ffb6bf 100%);
  fill: white;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-size: 300% 200%;
  animation: ${MevoLogoAnimation} 4s ease infinite;
  & svg {
    & * {
      fill: white;
    }
  }
`;
