import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';
import { DocumentTab } from './Tab';
import { PrescriptionHistory } from 'src/services/prescriptionHistory';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { get, uniq } from 'lodash';

export enum DOCUMENT_TABS_LABEL {
  MEDICINE = 'Receita',
  CERTIFICATE = 'Atestado',
  EXAM = 'Exame',
  REFERRAL = 'Encaminhamento',
  REPORT = 'Relatório',
  AND_OTHER_THINGS = '...',
}

interface DocumentTabsProps {
  prescription: PrescriptionHistory;
  onChange: (value: DOCUMENT_TABS_LABEL) => void;
}

export function DocumentTabs(props: DocumentTabsProps) {
  const { prescription, onChange } = props;
  const [selected, setSelected] = useState<DOCUMENT_TABS_LABEL>();

  const documentTabs = useMemo(() => {
    const { medicines, manualMedicines, exams, prescriptionMedicalStatements, medicalReports, prescriptionReferral } =
      prescription;

    const documentItems = [
      { items: medicines, label: DOCUMENT_TABS_LABEL.MEDICINE },
      { items: manualMedicines, label: DOCUMENT_TABS_LABEL.MEDICINE },
      { items: exams, label: DOCUMENT_TABS_LABEL.EXAM },
      { items: prescriptionMedicalStatements, label: DOCUMENT_TABS_LABEL.CERTIFICATE },
      { items: medicalReports, label: DOCUMENT_TABS_LABEL.REPORT },
      { items: prescriptionReferral, label: DOCUMENT_TABS_LABEL.REFERRAL },
    ];
    const availableDocuments = uniq(documentItems.filter((doc) => doc.items.length > 0).map((type) => type.label));

    return availableDocuments;
  }, [prescription]);

  const handleSelect = useCallback(
    (document: DOCUMENT_TABS_LABEL) => {
      setSelected(document);
      onChange(document);
    },
    [onChange]
  );

  useEffect(() => {
    if (!selected) {
      handleSelect(get(documentTabs, '[0]', DOCUMENT_TABS_LABEL.MEDICINE));
    }
  }, [documentTabs, handleSelect, selected]);

  return (
    <FlexWithoutScrollBar
      gap="10px"
      padding="5px"
      backgroundColor="support.surface"
      borderRadius="30px"
      maxWidth="100%"
      flexWrap="nowrap"
      overflowX="scroll"
    >
      {documentTabs.map((document) => (
        <Flex key={document}>
          <DocumentTab
            text={document}
            selected={selected === document}
            onClick={() => {
              handleSelect(document);
            }}
          />
        </Flex>
      ))}
    </FlexWithoutScrollBar>
  );
}

const FlexWithoutScrollBar = styled(Flex)`
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    // Chrome & Safari
    width: 0;
    height: 0;
  }
`;
