import React, { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Header } from 'src/components/Layout/Header';
import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { ReactComponent as Allegril } from 'src/assets/mevo-pill/allegril.svg';
import abstractBackground from 'src/assets/images/background.png';
import { useIsMobile } from 'src/helpers/useIsMobile';
import { Flex } from 'src/components/UI/Flex';
import Typography from 'src/components/Typography';
import { DEFAULT_MEVO_WHATSAPP_NUMBER } from 'src/helpers/defaultValues';
import { openWhatsApp } from 'src/helpers/openWhatsApp';
import WhatsappIcon from 'src/components/Icons/WhatsappIcon';
import { openApp } from 'src/helpers/openApp';

export function EmailConfirmed() {
  const navigate = useNavigate();

  const isMobile = useIsMobile();

  const handleWhatsApp = () => {
    openWhatsApp(DEFAULT_MEVO_WHATSAPP_NUMBER());
  };

  const [searchParams] = useSearchParams();

  const userCreatedThroughApp = useMemo(() => {
    return searchParams.get('createdThroughApp') || false;
  }, [searchParams]);

  const handleNavigate = () => {
    if (userCreatedThroughApp) {
      openApp(() => {
        navigate('/');
      });
      return;
    }

    navigate('/');
  };

  return (
    <Box w={'100%'} h={'100vh'} bg={'background'} overflowY={'auto'}>
      <Box p={'0 20px'}>
        <Header onGoBack={() => navigate('/')} />
      </Box>

      <Flex
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
        backgroundImage={isMobile ? '' : `url(${abstractBackground})`}
        pt={isMobile ? 0 : '100px'}
        justifyContent={'center'}
        h={'100%'}
      >
        <Box
          w={isMobile ? '100%' : '435px'}
          h={isMobile ? '100%' : '435px'}
          borderRadius={'30px'}
          boxShadow={!isMobile ? '12px 12px 40px rgba(61, 14, 107, 0.1)' : 'none'}
          bg={'background'}
        >
          <Box p={'40px'}>
            <Flex flexDir={isMobile ? 'column' : 'row'} alignItems={'center'}>
              <Box
                mr={!isMobile ? '20px' : 0}
                display={'flex'}
                alignItems={'center'}
                flexDirection={'column'}
                mb={'20px'}
              >
                <Allegril className={isMobile ? 'float-container' : ''} />
              </Box>

              <Typography model={'h1'} weight={'bold'}>
                Email confirmado com sucesso!
              </Typography>
            </Flex>

            <Box mb={'20px'}>
              <Typography model={'body'} size={'large'} weight={'regular'}>
                Seja bem-vindo(a) à Mevo Receita Digital! Agora você está pronto para prescrever suas receitas para seus
                pacientes de onde e quando quiser.
              </Typography>
            </Box>

            <Box mb={'20px'}>
              <Typography model={'body'} size={'large'} weight={'regular'}>
                Mas fique tranquilo, se houver qualquer dúvida, é só{' '}
                <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleWhatsApp}>
                  <span>falar com a gente.</span>
                  <Flex display="inline-flex" alignItems="center" ml="5px">
                    <WhatsappIcon variantColor="primary.1000" width={16} />
                  </Flex>
                </span>
              </Typography>
            </Box>

            <Box pos={isMobile ? 'absolute' : 'static'} bottom={'20px'} left={'20px'} right={'20px'}>
              <Button h={'60px'} w={'100%'} onClick={handleNavigate}>
                Começar a prescrever
              </Button>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
