import { api } from 'src/api';
import { DOCUMENT_TYPE } from './prescriber';
import { Icd } from './patient';

export const PrescriptionHistoryService = {
  async fetchPrescriptionHistory(
    prescriberId: string,
    params: FetchPrescriptionHistoryParams
  ): Promise<{
    prescriptions: Array<PrescriptionHistory>;
  }> {
    const { data } = await api.get(`/prescriber/${prescriberId}/prescriptions-history`, { params });

    return data;
  },
  async cancelPrescriptionHistory(prescriptionId: number) {
    const { data } = await api.delete(`/prescription/${prescriptionId}/history`);

    return data;
  },
};

export enum ORDER_TYPES {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum ORDER_BY {
  DATE = 'date',
  PATIENT_NAME = 'patientName',
}

export interface FetchPrescriptionHistoryParams {
  documentType?: Array<DOCUMENT_TYPE>;
  prescriptionStatus?: Array<PRESCRIPTION_STATUS>;
  initialDate?: string;
  endDate?: string;
  orderType: ORDER_TYPES;
  orderBy: ORDER_BY;
  limit: number;
  page: number;
  query?: string;
}

export interface PrescriptionHistory {
  _id: string;
  prescriptionId: number;
  prescriptionStatus: PRESCRIPTION_STATUS;
  validationCode: string;
  modificationDate: string;
  patientName: string;
  exams: PrescriptionExam[];
  medicines: PrescriptionMedicine[];
  manualMedicines: ManualMedicine[];
  observations?: string;
  prescriptionMedicalStatements: PrescriptionMedicalStatement[];
  prescriptionReferral: PrescriptionReferral[];
  medicalReports: MedicalReport[];
}

export interface PrescriptionExam {
  name: string;
  tussCode: string;
  requiresSide: boolean;
  requiresAnesthesia: boolean;
  applyAnesthesia: boolean;
  category: string;
  instructions: string;
  label: string;
  cid10Structured: Icd[];
  sigtap: string;
  laterality: string;
}

export interface PrescriptionMedicine {
  posology: string;
  comment: string;
  quantity: number;
  isContinuos: boolean;
  isPopularPharmacy: boolean;
  administrationWayName: string;
  twoWayPrinting: boolean;
  medicineId: number;
  name: string;
  unity: string;
  medicineType: string;
  medicineTypeWithAccents: string;
  pharmaceuticalForm: string;
  pharmaceuticalNameWithAccents: string;
  antimicrobial: boolean;
  antimicrobialDescription: string;
  LME: boolean;
  ordinance344Id: number;
  administrationWayId: number;
  MIP: boolean;
  Label: string;
  drugLabel: string;
  formattedUnitAndQuantity: string;
}

export interface ManualMedicine {
  manualMedicineId: number;
  posology: string;
  comment: '';
  quantity: number;
  isContinuos: boolean;
  isInternal: boolean;
  name: string;
  label: string;
}

export interface PrescriptionMedicalStatement {
  cid10Structured: Icd[];
  icdConsent: boolean;
  observation: string;
  period: string;
  text: string;
  periodType: string;
  prescriptionMedicalStatementId: string;
  isFreeText: string;
  isDeclarationOfAttendance: boolean;
  type: string;
  medicalStatementDate: string;
  startingHour: string;
  endingHour: string;
  completeText: string;
}

export interface PrescriptionReferral {
  date: string;
  inclusionDate: string;
  reason: string;
  clinicalHistory: string;
  conduct: string;
  specialty: Specialty;
}

export interface Specialty {
  name: string;
  professionalName: string;
  specialtyId: number;
}

export interface MedicalReport {
  text: string;
  title: string;
  Date: string;
  createdAt: string;
  updatedAt: string;
}

export enum PRESCRIPTION_STATUS {
  INITIATED = 'INITIATED',
  EMITTED = 'EMITTED',
  CANCELED = 'CANCELED',
}
