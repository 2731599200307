import { SimpleGrid } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { get, isEqual, omit } from 'lodash';

import MevoInput from 'src/components/Input';
import Typography from 'src/components/Typography';
import SelectGender from 'src/components/UI/SelectGender';
import AlertIcon from 'src/components/Icons/AlertIcon';
import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { Checkbox } from 'src/components/UI/Checkbox';
import { Flex } from 'src/components/UI/Flex';
import { FormControl } from 'src/components/UI/FormControl';
import { MASKS } from 'src/components/UI/Input/MaskedInput';

import { Yup } from 'src/helpers/Yup';
import { validateCPF } from 'src/helpers/cpf';
import { onlyDigits } from 'src/helpers/string';
import { DateFromFormat, validateDateFromBrazilianForm } from 'src/helpers/Date';

import { DialogWrappedComponentProps, useFeedbackContext } from 'src/providers/FeedbackProvider';
import { usePrescriberContext } from 'src/providers/PrescriberProvider';

import { GENDER, PatientBasicData, PatientService } from 'src/services/patient';
import { PatientAlreadyCreatedDialog } from 'src/containers/CreatePartialPatient/PatientAlreadyCreatedDialog';
import { useEffect, useMemo, useState } from 'react';
import { PersonalDataFormSkeleton } from '../skeletons/PersonalDataFormSkeleton';

interface PatientBasicDataForm extends PatientBasicData {
  hasDocument: boolean;
  isFormOnEditMode: boolean;
}

const PersonalDataSchema = Yup.object().shape({
  fullName: Yup.string().required('Informe o nome completo.'),
  hasDocument: Yup.bool().nullable(),
  document: Yup.string().when('hasDocument', {
    is: true,
    then: (schema: any) =>
      schema
        .required('Informe um número de CPF válido.')
        .test('validate cpf', 'Informe um número de CPF válido.', (schema: string) => {
          return validateCPF(schema || '');
        }),
    otherwise: (schema: any) => schema.nullable(),
  }),
  cellphoneNumber: Yup.string()
    .required('Informe um número de telefone válido.')
    .test('validate phoneNumber', 'Informe um número de celular válido.', function (phoneNumber) {
      if (!phoneNumber) return false;
      return onlyDigits(phoneNumber).length === 11;
    }),
  birthDate: Yup.string().test('match', 'Informe uma data de nascimento válida.', (birthDate) => {
    if (!birthDate) return true;

    return validateDateFromBrazilianForm(birthDate);
  }),
  sex: Yup.string().nullable(),
});

const initialValues: PatientBasicDataForm = {
  fullName: '',
  cellphoneNumber: '',
  birthDate: '',
  sex: GENDER.NOT_INFORMED,
  document: '',
  documentType: 'cpf',
  hasDocument: true,
  _id: null,
  isFormOnEditMode: true,
  active: false,
  lastAttendance: '',
};

interface BasicDataFormProps {
  onCreatePatient(patientId: string): void;
  onEditPatient(patientName: string, patientGender: GENDER): void;
  isLoadingData: boolean;
  formData: PatientBasicData | null;
}

export function BasicDataForm(props: BasicDataFormProps) {
  const [defaultValues, setDefaultValues] = useState<PatientBasicDataForm>(initialValues);

  const { prescriberId, setUserDoesNotHaveAnyPatients } = usePrescriberContext();
  const { openToast, openBaseDialog } = useFeedbackContext();

  const navigate = useNavigate();

  const formik = useFormik<PatientBasicDataForm>({
    initialValues: initialValues,
    validationSchema: PersonalDataSchema,
    validateOnChange: false,
    async onSubmit(values) {
      try {
        if (!prescriberId) return;
        const date = DateFromFormat(values.birthDate || '', 'dd/MM/yyyy');
        const document = onlyDigits(values.document);

        if (values.isFormOnEditMode && values._id) {
          await PatientService.updatePatient(
            { ...values, birthDate: DateTime.fromISO(date).toJSDate(), document },
            prescriberId,
            values._id
          );
          setUserDoesNotHaveAnyPatients(false);

          openToast('Paciente editado com sucesso.', { type: 'success', duration: 4000 });

          if (defaultValues.fullName !== values.fullName || defaultValues.sex !== values.sex) {
            props.onEditPatient(values.fullName, values.sex);
          }
        } else {
          const patient = await PatientService.createPartialPatient(
            { ...values, birthDate: DateTime.fromISO(date).toJSDate(), document },
            prescriberId
          );
          setUserDoesNotHaveAnyPatients(false);

          openToast('Paciente cadastrado com sucesso.', { type: 'success', duration: 4000 });

          props.onCreatePatient(patient._id);
        }
      } catch (error: any) {
        const status = get(error, 'response.status', null);
        const message = get(error, 'response.data.message', '');

        if (status === 412 && message.includes('Paciente já cadastrado')) {
          await openBaseDialog(
            (dialogProps: DialogWrappedComponentProps) => (
              <PatientAlreadyCreatedDialog
                onCancel={dialogProps.onClose}
                onConfirm={() => {
                  navigate('/inicio/receita');
                  dialogProps.onClose();
                }}
              />
            ),
            {
              width: '270px',
              height: '356px',
            }
          );
          return;
        }

        openToast(
          'Ops.. Um problema inesperado ocorreu. Aguarde alguns minutos e tente novamente. Caso o problema persista, fale com a gente.',
          { type: 'error', duration: 2000 }
        );
      }
    },
  });

  const setValues = useMemo(() => formik.setValues, [formik.setValues]);

  const isSameData = useMemo(() => {
    return isEqual(omit(defaultValues, ['_id']), omit(formik.values, ['_id']));
  }, [defaultValues, formik.values]);

  const isSubmitButtonDisabled = formik.values.isFormOnEditMode && isSameData;

  useEffect(() => {
    if (!props.formData) return;

    const patientId = get(props.formData, '_id', '');

    setValues({
      isFormOnEditMode: !!patientId,
      _id: patientId,
      fullName: props.formData.fullName,
      cellphoneNumber: props.formData.cellphoneNumber,
      birthDate: props.formData.birthDate ? props.formData.birthDate : '',
      sex: props.formData.sex,
      document: props.formData.document,
      documentType: props.formData.documentType,
      hasDocument: !!props.formData.document,
      active: props.formData.active,
      lastAttendance: props.formData.lastAttendance,
    });

    setDefaultValues({
      isFormOnEditMode: !!patientId,
      _id: patientId,
      fullName: props.formData.fullName,
      cellphoneNumber: props.formData.cellphoneNumber,
      birthDate: props.formData.birthDate ? props.formData.birthDate : '',
      sex: props.formData.sex,
      document: props.formData.document,
      documentType: props.formData.documentType,
      hasDocument: !!props.formData.document,
      active: props.formData.active,
      lastAttendance: props.formData.lastAttendance,
    });
  }, [setValues, props.formData]);

  if (props.isLoadingData) {
    return <PersonalDataFormSkeleton />;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex
        flexDirection={'column'}
        gap={'20px'}
        bgColor={{
          base: 'transparent',
          lg: 'white',
        }}
        borderRadius={'20px'}
        p={{ base: '0px', sm: '32px' }}
        h={'full'}
      >
        <Box display={{ base: 'none', sm: 'block' }}>
          <Typography model="h2" weight="bold">
            Dados pessoais
          </Typography>
        </Box>

        <SimpleGrid columns={1}>
          <Box>
            <FormControl isInvalid={!!formik.errors.fullName} mb={'20px'}>
              <MevoInput
                id="fullName"
                labelText="Nome completo do paciente*"
                h={'45px'}
                placeholderText={'Digite o nome'}
                value={formik.values.fullName}
                name={'fullName'}
                onChange={formik.handleChange}
                errorText={formik.errors.fullName}
                maxLength={200}
                autoFocus
              />
            </FormControl>
            <Flex mt={'30px'} gap={'10px'} flexDirection={{ base: 'column', sm: 'row' }}>
              <Box w={'100%'} mb={'20px'} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                <FormControl isInvalid={!!formik.errors.document}>
                  <MevoInput
                    id="document"
                    labelText="CPF"
                    type={'tel'}
                    h={'45px'}
                    placeholder={'000.000.000-00'}
                    name={'document'}
                    value={formik.values.document}
                    onChange={formik.handleChange}
                    onBlur={() => formik.validateField('document')}
                    errorText={formik.errors.document}
                    mask={MASKS.CPF}
                    disabled={!formik.values.hasDocument}
                  />
                </FormControl>
                <Checkbox
                  marginTop={formik.errors.document && '10px'}
                  name="hasDocument"
                  isChecked={!formik.values.hasDocument}
                  onChange={(e) => {
                    formik.setFieldValue('hasDocument', !e.target.checked);
                    if (e.target.checked) {
                      formik.setFieldValue('document', '');
                      formik.setErrors({ ...formik.errors, document: '' });
                      return;
                    }
                  }}
                >
                  <Box mt={'2px'}>
                    <Typography model="body" size="normal" weight="regular">
                      Não possui CPF
                    </Typography>
                  </Box>
                </Checkbox>
              </Box>
              <FormControl isInvalid={!!formik.errors.cellphoneNumber} mb={'20px'}>
                <MevoInput
                  id="cellphoneNumber"
                  labelText="Celular*"
                  h={'45px'}
                  type={'tel'}
                  placeholderText={'(99) 9 9999-9999'}
                  name={'cellphoneNumber'}
                  value={formik.values.cellphoneNumber}
                  onChange={formik.handleChange}
                  errorText={formik.errors.cellphoneNumber}
                  mask={MASKS.BRAZIL_PHONE}
                  onBlur={() => {
                    formik.validateField('cellphoneNumber');
                  }}
                />
              </FormControl>
            </Flex>

            <Flex mt={'20px'} gap={'10px'} flexDirection={{ base: 'column', sm: 'row' }}>
              <FormControl isInvalid={!!formik.errors.birthDate} mb={'20px'}>
                <MevoInput
                  id="birthDate"
                  labelText="Data de nascimento do paciente"
                  type={'tel'}
                  h={'45px'}
                  placeholder={'Digite a data de nascimento'}
                  name={'birthDate'}
                  value={formik.values.birthDate}
                  onChange={formik.handleChange}
                  onBlur={() => formik.validateField('birthDate')}
                  errorText={formik.errors.birthDate}
                  mask={MASKS.BIRTH_DATE}
                  maskChar={''}
                />
              </FormControl>

              <FormControl isInvalid={!!formik.errors.sex}>
                <SelectGender
                  label={'SEXO'}
                  value={formik.values.sex}
                  onChange={(value) => formik.setFieldValue('sex', value)}
                  errorMessage={'Informe o sexo do paciente.'}
                />
              </FormControl>
            </Flex>
          </Box>
        </SimpleGrid>
        <Flex flexDirection={{ base: 'column', sm: 'row' }} justifyContent={'space-between'}>
          <Flex
            w={{ base: '100%', md: '50%' }}
            maxW={{ base: '100%', md: '308px' }}
            mr={'10px'}
            border={'1px solid'}
            borderColor={'notification.alert'}
            borderRadius={'10px'}
            alignItems={'center'}
            justifyContent={'center'}
            mb={{ base: '20px', sm: 0 }}
            padding={'10px'}
          >
            <Box mr={'5px'}>
              <AlertIcon variantColor="notification.alert" width={12} />
            </Box>
            <Typography model="body" size="small" weight="regular">
              Ao escolher voltar, os dados preenchidos serão perdidos!
            </Typography>
          </Flex>
          <Button
            type={'submit'}
            isLoading={formik.isSubmitting}
            isDisabled={isSubmitButtonDisabled}
            variant={isSubmitButtonDisabled ? 'outline-primary-light' : 'solid'}
            w={{
              base: 'full',
              md: '150px',
            }}
            h={'60px'}
            alignSelf={'flex-end'}
            mt={'auto'}
          >
            Salvar
          </Button>
        </Flex>
      </Flex>
    </form>
  );
}
