import { Flex } from 'src/components/UI/Flex';
import { Box } from 'src/components/UI/Box';
import { Text } from 'src/components/UI/Text';
import Typography from 'src/components/Typography';
import abstractBackground from 'src/assets/images/logo-purple.png';
import argumentStand from 'src/assets/images/mockup-argumentos-stand.png';
import { DefaultPageProps } from '../defaultPageProps';
import { ReactComponent as MedicoExponencialLogo } from 'src/assets/images/partners/medico_exponencial.svg';
import { ReactComponent as EMSLogo } from 'src/assets/images/partners/ems.svg';
import { AvailableOnAppStore } from 'src/components/AvailableOnAppStore';
import { AvailableOnPlayStore } from 'src/components/AvailableOnPlayStore';

export function EMS(props: DefaultPageProps) {
  return (
    <Flex
      display={'flex'}
      flexDirection={'column'}
      backgroundSize={'contain'}
      backgroundRepeat={'no-repeat'}
      backgroundPosition={'right'}
      backgroundImage={`url(${abstractBackground})`}
      backgroundColor={'primary.1000'}
      w={'100%'}
      h={'100%'}
      px={'80px'}
      py={'70px'}
      data-testid={'ems'}
    >
      <Flex h={'full'} flexDirection={'column'} justifyContent={'space-between'}>
        <Flex alignItems={'center'}>
          <Box w={'50%'}>
            <Typography model={'h2'} fontSize={18} weight={'bold'} color={'secondary.400'}>
              Em parceria com a EMS, oferecemos um ambiente de acesso exclusivo para os médicos da comunidade Médico
              Exponencial.
            </Typography>
            <Box pt={'12px'}>
              <Typography model={'h3'} fontSize={18} weight={'regular'} color={'surface'}>
                Agora disponível para você prescrever quando,
              </Typography>
              <Typography model={'h3'} fontSize={18} weight={'regular'} color={'surface'}>
                como e onde quiser,{' '}
                <Text color={'#FFDE49'} as={'span'}>
                  gratuitamente.
                </Text>
              </Typography>
            </Box>
          </Box>
          <Flex ml={'10px'} gap={'10px'}>
            <Box mb={'10px'}>
              <MedicoExponencialLogo data-testid={'medico-exponencial-logo'} />
            </Box>
            <EMSLogo data-testid={'ems-logo'} height={'46px'} />
          </Flex>
        </Flex>
        <Flex
          flex={1}
          bgImage={argumentStand}
          bgPosition={'center'}
          bgSize={'contain'}
          bgRepeat={'no-repeat'}
          mt={'50px'}
          minH={'70%'}
        ></Flex>

        <Flex alignItems={'center'}>
          <Box maxW={'300px'} w={'100%'}>
            <Typography model={'h3'} fontSize={16} weight={'regular'} color={'surface'}>
              Use a Mevo também no seu celular.
            </Typography>
            <Typography model={'h3'} fontSize={16} weight={'regular'} color={'surface'}>
              <b>Baixe agora o app.</b>
            </Typography>
          </Box>

          <Flex gap={'10px'}>
            <AvailableOnAppStore />
            <AvailableOnPlayStore />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
