import React from 'react';
import { Box } from 'src/components/UI/Box';
import {
  NOT_OPEN_BOTTOM_BAR_HEIGHT,
  OPEN_BOTTOM_BAR_HEIGHT,
  usePrescriberContext,
} from 'src/providers/PrescriberProvider';

export function BottomTabNavigationBackdrop() {
  const { setBottomBarHeight, bottomBarHeight } = usePrescriberContext();

  return (
    <Box
      display={bottomBarHeight === OPEN_BOTTOM_BAR_HEIGHT ? 'absolute' : 'none'}
      position={'absolute'}
      top={0}
      bottom={0}
      left={0}
      right={0}
      backgroundColor={'secondary.900'}
      opacity={0.1}
      zIndex={2}
      onClick={() => setBottomBarHeight(NOT_OPEN_BOTTOM_BAR_HEIGHT)}
    ></Box>
  );
}
