import { useCallback, useMemo } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { NextButton } from './NextButton';
import { TabsHeader } from './TabsHeader';
import { TABS_INDEX } from './SignUp';

import { Box } from 'src/components/UI/Box';
import { ProtectedPassword } from 'src/components/UI/Input/ProtectedPassword';
import { usePrescriberContext } from 'src/providers/PrescriberProvider';

import { PrescriberService } from 'src/services/prescriber';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';
import Typography from 'src/components/Typography';

import { Yup } from 'src/helpers/Yup';

import useLoading from 'src/helpers/useLoading';
import { Tracker } from 'src/shared/tracker/Tracker';

interface CreatePasswordForm {
  password: string;
  confirmPassword: string;
  isPasswordProtected: boolean;
}

interface ProfessionalDataProps {
  onGoBack(index: number): void;
}

const initialValues: CreatePasswordForm = {
  password: '',
  confirmPassword: '',
  isPasswordProtected: false,
};

const createPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Informe uma senha válida.')
    .min(8, 'Sua senha deve ter no mínimo 8 caracteres')
    .max(16, 'Sua senha deve ter no máximo 16 caracteres')
    .test('validate password', 'Senha não atende os padrões de segurança.', function (password, context): boolean {
      return context.parent.isPasswordProtected;
    }),
  confirmPassword: Yup.string()
    .required('Informe uma senha de confirmação válida.')
    .min(8, 'Sua senha deve ter no mínimo 8 caracteres')
    .max(16, 'Sua senha deve ter no máximo 16 caracteres')
    .oneOf([Yup.ref('password'), ''], 'A senha e senha de confirmação devem ser iguais.'),
  isPasswordProtected: Yup.bool().required(),
});

export function CreatePassword(props: ProfessionalDataProps) {
  const { createPrescriberFormData, updateCreatePrescriberFormData } = usePrescriberContext();
  const { openToast } = useFeedbackContext();

  const navigate = useNavigate();
  const [loading, runWithLoading] = useLoading();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: createPasswordSchema,
    validateOnChange: false,
    async onSubmit(values, formik) {
      runWithLoading(async () => {
        try {
          formik.setSubmitting(false);

          updateCreatePrescriberFormData({
            password: values.password,
          });

          await PrescriberService.createUser({
            ...createPrescriberFormData,
            password: values.password,
          });

          Tracker.trackEvent({
            event: 'Cadastro Realizado',
            parameters: {
              Nome: createPrescriberFormData.fullName,
              email: createPrescriberFormData.email,
              'Data cadastro': new Date(),
            },
          });

          navigate({
            pathname: '/confirmaremail',
            search: createSearchParams({
              email: createPrescriberFormData.email,
            }).toString(),
          });

          sessionStorage.removeItem('personal-data-form');
          sessionStorage.removeItem('professional-data-form');
          sessionStorage.removeItem('place-service-form');
        } catch (e: any) {
          openToast(e.response.data.message, { type: 'error-outline' });
        }
      });
    },
  });

  const setFieldValue = useMemo(() => formik.setFieldValue, [formik.setFieldValue]);
  const validateField = useMemo(() => formik.validateField, [formik.validateField]);

  const updatePasswordFormValidation = useCallback(
    async (value: boolean) => {
      await setFieldValue('isPasswordProtected', value);
      await validateField('password');
    },
    [setFieldValue, validateField]
  );

  return (
    <form name={'create-password'} onSubmit={formik.handleSubmit}>
      <TabsHeader
        tabClick={(index) => {
          if (index === TABS_INDEX.CREATE_PASSWORD) return;

          if (index > TABS_INDEX.CREATE_PASSWORD) {
            formik.handleSubmit();
            return;
          }

          props.onGoBack(index);
        }}
        isFirstStepCompleted
        isSecondStepCompleted
        isThirdStepCompleted
        currentIndex={TABS_INDEX.CREATE_PASSWORD}
      />

      <Box>
        <Box mb={'20px'}>
          <Typography model={'body'} size={'large'} weight={'bold'}>
            Senha de acesso
          </Typography>
        </Box>

        <Box m={'20px 0'}>
          <ProtectedPassword
            label={'senha*'}
            errorMessage={formik.errors.password || formik.errors.isPasswordProtected}
            isInvalid={!!formik.errors.password || !!formik.errors.isPasswordProtected}
            placeholder={'Digite a senha'}
            value={formik.values.password}
            onChange={formik.handleChange}
            showPasswordProtectionAttributes={true}
            onProtected={updatePasswordFormValidation}
            name={'password'}
            onBlur={() => formik.validateField('password')}
            autoFocus
          />
        </Box>

        <Box m={'20px 0'}>
          <ProtectedPassword
            label={'Confirmar senha*'}
            errorMessage={formik.errors.confirmPassword}
            isInvalid={!!formik.errors.confirmPassword}
            placeholder={'Digite a senha de confirmação'}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            name={'confirmPassword'}
            onBlur={() => formik.validateField('confirmPassword')}
          />
        </Box>

        <NextButton
          currentIndex={TABS_INDEX.CREATE_PASSWORD}
          position={{ base: 'fixed', sm: 'static' }}
          padding={{ base: '0 20px', sm: '0' }}
          isLoading={loading}
        />
      </Box>
    </form>
  );
}
