import { DateTime, Settings } from 'luxon';

Settings.defaultZone = 'America/Sao_Paulo';
Settings.defaultLocale = 'pt-br';

export const DateFromFormat = (date: string, fromFormat: string): string => {
  return DateTime.fromFormat(date, fromFormat).toFormat("yyyy-MM-dd'T'HH:mm:ss");
};

export const DateFromIso = (date: string): string => {
  return DateTime.fromISO(date).toFormat("yyyy-MM-dd'T'HH:mm:ss");
};

export const DateFromISOToBrazilianFormat = (date: string): string => {
  return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
};

export const DateFromJSDate = (date: Date): string => {
  return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd'T'HH:mm:ss");
};

export const DateFromJSDateToBrazilianFormat = (date: Date): string => {
  return DateTime.fromJSDate(date).toFormat('dd/MM/yyyy');
};

export const getMonthFromIso = (date: string): string | null => {
  return DateTime.fromISO(date).monthLong;
};

export const getMonthFromJSDate = (date: Date): string | null => {
  return DateTime.fromJSDate(date).monthLong;
};

export const getMonthFromFormat = (date: string, fromFormat: string): string | null => {
  return DateTime.fromFormat(date, fromFormat).monthLong;
};

export const getHourAndMinuteFromIso = (date: string): string => {
  return DateTime.fromISO(date, { zone: 'UTC' }).toFormat('HH:mm');
};

export const joinDateWithHourAndMinutes = (date: string, hourAndMinute?: string) => {
  if (!hourAndMinute) return DateTime.fromFormat(date, 'dd/MM/yyyy').toFormat("yyyy-MM-dd'T'HH:mm:ss");

  const time = {
    hours: parseInt(hourAndMinute),
    minutes: parseInt(hourAndMinute.split(':')[1].trim()),
  };

  return DateTime.fromFormat(date, 'dd/MM/yyyy')
    .plus({ hours: time.hours, minutes: time.minutes })
    .toFormat("yyyy-MM-dd'T'HH:mm:ss");
};

export const validateDateFromBrazilianForm = (date: string | null | undefined) => {
  if (!date) return false;
  return (
    DateTime.fromFormat(date, 'dd/MM/yyyy').isValid && DateTime.fromFormat(date, 'dd/MM/yyyy').toJSDate() < new Date()
  );
};
