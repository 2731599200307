import { ToggleButton } from './ToggleButton';
import { PrescriptionStatusBadge } from './PrescriptionStatusBadge';
import { PRESCRIPTION_STATUS } from 'src/services/prescriptionHistory';

interface PrescriptionStatusFilterProps {
  onClick(): void;
  isActive: boolean;
  status: PRESCRIPTION_STATUS;
}

export const PRESCRIPTION_STATUS_LABEL = {
  // [PRESCRIPTION_STATUS.INITIATED]: 'Em andamento',
  [PRESCRIPTION_STATUS.EMITTED]: 'Emitida',
  [PRESCRIPTION_STATUS.CANCELED]: 'Cancelada',
};

export const PRESCRIPTION_STATUS_COLOR = {
  // [PRESCRIPTION_STATUS.INITIATED]: 'support.blue',
  [PRESCRIPTION_STATUS.EMITTED]: 'notification.success',
  [PRESCRIPTION_STATUS.CANCELED]: 'notification.error',
};

export function PrescriptionStatusFilter(props: PrescriptionStatusFilterProps) {
  return (
    <ToggleButton
      onClick={props.onClick}
      isActive={props.isActive}
      hideIcon
      label={<PrescriptionStatusBadge status={props.status} showLabel></PrescriptionStatusBadge>}
    />
  );
}
