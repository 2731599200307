import { useCallback, useEffect, useState } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';

import MevoInput from 'src/components/Input';
import Typography from 'src/components/Typography';
import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { Flex } from 'src/components/UI/Flex';
import { MASKS } from 'src/components/UI/Input/MaskedInput';
import { FormControl } from 'src/components/UI/FormControl';

import { Yup } from 'src/helpers/Yup';
import { validateCNS } from 'src/helpers/validateCNS';

import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';

import { Select } from 'src/components/UI/Select/Select';

import AlertIcon from 'src/components/Icons/AlertIcon';
import { SearchIcd } from 'src/components/SearchIcd';
import { GENDER, PatientClinicalData, PatientService, UpdateClinicalDataPayload } from 'src/services/patient';
import { SearchAllergy } from 'src/components/SearchAllergy';
import BooleanSelect from 'src/components/BooleanSelect';
import { IcdTag } from './IcdTag';
import { HStack } from 'src/components/UI/HStack';
import { AllergyTag } from './AllergyTag';
import { DEFAULT_ETHNICITY, ETHNICITIES } from 'src/helpers/ethnicities';
import { onlyDigits } from 'src/helpers/string';

const personalDataFormSchema = Yup.object().shape({
  CNS: Yup.string().test('validate CNS', 'Informe um CNS válido', (CNS) => {
    if (!CNS) return true;

    return validateCNS(CNS);
  }),

  allergies: Yup.array().nullable(),
  diagnostics: Yup.array().nullable(),
  height: Yup.string().nullable(),
  weight: Yup.string().nullable(),
  sex: Yup.string().nullable(),
  pregnant: Yup.string().nullable(),
  lactating: Yup.string().nullable(),
  smoker: Yup.string().nullable(),
  ethnicity: Yup.string().nullable(),
  patientId: Yup.string().nullable(),
});

const initialValues: UpdateClinicalDataPayload = {
  allergies: [],
  diagnostics: [],
  CNS: '',
  height: '',
  weight: '',
  sex: GENDER.NOT_INFORMED,
  pregnant: null,
  lactating: null,
  smoker: null,
  ethnicity: '',
  patientId: '',
};

interface ClinicalDataFormProps {
  formData: PatientClinicalData | null;
  onChange(): void;
}

export function ClinicalDataForm(props: ClinicalDataFormProps) {
  const [defaultValues, setDefaultValues] = useState<UpdateClinicalDataPayload>(initialValues);

  const { openToast } = useFeedbackContext();

  const { prescriberId } = usePrescriberContext();

  const { values, errors, isSubmitting, handleSubmit, setFieldValue, setValues, validateField, handleChange } =
    useFormik<UpdateClinicalDataPayload>({
      initialValues: initialValues,
      validationSchema: personalDataFormSchema,
      validateOnChange: false,
      onSubmit: onSubmit,
    });

  async function onSubmit(data: UpdateClinicalDataPayload) {
    if (!prescriberId) return;

    try {
      openToast('Dados salvos com sucesso.', {
        type: 'success',
        duration: 2000,
      });

      await PatientService.updatePatientClinicalData(prescriberId, {
        ...data,
        height: onlyDigits(data.height),
        weight: onlyDigits(data.weight),
        CNS: onlyDigits(data.CNS),
      });
    } catch (error) {
      openToast('Ocorreu um erro ao salvar os dados do paciente. Tente novamente em instantes.', {
        type: 'error',
        duration: 2000,
      });
    }
  }

  useEffect(() => {
    if (!props.formData) return;

    const isPatientMasculine = props.formData.sex === GENDER.MASCULINE;

    setValues({
      allergies: props.formData.patientAllergies,
      diagnostics: props.formData.patientDiagnostics,
      CNS: props.formData.CNS,
      height: props.formData.height,
      weight: props.formData.weight,
      sex: props.formData.sex,
      pregnant: isPatientMasculine ? false : props.formData.pregnant,
      lactating: isPatientMasculine ? false : props.formData.lactating,
      smoker: props.formData.smoker,
      ethnicity: props.formData.ethnicity || DEFAULT_ETHNICITY,
      patientId: props.formData.patientId,
    });

    setDefaultValues({
      allergies: props.formData.patientAllergies,
      diagnostics: props.formData.patientDiagnostics,
      CNS: props.formData.CNS,
      height: props.formData.height,
      weight: props.formData.weight,
      sex: props.formData.sex,
      pregnant: isPatientMasculine ? false : props.formData.pregnant,
      lactating: isPatientMasculine ? false : props.formData.lactating,
      smoker: props.formData.smoker,
      ethnicity: props.formData.ethnicity || DEFAULT_ETHNICITY,
      patientId: props.formData.patientId,
    });
  }, [setValues, props.formData]);

  const isSameData = isEqual(defaultValues, values);

  const shouldDisableSelect = props.formData?.sex === GENDER.MASCULINE;

  const deleteIcdTag = useCallback(
    (icdId: number) => {
      const filteredTags = values.diagnostics.filter((diagnostic) => diagnostic.id !== icdId);

      setFieldValue('diagnostics', filteredTags);
    },
    [values.diagnostics, setFieldValue]
  );

  const deleteAllergyTag = useCallback(
    (allergyId: number) => {
      const filteredTags = values.allergies.filter((allergy) => allergy.id !== allergyId);

      setFieldValue('allergies', filteredTags);
    },
    [values.allergies, setFieldValue]
  );

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        height: '100%',
      }}
      data-testId={'personal-data-form'}
    >
      <Flex
        flexDirection={'column'}
        gap={'20px'}
        bgColor={{
          base: 'transparent',
          lg: 'white',
        }}
        borderRadius={'20px'}
        p={{ base: '0px', sm: '32px' }}
        h={'full'}
      >
        <Box display={{ base: 'none', sm: 'block' }}>
          <Typography model={'h2'} size={'large'} weight={'bold'}>
            Dados clínicos
          </Typography>
        </Box>

        <Box>
          <SearchIcd
            label={'Diagnóstico'}
            onSelectIcd={(diagnostic) => {
              setFieldValue('diagnostics', [...values.diagnostics, diagnostic]);
            }}
            clearAfterSelect
            name={'diagnostics'}
            autoFocus
          />

          {values.diagnostics.length > 0 && (
            <HStack spacing={'10px'} mt={'5px'} overflowX={'auto'}>
              {values.diagnostics.map((diagnostic, index) => (
                <IcdTag
                  onClick={deleteIcdTag}
                  icd={diagnostic}
                  key={`${diagnostic.id}-${diagnostic.subcategory}-${index}`}
                ></IcdTag>
              ))}
            </HStack>
          )}
        </Box>

        <Box>
          <SearchAllergy
            label="ALERGIAS"
            onSelectAllergy={(allergy) => {
              setFieldValue('allergies', [...values.allergies, allergy]);
            }}
            clearAfterSelect
          />

          {values.allergies.length > 0 && (
            <HStack spacing={'10px'} mt={'5px'}>
              {values.allergies.map((allergy) => (
                <AllergyTag onClick={deleteAllergyTag} allergy={allergy} key={allergy.id}></AllergyTag>
              ))}
            </HStack>
          )}
        </Box>

        <FormControl isInvalid={!!errors.CNS}>
          <MevoInput
            name={'CNS'}
            placeholder={'000.000.000.000.000'}
            labelText={'Cartão Nacional de Saúde (CNS)'}
            value={values.CNS}
            onChange={handleChange}
            mask={MASKS.CNS}
            onBlur={() => validateField('CNS')}
            errorText={errors.CNS}
            m={0}
          />
        </FormControl>

        <SimpleGrid
          columns={{
            base: 1,
            xl: 3,
          }}
          gap={'20px'}
        >
          <FormControl>
            <MevoInput
              name={'height'}
              placeholder={'Digite a altura'}
              labelText={'Altura (cm)'}
              value={values.height}
              onChange={handleChange}
              onBlur={() => validateField('height')}
              m={0}
              mask={MASKS.NUMBER_THREE_DIGITS}
              maskChar={''}
            />
          </FormControl>

          <FormControl>
            <MevoInput
              name={'weight'}
              placeholder={'Digite o peso'}
              labelText={'Peso (kg)'}
              value={values.weight}
              onChange={handleChange}
              onBlur={() => validateField('weight')}
              m={0}
              mask={MASKS.NUMBER_THREE_DIGITS}
              maskChar={''}
            />
          </FormControl>

          <FormControl>
            <Box mb={'10px'}>
              <Typography model="caption" weight="bold" textTransform="uppercase">
                Raça/Cor/Etnia
              </Typography>
            </Box>

            <Select
              h={'45px'}
              m={0}
              p={0}
              value={values.ethnicity}
              onChange={(e) => {
                setFieldValue('ethnicity', e.target.value);
              }}
              data-testid={'ethnicity-select'}
            >
              {ETHNICITIES.map((ethnicity) => (
                <option value={ethnicity.value} key={ethnicity.label}>
                  {ethnicity.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </SimpleGrid>

        <SimpleGrid
          columns={{
            base: 1,
            xl: 2,
          }}
          gap={'20px'}
        >
          <FormControl isDisabled={shouldDisableSelect}>
            <BooleanSelect
              value={values.pregnant}
              label={'Gestante'}
              onChange={(value) => setFieldValue('pregnant', value)}
            ></BooleanSelect>
          </FormControl>

          <FormControl isDisabled={shouldDisableSelect}>
            <BooleanSelect
              value={values.lactating}
              label={'Lactante'}
              onChange={(value) => setFieldValue('lactating', value)}
            ></BooleanSelect>
          </FormControl>
        </SimpleGrid>

        <FormControl>
          <BooleanSelect
            value={values.smoker}
            label={'Fumante'}
            onChange={(value) => setFieldValue('smoker', value)}
          ></BooleanSelect>
        </FormControl>

        <Flex flexDirection={{ base: 'column', sm: 'row' }} justifyContent={'space-between'}>
          <Flex
            w={{ base: '100%', md: '180px' }}
            mr={'10px'}
            border={'1px solid'}
            borderColor={'notification.alert'}
            borderRadius={'10px'}
            alignItems={'center'}
            justifyContent={'center'}
            mb={{ base: '20px', sm: 0 }}
            padding={'10px'}
          >
            <Box mr={'5px'}>
              <AlertIcon variantColor="notification.alert" width={12} />
            </Box>
            <Typography model="body" size="small" weight="regular">
              Ao escolher voltar, os dados preenchidos serão perdidos!
            </Typography>
          </Flex>
          <Button
            type={'submit'}
            isLoading={isSubmitting}
            isDisabled={isSameData}
            variant={isSameData ? 'outline-primary-light' : 'solid'}
            w={{
              base: 'full',
              md: '150px',
            }}
            h={'60px'}
            alignSelf={'flex-end'}
            mt={'auto'}
          >
            Salvar
          </Button>
        </Flex>
      </Flex>
    </form>
  );
}
