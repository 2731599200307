import React from 'react';
import MevoPill from 'src/components/Pill/Pill';
import Typography from 'src/components/Typography';
import { Box } from 'src/components/UI/Box';
import { Button } from 'src/components/UI/Button';
import { Flex } from 'src/components/UI/Flex';

interface PatientAlreadyCreatedDialogProps {
  onConfirm(): void;
  onCancel(): void;
}

export function PatientAlreadyCreatedDialog(props: PatientAlreadyCreatedDialogProps) {
  return (
    <Box bg={'surface'}>
      <Flex justifyContent={'center'} mb={'30px'} mt={'10px'}>
        <MevoPill pillColor="red" faceStyle="crazy" pillWidth={100} faceWidth={37} />
      </Flex>
      <Box mb={'10px'}>
        <Typography model="h2" weight="bold">
          Paciente já cadastrado!
        </Typography>
      </Box>
      <Box mb={'20px'}>
        <Typography model="body" size="large" weight="regular" color="support.dark">
          Tem outro paciente cadastrado com esse CPF. <b>Quer verificar se é a mesma pessoa?</b>
        </Typography>
      </Box>
      <Flex justifyContent={'space-between'}>
        <Button onClick={props.onCancel} variant={'outline'} w={'100%'} h={'60px'} mr={'10px'}>
          Voltar
        </Button>
        <Button onClick={props.onConfirm} w={'100%'} h={'60px'}>
          Verificar
        </Button>
      </Flex>
    </Box>
  );
}
