import { Flex } from '@chakra-ui/react';
import Typography from 'src/components/Typography';
import styled, { keyframes } from 'styled-components';
import { Certificate } from './MedicalCertificate/MedicalCertificate';
import { DOCUMENT_TABS_LABEL } from './DocumentTabs/DocumentTabs';
import { Exam } from './Exam/Exam';
import { Prescription as PrescriptionArea } from './Prescription/Prescription';
import { Referral } from './Referral/MedicalReferral';
import { Report } from './MedicalReport/MedicalReport';
import { PrescriptionHistory } from 'src/services/prescriptionHistory';
import { Observation } from './Observation';
import { useMemo } from 'react';

interface ActiveAreaProps {
  tabActive: DOCUMENT_TABS_LABEL;
  prescription: PrescriptionHistory;
}

export function ActiveArea(props: ActiveAreaProps) {
  const { tabActive, prescription } = props;

  const areaContent = useMemo(() => {
    switch (tabActive) {
      case DOCUMENT_TABS_LABEL.EXAM:
        return <Exam items={prescription.exams} />;
      case DOCUMENT_TABS_LABEL.CERTIFICATE:
        return <Certificate items={prescription.prescriptionMedicalStatements} />;
      case DOCUMENT_TABS_LABEL.REFERRAL:
        return <Referral items={prescription.prescriptionReferral} />;
      case DOCUMENT_TABS_LABEL.REPORT:
        return <Report items={prescription.medicalReports} />;
      default:
        return <PrescriptionArea medicines={prescription.medicines} manualMedicines={prescription.manualMedicines} />;
    }
  }, [
    prescription.exams,
    prescription.medicalReports,
    prescription.medicines,
    prescription.prescriptionMedicalStatements,
    prescription.prescriptionReferral,
    tabActive,
    prescription.manualMedicines,
  ]);

  return (
    <Flex mt="20px" p="20px" mb="10px" flexDirection="column" bgColor="support.surface" borderRadius={'20px'}>
      <Flex py="10px" mb="20px" justifyContent="center" borderRadius="20px" bgColor="support.background">
        <Typography model="h3" weight="bold">
          {tabActive}
        </Typography>
      </Flex>
      <AreaContentFlex key={tabActive} alignItems="flex-start">
        {areaContent}
      </AreaContentFlex>

      {prescription.observations && <Observation text={prescription.observations} />}
    </Flex>
  );
}

const AreaContentAnimation = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;
const AreaContentFlex = styled(Flex)`
  animation: ${AreaContentAnimation} 0.3s linear;
`;
