export const STATES = [
  { name: 'Acre', initials: 'AC', code: '12' },
  { name: 'Alagoas', initials: 'AL', code: '127' },
  { name: 'Amapá', initials: 'AP', code: '16' },
  { name: 'Amazonas', initials: 'AM', code: '13' },
  { name: 'Bahia', initials: 'BA', code: '29' },
  { name: 'Ceará', initials: 'CE', code: '23' },
  { name: 'Distrito Federal', initials: 'DF', code: '53' },
  { name: 'Espírito Santo', initials: 'ES', code: '32' },
  { name: 'Goiás', initials: 'GO', code: '52' },
  { name: 'Maranhão', initials: 'MA', code: '21' },
  { name: 'Mato Grosso', initials: 'MT', code: '51' },
  { name: 'Mato Grosso do Sul', initials: 'MS', code: '50' },
  { name: 'Minas Gerais', initials: 'MG', code: '31' },
  { name: 'Pará', initials: 'PA', code: '15' },
  { name: 'Paraíba', initials: 'PB', code: '25' },
  { name: 'Paraná', initials: 'PR', code: '41' },
  { name: 'Pernambuco', initials: 'PE', code: '26' },
  { name: 'Piauí', initials: 'PI', code: '22' },
  { name: 'Rio de Janeiro', initials: 'RJ', code: '33' },
  { name: 'Rio Grande do Norte', initials: 'RN', code: '24' },
  { name: 'Rio Grande do Sul', initials: 'RS', code: '43' },
  { name: 'Rondônia', initials: 'RO', code: '11' },
  { name: 'Roraima', initials: 'RR', code: '14' },
  { name: 'Santa Catarina', initials: 'SC', code: '42' },
  { name: 'São Paulo', initials: 'SP', code: '35' },
  { name: 'Sergipe', initials: 'SE', code: '28' },
  { name: 'Tocantins', initials: 'TO', code: '17' },
];
