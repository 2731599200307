import { Box, Flex } from '@chakra-ui/react';
import { ExamItem } from './ExamItem';
import { PrescriptionExam } from 'src/services/prescriptionHistory';

interface ExamProps {
  items: PrescriptionExam[];
}

export function Exam(props: ExamProps) {
  const { items } = props;
  return (
    <Box>
      <Flex flexDirection="column" gap="20px">
        {items.map((item) => (
          <ExamItem item={item} key={item.label} />
        ))}
      </Flex>
    </Box>
  );
}
