import { Table, TableContainer, Th, Thead, Tr } from '@chakra-ui/react';
import { HistoryItem } from './HistoryItem';
import Typography from 'src/components/Typography';
import './style.module.css';
import { Button } from 'src/components/UI/Button';
import ArrowIcon from 'src/components/Icons/ArrowIcon';
import { AnimatePresence, motion } from 'framer-motion';
import { ORDER_BY, ORDER_TYPES, PrescriptionHistory } from 'src/services/prescriptionHistory';
import { isEmpty } from 'lodash';
import { EmptyView } from './EmptyView';
import { Skeleton } from 'src/components/UI/Skeleton';
import { Flex } from 'src/components/UI/Flex';

interface HistoryListProps {
  patientHistory: PrescriptionHistory[];
  onViewDetails(data: PrescriptionHistory): void;
  onChangeOrderBy(value: ORDER_BY): void;
  onChangeOrderType(value: ORDER_TYPES): void;
  orderBy: ORDER_BY | null;
  orderType: ORDER_TYPES;
  onCancelPrescription(validationCode: string, prescriptionId: number): void;
  isLoading: boolean;
}

export function HistoryList(props: HistoryListProps) {
  const handleChangeOrder = (orderBy: ORDER_BY) => {
    props.onChangeOrderType(props.orderType === ORDER_TYPES.DESC ? ORDER_TYPES.ASC : ORDER_TYPES.DESC);
    props.onChangeOrderBy(orderBy);
  };

  if (props.isLoading)
    return (
      <Flex mt={'10px'} direction={'column'} gap={'5px'}>
        <Skeleton w={'100%'} h={'50px'} borderRadius={'8px'} />
        <Skeleton w={'100%'} h={'50px'} borderRadius={'8px'} />
        <Skeleton w={'100%'} h={'50px'} borderRadius={'8px'} />
        <Skeleton w={'100%'} h={'50px'} borderRadius={'8px'} />
        <Skeleton w={'100%'} h={'50px'} borderRadius={'8px'} />
      </Flex>
    );

  return (
    <AnimatePresence>
      {isEmpty(props.patientHistory) ? (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <EmptyView />
        </motion.div>
      ) : (
        <TableContainer>
          <Table variant={'unstyled'} w={'100%'}>
            <Thead>
              <Tr>
                <Th textAlign={'left'}>
                  <Button
                    variant="ghost"
                    _hover={{}}
                    p={0}
                    gap={'7px'}
                    onClick={() => handleChangeOrder(ORDER_BY.DATE)}
                  >
                    <Typography model="body" size="normal" weight="regular" color="support.dark">
                      Emissão
                    </Typography>

                    <ArrowIcon
                      direction={props.orderBy === ORDER_BY.DATE && props.orderType === ORDER_TYPES.ASC ? 'up' : 'down'}
                      width={7}
                    />
                  </Button>
                </Th>
                <Th textAlign={'left'}>
                  <Button
                    variant="ghost"
                    _hover={{}}
                    p={0}
                    gap={'7px'}
                    onClick={() => handleChangeOrder(ORDER_BY.PATIENT_NAME)}
                  >
                    <Typography model="body" size="normal" weight="regular" color="support.dark">
                      Nome do paciente
                    </Typography>

                    <ArrowIcon
                      direction={
                        props.orderBy === ORDER_BY.PATIENT_NAME && props.orderType === ORDER_TYPES.ASC ? 'up' : 'down'
                      }
                      width={7}
                    />
                  </Button>
                </Th>
                <Th textAlign={'left'} display={{ base: 'none', md: 'table-cell' }}>
                  <Typography
                    model="body"
                    size="normal"
                    weight="regular"
                    color="support.dark"
                    textTransform="capitalize"
                  >
                    Documentos médicos
                  </Typography>
                </Th>
                <Th textAlign={'right'} display={{ base: 'none', md: 'table-cell' }}>
                  <Typography
                    model="body"
                    size="normal"
                    weight="regular"
                    color="support.dark"
                    textTransform="capitalize"
                  >
                    Token da Receita
                  </Typography>
                </Th>
              </Tr>
            </Thead>
            <AnimatePresence>
              <motion.tbody
                initial={{ y: '-30px', opacity: 0, position: 'absolute' }}
                animate={{ y: 0, opacity: 1, position: 'initial' }}
                style={{ backgroundColor: '#FFFFFF' }}
                key={props.patientHistory.length}
                transition={{
                  type: 'spring',
                  stiffness: 100,
                  mass: 0.3,
                }}
              >
                {props.patientHistory.map((data: PrescriptionHistory, index: number) => {
                  return (
                    <HistoryItem
                      prescriptionData={data}
                      key={data._id}
                      onViewDetails={props.onViewDetails}
                      onCancelPrescription={props.onCancelPrescription}
                    />
                  );
                })}
              </motion.tbody>
            </AnimatePresence>
          </Table>
        </TableContainer>
      )}
    </AnimatePresence>
  );
}
