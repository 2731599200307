import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';

import { UploadLogoDropZone } from './UploadLogoDropZone';
import { AttendancePlaceFormSkeleton } from './skeletons/AttendancePlaceFormSkeleton';

import MevoInput from 'src/components/Input';
import Typography from 'src/components/Typography';
import { Button } from 'src/components/UI/Button';
import { Flex } from 'src/components/UI/Flex';
import { MASKS } from 'src/components/UI/Input/MaskedInput';
import { FormControl } from 'src/components/UI/FormControl';

import { Yup } from 'src/helpers/Yup';
import { onlyDigits } from 'src/helpers/string';

import { CEPService } from 'src/services/cep';

import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';

import { PrescriberService } from 'src/services/prescriber';
import SelectState from 'src/components/SelectState';
import { Box } from 'src/components/UI/Box';

interface AttendancePlaceFormFields {
  placeName: string;
  phoneNumber: string;
  CNES: string;
  postalCode: string;
  address: string;
  houseNumber: string;
  district: string;
  complement: string;
  city: string;
  state: string;
}

const attendancePlaceFormSchema = Yup.object().shape({
  placeName: Yup.string().required('Informe o nome do local de atendimento.'),
  phoneNumber: Yup.string()
    .required('Informe um número de telefone válido.')
    .test('validate phoneNumber', 'Informe um número de telefone válido.', (phoneNumber) => {
      if (!phoneNumber) return false;
      return onlyDigits(phoneNumber).length === 10 || onlyDigits(phoneNumber).length === 11;
    }),
  CNES: Yup.string()
    .nullable()
    .test('Verify CNES length', 'O CNES deve conter exatamente 7 dígitos.', (cnes) => {
      if (!cnes) return true;

      return cnes.length > 0 && cnes.length < 7 ? false : true;
    }),
  postalCode: Yup.string()
    .required('Informe um CEP válido.')
    .test('validate postal code', 'Informe um CEP válido.', (postalCode) => {
      return onlyDigits(postalCode || '').length === 8;
    }),
  address: Yup.string()
    .required('Informe seu endereço profissional.')
    .max(100, 'O campo endereço deve ter no máximo 100 caracteres.'),
  houseNumber: Yup.string()
    .required('Informe o número do endereço profissional.')
    .max(10, 'O campo número deve ter no máximo 10 caracteres.'),
  district: Yup.string()
    .required('Informe o bairro do endereço profissional.')
    .max(100, 'O campo bairro deve ter no máximo 100 caracteres.'),
  complement: Yup.string().nullable().max(100, 'O campo complemento deve ter no máximo 100 caracteres.'),
  city: Yup.string().required('Informe a cidade.').max(100, 'O campo cidade deve ter no máximo 100 caracteres.'),
  state: Yup.string().required('Informe a UF do endereço profissional.'),
});

const initialValues: AttendancePlaceFormFields = {
  placeName: '',
  phoneNumber: '',
  CNES: '',
  postalCode: '',
  address: '',
  houseNumber: '',
  district: '',
  complement: '',
  city: '',
  state: '',
};

interface AttendancePlaceFormProps {
  onEdit?(): void;
}

export function AttendancePlaceForm(props: AttendancePlaceFormProps) {
  const [logoUrl, setLogoUrl] = useState('');
  const [mask, setMask] = useState(MASKS.BRAZIL_LANDLINE_PHONE_NINE_DIGITS);
  const [defaultValues, setDefaultValues] = useState<AttendancePlaceFormFields & { logoUrl: string }>({
    ...initialValues,
    logoUrl: '',
  });
  const { openToast } = useFeedbackContext();
  const { prescriberData, setPrescriberData } = usePrescriberContext();

  const {
    values,
    errors,
    isSubmitting,
    handleChange,
    setFieldValue,
    setValues,
    setFieldError,
    validateField,
    handleSubmit,
  } = useFormik<AttendancePlaceFormFields>({
    initialValues: initialValues,
    validationSchema: attendancePlaceFormSchema,
    validateOnChange: false,
    onSubmit: onSubmit,
  });

  async function onSubmit(data: AttendancePlaceFormFields) {
    try {
      if (!prescriberData) return;

      await PrescriberService.updatePrescriber(prescriberData._id, {
        ...prescriberData,
        attendanceAddress: {
          ...prescriberData.attendanceAddress,
          ...data,
          logoUrl: logoUrl,
        },
      });

      setPrescriberData({
        ...prescriberData,
        attendanceAddress: {
          ...prescriberData.attendanceAddress,
          ...data,
          logoUrl: logoUrl,
        },
      });

      openToast('Dados salvos com sucesso.', {
        type: 'success',
        duration: 2000,
      });

      if (props.onEdit) {
        props.onEdit();
      }
    } catch (error) {
      openToast('Ocorreu um erro ao salvar seus dados. Tente novamente em instantes.', {
        type: 'error',
        duration: 2000,
      });
    }
  }

  async function searchPostalCodeData(postalCode: string) {
    if (onlyDigits(postalCode).length < 8) return;

    try {
      const postalCodeData = await CEPService.searchForCepInViaCep(postalCode);

      if (!postalCodeData) return;

      setFieldValue('address', postalCodeData.logradouro);
      setFieldValue('district', postalCodeData.bairro);
      setFieldValue('complement', postalCodeData.complemento);
      setFieldValue('city', postalCodeData.localidade);
      setFieldValue('state', postalCodeData.uf);
    } catch (e: any) {
      if (e.message === 'CEP inválido') {
        return;
      }

      setFieldError('postalCode', 'Falha ao buscar CEP. Digite seu endereço abaixo.');
    }
  }

  useEffect(() => {
    if (!prescriberData) return;

    setValues({
      placeName: prescriberData.attendanceAddress.placeName,
      phoneNumber: prescriberData.attendanceAddress.phoneNumber,
      CNES: prescriberData.attendanceAddress.CNES ?? '',
      postalCode: prescriberData.attendanceAddress.postalCode,
      address: prescriberData.attendanceAddress.address,
      houseNumber: prescriberData.attendanceAddress.houseNumber,
      district: prescriberData.attendanceAddress.district,
      complement: prescriberData.attendanceAddress.complement,
      city: prescriberData.attendanceAddress.city,
      state: prescriberData.attendanceAddress.state,
    });

    setLogoUrl(prescriberData.attendanceAddress.logoUrl ?? '');

    setDefaultValues({
      placeName: prescriberData.attendanceAddress.placeName,
      phoneNumber: prescriberData.attendanceAddress.phoneNumber,
      CNES: prescriberData.attendanceAddress.CNES ?? '',
      postalCode: prescriberData.attendanceAddress.postalCode,
      address: prescriberData.attendanceAddress.address,
      houseNumber: prescriberData.attendanceAddress.houseNumber,
      district: prescriberData.attendanceAddress.district,
      complement: prescriberData.attendanceAddress.complement,
      city: prescriberData.attendanceAddress.city,
      state: prescriberData.attendanceAddress.state,
      logoUrl: prescriberData.attendanceAddress.logoUrl ?? '',
    });
  }, [prescriberData, setValues]);

  const isSameData = isEqual(defaultValues, { ...values, logoUrl: logoUrl });

  if (!prescriberData) {
    return <AttendancePlaceFormSkeleton />;
  }

  const currentLogoUrl = prescriberData.attendanceAddress.logoUrl ?? '';

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        height: '100%',
      }}
      data-testId={'attendance-place-form'}
    >
      <Flex
        flexDirection={'column'}
        gap={'20px'}
        bgColor={{
          base: 'transparent',
          lg: 'white',
        }}
        borderRadius={'20px'}
        p={{ base: '0px', sm: '32px' }}
      >
        <Box display={{ base: 'none', sm: 'block' }}>
          <Typography model={'h2'} size={'large'} weight={'bold'}>
            Local de atendimento
          </Typography>
        </Box>

        <UploadLogoDropZone currentLogoUrl={currentLogoUrl} onChangeLogoUrl={setLogoUrl} />

        <FormControl isInvalid={!!errors.placeName}>
          <MevoInput
            name={'placeName'}
            placeholder={'Digite o nome do local de atendimento'}
            labelText={'Nome do local de atendimento*'}
            value={values.placeName}
            onChange={handleChange}
            onBlur={() => validateField('placeName')}
            errorText={errors.placeName}
            isDisabled={true}
            m={0}
          />
        </FormControl>

        <FormControl isInvalid={!!errors.phoneNumber}>
          <MevoInput
            name={'phoneNumber'}
            placeholder={'(+55) 0 0000-0000'}
            labelText={'Telefone comercial*'}
            mask={mask}
            maskPlaceholder={''}
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={(e) => {
              validateField('phoneNumber');
              if (onlyDigits(e.target.value).length === 10) {
                setMask(MASKS.BRAZIL_LANDLINE_PHONE);
              }
              if (onlyDigits(e.target.value).length === 11) {
                setMask(MASKS.BRAZIL_LANDLINE_PHONE_NINE_DIGITS);
              }
            }}
            onFocus={() => {
              setMask('(99) 999999999');
            }}
            errorText={errors.phoneNumber}
            m={0}
            autoFocus
          />
        </FormControl>

        <FormControl isInvalid={!!errors.CNES}>
          <MevoInput
            name={'CNES'}
            placeholder={'0000000'}
            labelText={'Cadastro de estabelecimento de saúde (CNES)'}
            mask={'9999999'}
            maskPlaceholder={''}
            value={values.CNES}
            onChange={handleChange}
            onBlur={() => validateField('CNES')}
            errorText={errors.CNES}
            m={0}
          />
        </FormControl>

        <Flex
          gap={{
            base: '10px',
            sm: '0',
            md: '20px',
          }}
          alignItems={{
            base: 'center',
            sm: 'flex-start',
            md: 'center',
          }}
          flexDirection={{
            base: 'row',
            sm: 'column',
            md: 'row',
          }}
        >
          <FormControl
            w={{
              base: '60%',
              sm: '100%',
              md: '30%',
            }}
            isInvalid={!!errors.postalCode}
          >
            <MevoInput
              name={'postalCode'}
              id="postalCode"
              labelText="CEP*"
              type={'tel'}
              placeholderText={'Busque seu CEP'}
              mask={MASKS.BRAZIL_POSTAL_CODE}
              maskChar={''}
              value={values.postalCode}
              onChange={handleChange}
              onBlur={(e) => {
                validateField('postalCode');
                searchPostalCodeData(e.target.value);
              }}
              errorText={errors.postalCode}
              h={'45px'}
            />
          </FormControl>

          <Button
            w={'110px'}
            mt={{
              base: '10px',
              sm: 0,
              md: '10px',
            }}
            variant={'ghost'}
            color={'primary.1000'}
            textDecoration={'underline'}
            fontSize={'14px'}
            textAlign={'center'}
            h={'24px'}
            onClick={() => {
              window.open('https://buscacepinter.correios.com.br/app/endereco/index.php', '_blank', 'noopener');
            }}
          >
            Não sei meu cep
          </Button>
        </Flex>

        <Flex
          gap={{
            base: '10px',
            lg: '20px',
          }}
          flexDirection={{
            base: 'row',
            lg: 'column',
            xl: 'row',
          }}
        >
          <FormControl
            w={{
              base: '80%',
              lg: '100%',
              xl: '80%',
            }}
            isInvalid={!!errors.address}
          >
            <MevoInput
              name={'address'}
              placeholder={'Digite o nome da rua, avenida, etc'}
              labelText={'Endereço*'}
              value={values.address}
              onChange={handleChange}
              onBlur={() => validateField('address')}
              errorText={errors.address}
              maxLength={100}
              m={0}
            />
          </FormControl>

          <FormControl
            w={{
              base: '20%',
              lg: '100%',
              xl: '20%',
            }}
            isInvalid={!!errors.houseNumber}
          >
            <MevoInput
              name={'houseNumber'}
              placeholder={'000000'}
              labelText={'N˚*'}
              value={values.houseNumber}
              maxLength={10}
              onChange={handleChange}
              onBlur={() => validateField('houseNumber')}
              errorText={errors.houseNumber}
              m={0}
            />
          </FormControl>
        </Flex>

        <FormControl isInvalid={!!errors.district}>
          <MevoInput
            name={'district'}
            placeholder={'Digite o bairro'}
            labelText={'Bairro*'}
            value={values.district}
            onChange={handleChange}
            onBlur={() => validateField('district')}
            errorText={errors.district}
            maxLength={100}
            m={0}
          />
        </FormControl>

        <FormControl isInvalid={!!errors.complement}>
          <MevoInput
            name={'complement'}
            placeholder={'Digite o complemento'}
            labelText={'Complemento'}
            value={values.complement}
            onChange={handleChange}
            onBlur={() => validateField('complement')}
            errorText={errors.complement}
            maxLength={100}
            m={0}
          />
        </FormControl>

        <Flex
          gap={{
            base: '10px',
            lg: '20px',
          }}
        >
          <FormControl isInvalid={!!errors.city}>
            <MevoInput
              name={'city'}
              placeholder={'Digite a cidade'}
              labelText={'Cidade*'}
              value={values.city}
              onChange={handleChange}
              onBlur={() => validateField('city')}
              errorText={errors.city}
              maxLength={100}
              m={0}
            />
          </FormControl>

          <FormControl isInvalid={!!errors.state} maxW={'110px'}>
            <SelectState
              label={'UF*'}
              placeholder={'Selecionar'}
              value={values.state}
              onChange={(value) => setFieldValue('state', value)}
              onBlur={() => validateField('state')}
              errorMessage={errors.state}
            />
          </FormControl>
        </Flex>

        <Button
          type={'submit'}
          isLoading={isSubmitting}
          isDisabled={isSameData}
          w={{
            base: 'full',
            md: '150px',
          }}
          h={'60px'}
          alignSelf={'flex-end'}
        >
          Salvar
        </Button>
      </Flex>
    </form>
  );
}
