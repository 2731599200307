import Typography from 'src/components/Typography';
import ArrowIcon from 'src/components/Icons/ArrowIcon';
import { AccordionButton } from './UI/Accordion';

interface AccordionFormButtonProps {
  text: string;
  isDisabled: boolean;
  isExpanded: boolean;
  onClick(): void;
}

export function AccordionFormButton(props: AccordionFormButtonProps) {
  return (
    <AccordionButton
      bgColor={'white'}
      borderRadius={props.isExpanded ? '10px 10px 0 0' : '10px'}
      boxShadow={props.isExpanded ? 'none' : '3px 3px 10px #3D0E6B1A'}
      minW={'full'}
      h={'80px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      border={'1px'}
      borderColor={'transparent'}
      p={'0 30px'}
      _disabled={{
        opacity: '0.5',
        borderColor: 'primary.1000',
        backgroundColor: 'background',
      }}
      top={0}
      zIndex={10}
      disabled={props.isDisabled}
      onClick={props.onClick}
      _focus={{ backgroundColor: 'white' }}
      _expanded={{ backgroundColor: 'white' }}
      _hover={{}}
    >
      <Typography fontSize={18}>{props.text}</Typography>
      <ArrowIcon direction={props.isExpanded ? 'up' : 'down'} width={20} filled variantColor="secondary.main" />
    </AccordionButton>
  );
}
