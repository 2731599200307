import ExitIcon from 'src/components/Icons/ExitIcon';
import { IconButton } from 'src/components/UI/IconButton';
import { Tag } from 'src/components/UI/Tag/Tag';
import { TagLabel } from 'src/components/UI/Tag/TagLabel';
import { Allergy } from 'src/services/patient';

interface AllergyTagProps {
  allergy: Allergy;
  onClick(allergyId: number): void;
}

export function AllergyTag(props: AllergyTagProps) {
  const handleDelete = () => {
    props.onClick(props.allergy.id);
  };

  return (
    <Tag bgColor={'notification.error'} color={'surface'} borderRadius={'20px'} p={'5px'}>
      <IconButton
        onClick={handleDelete}
        _hover={{}}
        aria-label={'close'}
        variant={'ghost'}
        p={'0'}
        minW={'13px'}
        h={'13px'}
        mr={'5px'}
      >
        <ExitIcon width={13} variantColor={'notification.error'} baseColor={'white'} />
      </IconButton>
      <TagLabel fontSize={'10px'} fontWeight={700} whiteSpace={'nowrap'}>
        {props.allergy.name}
      </TagLabel>
    </Tag>
  );
}
