import * as React from 'react';
import { getStringColor } from 'src/theme/colors';
import { ShadowTypes } from '../Helpers/Shadows';
import { StyledIconContainer } from './_StyledContainers';

export interface IconBackgroundProps {
  color: string;
  radius?: number;
  padding?: number | string;
}

export interface IconProps {
  width?: number;
  variantColor?: string;
  baseColor?: string;
  disabled?: boolean;
  fullColor?: boolean;
  background?: IconBackgroundProps;
  shadow?: ShadowTypes;
  onClick?: () => void;
}

interface IconPatternProps extends IconProps {
  component: React.FunctionComponent<any>;
  variantPlaces: Array<number> | number;
  customStyle?: string;
}

const IconPattern: React.FunctionComponent<IconPatternProps> = ({
  variantColor = 'secondary.900',
  baseColor,
  width = 30,
  component,
  variantPlaces = [],
  disabled = false,
  fullColor = false,
  customStyle = '',
  background,
  shadow,
  onClick,
}) => {
  const parsedVariantColor = React.useMemo(() => {
    return getStringColor(variantColor);
  }, [variantColor]);

  const styledPlaces = React.useMemo(() => {
    if (typeof variantPlaces === 'number') {
      return `path:nth-of-type(${variantPlaces})`;
    }
    return `${variantPlaces.reduce((prev, crr) => {
      return prev ? prev + `, & > path:nth-of-type(${crr})` : `path:nth-of-type(${crr})`;
    }, '')}`;
  }, [variantPlaces]);

  const styledBase = React.useMemo(() => {
    if (!baseColor) return '';

    if (typeof variantPlaces === 'number') {
      return `path:not(:nth-of-type(${variantPlaces}))`;
    }
    return `${variantPlaces.reduce((prev, crr) => {
      return prev ? prev + `, & > path:not(:nth-of-type(${crr}))` : `path:not(:nth-of-type(${crr}))`;
    }, '')}`;
  }, [baseColor, variantPlaces]);

  const fullColorStyle = React.useMemo(() => {
    if (!fullColor) return '';

    return `& path {
      fill: ${parsedVariantColor};
    }`;
  }, [fullColor, parsedVariantColor]);

  const useBackground = React.useMemo(() => {
    if (!background) return '';
    return `
      background-color: ${getStringColor(background.color)};
      border-radius: ${background.radius === undefined ? 20 : background.radius}px;
      padding: ${typeof background.padding === 'string' ? background.padding : `${background.padding}px`};
    `;
  }, [background]);

  const SVGComponent = React.useMemo(() => {
    return component;
  }, [component]);

  return (
    <StyledIconContainer
      className={`icon-container ${shadow}`}
      onClick={onClick}
      hasClick={!!onClick}
      useBackground={useBackground}
      parsedVariantColor={parsedVariantColor}
      styledPlaces={styledPlaces}
      styledBase={styledBase}
      fullColorStyle={fullColorStyle}
      width={width}
      disabled={disabled}
      fullColor={fullColor}
      customStyle={customStyle}
      baseColor={baseColor}
    >
      <SVGComponent />
    </StyledIconContainer>
  );
};

export default IconPattern;
