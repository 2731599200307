import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import useLoading from 'src/helpers/useLoading';
import { useFeedbackContext } from 'src/providers/FeedbackProvider';
import { Allergy, PatientService } from 'src/services/patient';
import AutoComplete from './UI/AutoComplete';
import { FormControl } from './UI/FormControl';
import { FormErrorMessage } from './UI/FormErrorMessage';
import { FormLabel } from './UI/FormLabel';

interface SearchAllergyProps {
  label?: string;
  onSelectAllergy(allergies: Allergy): void;
  disabled?: boolean;
  hasError?: boolean;
  errorMessage?: string;
  clearAfterSelect?: boolean;
}

export function SearchAllergy(props: SearchAllergyProps) {
  const { onSelectAllergy, label, hasError, errorMessage, clearAfterSelect } = props;

  const [allergyOptions, setAllergyOptions] = useState<Allergy[]>([]);
  const [value, setValue] = useState<string | null>('');

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, runWithLoading] = useLoading();

  const { openToast } = useFeedbackContext();

  const findAllergy = useMemo(
    () =>
      debounce(async (allergy: string) => {
        if (allergy === '') return;

        try {
          runWithLoading(async () => {
            const allergyData = await PatientService.getAllergies(allergy);
            setAllergyOptions(allergyData);
            if (!isMenuOpen) {
              setIsMenuOpen(true);
            }
          });
        } catch (e) {
          openToast('Não foi possível buscar alergias, tente novamente.', { type: 'error' });
        }
      }, 400),
    [runWithLoading, isMenuOpen, openToast]
  );

  const onInputAllergy = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      findAllergy(event.target.value);
      setValue(event.target.value);
    },
    [findAllergy, setValue]
  );

  const handleSelectAllergy = useCallback(
    (allergy: Allergy) => {
      onSelectAllergy(allergy);
      setAllergyOptions([]);
      setIsMenuOpen(false);

      if (clearAfterSelect) {
        setValue(``);
      }
    },
    [onSelectAllergy, setAllergyOptions, setValue, clearAfterSelect]
  );

  return (
    <FormControl isInvalid={hasError || false}>
      {label && <FormLabel textTransform={'uppercase'}>{label}</FormLabel>}
      <AutoComplete
        onInputChange={onInputAllergy}
        inputValue={value || ''}
        options={allergyOptions}
        onSelect={handleSelectAllergy}
        isMenuOpen={isMenuOpen}
        onCloseMenu={() => setIsMenuOpen(false)}
        noOptionsText={'Nenhuma alergia encontrada'}
        placeholder={'Informe uma alergia'}
        getOptionLabel={(option: Allergy) => option.name}
        menuMaxHeigh={'100px'}
        onClearInput={() => {
          setValue('');
        }}
        isLoading={loading}
      />
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
}
