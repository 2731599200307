import { Flex } from 'src/components/UI/Flex';
import { Button } from 'src/components/UI/Button';
import Typography from 'src/components/Typography';
import WhatsappIcon from 'src/components/Icons/WhatsappIcon';

import { openWhatsApp } from 'src/helpers/openWhatsApp';
import { DEFAULT_MEVO_WHATSAPP_NUMBER } from 'src/helpers/defaultValues';

import { AuthService } from 'src/services/AuthService';

import { useFeedbackContext } from 'src/providers/FeedbackProvider';

import { ReactComponent as Allegril } from 'src/assets/mevo-pill/allegril.svg';

interface ConfirmEmailDialogProps {
  email: string;
  onResendConfirmationEmail(): void;
}

export function ConfirmEmailDialog({ email, onResendConfirmationEmail }: ConfirmEmailDialogProps) {
  const { openToast } = useFeedbackContext();

  async function resendEmail() {
    try {
      await AuthService.resendEmail(email);

      openToast('E-mail reenviado com sucesso.', { type: 'success', duration: 4000 });

      onResendConfirmationEmail();
    } catch (error) {
      openToast('Você alcançou o limite de tentativas, tente novamente mais tarde.', { type: 'error', duration: 4000 });
    }
  }

  function handleWhatsApp() {
    openWhatsApp(DEFAULT_MEVO_WHATSAPP_NUMBER());
  }

  return (
    <Flex
      w={'full'}
      h={'full'}
      py={'20px'}
      px={{
        base: '20px',
        md: '50px',
      }}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Flex flexDirection={'column'} gap={'20px'}>
        <Flex alignItems={'center'} gap={'20px'}>
          <Flex alignItems={'center'} flexDirection={'column'}>
            <Allegril />
          </Flex>

          <Typography model={'h1'} weight={'bold'}>
            Confirme <br />
            seu email!
          </Typography>
        </Flex>

        <Typography model={'body'} size={'large'} weight={'regular'}>
          Não foi possível efetuar o login pois <b>seu email não foi confirmado.</b> Verifique sua caixa de entrada ou
          clique abaixo para reenviar o email de confirmação.
        </Typography>

        <Typography model={'body'} size={'large'} weight={'regular'}>
          Caso não tenha recebido o email, verifique sua caixa de spam ou{' '}
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleWhatsApp}>
            <span>fale com a gente.</span>
            <Flex display={'inline-flex'} alignItems={'center'} ml={'5px'}>
              <WhatsappIcon variantColor={'primary.1000'} width={16} />
            </Flex>
          </span>
        </Typography>

        <Button h={'60px'} w={'100%'} onClick={resendEmail}>
          Reenviar email
        </Button>
      </Flex>
    </Flex>
  );
}
