import axios from 'axios';

export const CEPService = {
  async searchForCepInViaCep(cep: string): Promise<Address | null> {
    const { data } = await axios.get<Address | null>(`https://viacep.com.br/ws/${cep}/json/`);
    if (!data || data.erro) throw new Error('CEP inválido');
    return data;
  },
};

export interface Address {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
  erro?: boolean;
}
