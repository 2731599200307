import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_PATH,
  headers: {
    // Authorization: `Basic ${process.env.REACT_APP_API_BASIC_TOKEN}`,
  },
});

export const setAuthorizationHeader = (authorizationToken: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${authorizationToken}`;
};
