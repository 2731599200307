import { SimpleGrid } from '@chakra-ui/react';
import { usePrescriberContext } from 'src/providers/PrescriberProvider';
import Typography from './Typography';
import { Box } from './UI/Box';
import { Text } from './UI/Text';
import { Flex } from './UI/Flex';
import { Button } from './UI/Button';
import AddIcon from './Icons/AddIcon';
import 'src/components/Helpers/animations.css';
import { BasicAbstractBackground } from './BasicAbstractLayout';
import DocPaperMultipleIcon from './Icons/DocPaperMultipleIcon';
import CellPhoneIcon from './Icons/CellPhoneIcon';
import TemplatePdfIcon from './Icons/TemplatePdfIcon';
import { getFirstAndLastWords } from 'src/helpers/getFirstAndLastWords';

interface EmptyPatientViewProps {
  onClick(): void;
}

const baseBoxStyle = {
  borderRadius: '20px',
  padding: '20px',
  background: 'surface',
  height: '100%',
  borderWidth: '1px',
  borderColor: 'primary.50',
};

const tourData = [
  {
    icon: <DocPaperMultipleIcon />,
    title: 'Menos cliques, mais agilidade',
    description: (
      <Typography model={'body'} size={'large'} weight={'regular'}>
        Crie e <b>salve modelos</b> de prescrições, <b>para</b> ter mais <b>praticidade</b> nos seus atendimentos.
      </Typography>
    ),
  },
  {
    icon: <TemplatePdfIcon />,
    title: 'O melhor nas suas mãos',
    description: (
      <Typography model={'body'} size={'large'} weight={'regular'}>
        Tenha mais <b>segurança</b> para prescrever, <b>com</b> alerta de <b>interações medicamentosas</b> em tempo
        real.
      </Typography>
    ),
  },
  {
    icon: <CellPhoneIcon variantColor="support.blue" />,
    title: 'Comodidade para seu paciente',
    description: (
      <Typography model={'body'} size={'large'} weight={'regular'}>
        O paciente <b>recebe</b> a Receita Digital Mevo no <b>celular</b>, consegue <b>comprar online</b> e pode
        <b> consultar</b> sempre que precisar.
      </Typography>
    ),
  },
];

export function EmptyPatientView(props: EmptyPatientViewProps) {
  const { prescriberData } = usePrescriberContext();

  return (
    <BasicAbstractBackground>
      <Flex w={'100%'} maxH={'100%'} flexDir={'column'}>
        <Flex justifyContent={'center'}>
          {prescriberData && (
            <Box mt={'38px'} mb={{ base: '20px', lg: 'calc(100vh/4)' }}>
              <Typography model={'h3'} weight={'regular'} textAlign={'center'}>
                Seja bem-vindo,
              </Typography>
              <Typography model={'h1'} weight={'bold'} textAlign={'center'} textTransform={'capitalize'}>
                {getFirstAndLastWords(prescriberData.fullName)}
              </Typography>
            </Box>
          )}
        </Flex>

        <SimpleGrid columns={{ base: 1, sm: 2, lg: 2, xl: 4 }} gap={'10px'}>
          <Box {...baseBoxStyle} bg={'secondary.100'} borderColor={'secondary.100'}>
            <Text
              fontSize={{ base: '30px', sm: '24px', md: '24px' }}
              lineHeight={'27px'}
              fontWeight={700}
              mb={'22px'}
              whiteSpace={'break-spaces'}
            >
              Começar a prescrever!
            </Text>
            <Box mb={'22px'}>
              <Typography model={'body'} size={'large'} weight={'regular'}>
                Cadastre o primeiro paciente e comece a prescrever com a Mevo.
              </Typography>
            </Box>

            <Button
              leftIcon={
                <AddIcon width={16} variantColor="notification.success" baseColor="secondary.100" outlined={true} />
              }
              onClick={props.onClick}
              p={'13px'}
              whiteSpace={'break-spaces'}
              fontSize={'14px'}
              w={'100%'}
              className={'primary-pulse-animation'}
            >
              Cadastrar paciente
            </Button>
          </Box>

          {tourData.map((tour) => {
            return (
              <Box {...baseBoxStyle} key={tour.title}>
                <Flex mb={'33px'}>{tour.icon}</Flex>
                <Box mb={'23px'}>
                  <Typography model={'h3'} weight={'bold'}>
                    {tour.title}
                  </Typography>
                </Box>
                {tour.description}
              </Box>
            );
          })}
        </SimpleGrid>
      </Flex>
    </BasicAbstractBackground>
  );
}
