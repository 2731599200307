import { NavLink } from 'react-router-dom';
import { Flex } from '../../UI/Flex';

interface BottomTabButtonProps {
  label: React.ReactNode;
  pathTo: string;
  onClick(): void;
}

export const BottomTabButton = (props: BottomTabButtonProps) => {
  const baseStyle = {
    width: '100%',
    padding: '20px',
    borderRadius: '20px',
  };

  const activeStyle = {
    ...baseStyle,
    backgroundColor: '#FFE4EB',
  };

  return (
    <NavLink to={props.pathTo} style={({ isActive }) => (isActive ? activeStyle : baseStyle)} onClick={props.onClick}>
      <Flex alignItems={'center'} justifyContent={'center'} h={'100%'} w={'100%'} userSelect={'none'}>
        {props.label}
      </Flex>
    </NavLink>
  );
};
